import "./add_campaign.css"
import { Dialog, DialogContent, DialogTitle, DialogActions } from "@material-ui/core";
import React,{useState,useEffect} from "react";
import { useHistory } from "react-router-dom";
import CancelIcon from '@material-ui/icons/Cancel';
import {Form, Row} from 'react-bootstrap'
import AddIcon from '@mui/icons-material/Add';
import InputAdornment from '@material-ui/core/InputAdornment';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import RemoveIcon from '@mui/icons-material/Remove';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InputLabel from '@material-ui/core/InputLabel';

import {makeStyles} from '@material-ui/core/styles';
import { create_campaign, get_suppliers } from "../../../../services/for_allServices";



const useStyles = makeStyles((theme) => ({
    indeterminateColor: {
      color: "#f50057"
    },
    selectAllText: {
      fontWeight: 500
    },
    selectedAll: {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)"
      }
    }
  }));
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    variant: "menu"
  };



const Add_campaign = ({campaigns,setcampaigns,forms_types,forms_models,add_campaign_model,setadd_campaign_model,update_campaign_model,setupdate_campaign_model})=>{

    const classes = useStyles();
    let user = JSON.parse(localStorage.getItem('user-info'));

    const [code, setcode] = useState("");
    const [title, settitle] = useState("");
    const [form_model, setform_model] = useState("null");
    const [form_type, setform_type] = useState("null");
    const [type_form_models, settype_form_models] = useState([]);

    const [suppliers, setsuppliers] = useState([]);
    const [selected, setSelected] = useState([]);
    const [selectedId, setSelectedId] = useState([]);

    const [errorText, setErrorText] = useState(null);
    const [validation_errorText, setvalidation_errorText] = useState("");
    const [wait_submitting, setwait_submitting] = useState(false);


    useEffect(()=>{
        if(update_campaign_model!=false){
            let tmp_array = [];
            /*fournisseursEdit.split(',').map((fournisseurId) => {
              suppliers.map((supplier) => {
                if(supplier.id == fournisseurId) tmp_array.push(supplier.nom);
              });
            });
            setSelected(tmp_array);
            setSelectedId(fournisseursEdit.split(','));*/
          }

          get_suppliers().then((response)=>{
            setsuppliers(response.data);
          }).catch((error)=>{
            //console.log(error.response);
          });

    },[]);

    const setforms_model_types = async (value)=>{
        let tmp_type_form_models = [...forms_models];
        tmp_type_form_models = tmp_type_form_models.filter((elem) => {
            return elem.form_type == value
        });
        await settype_form_models(tmp_type_form_models);
        await setform_type(value);
    }


    function fournisseurId_parNom(noms){
        let id=[];
        suppliers.map((supplier) => {
          noms.map((nom) => {
            if(supplier.nom == nom) id.push(supplier.id);
          });
        });
        return id;
      }


    const isAllSelected =
        suppliers.length > 0 && selected.length === suppliers.length;

    const handleChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
          let tmp_array = [];
          let tmp_id_array = [];
          suppliers.map((supplier) => {
              tmp_array.push(supplier.nom);
              tmp_id_array.push(supplier.id);
          })
          setSelected(selected.length === suppliers.length ? [] : tmp_array);
          setSelectedId(selected.length === suppliers.length ? [] : tmp_id_array);
          return;
        }
        setSelected(value);
        //console.log(value);
        setSelectedId(fournisseurId_parNom(value));
    };



    function submit(){
        setErrorText('');
        setvalidation_errorText('');
        let error_tmp="";
        if(code!="" && form_type!="null" && form_model!="null" && title!=""){
            if(error_tmp==""){
                setwait_submitting(true);
                let formData = new FormData();
                formData.append("code",code);
                formData.append("title",title);
                formData.append("form_type",form_type);
                formData.append("form_model",form_model);
                formData.append("appraisees",selected);
                formData.append("id_appraiser",user.id);
                create_campaign(formData).then((response)=>{
                    console.log(response.data);
                    let campaigns_tmp = [...campaigns];
                    campaigns_tmp.unshift(response.data);
                    setcampaigns(campaigns_tmp);
                    setadd_campaign_model(false);
                }).catch((error)=>{
                    //console.log(error.response);
                    let data = error.response.data;
                    if(data.errors){
                        let validation_errors = data.errors;
                        let errorMsg = "";
                        for(var key in validation_errors){
                            console.log(key);
                            if(key=="code" && errorMsg==""){
                                errorMsg="code"; 
                                setErrorText('Code déjà existant !'); 
                                setvalidation_errorText("Code déjà existant !");
                            }
                            if(key=="title" && errorMsg==""){
                                errorMsg="title"; 
                                setErrorText('Titre déjà existant !'); 
                                setvalidation_errorText("Titre déjà existant !");
                            }
                        }
                    }
                    setwait_submitting(false);
                })
            }
        }else{
            setErrorText('Champs invalides !');
        }
    }

    return (
        <Dialog fullWidth={true} maxWidth={'md'} open={add_campaign_model} onClose={()=>{setadd_campaign_model(false);setupdate_campaign_model(null)}} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">
          {update_campaign_model?"Modifier la campaign":"Ajouter une campaign"}
        </DialogTitle>
        <DialogContent>
        <div className="addupdate_campaign_form_container" >
                <Row className="mb-12">
                    <Form.Group className="col-md-6 simple_input">
                        <label>Code</label>
                        <Form.Control
                            required
                            autoComplete="new-password"
                            type="text"
                            placeholder="Code *"
                            max={10}
                            value={code}
                            onChange={ e =>  {setcode(e.target.value)}}
                            className={ errorText && (validation_errorText.includes("Code") || code==="") ? "is-invalid":""}
                        />
                    </Form.Group>
                    <Form.Group className="col-md-6 simple_input">
                        <label>Titre</label>
                        <Form.Control
                            required
                            autoComplete="new-password"
                            type="text"
                            placeholder="Titre *"
                            max={10}
                            value={title}
                            onChange={ e =>  {settitle(e.target.value)}}
                            className={ errorText && (validation_errorText.includes("Titre") || title==="") ? "is-invalid":""}
                        />
                    </Form.Group>
                </Row>  

                <Row className="mb-12">

                    <div className="col-md-6 simple_input">
                        <label>Type du formulaire</label>
                        <Form.Select
                            required
                            value={form_type}
                            onChange={e => {
                                setforms_model_types(e.target.value);
                            }}
                            className={ errorText && form_type==="null" ? "is-invalid":""}
                            name="form_type"
                        >
                            <option value={"null"} disabled selected>Type du formulaire</option>
                            <>
                            {
                                forms_types.map((model_forms_type,ind) => (
                                    <option value={model_forms_type.id} key={"form_type"+ind}>{model_forms_type.title}</option>
                                ))
                            }
                            </>
                        </Form.Select>
                    </div>

                    <div className="col-md-6 simple_input">
                        <label>Modèle de formulaire</label>
                        <Form.Select
                            required
                            disabled={form_type!="null"?false:true}
                            value={form_model}
                            onChange={e => {
                                setform_model(e.target.value);
                            }}
                            className={ errorText && form_type==="null" ? "is-invalid":""}
                                name="form_model" >
                            <option value={"null"} disabled selected>Modèle de formulaire</option>
                            <>
                            {
                                type_form_models.map((forms_model,ind) => (
                                    <option value={forms_model.id} key={"form_model"+ind}>{forms_model.title}</option>
                                ))
                            }
                            </>
                        </Form.Select>
                    </div>

                </Row>        

                
                <Row className="mb-12">
                    <div className="col-md-6 simple_input">
                    <label>Fournisseurs</label>
                    <FormControl className="multi_form_select">
                        <Select
                            labelId="mutiple-select-label"
                            multiple
                            disabled={form_model=="null"?true:false}
                            value={selected}
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                        >
                            <MenuItem
                                value="all"
                                classes={{
                                    root: isAllSelected ? classes.selectedAll : ""
                                }}
                            >
                            <ListItemIcon>
                                <Checkbox
                                    classes={{ indeterminate: classes.indeterminateColor }}
                                    checked={isAllSelected}
                                    indeterminate={
                                        selected.length > 0 && selected.length < suppliers.length
                                    }
                                />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ primary: classes.selectAllText }}
                                primary="Select All"
                            />
                            </MenuItem>
                            {
                            suppliers.map((option) => (
                                <MenuItem key={option.id} value={option.nom}>
                                    <ListItemIcon>
                                    <Checkbox checked={selected.indexOf(option.nom) > -1} />
                                    </ListItemIcon>
                                    <ListItemText primary={option.nom} />
                                </MenuItem>
                            ))
                            }
                        </Select>
                        </FormControl>
                    </div>
                </Row>   
        </div>
        </DialogContent>
        <DialogActions>
          <div className='dialogBottom'>
            <div>
                {
                    errorText === ""
                    ?<></>
                    :<p className={errorText ? "col-md-10 error" : "col-md-10 valid-champ"}>
                            {errorText}
                        </p>
                }
            </div>
            <div>
                <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon />} onClick={()=>{setadd_campaign_model(false);setupdate_campaign_model(null)}} >
                    Annuler
                </Button>
                <Button variant="contained" className="submit_but valide_but" startIcon={<AddIcon />} onClick={submit} >
                    {update_campaign_model?"Modifier":"Ajouter"}
                </Button>  
            </div>
               
          </div>
        </DialogActions>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait_submitting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    );
}

export default Add_campaign;