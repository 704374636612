import '../../../../assets/css/request_details.css'
import React, { useState , useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges';

import { Accordion } from 'react-bootstrap';

import Loading_animation_view from '../../../loading_animation_view/loading_animation_view';
import { get_applicant_request_details } from '../../../../services/purchase_service';
import { get_order_productsInfos, download_order_file } from '../../../../services/for_allServices';
import Order_details_modal from './order_details/order_details';
import Catalogue_modal from './catalogue_modal/catalogue_modal';
import Products_gallery_modal from './products_gallery/products_gallery';
import View_refuse_reason from '../view_refuse_reason/view_refuse_reason';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }));
  

function Request_detail({title,details}){
    return (
      <div className="requester_details_container">
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <div className='accordion_header_content'> {title} </div>
                </Accordion.Header>
                <Accordion.Body>
                <div className="order_form_details_container">   
                 <div className="order_form_infos_container">
                    <div className='order_form_infos_title'> 1 - Infos personnel</div>
                    <Row className="mb-12">
                            <Form.Group className="col-6">
                                <label>Nom</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Nom *"
                                    value={details.requester_lastName}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Prénom</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Prénom *"
                                    value={details.requester_firstName}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Département</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Département *"
                                    value={details.requester_departement}
                                    readOnly
                                />           
                            </Form.Group>
                            <Form.Group className="col-6"> </Form.Group>
                            <Form.Group className="col-6">
                                <label>Email</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="email"
                                    placeholder="Email *"
                                    value={details.requester_email}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Numéro de téléphone</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Numéro de téléphone *"
                                    value={details.requester_phoneNumber}
                                    readOnly
                                />
                            </Form.Group>
                    </Row>
                    <div className='order_form_infos_title'>2 - Infos de livraison</div>
                    <Row className="mb-12">
                            <Form.Group className="col-6">
                                <label>Pays</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Pays *"
                                    value={details.delivery_country}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Ville</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Ville *"
                                    value={details.delivery_city}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Adresse</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Adresse *"
                                    value={details.delivery_address}
                                    readOnly
                                />           
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Code postal</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Code postal *"
                                    value={details.delivery_postCode}
                                    readOnly
                                />           
                            </Form.Group>

                            <Form.Group className="col-6">
                                <label>Date de livraison souhaitée</label>
                                <Form.Control
                                    type="date"
                                    min={(new Date()).toISOString().split('T')[0]}
                                    placeholder="Date de livraison souhaitée *"
                                    value={details.delivery_supposedDate}
                                    readOnly
                                />
                            </Form.Group>
                    </Row>
                    <div className='order_form_infos_title'>3 - Autres informations</div>
                    <Row className="mb-12">
                            <Form.Group className="col-sm-12">
                                <label>Projet</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    rows={3}
                                    placeholder="Projet *"
                                    value={details.project}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="col-sm-12">
                                <label>Objet de la demande</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    as="textarea" rows="3" 
                                    placeholder="Objet de la demande *"
                                    value={details.object}
                                    readOnly
                                />
                            </Form.Group>
                      </Row>
                    </div>
                </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
     </div>
    )
}

function state(request_state){
    return(
      <>
        {
         request_state === null || request_state === "demander à être changée"
         ?<Button variant="contained" className="status_waiting">{request_state?request_state:'En cours'}</Button>
         :request_state === "refusée" || request_state === "validation refusée" || request_state === "annulée" || request_state === "non conforme"
            ?<Button variant="contained" className="status_deny">{request_state}</Button>
            :request_state === "sous réserve de validation"
              ?<Button variant="contained" className="status_waiting">{request_state}</Button>
              :<Button variant="contained" className="status_success">{request_state}</Button>
        }
      </>
  
    );
  }

function Order_detail({title,order_number,id_supplier,request_code,supplier,details,
        products_bysupplier,setProducts_bysupplier,order_key,open_products_gallery_changeSupplier}){

    const [chosen_supplier,setChosen_supplier] = useState(null);
    const [open_reason_modal,setOpen_reason_modal]=useState(null);

    function download_order_details_file(e,request_code,id_supplier){
        e.stopPropagation();
        let request_code_tmp_array = request_code.split(' - ');
        const formData = new FormData();
        formData.append('id_supplier',id_supplier);
        formData.append('request_code',request_code);
        download_order_file(formData).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', request_code_tmp_array[0]+"_"+request_code_tmp_array[1]+"_"+id_supplier+".pdf");
            document.body.appendChild(fileLink);
            fileLink.click();
        });
    }

    return (
      <div>
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                    <div className='order_accordion_header'>
                      <div className='accordion_header_content'>
                            <span>{title}</span>
                            <span className="supplier_infos">
                                {supplier}
                                <span onClick={(e) => {e.stopPropagation(); setChosen_supplier(id_supplier);}}><CollectionsBookmarkIcon className="my_icon" /></span> 
                            </span>
                        </div>
                        <div className='accordion_header_actions'>
                             <div className='request_state_container'>
                                {state(details.order_state)}
                                <>
                                    {
                                    details.order_state === "sous réserve de validation" || details.order_state === "refusée" || details.order_state === "validation refusée" || details.order_state === "annulée" || details.order_state === "non conforme"
                                    ?<span className='view_reason_button' onClick={(e) => { e.stopPropagation(); setOpen_reason_modal(details.service_refuse_reason && details.order_state !== "sous réserve de validation" && details.order_state !== "refusée" ?details.service_refuse_reason:details.refuse_reason)}}>Motif</span>
                                    :<></>
                                    }
                                </>
                            </div>
                            <span style={{display:'flex'}}>
                                <Tooltip title="Changer le fournisseur" placement="bottom" >
                                    <span style={{marginLeft:'8px'}}>
                                        <Fab size="small" onClick={(e) => open_products_gallery_changeSupplier(e,request_code,id_supplier)} color='default' style={{backgroundColor:'#29c981',color:'white'}} className={details.order_state!=="refusée"?"round_button disabled_button":"round_button"} >
                                            <PublishedWithChangesIcon className="my_icon" />
                                        </Fab>
                                    </span>
                                </Tooltip>
                                <Tooltip title="Exporter en fichier pdf" placement="bottom" >
                                    <span style={{marginLeft:'6px'}}>
                                    <Fab size="small" onClick={(e) => download_order_details_file(e,request_code,id_supplier)} color='default' style={{backgroundColor:'#efefef'}} className={details.order_state!=null  && details.order_state!=="annulée" 
                                                                                        && details.order_state!=="refusée" && details.order_state!=="validation refusée" && details.order_state!=="sous réserve de validation"  ?"round_button":"round_button disabled_button"} >
                                        <GetAppRoundedIcon className="my_icon"/>
                                    </Fab>
                                    </span>
                                </Tooltip>
                            </span>
                            
                        </div>  
                    </div>
                    
                </Accordion.Header>
                <Accordion.Body>
                    <Order_details_modal selected_products_details={details}
                             request_code={request_code} products_bysupplier={products_bysupplier}
                             id_supplier={id_supplier} setProducts_bysupplier={setProducts_bysupplier}
                             order_key={order_key} order_number={order_number} />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>

        {
            open_reason_modal!==null
            ?<View_refuse_reason open_reason_modal={open_reason_modal} setOpen_reason_modal={setOpen_reason_modal} />
            :<></>
        }
        {
            chosen_supplier!==null
            ?<Catalogue_modal setChosen_supplier={setChosen_supplier} chosen_supplier={chosen_supplier}/>
            :<></>
        }
        
     </div>
    )
}

function Request_details({request_code,setRequest_code}){

    const [products_bysupplier,setProducts_bysupplier]=useState(null);
    const [details,setDetails]=useState(null);
    const [wait_submitting,setWait_submitting]=useState(false);

    const [order_count,setOrder_count]=useState(1);
    const [changeSupplier,setChangeSupplier]=useState(null);
    const [changeSupplier_done,setChangeSupplier_done]=useState(null);
    
    const classes = useStyles();


    async function get_replacement(tmp_products_bysupplier,replaced_by,selected_products_tmp_array){
        var replaced_by_tmp = replaced_by;
        let replaced_by_array = replaced_by_tmp.split(',');
        var mergedObject={};
        for(let i in replaced_by_array){
            if(replaced_by_array[parseInt(i)]!==""){
                let formData = new FormData();
                formData.append('products_codes',JSON.stringify(selected_products_tmp_array));
                formData.append('request_code',request_code);
                formData.append('id_supplier',parseInt(replaced_by_array[parseInt(i)]));
                mergedObject = await get_order_productsInfos(formData).then((response)=>{
                    let data = response.data;
                    for(const id_supplier_name in data){
                        tmp_products_bysupplier[id_supplier_name] = response.data[id_supplier_name];
                        tmp_products_bysupplier[id_supplier_name]["replaced_by"] = response.data[id_supplier_name].replaced_by?response.data[id_supplier_name].replaced_by:"";
                        tmp_products_bysupplier[id_supplier_name]["order_state"] = response.data[id_supplier_name].order_state;
                        tmp_products_bysupplier[id_supplier_name]["service_accord"] = response.data[id_supplier_name].service_accord;
                        tmp_products_bysupplier[id_supplier_name]["products"] = data[id_supplier_name].products;
                        if(response.data[id_supplier_name].replaced_by) get_replacement(tmp_products_bysupplier,replaced_by,selected_products_tmp_array);;
                    }
                    return tmp_products_bysupplier;
                });
            }
        }
        return mergedObject;
    }


    useEffect(()=>{
        setDetails(null);
        setProducts_bysupplier(null);

        async function get_orders_products(orders,request_code){
            var tmp_products_bysupplier = {};
            var mergedObject = {};
            for(const id_supplier in ((orders))){
                let selected_products = orders[id_supplier];
                let selected_products_tmp_array = [];
                selected_products.map((selected_product) => {
                    selected_products_tmp_array.push(selected_product.product_code);
                })
                //console.log(selected_products_tmp_array);

                let formData = new FormData();
                formData.append('products_codes',JSON.stringify(selected_products_tmp_array));
                formData.append('request_code',request_code);
                formData.append('id_supplier',id_supplier);
                //console.log(request_code,id_supplier);
                var tmp_add_products_bysupplier = {};
                var selected_products_details_tmp_array = [];
                var replaced_by = null;
                tmp_products_bysupplier = await get_order_productsInfos(formData).then((response)=>{
                    let data = response.data;
                    //console.log(data);
                    for(const id_supplier_name in data){
                        data[id_supplier_name].products.map((element,id) => {
                            selected_products.map((selected_product,id) => {
                                if(selected_product.product_code === element.product_code){
                                        element.product_amount = element.product_amount?element.product_amount:selected_product.product_amount;
                                } 
                            })
                            selected_products_details_tmp_array.push(element);
                        });
                        tmp_products_bysupplier[id_supplier_name] = response.data[id_supplier_name];
                        tmp_products_bysupplier[id_supplier_name]["replaced_by"] = response.data[id_supplier_name].replaced_by?response.data[id_supplier_name].replaced_by:"";
                        tmp_products_bysupplier[id_supplier_name]["order_state"] = response.data[id_supplier_name].order_state;
                        tmp_products_bysupplier[id_supplier_name]["service_accord"] = response.data[id_supplier_name].service_accord;
                        tmp_products_bysupplier[id_supplier_name]["products"] = selected_products_details_tmp_array;
                        replaced_by = response.data[id_supplier_name].replaced_by?response.data[id_supplier_name].replaced_by:"";
                        return tmp_products_bysupplier;
                    }
                }).catch((error)=>{
                    //console.log(error.response);
                });
                const replacemment =  await get_replacement(tmp_products_bysupplier,replaced_by,selected_products_tmp_array);
                if(replacemment && Object.keys(replacemment).length ){
                    mergedObject = {
                        ...mergedObject,
                        ...replacemment
                    };
                }else{
                    mergedObject = {
                        ...mergedObject,
                        ...tmp_products_bysupplier
                    };
                }
                //console.log(mergedObject);
            }
            setProducts_bysupplier(mergedObject);
        } 


        async function load_data(){
            const formData = new FormData();
            formData.append('request_code',request_code);
            await get_applicant_request_details(formData).then((response)=>{
                //console.log(response.data);
                setDetails(response.data);
                get_orders_products(JSON.parse(response.data.products),request_code);
            }).catch((error)=>{
                //console.log(error.response);
            })  
        } 
        
       

        load_data();

    },[changeSupplier_done]);



    function open_products_gallery_changeSupplier(e,request_code,id_supplier){
        e.stopPropagation();
        let formData = new FormData();
        formData.append('request_code',request_code);
        formData.append('id_supplier',id_supplier);
        setChangeSupplier(formData);
    }

    
    function get_order_number(data,id){
        if(id===0){
            return id+1;
        }
        else{ 
            if(products_bysupplier[data[id-1]].replaced_by!==""){
                return id;
            }
            else{
                let max_id = 1;
                for(let j=0;j<id;j++){
                    max_id = Math.max(max_id,get_order_number(data,j));
                }
                return max_id+1;
            }
        }
    }


    return (
        <Dialog disableEnforceFocus fullScreen open={request_code?true:false} onClose={() => setRequest_code(null)} className="my_full_dialog contract_full_dialog">
            <AppBar className={classes.appBar}  >
                <Toolbar style={{backgroundColor:'#343a40'}} >
                    <div className='page_title'>
                        <IconButton edge="start" color="inherit" onClick={() => setRequest_code(null)} aria-label="close" >
                            <CloseIcon className="my_icon" />
                        </IconButton>
                        <span> Details de demande : {request_code} </span>
                    </div>
                </Toolbar>
            </AppBar>

          {
            details!==null && products_bysupplier!==null
            ?<div className="request_details_container">
                <Request_detail 
                    title="Détails" 
                    details={details} />
                <>
                {
                    Object.keys(products_bysupplier).length!==0
                    ?<>
                    {   Object.keys(products_bysupplier).map((key, id) => (
                             <Order_detail 
                                title={"Commande "+(get_order_number(Object.keys(products_bysupplier),id))+" / "}
                                order_number={get_order_number(Object.keys(products_bysupplier),id)}
                                id_supplier={key.split("*")[0]} 
                                supplier={key.split("*")[1]} 
                                request_code={request_code}
                                details={products_bysupplier[key]} products_bysupplier={products_bysupplier}
                                setProducts_bysupplier={setProducts_bysupplier}  order_key={key} key={key} open_products_gallery_changeSupplier={open_products_gallery_changeSupplier} />
                        ))   
                    }
                    </>
                    :<></>
                }
                </>
             </div>
            :<Loading_animation_view />
          }


        {
            changeSupplier!==null
            ?<Products_gallery_modal changeSupplier={changeSupplier} setChangeSupplier={setChangeSupplier}  setChangeSupplier_done={setChangeSupplier_done} />
            :<></>
        }
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait_submitting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        
     </Dialog>
    )
}

export default Request_details;
