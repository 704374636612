import './offers_table.css';
import React, { useEffect,useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import RadialCharts_offersState from '../radialCharts_offersState/radialCharts_offersState'
import {makeStyles} from '@material-ui/core/styles';


function offer_closed(stat){
    return(
      <>
        {
         stat !== 1
         ?<Button variant="contained" className="status_waiting">en cours</Button>
         :<Button variant="contained" className="status_success">clôturé</Button>
        }
      </>
  
    );
  }

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const columns = [
    { id: 'Objet', label: "Objet d'appel", minWidth: 0 },
    { id: 'direction', label: "Direction", minWidth: 0 },
    { id: 'categorie', label: "Famille d'achat", minWidth: 0 },
    { id: 'limiteDate', label: "Date limite", minWidth: 0 },
    { id: 'openingDate', label: "Date d'ouverture des plis", minWidth: 0 },
    { id: 'closed', label: "État de clôture", minWidth: 0 }
  ];
  

function Offers({offers,getCategorieById}){
    
    return(
        <>
        {
            offers.map((offer,index1) => (
                <TableRow key={offer.id_appel+"col"+index1}>
                    <TableCell align="left">{offer.object}</TableCell>
                    <TableCell align="left">{offer.direction}</TableCell>
                    <TableCell align="left">{getCategorieById(offer.familleAchat)}</TableCell>
                    <TableCell align="left">{(new Date(offer.dateLimite)).toLocaleString()}</TableCell>
                    <TableCell align="left">{(new Date(offer.dateOuvertureOffre)).toLocaleString()}</TableCell>
                    <TableCell align="center">
                        {offer_closed(offer.closed)}
                    </TableCell>
                </TableRow>                
             ))
        }
        </>
    );
}


const Offers_table = React.memo(({categories,data}) => {

    const classes = useStyles();
    const [show_elemnts,setShowing_elements] = useState(false);

    function getCategorieById(id){
        var categorie="";
        categories.map((catg)=>{
          if(catg.id_categorie == id) categorie = catg.nom;
        })
        return categorie;
    }



//////////////////////
/////////////////////////////////////////////////////////
///////////////////////////////////

  return (
    <>
    {
      show_elemnts!==true
        ?<RadialCharts_offersState setShowing_elements={setShowing_elements} elements_number={data.count} count_closed={data.count_closed} count_unclosed={data.count_unclosed} fontColor="rgb(104 56 211)" />
        :<TableContainer className="offers_table" component={Paper} onDoubleClick={() => setShowing_elements(false)} style={{cursor:'pointer'}} onTouchStartCapture={(e)=>{e.stopPropagation();setShowing_elements(false)}} >
            <Table className={classes.table} aria-label="sticky table">
                <TableHead>
                <TableRow>
                    {
                        columns.map((column) => (
                            <TableCell key={column.id} align="left">{column.label}</TableCell>
                        ))
                    }
                </TableRow>
                </TableHead>
                <TableBody>
                {
                    data.last_offers.length!==0
                    ?
                    <>
                        <Offers offers={data.last_offers} getCategorieById={getCategorieById} />
                    </>
                            
                    :<div style={{margin:"10px",fontSize:".75rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucun offre disponible</div>
                }
                </TableBody> 
            </Table>
        </TableContainer>
    }
    
      
    </>
    
  );
})

export default React.memo(Offers_table);
