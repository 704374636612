import '../../../../../assets/css/setContract_forSubmission.css';
import React, { useState, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Fab from '@mui/material/Fab';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DialogContent from '@material-ui/core/DialogContent';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import DialogTitle from '@material-ui/core/DialogTitle';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import Contract_content_view from './contract_content_view';
import { contract_check } from '../../../../../services/purchase_service';
import { get_contracts_types } from '../../../../../services/for_allServices';


function SetContract_forSubmission({setContract,handleCloseSetContrat,chosenSubmission,purchase_service}){

  const [contracts_types,setContracts_types] = useState(null);
  const [contract_type,setContract_type] = useState(null);
  const [contract_subtypes,setContract_subtypes] = useState(null);
  const [waiting,setWaiting] = useState(true);
        
  function ChooseTypeContrat(index,type) {
    setContract_type(type);
    setContract_subtypes(contracts_types[type]);
  }

  useEffect(()=>{

    contract_check(chosenSubmission.id_demande,"all").then((response)=>{
        if(response.data!==""){
          console.log(response.data);
          let rep = response.data;
          setContract_type(Object.keys(rep)[0]);
          setContract_subtypes(rep[Object.keys(rep)[0]]);
          setWaiting(false);
        }
        else{
          get_contracts_types().then((response)=>{
            console.log(response.data);
            setContracts_types(response.data);
            setWaiting(false);
          }).catch((error)=>{
            //console.log(error.response);
          })
        }
    }).catch((error)=>{
      //console.log(error.response);
    })
    
  },[])



    return (
     <>{
      waiting===true
      ?<Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 3 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        :<Dialog disableEnforceFocus fullWidth={true} maxWidth={'md'} open={setContract} onClose={handleCloseSetContrat} aria-labelledby="form-dialog-title" >
            <DialogTitle id="form-dialog-title">Choisissez le type de contrat </DialogTitle>
            {
              contract_type==null && contract_subtypes==null
              ? <DialogContent className="rateEdit" >
                  <div className='contract_type_Container'>
                    {
                      Object.keys(contracts_types).map((key, id)=>{
                        return ( 
                          <div className='contract_type'>
                                <Fab onClick={()=>ChooseTypeContrat(chosenSubmission,key)} key={id} size="small" color="secondary" style={{backgroundColor:'#ef233c'}}> <VisibilityIcon className="my_icon" /> </Fab>
                                <span className='documentsTitle'> {key} </span>
                            </div>
                          )
                        })
                    }
                  </div>
                  </DialogContent>
                :<></>
            }

            {
              contract_type!=null && contract_subtypes!=null
              ?<Contract_content_view contract_type={contract_type} contract_subtypes={contract_subtypes} close_contact_type={()=>{setContract_type(null);handleCloseSetContrat()}} chosenSubmission={chosenSubmission} purchase_service={purchase_service} />
              :<></>
            }
          </Dialog>
      }   
     </>
    );
}

export default SetContract_forSubmission;
