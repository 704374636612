import React, { useState , useEffect } from 'react'
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import './referencement.css'
import {makeStyles} from '@material-ui/core/styles';
import Fab from '@mui/material/Fab';
import DownloadIcon from '@mui/icons-material/Download';
import Stack from '@mui/material/Stack';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Form from 'react-bootstrap/Form'

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import SendIcon from '@material-ui/icons/Send';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import swal from 'sweetalert';

import { load_supplier_infos, upload_supplier_docs ,getSupplier_infos_byId} from '../../../services/supplier_service';
import { load_chat_fournisseur , get_categories , countmsg_non_lue_fournisseur, downloadBilanRC} from '../../../services/purchase_service';

import ChatView from './chatView/chatView';
import Identity_supplier from './identity_supplier_modal/identity_supplier_modal';
import Loading_animation_view from '../../loading_animation_view/loading_animation_view';



const columns = [
  { id: 'Nom', label: 'Nom', minWidth: 0 },
  { id: 'MaisonMere', label: "Maison mère", minWidth: 0 },
  { id: 'FamilleAchat', label: "Famille d'achat", minWidth: 0 },
  { id: 'Documents', label: "Documents", minWidth: 0 , align: 'left'},
  { id: 'Compte', label: "Compte", minWidth:  0, align: 'left' }
];


function setStatus(stat){
  return(
    <>
      {
       stat === null
       ?<Button variant="contained" className="status_waiting">{status[0]}</Button>
       :stat === 0
          ?<Button variant="contained" className="status_deny">{status[1]}</Button>
          :<Button variant="contained" className="status_success">{status[2]}</Button>
      }
    </>
  );
}

const useStyles = makeStyles((theme) => ({
  button: { margin: theme.spacing(1),},
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));




function getFileName(event) {
  const name = event.target.files[0].name;
  const lastDot = name.lastIndexOf('.');

  const fileName = name.substring(0, lastDot);
  const ext = name.substring(lastDot + 1);
  return fileName+"."+ext;
}

const status = ['En Cours','Refusé','Accepté'];


function states(supplier_infos){
  if(supplier_infos==null){
    return status[0];
  }else{
    if(supplier_infos===0) return status[1];
    else if(supplier_infos===1) return status[2];
  }
}


function Reference (){

  let user = JSON.parse(localStorage.getItem('user-info'));
  const id_fournisseur = user.id;

  const classes = useStyles();

  const [show,setShow]=useState(false);
  const [show_msg,setShow_msg]=useState(false);
  const [id_service,setId_service]=useState();

  const [fournisseur,setFournisseur]=useState([]);
  const [categories,setCategories]=useState([]);

  const [Bilan,setBilan]=useState(null);
  const [RC,setRC]=useState(null);
  const [bilanFileName,setBilanFileName]=useState("");
  const [rcFileName,setRCFileName]=useState("");

  const [bilanFileLoaded,setBilanFileLoaded]=useState("");
  const [rcFileLoaded,setRCFileLoaded]=useState("");

  const [waiting, setWaiting] = useState(false);
  const [supplier_account,setSupplier_account] = useState(null);
  const [supplier_reference,setSupplier_reference] = useState(null);

  useEffect(()=>{

    async function load_data(){
      await get_categories().then((response)=> {
        setCategories(response.data);
      }).catch((error)=>{
        //console.log(error.response);
      });
  
      await countmsg_non_lue_fournisseur(id_fournisseur,id_service).then((response)=>{
          //setMsg_non_lue(response.data);  
        }).catch((error) => {
          //console.log(error.response);
        });

        await load_supplier_infos(user.id).then((response)=> {
            setSupplier_account(response.data.values[0]);
            setSupplier_reference(response.data.referencements[0]);
            if(response.data.values[0].bilan != "null" ) setBilanFileName(fournisseur.bilan);
            if(response.data.values[0].RC != "null" ) setRCFileName(fournisseur.RC);
            setId_service(response.data.values[0].ajouterPar);
        }).catch((error) => {
            console.log(error);
        });
      }

      
    load_data();

  },[])

  //////////////////////
///////////////////////////////////

function viewSupplier_chat(id_fournisseur){ 
  setShow_msg(true);
}



//////////////////////
///////////////////////////////////
function viewSupplierIdentity(id_fournisseur){      
  getSupplier_infos_byId(id_fournisseur)
      .then(response => {
        setFournisseur(response.data.values[0]);
        setShow(true);
      }).catch(error => {
          //console.log(error.response);
      })     
}



//////////////////////
/////////////////////////////////////////////////////////
///////////////////////////////////

  function sendDoc(){
    swal({
      title: "Etes-vous sûr ?",
      text: "!!!!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {
      let formData = new FormData();
      formData.append('id_fournisseur',user.id);
      formData.append('bilan',Bilan);
      formData.append('RC',RC);
      formData.append('familleAchat',getCategorieById((supplier_account.familleAchat).split(',')));
      setWaiting(true);
      upload_supplier_docs(formData).then((response) => {
          setWaiting(false);
          swal("Le service sera bientôt notifié !", {
            icon: "success",
            buttons: true,
          }).then((willDelete) => {
              if (willDelete) { 
          }}) 
      }).catch((error) => {
        setWaiting(false);
        console.log(error,error.response);
      });
  }})
  }

  function download(index,choix){
    if(bilanFileLoaded!="" && choix=="bilan"){
        var fileLink = document.createElement('a');
        fileLink.href = bilanFileLoaded;
        fileLink.setAttribute('download', bilanFileName);
        document.body.appendChild(fileLink);
        fileLink.click();
    }else{

      if(rcFileLoaded!="" && choix=="RC"){
          var fileLink = document.createElement('a');
          fileLink.href = rcFileLoaded;
          fileLink.setAttribute('download', rcFileName);
          document.body.appendChild(fileLink);
          fileLink.click();
      }else{
          const formData = new FormData();
          formData.append('id_fournisseur',supplier_account.id);
          formData.append('choix',choix);
          downloadBilanRC(formData).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
              var fileLink = document.createElement('a');
              fileLink.href = fileURL;
              fileLink.setAttribute('download', supplier_account.id+"_"+choix+".pdf");
              document.body.appendChild(fileLink);
              fileLink.click();
          });
        }
    }
    
  }
  
  function upload_bilan(event){
    setWaiting(true);
    setBilan(event.target.files[0]);
    setBilanFileName(getFileName(event));
    setBilanFileLoaded(URL.createObjectURL(event.target.files[0]));
    setWaiting(false);
  }

  function upload_rc(event){
    setWaiting(true);
    setRC(event.target.files[0]);
    setRCFileName(getFileName(event));
    setRCFileLoaded(URL.createObjectURL(event.target.files[0]));
    setWaiting(false);

  }

  const getCategorieById = (categorie)=>{
    var tmp_categorie="";
    categories.map((catg)=>{
      categorie.map((id)=>{
        if(catg.id_categorie === parseInt(id)){
          if(tmp_categorie==="") tmp_categorie = tmp_categorie+catg.nom;
          else tmp_categorie = tmp_categorie+", "+catg.nom;
        }
      })
    })
    return tmp_categorie;
  }

  //////////////////////
/////////////////////////////////////////////////////////
///////////////////////////////////

  function createData(Nom, MaisonMere,FamilleAchat,Documents,Compte) {
    return { Nom, MaisonMere,FamilleAchat,Documents,Compte};
  }

  const rows = supplier_account!==null && supplier_reference!=null ? [
    [supplier_account].map((supplier_account, i) => createData(supplier_account.nom,supplier_account.maisonMere,getCategorieById((supplier_account.familleAchat).split(',')),
      <Stack direction="row" spacing={1} alignItems="center" >
        <div className='documentsContainer'>
             <div style={{display:'flex',marginBottom:'10px'}}>
              <div className='documentsDownload'>
                <label htmlFor="bilan"> 
                    <Fab size="small" component="span" color="default"> <CloudUploadIcon className="my_icon" /> </Fab>
                    <input accept="application/pdf" className={classes.input} name="bilan" id="bilan" multiple type="file" onChange={(e)=>upload_bilan(e)} />
                </label>
                {
                    bilanFileName!=""
                    ?<Fab size="small" className="download_button" color="default"><DownloadIcon className="my_icon"  onClick={()=>download(i,'bilan')}/> </Fab>
                    :<Fab size="small" className="download_button disabled_button" color="default"><DownloadIcon className="my_icon"  /></Fab>
                  
                  }
                <span className='documentsTitle'>Bilan</span>
              </div>
              <div className='documentsStatus_valide' >
                  { supplier_reference.remarquesBilan!==null ? setStatus(supplier_reference.bilan):setStatus(null)}
              </div>
            </div>


            <div style={{display:'flex',marginBottom:'8px'}}>
              <div className='documentsDownload'>
                  <label htmlFor="rc"> 
                     <Fab size="small" component="span" color="default"><CloudUploadIcon className="my_icon"  /> </Fab>
                        <input accept="application/pdf" className={classes.input} name="rc" id="rc" multiple type="file" onChange={(e)=>upload_rc(e)} />
                  </label>
                  {
                    rcFileName!=""
                    ?<Fab size="small" className="download_button" ><DownloadIcon className="my_icon" onClick={()=>download(i,'RC')}/> </Fab>
                    :<Fab size="small" className="download_button disabled_button" ><DownloadIcon className="my_icon" /></Fab>
                  
                  }
               
                  <span className='documentsTitle'>RC</span>
              </div>
                <div className='documentsStatus_valide'  >
                { supplier_reference.remarquesRC!==null ? setStatus(supplier_reference.RC):setStatus(null)}
              </div>
            </div>
        </div>
      </Stack>,

        <Stack direction="row" spacing={1} alignItems="center" >
          <div style={{display:'flex',width: '100%'}}>
            <div className='documentsDownload'>
              <Fab size="small" color="warning"><AssignmentIndIcon className="my_icon"  onClick={() => viewSupplierIdentity(supplier_account.id)}/></Fab>
              <span className='documentsTitle'>Fiche</span>
            </div>
            <div className='documentsStatus_valide'  >
                  { supplier_account.nouveaute!==0 ? setStatus(supplier_account.valider):setStatus(null)}
            </div>
        </div>
        </Stack>

      )
    )
  ]:[];


  return (
    <>{
      supplier_account!==null && supplier_reference!==null
      ?<div className="supplier_reference_container references_container" >
        <div className='references_header_container'>
          <div className='page_title'>Mon referencement</div>
          <div className='Msg_button_container'>
            <Button onClick={() => viewSupplier_chat(id_fournisseur)} variant="contained" className="submit_but"> 
                Discussion 
            </Button>
          </div>
        </div>
        <TableContainer style={{width:'99%'}}>
          <Table className={classes.table} aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="left">Nom Complet</TableCell>
                <TableCell align="left">Maison mère</TableCell>
                <TableCell align="left">Famille d'achat</TableCell>
                <TableCell align="left">Documents</TableCell>
                <TableCell align="left">Fiche d'identité</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <>
              {
                supplier_account!==null && supplier_reference!==null
                ?<>{
                  rows[0].map((row) => {
                    return (
                      <TableRow role="checkbox" tabIndex={-1} key={row.id_Fournisseur} >
                        {
                          columns.map((column) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {value}
                              </TableCell>
                            );
                          })
                        }
                      </TableRow>
                    );
                  })
                }</>
                :<></>
                }
              </>
              
            </TableBody> 
          </Table>
        </TableContainer>
        <div className="sendBut_conatainer">
          <Button onClick={() => sendDoc()} variant="contained" className="submit_but" endIcon={<SendIcon className="my_icon" />}> Envoyer </Button>
        </div>
        {
          show_msg!=false
            ?<ChatView show={show_msg} handleClose={() => setShow_msg(false)} id_creator={id_service} />
            :<></>
        }
        { 
          fournisseur.length!=0 
            ? <><Identity_supplier show={show} categories={categories}
                    handleClose={() =>{ setShow(false);setFournisseur([])}} supplier={fournisseur} /></>
            : <></>
        }

          <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={waiting}
                >
                    <CircularProgress color="inherit" />
            </Backdrop>

        </div>
      :<Loading_animation_view />
    }
    </>
  )

}

export default Reference