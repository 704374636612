import './contracts_table.css';
import React, { useEffect,useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import Widget_count_elements from '../widget_count_elements/widget_count_elements'
import {makeStyles} from '@material-ui/core/styles';


function submission_treated(stat){
    return(
      <>
        {
         stat == 0
         ?<Button variant="contained" className="status_deny">refusée</Button>
         :stat == 1
         ?<Button variant="contained" className="status_success">acceptée</Button>
         :<Button variant="contained" className="status_waiting">en cours</Button>
        }
      </>
  
    );
  }

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const columns = [
    { id: 'code', label: "Code de soumission", minWidth: 0 },
    { id: 'Objet', label: "Objet d'appel", minWidth: 0 },
    { id: 'supplier', label: "Fournisseur", minWidth: 0 },
    { id: 'contract_type', label: "Type de contrat", minWidth: 0 },
    { id: 'contract_subTypes', label: "Sous types de contrat", minWidth: 0 }
  ];
  

function Offers({offers,getCategorieById}){
    
    return(
        <>
        {
            offers.map((submission,index1) => (
                <TableRow key={submission.id_appel+"col"+index1}>
                    <TableCell align="left">{submission.submission_id}</TableCell>
                    <TableCell align="left">{submission.offer_object}</TableCell>
                    <TableCell align="left">{submission.supplier_name}</TableCell>
                    <TableCell align="left">{submission.contract_type}</TableCell>
                    <TableCell align="center">{submission.contract_subTypes}</TableCell>
                </TableRow>                
             ))
        }
        </>
    );
}


function Contracts_table({categories,data}) {

    const classes = useStyles();
    const [show_elemnts,setShowing_elements] = useState(false);


    function getCategorieById(id){
        var categorie="";
        categories.map((catg)=>{
          if(catg.id_categorie == id) categorie = catg.nom;
        })
        return categorie;
    }



//////////////////////
/////////////////////////////////////////////////////////
///////////////////////////////////

  return (
    <>
    {
      show_elemnts!==true
        ?<Widget_count_elements setShowing_elements={setShowing_elements} elements_number={data.count} fontColor="#51a5cf" progress={data.progress} />
        :<TableContainer className="contracts_widget_table" component={Paper} onDoubleClick={() => setShowing_elements(false)} style={{cursor:'pointer'}} onTouchStartCapture={(e)=>{e.stopPropagation();setShowing_elements(false)}} >
            <Table className={classes.table} aria-label="sticky table">
                <TableHead>
                <TableRow>
                    {
                        columns.map((column) => (
                            <TableCell key={column.id} align="left">{column.label}</TableCell>
                        ))
                    }
                </TableRow>
                </TableHead>
                <TableBody>
                <>
                    <Offers offers={data.last_offers} getCategorieById={getCategorieById} />
                </>
                </TableBody> 
            </Table>
        </TableContainer>
    }
    
      
    </>
    
  );
}

export default React.memo(Contracts_table);
