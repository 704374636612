import './add_product_modal.css'
import React, { useState } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@mui/icons-material/Add';


function Add_product_modal({open_add_product_modal,setOpen_add_product_modal,articles,setArticles}){


  const [errorText,setErrorText] = useState("");

  const [description,setdescription]=useState('');


  function submit_refuse_description() {
    setErrorText("");
    if(description===''){
        setErrorText("Champ vide !");
      }else{
        let tmp_array = [ ...articles ];
        tmp_array.push(description);
        setArticles(tmp_array);
        setdescription('');
        setOpen_add_product_modal(false);
      }     
   }

     

    return (
      <Dialog className="set_refuse_raison_container" fullWidth={true} maxWidth={'md'} open={open_add_product_modal} onClose={()=>setOpen_add_product_modal(false)} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">Décrivez l'article que vous souhaitez et sa quantité</DialogTitle>
        <DialogContent>
          <div>
                {
                    errorText === "" 
                    ?<></>
                    :<p className={errorText ? "error" : "valid-champ"}>
                            {errorText}
                        </p>
                }

              <div style={{width:"100%",marginTop:"5px"}} >
              <TextField
                  id="outlined-multiline-flexible"
                  label="Description"
                  variant='outlined'
                  multiline
                  rows={4}
                  value={description}
                  onChange={(e)=>setdescription(e.target.value)} 
                  style={{width:"100%"}}
                />
              </div>
          </div>
          
     </DialogContent>
        <DialogActions>
          <div className='dialogBottom'>
            <div>
              <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon className="my_icon" />} onClick={()=>setOpen_add_product_modal(false)} >
                  Annuler
              </Button>
              <Button variant="contained" className="submit_but valide_but" startIcon={<AddIcon className="my_icon"/>} onClick={submit_refuse_description} >
                  Ajouter
              </Button> 
            </div>
          </div>
        </DialogActions>
      </Dialog>
    )
}

export default Add_product_modal
