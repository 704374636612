import "../campaigns.css";
import react,{useState, useEffect} from "react";
import { useHistory } from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "react-js-pagination";
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Fab from '@mui/material/Fab';
import DeleteIcon from '@mui/icons-material/Delete';

import Loading_animation_view from "../../../loading_animation_view/loading_animation_view";
import Add_campaign_appraisees from "./add_campaign_appraisees/add_campaign_appraisees";
import { delete_appraisee_from_campaign, get_forms_by_appraiser, get_suppliers } from "../../../../services/for_allServices";
import Form_content from "./form_content/form_content";
import swal from "sweetalert";

const columns_form_campaigns = [
    { id: 'appraisee', label: "Evalué", minWidth: 0 },
    { id: 'form_model', label: "Modèle du formulaire", minWidth: 0 },
    { id: 'status', label: "Statut du formulaire", minWidth: 0 },
    { id: 'created_at', label: "Date de création", minWidth: 0 },
    { id: 'updated_at', label: "Date de modification", minWidth: 0 },
    { id: 'view', label: "Visualiser", minWidth: 0 },
  ];


  function setStatus(form_status){
    return(
      <>
        <span>
            {
                form_status==="Soumis"
                ?<Button className="status_success" >{form_status}</Button>
                :<Button className="status_waiting" >{"A effectuer"}</Button>
            }
        </span>
      </>
    );
  }

const Campaign_details = ({view_campaign_details,setview_campaign_details,forms_models}) => {

    let user = JSON.parse(localStorage.getItem('user-info'));
    const [url_updated , setUrl_updated] = useState(null);
    let history = useHistory();

    const [appraisees,setappraisees] = useState(null);
    const [campaign_type,setcampaign_type] = useState("default");

    const [waiting, setWaiting] = useState(false);
    const [currentPage,setCurrentPage]=useState(1);
    const [dataPerPage,setdataPerPage] =useState(6);
    const [data_count,setdata_count]=useState(0);
    
    const [add_campaign_appraisees,setadd_campaign_appraisees] = useState(null);
    const [view_appraisee_form_content,setview_appraisee_form_content] = useState(null);
    const [selected_appraisee_infos,setselected_appraisee_infos] = useState(null);

    const [suppliers,setsuppliers] = useState(null);

    useEffect(()=>{
        setsuppliers(null);
        setappraisees(null);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let tmp_id_appraisee = null; 
        let view_model_tmp = null; 

        async function load_data(){
            tmp_id_appraisee = urlParams.get('appraisee')?urlParams.get('appraisee'):null;
            view_model_tmp = urlParams.get('model')=="editor"?true:false;

            const tmp_suppliers = await get_suppliers().then((response)=>{
                return (response.data);
            }).catch((error)=>{
                //console.log(error.response);
            });
            const tmp_appraisees = await get_forms_by_appraiser(user.id,view_campaign_details.id).then((response)=>{
                return (response.data);
            }).catch((error)=>{
                //console.log(error.response);
            });

            if(tmp_id_appraisee && view_model_tmp){
                const tmp_appraisee = tmp_appraisees.filter((elem)=>{
                    return elem.id_appraisee == tmp_id_appraisee;
                })
                setview_appraisee_form_content(tmp_appraisee[0]);
                const tmp_appraisee_infos = tmp_suppliers.filter((elem)=>{
                    return elem.id == tmp_id_appraisee;
                })
                setselected_appraisee_infos(tmp_appraisee_infos[0]);
            }else{
                urlParams.delete("appraisee");
                urlParams.delete("model");
                history.replace({
                    search: urlParams.toString(),
                  });
                await setsuppliers(tmp_suppliers);
                await setappraisees(tmp_appraisees);
            }
           
        }
        load_data();

    },[url_updated]);

    function select_campaign_type(value){
        console.log(value);
    }

    const get_form_model_title_byID = (id)=>{
        let tmp_title = "";
        forms_models.forEach((forms_model) => {
            if(forms_model.id == id) tmp_title = forms_model.title;
        })
        return tmp_title;
    }

    const set_appraisee_fullName = (id_appraisee) => {
        const tmp_supplier = suppliers.filter((elem)=>{
            return elem.id == id_appraisee;
        });
        return tmp_supplier[0].nom;
    }

    function open_form_model(appraisee){
        const path = history.location.pathname;
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        let id_appraisee = urlParams.get("appraisee");
        if(id_appraisee) urlParams.set("appraisee",appraisee.id_appraisee);
        else urlParams.append("appraisee",appraisee.id_appraisee);
        urlParams.append("model","editor");
        history.replace({
            search: urlParams.toString(),
        });
        setUrl_updated(urlParams.toString());
    }

    async function delete_appraisee(appraisee,id){
        swal({
            title: "Etes-vous sûr ?",
            text: "!!!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
            setWaiting(true);
            delete_appraisee_from_campaign(appraisee.id_campaign,appraisee.id_appraisee).then((response)=>{
                console.log(response.data);
                let tmp_appraisees = [...appraisees];
                tmp_appraisees.splice(id,1);
                setappraisees(tmp_appraisees);
                setWaiting(false);
            }).catch((error)=>{
                //console.log(error.response);
                setWaiting(false);
            })
          }
        })
    }

    async function change_page(page){
        await setCurrentPage(page);
        let path = history.location.pathname;
        let params = history.location.search;
        let search_params = params.split('&');
        let tmp_params = path;
        if(!params) history.push(path+"?page="+page);
        else{
            for(let i in search_params){
            if(search_params[i].includes("page=")){
                if(i==0) tmp_params=tmp_params+"?page="+page;
                else tmp_params=tmp_params+"&page="+page;
            }else{
                if(i==0) tmp_params=tmp_params+search_params[i];
                else tmp_params=tmp_params+"&"+search_params[i];
            }
            }
            if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
            history.push(tmp_params);
        }
        await setUrl_updated(page);
    }


    return (
        <>
        {
            view_campaign_details==null || appraisees==null || suppliers==null
            ?<Loading_animation_view />
            :<div className='campaigns_list_container'>
                <div className='campaigns_list_container_header'>
                        <div className='title_container'>
                            <label>{view_campaign_details.title+" - Details"}</label>
                            <Tooltip title="Ajouter un évalué">
                                <Fab className={waiting ? "add_new_object_fab disabled_button":"add_new_object_fab"} >
                                    <AddIcon className="add_new_object_icon" onClick={()=>setadd_campaign_appraisees(view_campaign_details)} />
                                </Fab>
                            </Tooltip>
                        </div>
                        <div className='select_container'>
                            <FormControl style={{width:"225px",marginRight:'20px'}} >
                                <InputLabel>Statut du formulaire</InputLabel>
                                    <Select required
                                        value={campaign_type}
                                        onChange={e => {
                                            select_campaign_type(e.target.value);
                                        }} name="Etat" >
                                        <MenuItem value={"default"}>
                                            Tous les formulaires
                                        </MenuItem>
                                            <MenuItem value="active">Actif</MenuItem>
                                            <MenuItem value="inactive">Inactif</MenuItem>
                                    </Select>
                            </FormControl>
                        </div>
                </div>

                    <div>
                        <TableContainer className="campaigns_list_table">
                            <Table aria-label="sticky table">
                                <TableHead>
                                <TableRow>
                                    {
                                        columns_form_campaigns.map((column) => (
                                            <TableCell key={column.id} align="left">{column.label}</TableCell>
                                        ))
                                    }
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    appraisees.length!==0
                                    ?
                                    <>
                                    {
                                        appraisees.map((appraisee,id) => (
                                            <TableRow key={"appraisee"+id}>
                                                <TableCell align="left">
                                                    <div style={{width:'fit-content'}}>
                                                        {set_appraisee_fullName(appraisee.id_appraisee)}
                                                        <div style={{width:'100%',display:'flex'}}>
                                                            <Tooltip title="Supprimer" placement="top" >
                                                                <span className={"closeSharpIcon"} >
                                                                        <DeleteIcon className="my_icon" style={{cursor:'pointer'}}
                                                                            onClick={()=>delete_appraisee(appraisee,id)} />
                                                                </span>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="left">{get_form_model_title_byID(appraisee.form_model)}</TableCell>
                                                <TableCell align="left">{setStatus(appraisee.status)}</TableCell>
                                                <TableCell align="left">{(new Date(appraisee.created_at)).toLocaleString()}</TableCell>
                                                <TableCell align="left">{(new Date(appraisee.updated_at)).toLocaleString()}</TableCell>
                                                <TableCell align="left">
                                                    <VisibilityIcon className="editIcon" onClick={()=>open_form_model(appraisee)}  />
                                                </TableCell>
                                            </TableRow>                
                                        ))
                                    }
                                    </>
                                    :<div style={{margin:"10px",fontSize:".8rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucun évalué disponible</div>
                                }
                                </TableBody> 
                            </Table>
                        </TableContainer>
                        {
                            appraisees.length!==0
                            ?<div className="pagination_container">
                                <Pagination
                                    activePage={Number(currentPage)}
                                    itemsCountPerPage={dataPerPage}
                                    totalItemsCount={Number(data_count)}
                                    pageRangeDisplayed={4}
                                    onChange={change_page.bind(this)}
                                />
                            </div>
                            :<></>
                        }
                    </div>
                </div>
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {
                add_campaign_appraisees!=null
                ?<Add_campaign_appraisees add_campaign_appraisees={add_campaign_appraisees} 
                    setadd_campaign_appraisees={setadd_campaign_appraisees} suppliers={suppliers}
                    appraisees={appraisees} setappraisees={setappraisees} />
                :<></>
            }
            {
                view_appraisee_form_content!=null && selected_appraisee_infos!=null
                ?<Form_content view_appraisee_form_content={view_appraisee_form_content} forms_models={forms_models} selected_appraisee_infos={selected_appraisee_infos}
                                setview_appraisee_form_content={setview_appraisee_form_content} setselected_appraisee_infos={setselected_appraisee_infos} />
                :<></>
            }
        </>
    );

};

export default Campaign_details;