import './submissions_table.css';
import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';

import {makeStyles} from '@material-ui/core/styles';
import RadialCharts_submissionsState from '../radialCharts_submissionsState/radialCharts_submissionsState';

function submission_treated(stat){
    return(
      <>
        {
         stat == 0
         ?<Button variant="contained" className="status_deny">refusée</Button>
         :stat == 1?<Button variant="contained" className="status_success">adjugée</Button>
                    :<Button variant="contained" className="status_waiting">en cours</Button>
        }
      </>
  
    );
  }

  

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const columns = [
    { id: 'objet', numeric: false, disablePadding: false, label: 'Objet d\'achat',align: 'left' },
    { id: 'score_financier', numeric: true, disablePadding: false, label: 'Score financier',align: 'left'  },
    { id: 'score_technique', numeric: true, disablePadding: false, label: 'Score technique' ,align: 'left' },
    { id: 'dateDemande', numeric: true, disablePadding: false, label: 'Date de soumission' ,align: 'left' },
    { id: 'accord', numeric: true, disablePadding: false, label: 'Accord',align: 'center'}
];

function Submissions({submissions}){
    
    return(
        <>
        {
            submissions.map((submission,index1) => (
                <TableRow key={submission.id_appel+"last_submission_col"+index1}>
                    <TableCell align="left">{submission.object}</TableCell>
                    <TableCell align="center">
                            {submission.score_financier}
                    </TableCell>
                    <TableCell align="center">
                            {submission.score_technique}
                    </TableCell>
                    <TableCell align="left">{(new Date(submission.date)).toLocaleString()}</TableCell>
                    <TableCell align="center">
                        {submission_treated(submission.accord)}
                    </TableCell>
                </TableRow>                
             ))
        }
        </>
    );
}


const Last_submissions = ({categories,data}) => {

    const classes = useStyles();
    const [show_elemnts,setShowing_elements] = useState(false);

    function getCategorieById(id){
        var categorie="";
        categories.map((catg)=>{
            if(catg.id_categorie == id) categorie = catg.nom;
        })
        return categorie;
    }

  return (
    <>
    {
      show_elemnts!==true
        ?<RadialCharts_submissionsState setShowing_elements={setShowing_elements} elements_number={data.count} count_accorded={data.count_accorded} count_waiting={data.count_waiting} count_lost={data.count_lost} fontColor="rgb(104 56 211)" />
        :<TableContainer className="submissions_table" component={Paper} onDoubleClick={() => setShowing_elements(false)} style={{cursor:'pointer'}} onTouchStartCapture={(e)=>{e.stopPropagation();setShowing_elements(false)}} >
            <Table className={classes.table} aria-label="sticky table">
                <TableHead>
                <TableRow>
                    {
                        columns.map((column) => (
                            <TableCell key={column.id} align={column.align}>{column.label}</TableCell>
                        ))
                    }
                </TableRow>
                </TableHead>
                <TableBody>
                {
                    data.last_submissions.length!=0
                    ?
                    <>
                        <Submissions submissions={data.last_submissions} getCategorieById={getCategorieById} />
                    </>
                            
                    :<div style={{margin:"10px",fontSize:".8rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucun offre disponible</div>
                }
                </TableBody> 
            </Table>
        </TableContainer>
    }
    </>
  );
}

export default React.memo(Last_submissions);
