import './emails_sent.css';
import React, { useEffect,useState } from 'react';
import { useHistory } from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "react-js-pagination";
import Fab from '@mui/material/Fab';
import VisibilityIcon from '@mui/icons-material/Visibility';

import {makeStyles} from '@material-ui/core/styles';
import Loading_animation_view from '../../loading_animation_view/loading_animation_view'
import Send_email from './send_email/send_email';
import { get_user_emails_sent, search_users_byname_or_email_forServ  } from '../../../services/for_allServices';
import Email_details from './email_details/email_details';




const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const columns = [
    { id: 'recipients', label: "Destinataires", minWidth: 0 },
    { id: 'object', label: "Objet d'email", minWidth: 0 },
    { id: 'date', label: "Date d'envoie", minWidth: 0 },
    { id: 'date', label: "Détail d'email", minWidth: 0 },
  ];
  


function Emails({emails_sent,setOpen_email_details}){
    
    return(
        <>
        {
            emails_sent.map((email_sent,id) => (
                <TableRow key={"email"+id}>
                    <TableCell align="left">{email_sent.recipients}</TableCell>
                    <TableCell align="left">{email_sent.object}</TableCell>
                    <TableCell align="left">{new Date(email_sent.created_at).toLocaleString()}</TableCell>
                    <TableCell align="left">
                        <Fab onClick={()=>setOpen_email_details(email_sent)} size="small" color="secondary" style={{backgroundColor:'#ef233c'}}>
                            <VisibilityIcon className="my_icon" />
                        </Fab>
                    </TableCell>
                </TableRow>                
             ))
        }
        </>
    );
}



function Emails_sent() {

    const classes = useStyles();
    const [url_updated , setUrl_updated] = useState(null);
    let history = useHistory();

    const [emails_state,setemails_state] = useState("null");
    const [emails_sent,setemails_sent]=useState(null);
    const [open_email_details,setOpen_email_details]=useState(null);
    
    const [waiting, setWaiting] = useState(false);

    const [opend_sd_em_modal, setOpend_sd_em_modal] = useState(false);

    const [available_recipients, setAvailable_recipients] = useState(null);

    const [currentPage,setCurrentPage]=useState(1);
    const [dataPerPage,setdataPerPage] =useState(6);
    const [data_count,setdata_count]=useState(0);
    
  useEffect(()=>{

    let service=JSON.parse(localStorage.getItem('user-info'))

    setemails_sent(null);
    setdata_count(0);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    async function load_data(){
        let emails_state_tmp = "null";
        if(urlParams.get('emails')){
            setemails_state(urlParams.get('emails'));
            emails_state_tmp = urlParams.get('emails');
        }
        let page = (urlParams.get('page')?urlParams.get('page'):1);
        await setCurrentPage(page);
        
        await get_user_emails_sent(service.id,emails_state_tmp,page).then((response)=>{
            setemails_sent(response.data.data);
            setdata_count(response.data.total);
        }).catch((error)=>{
            //console.log(error.response);
        })

        await search_users_byname_or_email_forServ().then((response)=>{
            //console.log(response.data);
            setAvailable_recipients(response.data);
        }).catch((error)=>{
            //console.log(error.response);
        }) 
    }

    load_data();
    

  },[url_updated,history.location]);


  function select_categorie_stat(value){
    setemails_state(value);
    let path = window.location.href.split('?')[0]
    window.location.href = path+"?emails="+value;
  }


  async function change_page(page){
    await setCurrentPage(page);
    let path = history.location.pathname;
    let params = history.location.search;
    let search_params = params.split('&');
    let tmp_params = path;
    if(!params) history.push(path+"?page="+page);
    else{
      for(let i in search_params){
        if(search_params[i].includes("page=")){
          if(i==0) tmp_params=tmp_params+"?page="+page;
          else tmp_params=tmp_params+"&page="+page;
        }else{
          if(i==0) tmp_params=tmp_params+search_params[i];
          else tmp_params=tmp_params+"&"+search_params[i];
        }
      }
      if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
      history.push(tmp_params);
    }
    await setUrl_updated(page);
  }

  return (
    <>
    {
        emails_sent==null
        ?<Loading_animation_view />
        :<div className='emails_sent_container'>
            <div className='emails_sent_container_header three_forms_header_container'>
                    <div className='page_title'>
                        <div>Emails envoyés</div>
                        <div>
                          <Button variant="contained" startIcon={<AddIcon className="my_icon"/>} className={waiting ? "submit_but disabled_button":"submit_but"} onClick={() => setOpend_sd_em_modal(true)}>
                            Nouveau message
                        </Button>  
                        </div>
                        
                    </div>
                    <div className='select_container'>
                        <FormControl className="header_form" >
                            <InputLabel>Emails</InputLabel>
                                <Select required
                                    value={emails_state}
                                    onChange={e => {
                                        select_categorie_stat(e.target.value);
                                    }} name="Etat" >
                                    <MenuItem value={"null"} disabled>
                                        Emails
                                    </MenuItem>
                                    <MenuItem value={"recent"}>Récent</MenuItem>
                                    <MenuItem value={"old"}>Ancien</MenuItem>
                                </Select>
                        </FormControl>
                    </div>
            </div>
                    <TableContainer className="emails_sent_table">
                            <Table className={classes.table} aria-label="sticky table">
                                <TableHead>
                                <TableRow>
                                    {
                                        columns.map((column) => (
                                            <TableCell key={column.id} align="left">{column.label}</TableCell>
                                        ))
                                    }
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    emails_sent.length!==0
                                    ?
                                    <>
                                        <Emails emails_sent={emails_sent}  setOpen_email_details={setOpen_email_details} />
                                    </>
                                    :<div style={{margin:"10px",fontSize:".8rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucun message disponible</div>
                                }
                                </TableBody> 
                            </Table>
                        </TableContainer>
                        {
                            emails_sent.length!==0
                                ?<div className="pagination_container">
                                    <Pagination
                                        activePage={Number(currentPage)}
                                        itemsCountPerPage={dataPerPage}
                                        totalItemsCount={Number(data_count)}
                                        pageRangeDisplayed={4}
                                        onChange={change_page.bind(this)}
                                    />
                                </div>
                                :<></>
                            }
            </div>
        }

        {
            opend_sd_em_modal!==false && available_recipients!==null
            ?<Send_email opend_sd_em_modal={opend_sd_em_modal} setOpend_sd_em_modal={setOpend_sd_em_modal} setUrl_updated={setUrl_updated} available_recipients={available_recipients} />
            :<></>
        }

        {
            open_email_details!==null
            ?<Email_details open_email_details={open_email_details} setOpen_email_details={setOpen_email_details}  />
            :<></>
        }

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </>
  );
}

export default Emails_sent;