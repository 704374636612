import '../../../../../assets/css/catalogue_modal.css'
import React, { useState,useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CloseIcon from '@mui/icons-material/Close';
import empty from '../../../../empty.jpg'

import { getSupplier_categories } from '../../../../../services/supplier_service';
import Loading_animation_view from '../../../../loading_animation_view/loading_animation_view';
import { get_supplier_products } from '../../../../../services/for_allServices';

function Catalogue_modal({ setChosen_supplier, chosen_supplier }) {

  const [products,setProducts] = useState(null);

  const [categories,setCategories]=useState([]);

  const [filter_categorie,setFilter_categorie]=useState("all");
  const [search_value,setsearch_value]=useState("");


  useEffect(()=>{

    async function load_data(){
       await getSupplier_categories(chosen_supplier).then((response)=> {
        //console.log(response.data);
            setCategories(response.data);
        }).catch((error)=>{
            //console.log(error.response);
        });

        await load_supplier_products(chosen_supplier,filter_categorie,"null");
    }
    
    load_data();

    

  },[]);

    async function load_supplier_products(id_supplier,filterCategorie,searchValue) {
        await get_supplier_products(id_supplier,filterCategorie,searchValue).then((response)=>{
            setProducts(response.data.data);
        }).catch((error)=>{
          //console.log(error.response);
        });
    }


    function getCategorieById(id){
        var categorie="";
        categories.map((catg)=>{
        if(catg.id_categorie === id) categorie = catg.nom;
        })
        return categorie;
    }


    function search(){
        setProducts(null);
        load_supplier_products(chosen_supplier,filter_categorie,search_value);
    }

    function reset_search(){
        setProducts(null);
        setsearch_value("");
        load_supplier_products(chosen_supplier,filter_categorie,"null");
    }

    function set__categorie(value){
        setProducts(null);
        setFilter_categorie(value);
        load_supplier_products(chosen_supplier,value,"null");
    }

  return (
    <Dialog className="catalogue_modal" fullWidth={true} maxWidth={'xl'}  open={chosen_supplier?true:false} onClose={()=>setChosen_supplier(null)} centered>
      <DialogContent>
        <div className="products_gallery_modal_container">
            <div className="products_gallery_modal_header_container">
                <div className='search_filter_container'>
                    <FormControl variant="standard" className="header_form">
                        <InputLabel id="demo-simple-select-helper-label">Famille d'achat</InputLabel>
                        <Select required value={filter_categorie} 
                            onChange={e => {set__categorie(e.target.value) }} >
                            
                            <MenuItem value={"all"} disabled>Familles d'achat</MenuItem>
                            {
                            categories.map((categorie,id)=>(
                                <MenuItem value={categorie.nom} key={id} >{categorie.nom}</MenuItem>
                            ))
                            }
                            
                        </Select>
                    </FormControl>
                    <Grid container spacing={1} alignItems="flex-start" className="search_bar">
                        <Grid item>
                            <SearchIcon onClick={search} className={search_value===""?"my_icon disabled_button":"my_icon" } style={{cursor:"pointer"}}  />
                        </Grid>
                        <Grid>
                            <TextField label="Chercher"  value={search_value} onChange={(e)=>{setsearch_value(e.target.value)}} />
                        </Grid>
                        <Grid item style={{marginLeft:"5px"}}>
                            <CloseIcon onClick={reset_search} className={search_value===""?"my_icon disabled_button":"my_icon" }  style={{cursor:"pointer"}}  />
                        </Grid>
                    </Grid>
                    </div>
                </div>
                <div className="products_gallery_modal_contents_container">
                <>
                  {
                    products===null
                    ?<Loading_animation_view />
                    :<>{
                        products.length !== 0
                        ?<>
                        <div className="products_contents_container">
                            {
                                products.map((val,id) => {
                                return (
                                    <React.Fragment key={id}>
                                        <div className="main_card_container">
                                            <div className="main_card_img_container">
                                                <img src={val.product_pic!==""?`data:image/png;base64,${val.product_pic}`:empty}/>
                                            </div>
                                            <div className="main_card_infos_container">
                                                <div className="card_title">{val.title}</div>
                                                <div className="card_description">{val.description}</div>
                                                <div className="card_title">{val.product_price+" MAD"}</div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                            
                                    );
                                })
                            }
                        </div>
                        </>:<div className='emptytable_msg_container'>Aucun produit disponible</div>
                    }
                    </>    
                  }
                </>
                </div>
            </div>
      </DialogContent>
    </Dialog>
  );
}

export default Catalogue_modal;
