import axios from "axios"
import './request_interceptor'


const end_point1 = "https://com.portailachats.ma/api/serviceMetier/";
//const end_point1 = "http://localhost:8000/api/serviceMetier/";





////////////////////////////////////////////////////
//// bills
////////////////////////////////////////////////////


export const getAll_businessService_market = async(id_businessServ,filterCategorie,searchFilter,searchValue,page) => {
    return await axios.get(end_point1+'getAll_businessService_market/'+id_businessServ+'/'+filterCategorie+'/'+searchFilter+'/'+searchValue+"?page="+page);
}


export const getAll_businessService_orders_bills = async(data,searchFilter,searchValue,page) => {
    return await axios.post(end_point1+'getAll_businessService_orders_bills/'+searchFilter+'/'+searchValue+"?page="+page,data);
}


////////////////////////////////////////////////////
//// applicant_requests
////////////////////////////////////////////////////

export const requester_change_order_state = async(data) => {
    return await axios.post(end_point1+'requester_change_order_state',data);
}




////////////////////////////////////////////////////
//// MainWidget
////////////////////////////////////////////////////

export const billsWidget_by_business = async(id,time_range,bills_src,filter) => {
    return await axios.get(end_point1+'billsWidget_by_business/'+id+"/"+time_range+"/"+bills_src+"/"+filter);
}

export const charts_ofBillsAmount_payed_unpayed_byMonth = async(id,time_range) => {
    return await axios.get(end_point1+'charts_ofBillsAmount_payed_unpayed_byMonth/'+id+"/"+time_range);
}

export const delay_between_bills_expired_payed_dates = async(id,time_range,bills_src) => {
    return await axios.get(end_point1+'delay_between_bills_expired_payed_dates/'+id+"/"+time_range+"/"+bills_src);
}

export const count_requests_with_catalogue_byState = async(id,time_range,state) => {
    return await axios.get(end_point1+'count_requests_with_catalogue_byState/'+id+"/"+time_range+"/"+state);
}

export const count_requests_without_catalogue_byState = async(id,time_range,state) => {
    return await axios.get(end_point1+'count_requests_without_catalogue_byState/'+id+"/"+time_range+"/"+state);
}

export const total_price_requests_with_catalogue_byState = async(id,time_range,state) => {
    return await axios.get(end_point1+'total_price_requests_with_catalogue_byState/'+id+"/"+time_range+"/"+state);
}

export const total_price_requests_without_catalogue_byState = async(id,time_range,state) => {
    return await axios.get(end_point1+'total_price_requests_without_catalogue_byState/'+id+"/"+time_range+"/"+state);
}

export const delay_between_delivery_supposedDate = async(id,time_range) => {
    return await axios.get(end_point1+'delay_between_delivery_supposedDate/'+id+"/"+time_range);
}

export const chart_of_the_bestSuppliers_for_orders = async(id,time_range) => {
    return await axios.get(end_point1+'chart_of_the_bestSuppliers_for_orders/'+id+"/"+time_range);
}