import './demandeAppel_View.css';

import React, { useState , useEffect } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Button from '@material-ui/core/Button';
import Fab from '@mui/material/Fab';
import DownloadIcon from '@mui/icons-material/Download';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CheckIcon from '@material-ui/icons/Check';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import swal from 'sweetalert';

import Pagination from "react-js-pagination";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import Swal from 'sweetalert2';
import Plus_details_dialog from './plus_details_Dialoh';
import SetContrat_pourDemande from './setContract_forSubmission/setContract_forSubmission';
import Data_complements from './data_complements/data_complements_dialog';
import { accord_supplier_proposel_document, accord_supplier_soumission, get_offer_submissions, rate_supplier_proposel } from '../../../../services/purchase_service';
import { downloadDocAdminServ, downloadDocTechServ, downloadDocFinanceServ } from '../../../../services/purchase_service';
import Loading_animation_view from '../../../loading_animation_view/loading_animation_view';

let purchase_service = JSON.parse(localStorage.getItem('user-info'));


function state(state){
  return(
    <>
      {
       state === 1
       ?<Button variant="contained" className="status_success">{'Accepté'}</Button>
       :state === 0
          ?<Button variant="contained" className="status_deny">{'Refusé'}</Button>
          :<Button variant="contained" className="status_waiting">{'En cours'}</Button>
      }
    </>
  );
}

function verify_if_able_toAcccord(submission){
  return submission.accordAdmin && submission.accordTech && submission.accordFinance;
}

const headCells = [
  { id: 'nom', numeric: false, disablePadding: false, label: 'Nom Complet' },
  { id: 'score_financier', numeric: true, disablePadding: false, label: 'Score financier' },
  { id: 'score_technique', numeric: true, disablePadding: false, label: 'Score technique' },
  { id: 'dateDemande', numeric: true, disablePadding: false, label: 'Date de soumission' },
  { id: 'docs', numeric: true, disablePadding: false, label: 'Documents' },
  { id: 'details', numeric: true, disablePadding: false, label: 'Details' },
  { id: 'complement', numeric: true, disablePadding: false, label: 'Complément des dossiers' },
  { id: 'accord', numeric: true, disablePadding: false, label: 'Accord' },
];


function DemandeAppel_View({offerClicked}) {

  const [chosenSubmission,setchosenSubmission]=useState(null);
  const [submissions,setsubmissions]=useState(null);
  
  const [setContract, setSetContract] = useState(false);
  const [openDetails, setOpenDetails] = useState(false);
  const [openDataComplements, setOpenDataComplements] = useState(null);
  const [waiting, setWaiting] = useState(false);

  const [currentPage,setCurrentPage]=useState(1);
  const [dataPerPage,setdataPerPage] =useState(5);
  const [data_count,setdata_count]=useState(0);

  useEffect(()=>{
      setsubmissions(null);

      async function load_data(){
        const formData = new FormData();
        formData.append('id_appel',offerClicked.id_appel);
        const return_values = await get_offer_submissions(formData,currentPage).then(response => {
            setdata_count(response.data.total);
            return response.data.data;
          })
          .catch(err=>{
            console.log(err,err.response);
          }); 
          let tmp_scores_tech = [];
          let tmp_scores_finance = [];
          for(let i in return_values){
            tmp_scores_finance.push(return_values[i].score_financier);
            tmp_scores_tech.push(return_values[i].score_technique);
          }
          await setsubmissions(return_values);
      }

      load_data();
      
    },[currentPage]);

  function handleCloseDetails(){
    setOpenDetails(false);
    setchosenSubmission(null); 
  };

  function handleCloseDataComplements(){
    setOpenDataComplements(null);
    setchosenSubmission(null); 
  };

  function details(id){
    setchosenSubmission(submissions[id]);
    setOpenDetails(true);
  }

  function dataComplements(id){
    setOpenDataComplements(id);
    setchosenSubmission(submissions[id]);
  }

  function handleCloseSetContrat(){
    setSetContract(false);
    setchosenSubmission(null); 
  }

  function openContractView(submission){
    setSetContract(true);
    setchosenSubmission(submission); 
  }


  function setSoumission_docs_rates(newValue,id,id_demande,docType){
    setWaiting(true);
    let formData = new FormData();
    formData.append("id_demande",id_demande);
    if(docType === "finance"){
      formData.append("finance",newValue);
      rate_supplier_proposel(formData).then((response) => {
        const arr= [ ...submissions ];
        arr[id].score_financier=newValue;
        setsubmissions(arr);
        setWaiting(false);
      }).catch((error) => {
        //console.log(error.response);
      });
    }else{
      formData.append("technique",newValue);
      rate_supplier_proposel(formData).then((response) => {
        const arr= [ ...submissions ];
        arr[id].score_technique=newValue;
        setsubmissions(arr);
        setWaiting(false);
      }).catch((error) => {
        //console.log(error.response);
      });
    } 
  }

   function downloadDocAdmin(name,id) {
    const formData = new FormData();
    formData.append('id_demande',submissions[id].id_demande);
    downloadDocAdminServ(formData).then((response) => {
       var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', name);
        document.body.appendChild(fileLink);
        fileLink.click();
    }).catch(err=>{
      console.log(err,err.response);
    });
  }


   function downloadDocTech(name,id) {
    const formData = new FormData();
    formData.append('id_demande',submissions[id].id_demande);
    downloadDocTechServ(formData).then((response) => {
       var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', name);
        document.body.appendChild(fileLink);
        fileLink.click();
    });
  }

   function downloadDocFinance(name,id) {
    const formData = new FormData();
    formData.append('id_demande',submissions[id].id_demande);
    downloadDocFinanceServ(formData).then((response) => {
       var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', name);
        document.body.appendChild(fileLink);
        fileLink.click();
    });
  }


   function accept_refuse(choix,id) {
      if(submissions[id].score_financier===0 ||submissions[id].score_technique===0){
        swal("Noter la soumission !", {
          icon: "error",
          buttons: true,
        }).then((willDelete) => {
            if (willDelete) {
        }})  
      }else{
        setWaiting(true);
        let formData = new FormData();
        formData.append("id_demande",submissions[id].id_demande);
        formData.append("choix",choix);
        accord_supplier_soumission(formData).then(response => {
          swal("le fournisseur sera bientôt notifié !", {
            icon: "success",
            buttons: true,
          }).then((willDelete) => {
              if (willDelete) { 
                const arr=[...submissions];
                arr[id]=response.data;
                setsubmissions(arr);
          }})  
            setWaiting(false);
          })
          .catch(err=>{
            console.log(err,err.response);
            setWaiting(false);
          });
      }
 }

  
  function AccordDoc(choix,id,doc){
    setWaiting(true);
    const formData = new FormData();
    formData.append('id_demande',submissions[id].id_demande);
    formData.append('choix',choix);
    formData.append('doc',doc);
    formData.append('id_appel',submissions[id].id_appel);
    accord_supplier_proposel_document(formData).then((response) => {
      const arr=[...submissions];
      arr[id]=response.data;
      setsubmissions(arr);
      setWaiting(false);
    }).catch((error) => {
      //console.log(error.response);
    })
  }
 

 
  function sweetAlert(msg,icon){
    Swal.fire({
      icon: icon,
      title: msg,
    })
  }
  
  async function change_page(page){
    await setCurrentPage(page);
  }


  return (
    <div className="demandeAppel_container" >
      {
        submissions==null
        ?<Loading_animation_view />
        :<div>
            <TableContainer>
              <Table className="submittions_table" aria-labelledby="tableTitle"  aria-label="enhanced table" >
                <TableHead>
                  <TableRow>
                      {
                          headCells.map((column) => (
                              <TableCell key={column.id} align="left">{column.label}</TableCell>
                          ))
                      }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                  submissions.map((submission, id) => {
                      if (submission.code!=='null') {
                      return (
                        <TableRow key={id}>
                          <TableCell align="left">{submission.nom}</TableCell>
                          <TableCell align="center" >
                            <Rating
                                name="score_financier" 
                                value={submission.score_financier+0} 
                                onChange={(event, newValue) => { setSoumission_docs_rates(newValue,id,submission.id_demande,"finance"); }}
                              />
                                {
                                  submission.score_financier !== 0 
                                  ?<Box style={{alignContent:"center" , fontWeight:"600"}}>{submission.score_financier}</Box>
                                  :<Box sx={{ ml: 2 }}>0</Box>
                                }

                          </TableCell>
                          <TableCell align="center">
                            <Rating
                                name="score_technique" 
                                value={submission.score_technique+0} 
                                onChange={(event, newValue)=>{ setSoumission_docs_rates(newValue,id,submission.id_demande,"technique"); }}
                              />
                                {
                                  submission.score_technique !== 0 
                                  ?<Box style={{alignContent:"center" , fontWeight:"600"}}>{submission.score_technique}</Box>
                                  :<Box sx={{ ml: 2 }}>0</Box>
                                }
                          </TableCell>
                          <TableCell align="left">{(new Date(submission.date)).toLocaleString()}</TableCell>
                          <TableCell align="left" >
                          <div className='documentsContainer' style={{width:'90%',justifyContent:'space-between'}}>
                              <div style={{display:'flex',marginBottom:'10px',justifyContent:'space-between'}}>
                                  <div className='documentsDownload'>
                                      <Fab size="small" color="default" onClick={()=>downloadDocAdmin("DocAdmin.pdf",id)}><DownloadIcon className="my_icon" /></Fab>
                                      <span className='documentsTitle'>Administrative</span>
                                  </div>
                                  <div className='documentsStatus_valide' >
                                      <div style={{display:'block'}}>
                                          <div><CheckIcon className="checkIcon my_icon" style={{color:'#00b200'}} onClick={()=>{AccordDoc(1,id,'admin')}} /></div>
                                          <div><CloseSharpIcon className="closeSharpIcon my_icon" style={{color:'#ff0000'}} onClick={()=>{AccordDoc(0,id,'admin')}} /></div>
                                      </div>
                                      {state(submission.accordAdmin)}
                                  </div>
                              </div>

                              <div style={{display:'flex',marginBottom:'10px',justifyContent:'space-between'}}>
                                  <div className='documentsDownload'>
                                      <Fab size="small" color="default" onClick={()=>downloadDocTech("DocTech.pdf",id)}><DownloadIcon className="my_icon" /></Fab>
                                      <span className='documentsTitle'>Technique</span>
                                  </div>
                                      <div className='documentsStatus_valide'  >
                                      <div>
                                          <div><CheckIcon className="checkIcon my_icon" style={{color:'#00b200'}} onClick={()=>{AccordDoc(1,id,'tech')}} /></div>
                                          <div><CloseSharpIcon className="closeSharpIcon my_icon" style={{color:'#ff0000'}} onClick={()=>{AccordDoc(0,id,'tech')}} /></div>
                                      </div>
                                        {state(submission.accordTech)}               
                                  </div>
                              </div>

                              <div style={{display:'flex',marginBottom:'10px',justifyContent:'space-between'}}>
                                  <div className='documentsDownload'>
                                      <Fab size="small" color="default" onClick={()=>downloadDocFinance("DocFinance.pdf",id)}><DownloadIcon className="my_icon" /></Fab>
                                      <span className='documentsTitle'>Finance</span>
                                  </div>
                                      <div className='documentsStatus_valide'  >
                                      <div>
                                          <div><CheckIcon className="checkIcon my_icon" style={{color:'#00b200'}} onClick={()=>{AccordDoc(1,id,'finance')}} /></div>
                                          <div><CloseSharpIcon className="closeSharpIcon my_icon" style={{color:'#ff0000'}} onClick={()=>{AccordDoc(0,id,'finance')}} /></div>
                                      </div>
                                      {state(submission.accordFinance)}             
                                  </div>
                              </div>
                            </div>
                          </TableCell>

                          <TableCell align="left">
                            <Fab size="small" color="default" onClick={()=>details(id)}><AssignmentIndIcon className="my_icon"  /></Fab>
                          </TableCell>

                          <TableCell align="left">
                            <Fab size="small" color="default" onClick={()=>dataComplements(id)}><MoreHorizIcon className="my_icon"  /></Fab>
                          </TableCell>

                          <TableCell align="left">
                            <div className='AccordCtrl_container'>
                              <div className='AccordCtrl_contract'>
                                {
                                  submission.accord===1
                                  ?<Fab size="small" color="info" onClick={()=>openContractView(submission)}><ContentPasteIcon className="my_icon"  /></Fab>
                                  :<></>
                                }
                              </div>
                              <div style={{display:'flex'}} >
                                  <CheckIcon onClick={()=>{accept_refuse(1,id)}} className={verify_if_able_toAcccord(submission)?"my_icon checkIcon":"my_icon checkIcon disabled_button"} />
                                  <CloseSharpIcon onClick={()=>{accept_refuse(0,id)}} className="my_icon closeSharpIcon" />
                              </div>
                              <div>
                                {state(submission.accord)}
                              </div>
                            </div>
                          </TableCell>
                        </TableRow>
                      );
                      }
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="pagination_container">
                              <Pagination
                                  activePage={Number(currentPage)}
                                  itemsCountPerPage={dataPerPage}
                                  totalItemsCount={Number(data_count)}
                                  pageRangeDisplayed={4}
                                  onChange={change_page.bind(this)}
                              />
                          </div>
          </div>
      }
      {
        chosenSubmission!=null && openDetails==true
        ?<Plus_details_dialog openDetails={openDetails} handleCloseDetails={handleCloseDetails} chosenSubmission={chosenSubmission} emailService={purchase_service.email} />         
        :<></>
      }

      {
        chosenSubmission!=null && openDataComplements!==true
        ?<Data_complements openDataComplements={openDataComplements} handleCloseDataComplements={handleCloseDataComplements} submissions={submissions} setSubmissions={setsubmissions} chosenSubmission={chosenSubmission} />         
        :<></>
      }

      {
        chosenSubmission!=null && setContract==true
        ?<SetContrat_pourDemande setContract={setContract} handleCloseSetContrat={()=>handleCloseSetContrat()} chosenSubmission={chosenSubmission} purchase_service={purchase_service} />
        :<></>
      }

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 3 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>

    </div>
  );
}
export default DemandeAppel_View
