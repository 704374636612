import './add_new_product.css'
import React, { useState, useEffect } from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import PaymentIcon from '@material-ui/icons/Payment';
import AddIcon from '@material-ui/icons/Add';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';
import MenuItem from '@material-ui/core/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Form from 'react-bootstrap/Form'
import Select from '@material-ui/core/Select';
import swal from 'sweetalert';

import { getSupplier_categories } from '../../../../../../../services/supplier_service';
import { add_product,get_product_by_infos } from '../../../../../../../services/purchase_service';


function Add_new_product_modal({open_add_prd_modal,setOpen_add_prd_modal,add_product_to_list,id_supplier}){
  
  const [errorText,setErrorText] = useState("");
  const [waiting, setWaiting] = useState(false);

  const [valide, setValid] = useState(true);
  const [submitted, setSubmitted] = useState(false);

  const [product_code,setProduct_code]=useState('');
  const [title,settitle]=useState('');
  const [price,setprice]=useState(''); 
  const [description,setdescription]=useState('');
  const [Categorie,SetCategorie]=useState('null');

  const [categories,setCategories]=useState([]);

  useEffect(()=>{
    getSupplier_categories(id_supplier).then((response)=> {
      setCategories(response.data);
    }).catch((error)=>{
      //console.log(error.response);
    });
  },[]);
  
  function validated_elem(){
    return submitted && valide;
}

    async function addProduct() {
        setSubmitted(true);
        setValid(true);
        setErrorText("");
        if(title==='' || price==='' || description===''){
          setValid(false);
          setErrorText("Champs invalides !");
        }else{
            swal({
                title: "Etes-vous sûr ?",
                text: "!!!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then((willDelete) => {
               if(willDelete) {
                setWaiting(true);
                const formData = new FormData();
                formData.append('product_code',product_code);
                formData.append('title',title);
                formData.append('description',description);
                formData.append('id_supplier',id_supplier);
                formData.append('price',price);
                formData.append('id_categorie',Categorie);
                add_product(formData).then((response) => {
                    //console.log(response.data);
                    let formData2 = new FormData();
                    formData2.append('product_code',product_code);
                    get_product_by_infos(formData2).then((response) => {
                        setWaiting(false);
                        //console.log(response.data);
                        setOpen_add_prd_modal(false);
                        add_product_to_list(response.data);
                        swal("votre réponse a été enregistrée avec succès, produit bien ajouté !", {
                            icon: "success",
                        });
                    }).catch((error)=>{
                        setWaiting(false);
                        //console.log(error.response);
                    })
                }).catch((error)=>{
                    //console.log(error.response);
                })
              }
            })
          }    
   }

    return (
      <Dialog className="add_product_byservice_container" fullWidth={true} maxWidth={'md'} open={open_add_prd_modal} onClose={()=>setOpen_add_prd_modal(false)} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">Créer un nouveau produit pour le fournisseur</DialogTitle>
        <DialogContent style={{height:'100%',display:'flex',flexDirection:'column'}}>
            <div className="plus_details_dialog_container" >

                <TextField label="Code de produit" className="header_form"  onChange={(e)=>setProduct_code(e.target.value)} />

                <TextField label="Titre" className="header_form" onChange={(e)=>settitle(e.target.value)} />

                <TextField label="Prix (DH) " type="number" className="header_form"
                    InputProps={{ startAdornment: ( <InputAdornment position="start"> <PaymentIcon className="my_icon" /> </InputAdornment> ), }} onChange={(e)=>setprice(e.target.value)} />
            </div>
            <div style={{marginBottom:"15px"}} className="plus_details_dialog_container"  >
                <FormControl className="header_form" >
                    <InputLabel id="demo-simple-select-helper-label">Famille d'achat</InputLabel>
                    <Select value={Categorie}  onChange={(e)=>{SetCategorie(e.target.value)}}>
                      <MenuItem value="null" disabled selected>
                          Famille d'achat
                      </MenuItem>
                      {categories.map((val,index,) => {
                        return (<MenuItem key={index} value={val.id_categorie}>{val.nom}</MenuItem>)
                      })}
                    </Select>
                  </FormControl>
            </div>
            <div style={{width:"100%",margin:"0"}} >
                    <InputLabel htmlFor='reason'>Description</InputLabel>
                    <Form.Group name="reason" style={{width: '100%',height: '100%',position: 'relative',padding:"0px"}} >
                        <Form.Control as="textarea" placeholder='Description...' rows={9} value={description} onChange={(e)=>setdescription(e.target.value)} />
                    </Form.Group>
              </div>
       </DialogContent>
       <DialogActions>
          <div className='dialogBottom'>
            <div>
                {
                    validated_elem() 
                    ?<></>
                    :<p className={submitted ? "col-md-10 error" : "col-md-10 valid-champ"}>
                            {submitted ? errorText : ""}
                        </p>
                }
            </div>
            <div>
                <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon className="my_icon"  />}
                    onClick={()=>{setOpen_add_prd_modal(false);}} >
                    Annuler
                </Button>
                <Button variant="contained" className={waiting===true?"submit_but valide_but disabled_button":"submit_but valide_but"} startIcon={<AddIcon className="my_icon"  />} onClick={addProduct} >
                    Ajouter
                </Button> 
            </div>
          </div>
        </DialogActions>
      </Dialog>
    )
}

export default Add_new_product_modal
