import React, { useState , useEffect, useLayoutEffect } from 'react'
import   './chatView.css'
import SendIcon from '@material-ui/icons/Send';

import Fab from '@mui/material/Fab';
import Form from 'react-bootstrap/Form';
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { sendMsg } from '../../../services/supplier_service';


function ChatView_Service ({ handleClose, open, chats ,id_service, id_supplier, supplier_name }){


  const [message,setmessage]=useState("");

  useLayoutEffect(()=>{
    let msgs_cont = document.getElementById('messages');
    if(msgs_cont) msgs_cont.scrollTop = msgs_cont.scrollHeight;
  },[supplier_name])


  function send(id_supplier){
      if(message!==""){
        let msg = {
            "id_personne": id_service,
            "time": new Date().toISOString(),
            "content": message,
            "type": "text",
            "lue": false
          }
        let msg_item={msg}
        const formData = new FormData();
        formData.append('id_service',id_service);
        formData.append('id_fournisseur',id_supplier);
        formData.append('content',JSON.stringify(msg));
        sendMsg(formData).then((response)=>{
            console.log(response.data);
            let array_tmp = chats;
            array_tmp.push(msg);
            setmessage("");
            let msgs_cont = document.getElementById('messages');
            msgs_cont.scrollTop = msgs_cont.scrollHeight;
        }).catch((error)=>{
            //console.log(error.response);
        })
      }
      
  }


    return (
      <Dialog fullWidth={true} maxWidth={'md'} className="chatModal_container" open={open} aria-labelledby="form-dialog-title" >
        <DialogContent>
          <div className="chatView_container" >
            <div id="user" className="contact-profile" style={{display:'flex',justifyContent:'space-between'}}>
                <span>
                    {supplier_name?supplier_name:"Fournisseur"}
                </span>
                <span>
                    <CloseIcon className="my_icon" style={{cursor:'pointer'}} onClick={handleClose} />
                </span>
            </div>
            
        
            <ul id="messages" className='msgs messages'>
                {
                    chats.map((chat)=>{
                        return <>
                            {
                                chat.id_personne == id_service
                                ?<li key={chat.time} className="emetteur">
                                    <div>Vous</div>
                                    <span>{chat.content}</span>
                                    <div className="temps">{new Date(chat.time).toLocaleString()}</div>
                                </li>
                                : <li key={chat.time} className="destinateur">
                                    <div>fournisseur</div>
                                    <span>{chat.content}</span>
                                    <div className="temps">{new Date(chat.time).toLocaleString()}</div>
                                </li>
                            }
                        </>
                    })
                }
            </ul>
            <div className="input_container">
                <div className="textarea_container" >
                        <Form.Control as="textarea" placeholder="Votre message ici" value={message} rows={2} onChange={(e)=>setmessage(e.target.value)} />
                </div>
                <div className="sendBut_container">
                    <Fab size="small" color="secondary" style={{backgroundColor:'transparent',color:"black"}} onClick={()=>send(id_supplier)}>
                        <SendIcon />
                    </Fab>
                </div>
            </div>
        </div>
        </DialogContent>
    </Dialog>
    )

}

export default ChatView_Service;

//scroll={"body"}