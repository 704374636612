import React, { useState , useEffect } from 'react'
import { useHistory } from "react-router-dom";

import './suppliers.css'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@mui/material/Tooltip';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import ChatIcon from '@mui/icons-material/Chat';
import Fab from '@mui/material/Fab';
import Stack from '@mui/material/Stack';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import swal from 'sweetalert';

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Pagination from "react-js-pagination";

import Button from '@material-ui/core/Button';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { countmsg_non_lue_service_fournisseur, get_categories, get_suppliers_for_services, load_chat_service_fournisseur} from '../../../services/purchase_service';
import { getSupplier_infos_byId } from '../../../services/supplier_service';

import Loading_animation_view from '../../loading_animation_view/loading_animation_view';
import Identifiantsupplier from './identity_supplier_modal/identity_supplier_modal';
import ChatView_Service from '../chatView/chatView';




const status = ['En Cours','Refusé','Accepté'];


const columns = [
  { id: 'Nom', label: 'Nom', minWidth: 0 },
  { id: 'MaisonMere', label: "Maison mère", minWidth: 0 },
  { id: 'FamilleAchat', label: "Famille d'achat", minWidth: 0 },
  { id: 'Compte', label: "Compte", minWidth:  0, align: 'left' },
  { id: 'chat', label: 'chat', minWidth:  0, align: 'left' }
];


function Suppliers (){
  let history = useHistory();
  const [url_updated , setUrl_updated] = useState(null);

  let user=JSON.parse(localStorage.getItem('user-info'))
  const id_service = user.id;

  const [fournisseur,setFournisseur]=useState(null);

  const [show,setShow]=useState(false);

  const [Data,setData]=useState(null);
  const [categories,setCategories]=useState([]);
  
  const [searchValue,setSearchValue]=useState('');
  const [filter,setFilter]=useState("status");

  const [currentPage,setCurrentPage]=useState(1);
  const [dataPerPage,setdataPerPage] =useState(5);
  const [data_count,setdata_count]=useState(0);

  const [show_msg,setShow_msg]=useState(false);
  const [chats,setChats]=useState(null);
  const [msg_non_lue,setMsg_non_lue]=useState([]);
  const [id_supplier,setid_supplier]=useState(null);
  const [supplier_name,setsupplier_name]=useState(null);

  const [waiting, setWaiting] = useState(false);

  const [sortBy,setsortBy]=useState("null");
  const [sortOrder,setsortOrder]=useState("asc");

  async function axiosTest(if_supplier) {
      const response = await countmsg_non_lue_service_fournisseur(id_service,if_supplier).then((response)=>{
                return response.data;
              }).catch((error) => {
                //console.log(error.response);
              });
      return response;
  }

  useEffect(()=>{
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      setData(null);
      setdata_count(0);
    async function set_filter(){
      if(urlParams.get('fournisseur')){
        await setFilter(urlParams.get('fournisseur'));
      }
      if(urlParams.get('valeur')){
        await setSearchValue(urlParams.get('valeur'));
      }
    }
    async function load_data(){
      let page = (urlParams.get('page')?urlParams.get('page'):1);
      let filte_tmp = (urlParams.get('fournisseur')?urlParams.get('fournisseur'):filter);
      let searchvalue_tmp = (urlParams.get('valeur')?urlParams.get('valeur'):"null");
      await setCurrentPage(page)
      await get_categories().then((response)=> {
        setCategories(response.data);
      }).catch((error)=>{
        //console.log(error.response);
      });
      const result = await get_suppliers_for_services(filte_tmp,searchvalue_tmp,page,sortBy,sortOrder).then((response)=> {
          setdata_count(response.data.total);
          return response.data;
        }).catch((error)=>{
          //console.log(error.response);
        }); 

      for(const supplier in result.data){
        result.data[supplier].msg_non_lue = (await axiosTest(result.data[supplier].id));
      }
      setData(result.data);
    }

    set_filter();
    load_data();

  },[url_updated,history.location])


//////////////////////
///////////////////////////////////
    function viewSupplierIdentity(id_fournisseur){   
      setWaiting(true);   
      getSupplier_infos_byId(id_fournisseur)
          .then(response => {
            setFournisseur(response.data.values[0]);
            setWaiting(false);
            setShow(true);
          }).catch(error => {
              //console.log(error.response);
              setWaiting(false);
          })     
    }

    function hideModal(){
      setShow(false);
    }


function viewService_Supplier_chat(id_supplier,supplier_name,id){ 
  setWaiting(true);
  load_chat_service_fournisseur(id_service,id_supplier).then((response)=>{
    //console.log(response.data);
       setChats(response.data);
       response.data.map((chat)=>{
           if(chat.id_personne != id_service ){
              let tmp_data = [ ...Data ];
              tmp_data[id].msg_non_lue = 0;
              setData(tmp_data);
               return null;
           }
       });
       setWaiting(false);
       setid_supplier(id_supplier);
       setsupplier_name(supplier_name);
       setShow_msg(true);
   }).catch((error)=>{
       //console.log(error.response);
   });   
 }

 function onCloseDiscussion(){
  setShow_msg(false); 
  setChats([]);
  setid_supplier(null);
  setsupplier_name(null);
}

  const getCategorieById = (categorie)=>{
    var tmp_categorie="";
    categories.map((catg)=>{
      categorie.map((id)=>{
        if(catg.id_categorie === parseInt(id)){
          if(tmp_categorie==="") tmp_categorie = tmp_categorie+catg.nom;
          else tmp_categorie = tmp_categorie+", "+catg.nom;
        }
      })
    })
    return tmp_categorie;
  }

//////////////////////
/////////////////////////////////////////////////////////
///////////////////////////////////

function createData(Nom, MaisonMere,FamilleAchat,Compte,chat) {
  return { Nom, MaisonMere,FamilleAchat,Compte,chat};
}

const rows = Data && msg_non_lue!==[]?[
  Data.map((o, i) => createData(Data[i].nom,Data[i].maisonMere,getCategorieById((Data[i].familleAchat).split(',')),
      <Stack direction="row" spacing={1} alignItems="center" >
        <div style={{display:'flex',width: '100%'}}>
          <div className='documentsDownload'>
            <Fab size="small" color="warning" onClick={() => viewSupplierIdentity(Data[i].id)} ><AssignmentIndIcon className="my_icon" /></Fab>
            <span className='documentsTitle'>Fiche</span>
          </div>
      </div>
      </Stack>,
      <>
        <Tooltip title="Chat">
          <span>
            <Fab onClick={()=>viewService_Supplier_chat(Data[i].id,Data[i].nom,i)}  size="small" color="info" style={{backgroundColor:'#343a40',color:"white"}}>
              <ChatIcon className="my_icon" />
              {
                Data[i].msg_non_lue !== null && Data[i].msg_non_lue !== 0 
                ?<div id="text_non_lue"> {Data[i].msg_non_lue} </div>
                :<></>
              }
            </Fab>
          </span>
        </Tooltip>
        </>

    )
  )
]:[];


/////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////


  function search(){
    let path = history.location.pathname;
    if(filter!=="status") history.push(path+"?fournisseur="+filter+"&valeur="+searchValue);
    else history.push(path+"?valeur="+searchValue);
    setUrl_updated(searchValue);
  }

  function setSelectedValue(value){
      setFilter(value);
      let path = history.location.pathname;
      history.push(path+"?fournisseur="+value);
      setsortBy("null");
      setUrl_updated(value);
  }

  ///////////////////////////
  /////////////////////////
  async function change_page(page){
    await setCurrentPage(page);
    let path = history.location.pathname;
    let params = history.location.search;
    let search_params = params.split('&');
    let tmp_params = path;
    if(!params) history.push(path+"?page="+page);
    else{
      for(let i in search_params){
        if(search_params[i].includes("page=")){
          if(i==0) tmp_params=tmp_params+"?page="+page;
          else tmp_params=tmp_params+"&page="+page;
        }else{
          if(i==0) tmp_params=tmp_params+search_params[i];
          else tmp_params=tmp_params+"&"+search_params[i];
        }
      }
      if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
      history.push(tmp_params);
    }
    await setUrl_updated(page);
  }


  async function requestSort(cell,order){
    setWaiting(true);
    setsortBy(cell);
    let tmp_sortBy = cell;
    let tmp_sortOrder = "asc";
    if(sortBy!==cell){
      setsortOrder('asc');
    }
    else{
      order==="asc"?await setsortOrder("desc"):await setsortOrder("asc");
      order==="asc"?tmp_sortOrder="desc":tmp_sortOrder="asc";
    } 
    const result = await get_suppliers_for_services(filter,searchValue?searchValue:"null",currentPage,tmp_sortBy,tmp_sortOrder).then((response)=> {
      //console.log(response.data)
      setdata_count(response.data.total);
      return response.data;
    }).catch((error)=>{
      //console.log(error.response);
    });

    //console.log(result);
    for(const supplier in result.data){
      result.data[supplier].msg_non_lue = (await axiosTest(result.data[supplier].id));
    }
    setData(result.data);
    setWaiting(false);

  }

    return (
      <>
        {
          Data==null
          ?<Loading_animation_view />
          :<div className="suppliers_container" >
            <div className='suppliers_header_container'>
              <div className='page_title'>Fournisseurs</div>
              <div className='search_filter_container'>
                  <FormControl className="header_form" >
                      <InputLabel>Fournisseur</InputLabel>
                          <Select required
                                value={filter}
                                onChange={e => {
                                    setSelectedValue(e.target.value);
                                }} name="Etat" >
                              <MenuItem value={"status"} disabled>
                                    Fournisseur
                              </MenuItem>
                              <MenuItem value={"recent"}>Récent</MenuItem>
                              <MenuItem value={"ancien"}>Ancien</MenuItem>
                          </Select>
                  </FormControl>
                  <Grid container spacing={1} alignItems="flex-start" className={"search_bar"} >
                    <Grid item style={{cursor:'pointer'}}>
                      <SearchIcon className={searchValue===""?"my_icon disabled_button":"my_icon" } onClick={search} />
                    </Grid>
                    <Grid>
                      <TextField id="input-with-icon-grid" label="Nom de Fournisseur" value={searchValue} style={{color:'blue'}} onChange={(e)=>{setSearchValue(e.target.value)}}/>
                    </Grid>
                </Grid>
              </div>
          </div>

          <TableContainer className="reference_table">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                     <TableSortLabel
                        active={sortBy === "name"}
                        direction={sortOrder}
                        onClick={() => requestSort("name",sortOrder)}
                      >
                          Nom Complet
                      </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">
                    <TableSortLabel
                          active={sortBy === "company"}
                          direction={sortOrder}
                          onClick={() => requestSort("company",sortOrder)}
                        >
                          Maison mère
                      </TableSortLabel>
                  </TableCell>
                  <TableCell align="left">Famille d'achat</TableCell>
                  <TableCell align="left">Fiche d'identité</TableCell>
                  <TableCell align="left">Chat</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  rows[0].length!=0
                  ?rows[0].map((row,idx) => {
                    return (
                      <TableRow role="checkbox" tabIndex={-1} key={idx} >
                        {
                          columns.map((column,idx1) => {
                            const value = row[column.id];
                            return (
                              <TableCell key={column.id} align={column.align}>
                                {value}
                              </TableCell>
                            );
                          })
                        }
                      </TableRow>
                    );
                  })
                  :<div style={{margin:"10px", textAlign:"center",alignContent:"center",fontSize:'.75rem'}}>Aucun referencement</div>
                }
              </TableBody> 
            </Table>
          </TableContainer>
          {
                rows[0].length===0
                ?<></>
                :<div className="pagination_container">
                    <Pagination
                        activePage={Number(currentPage)}
                        itemsCountPerPage={dataPerPage}
                        totalItemsCount={Number(data_count)}
                        pageRangeDisplayed={4}
                        onChange={change_page.bind(this)}
                    />
                </div>
            }
          </div>
        }
        {
          fournisseur!==null && show
          ?<Identifiantsupplier show={show} handleClose={hideModal} supplier={fournisseur} />
          :<></>
        }
              
        {   
            id_supplier!==null && chats!==[] && show_msg==true
            ?<ChatView_Service open={show_msg} handleClose={onCloseDiscussion} chats={chats} id_service={id_service} id_supplier={id_supplier} supplier_name={supplier_name} />
            :<></>
        }

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
     </>
    )
}

export default Suppliers
