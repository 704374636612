import '../../../assets/css/catalogue_modal.css'
import './supplier_products.css'
import { useHistory } from "react-router-dom";

import React, { useState , useEffect } from 'react'
import Button from '@material-ui/core/Button';
import swal from 'sweetalert';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import empty from '../../empty.jpg'
import Pagination from "react-js-pagination";

import Loading_animation_view from "../../loading_animation_view/loading_animation_view"
import Add_product_modal from './add_product_modal/add_product_modal';
import { getSupplier_categories, delete_product_byCode, get_products_byCode_forSupplier } from '../../../services/supplier_service';
import { get_supplier_products } from '../../../services/for_allServices';
import Edit_product_modal from './add_product_modal/edit_product_modal';
import { Fab, Tooltip } from '@material-ui/core';


function Supplier_products (){

  let supplier = JSON.parse(localStorage.getItem('user-info'));
  let history = useHistory();
  const [url_updated , setUrl_updated] = useState(null);
  
  const [products,setProducts] = useState(null);
  const [filter_categorie,setFilter_categorie]=useState("all");
  const [search_value,setsearch_value]=useState("");

  const [pageNumber, setPageNumber] = useState(0);

  const [categories,setCategories]=useState(null);
  const [open_updateProduct_modal, setopen_updateProduct_modal] = useState(null);
  const [open_addProduct_modal, setOpen_addProduct_modal] = useState(false);
  const [list_updated_with_success,setList_updated_with_success ] = useState(null);

  const [waiting,setWaiting] = useState(false);
  
  const [currentPage,setCurrentPage]=useState(1);
  const [dataPerPage,setdataPerPage] =useState(9);
  const [data_count,setdata_count]=useState(0);


  useEffect(()=>{

    setProducts(null);
    setdata_count(0);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var tmp_searchValue = "null";
    var tmp_filterCategorie = "all";

    async function load_data(){
      if( urlParams.get('valeur')){
        tmp_searchValue = urlParams.get('valeur');
      }if(urlParams.get('categorie')){
        tmp_filterCategorie = urlParams.get('categorie');
      }
      await setsearch_value(tmp_searchValue==="null"?"":tmp_searchValue);
      await setFilter_categorie(tmp_filterCategorie);
      let page = (urlParams.get('page')?urlParams.get('page'):1);
      await setCurrentPage(page);

      await getSupplier_categories(supplier.id).then((response)=> {
        setCategories(response.data);
      }).catch((error)=>{
        //console.log(error.response);
      });

      await get_supplier_products(supplier.id,tmp_filterCategorie,tmp_searchValue,page).then((response)=>{
        setdata_count(response.data.total);
        setProducts(response.data.data);
      }).catch((error)=>{
        //console.log(error.response);
      });

    }
    load_data();

  },[list_updated_with_success,url_updated,history.location]);



  const handleClose_addProduct = () => {
    setOpen_addProduct_modal(false); 
  };

  
  function delete_product(product_code){
    swal({
        title: "Etes-vous sûr ?",
        text: "une fois qu'il est supprimé, vous ne pouvez plus le voir !",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        setWaiting(true);
        delete_product_byCode(product_code).then((response)=>{
          setProducts(null);
          setList_updated_with_success(response.data);
          setWaiting(false);
        }).catch((error) => {
          //console.log(error.response);
          setWaiting(false);
        });
      }
    })
  }


  async function open_update_modal(product_code){
    setWaiting(true);
    let formData = new FormData();
    formData.append('products_codes',JSON.stringify([product_code]));
    formData.append('id_supplier',supplier.id);
    await get_products_byCode_forSupplier(formData).then((response)=>{
        //console.log(response.data);
        setopen_updateProduct_modal(response.data);
        setWaiting(false);
    }).catch((error)=>{
        //console.log(error.response);
        setWaiting(false);
    });
  }



  function search(){
    let path = history.location.pathname;
    if(filter_categorie!=="all") history.push(path+"?categorie="+filter_categorie+"&valeur="+search_value);
    else history.push(path+"?valeur="+search_value);
    setUrl_updated(search_value);
  }

  function reset_search(){
    let path = history.location.pathname;
    if(filter_categorie!=="all"){
      history.push(path+"?categorie="+filter_categorie);
    } 
    else history.push(path);
    setUrl_updated(filter_categorie);
  }


  function set__categorie(value){
    let path = history.location.pathname;
    if(value!=="all") history.push(path+"?categorie="+value);
    else history.push(path+"?categorie=all");
    setUrl_updated(value);
  }

  function view_details(product_code){
    history.push("/fournisseur/produit/"+product_code);
  }


  async function change_page(page){
    await setCurrentPage(page);
    let path = history.location.pathname;
    let params = history.location.search;
    let search_params = params.split('&');
    let tmp_params = path;
    if(!params) history.push(path+"?page="+page);
    else{
      for(let i in search_params){
        if(search_params[i].includes("page=")){
          if(i==0) tmp_params=tmp_params+"?page="+page;
          else tmp_params=tmp_params+"&page="+page;
        }else{
          if(i==0) tmp_params=tmp_params+search_params[i];
          else tmp_params=tmp_params+"&"+search_params[i];
        }
      }
      if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
      history.push(tmp_params);
    }
    await setUrl_updated(page);
  }

  return(
    <>
    {
      products===null || categories===null
      ?<Loading_animation_view />
      :<div className="products_gallery_modal_container supplier_page">
        <div className="products_gallery_header_container">
          <div className='addButton_container'>
                <Tooltip title="Ajouter">
                  <Fab className="add_new_object_fab add_new_object_fab_big" onClick={()=>setOpen_addProduct_modal(true)} >
                    <AddIcon className="add_new_object_icon" />
                  </Fab>
                </Tooltip>
              </div>
          <div className='search_filter_container'>
              <FormControl variant="standard" className="header_form" >
                  <InputLabel id="demo-simple-select-helper-label">Famille d'achat</InputLabel>
                  <Select required value={filter_categorie} 
                      onChange={e => {set__categorie(e.target.value) }} >
                      
                      <MenuItem value={"all"}>Toutes les familles d'achat</MenuItem>
                      {
                      categories.map((categorie,id)=>(
                          <MenuItem value={categorie.nom} key={id} >{categorie.nom}</MenuItem>
                      ))
                      }
                      
                  </Select>
              </FormControl>
              <Grid container spacing={1} alignItems="flex-start" className="search_bar">
                  <Grid item>
                      <SearchIcon onClick={search} className={search_value===""?"my_icon disabled_button":"my_icon" } style={{cursor:"pointer"}}  />
                  </Grid>
                  <Grid>
                      <TextField label="Chercher"  value={search_value} onChange={(e)=>{setsearch_value(e.target.value)}} />
                  </Grid>
                  <Grid item style={{marginLeft:"5px"}}>
                      <CloseIcon onClick={reset_search} className={search_value===""?"my_icon disabled_button":"my_icon" }  style={{cursor:"pointer"}}  />
                  </Grid>
              </Grid>
              </div>
          </div>
          <div className="products_gallery_modal_contents_container products_gallery_contents_container">
              {
                  products.length !== 0
                  ?<>
                  <div className="products_contents_container">
                      {
                          products.map((val,id) => {
                          return (
                              <React.Fragment key={id}>
                                  <div className="main_card_container">
                                      <div className="main_card_img_container">
                                           <img src={val.product_pic!==""?`data:image/png;base64,${val.product_pic}`:empty}/>
                                      </div>
                                      <div className="main_card_infos_container">
                                          <div className="card_title">{val.title}</div>
                                          <div className="card_description">{val.description}</div>
                                          <div className="card_title">{val.product_price+" MAD"}</div>
                                          <div className="view_more_button_container">
                                              <div>
                                                <Button variant="contained" className="submit_but edit_but" onClick={() => {view_details(val.product_code)}} >
                                                    Plus de détails
                                                </Button>
                                              </div>
                                              <div className="update_buttons">
                                                  <Tooltip title="Supprimer">
                                                    <Fab className="cancel_object_fab" onClick={()=>{ delete_product(val.product_code) }} >
                                                      <DeleteIcon className="my_icon" />
                                                    </Fab>
                                                  </Tooltip>
                                                  <Tooltip title="Modifier">
                                                    <Fab className="success_object_fab" onClick={() => open_update_modal(val.product_code) }>
                                                      <EditIcon className="my_icon" />
                                                    </Fab>
                                                  </Tooltip>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </React.Fragment>
                      
                              );
                          })
                      }
                  </div>
                  <div className="pagination_container">
                          <Pagination
                              activePage={Number(currentPage)}
                              itemsCountPerPage={dataPerPage}
                              totalItemsCount={Number(data_count)}
                              pageRangeDisplayed={4}
                              onChange={change_page.bind(this)}
                          />
                      </div>
                  </>:filter_categorie!=="all"
                        ?<div className='emptytable_msg_container'>Aucun produit disponible</div>
                        :<div className='emptytable_msg_container'>Sélectionner votre Famille d'achat</div>
              }
          </div>
        </div>
    }

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>

        {
            open_addProduct_modal==true
            ?<Add_product_modal categories={categories} supplier={supplier} open_addProduct_modal={open_addProduct_modal} 
                    handleClose_addProduct={handleClose_addProduct} setList_updated_with_success={setList_updated_with_success} setProducts={setProducts} />
            :<></>
          }
          {
            open_updateProduct_modal!==null
            ?<Edit_product_modal categories={categories} supplier={supplier} open_updateProduct_modal={open_updateProduct_modal} 
                    setOpen_updateProduct_modal={setopen_updateProduct_modal} setList_updated_with_success={setList_updated_with_success} setProducts={setProducts} />
            :<></>
          }

    </>
  )
}
export default Supplier_products
