import '../../../../assets/css/product_details.css'
import React, { useState , useEffect } from 'react'
import { useParams } from "react-router-dom";

import empty from '../../../empty.jpg'

import Loading_animation_view from '../../../loading_animation_view/loading_animation_view';
import { get_categories } from '../../../../services/purchase_service';
import { get_products_byCode_forSupplier } from '../../../../services/supplier_service';

import { Carousel } from 'react-bootstrap';



function Product_details(){

  let supplier = JSON.parse(localStorage.getItem('user-info'));
  let { product_code } = useParams();

  const [product,setProduct] = useState(null);
  
  const [categories,setCategories]=useState([]);

 
  useEffect(()=>{

    let formData = new FormData();
    formData.append('products_codes',JSON.stringify([product_code]));
    formData.append('id_supplier',supplier.id);
    get_products_byCode_forSupplier(formData).then((response)=>{
      setProduct(response.data.products[0]);
      //console.log(response.data);
    }).catch((error)=>{
      //console.log(error.response);
    });

    get_categories().then((response)=>{
        setCategories(response.data);
    }).catch((error)=>{
      //console.log(error.response);
    })

  },[]);
    

  
    
  function getCategorieById(id){
    var categorie="";
    categories.map((catg)=>{
      if(catg.id_categorie === id) categorie = catg.nom;
    })
    return categorie;
  }

  return (
    <>
      {
        product===null || categories==null
        ?<Loading_animation_view />
        :<div className="product_details_modal_container supplier_product_details_modal_container">
            <div className="product_details_carousel_container">
              <Carousel>
                {
                    product.product_pics.length!==0
                    ?(product.product_pics).map( (item, i) => (
                            <Carousel.Item key={i} slide={true}>
                                <img src={`data:image/png;base64,${item}`} />
                            </Carousel.Item>
                      ))
                    : <Carousel.Item key={1} slide={true}>
                                <img src={empty} />
                            </Carousel.Item>
                  
                }
              </Carousel>
            </div>
            <div className="product_details_infos_container">
                <div className="card_title_descr">{product.title}</div>
                <div>
                  <div className="card_price_descr">{product.product_price+" MAD"}</div>
                  <div className="card_categorie_descr"><span>Famille d'achat : </span>{getCategorieById(product.id_categorie)}</div>
                  <div className="card_categorie_descr"><span>Code du produit : </span>{product.product_code}</div>
                </div>
                
                <div className="card_description_details">{product.description}</div>
            </div>
            <div className="details_container">
              <div  className="container_title">Description</div>
              <div className="description">{product.description}</div>
            </div>

          </div>
      }
      </>
  )

}

export default Product_details;
