import "../../add_campaign/add_campaign.css"
import { Dialog, DialogContent, DialogTitle, DialogActions } from "@material-ui/core";
import React,{useState,useEffect} from "react";
import { useHistory } from "react-router-dom";
import CancelIcon from '@material-ui/icons/Cancel';
import {Form, Row} from 'react-bootstrap'
import AddIcon from '@mui/icons-material/Add';
import InputAdornment from '@material-ui/core/InputAdornment';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import RemoveIcon from '@mui/icons-material/Remove';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InputLabel from '@material-ui/core/InputLabel';

import {makeStyles} from '@material-ui/core/styles';
import { add_appraisees_to_campaign_byCode, get_suppliers } from "../../../../../services/for_allServices";



const useStyles = makeStyles((theme) => ({
    indeterminateColor: {
      color: "#f50057"
    },
    selectAllText: {
      fontWeight: 500
    },
    selectedAll: {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)"
      }
    }
  }));
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    variant: "menu"
  };



const Add_campaign_appraisees = ({add_campaign_appraisees,setadd_campaign_appraisees,suppliers,appraisees,setappraisees})=>{

    const classes = useStyles();
    let user = JSON.parse(localStorage.getItem('user-info'));

    const [selected, setSelected] = useState([]);
    const [selectedId, setSelectedId] = useState([]);

    const [errorText, setErrorText] = useState(null);
    const [wait_submitting, setwait_submitting] = useState(false);


    useEffect(()=>{

        async function load_data(){
            const tmp_appraisees = add_campaign_appraisees.appraisees;
            if(tmp_appraisees!=""){
                let tmp_array = [];
                tmp_appraisees.split(',').map((supplier_id) => {
                    suppliers.map((supplier) => {
                    if(supplier.id == supplier_id) tmp_array.push(supplier.nom);
                  });
                });
                setSelected(tmp_array);
                setSelectedId(tmp_appraisees.split(','));
            }
        }
        load_data();

    },[]);

    function fournisseurId_parNom(noms){
        let id=[];
        suppliers.map((supplier) => {
          noms.map((nom) => {
            if(supplier.nom == nom) id.push(supplier.id);
          });
        });
        return id;
      }


    const isAllSelected =
        suppliers.length > 0 && selected.length === suppliers.length;

    const handleChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
          let tmp_array = [];
          let tmp_id_array = [];
          suppliers.map((supplier) => {
              tmp_array.push(supplier.nom);
              tmp_id_array.push(supplier.id);
          })
          setSelected(selected.length === suppliers.length ? [] : tmp_array);
          setSelectedId(selected.length === suppliers.length ? [] : tmp_id_array);
          return;
        }
        setSelected(value);
        //console.log(value);
        setSelectedId(fournisseurId_parNom(value));
    };



    function submit(){
        setErrorText('');
        let error_tmp="";
        if(selectedId.length!=0){
            setwait_submitting(true);
            let formData = new FormData();
            formData.append("code",add_campaign_appraisees.code);
            formData.append("appraisees",selectedId);
            formData.append("id_appraiser",user.id);
            add_appraisees_to_campaign_byCode(formData).then((response)=>{
                setappraisees(response.data);
                setadd_campaign_appraisees(null);
                setwait_submitting(false);
            }).catch((error)=>{
                //console.log(error.response);
                setwait_submitting(false);
            })
        }else{
            setErrorText('Champ invalide !');
        }
    }

    return (
        <Dialog fullWidth={true} maxWidth={'md'} open={add_campaign_appraisees?true:false} onClose={()=>{setadd_campaign_appraisees(null)}} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">Ajouter un évalué</DialogTitle>
        <DialogContent>
        <div className="addupdate_campaign_form_container" >
                <Row className="mb-12">
                    <div className="col-md-6 simple_input">
                    <label>Fournisseurs</label>
                    <FormControl className="multi_form_select">
                        <Select
                            labelId="mutiple-select-label"
                            multiple
                            value={selected}
                            onChange={handleChange}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={MenuProps}
                        >
                            <MenuItem
                                value="all"
                                classes={{
                                    root: isAllSelected ? classes.selectedAll : ""
                                }}
                            >
                            <ListItemIcon>
                                <Checkbox
                                    classes={{ indeterminate: classes.indeterminateColor }}
                                    checked={isAllSelected}
                                    indeterminate={
                                        selected.length > 0 && selected.length < suppliers.length
                                    }
                                />
                            </ListItemIcon>
                            <ListItemText
                                classes={{ primary: classes.selectAllText }}
                                primary="Select All"
                            />
                            </MenuItem>
                            {
                            suppliers.map((option) => (
                                <MenuItem key={option.id} value={option.nom}>
                                    <ListItemIcon>
                                    <Checkbox checked={selected.indexOf(option.nom) > -1} />
                                    </ListItemIcon>
                                    <ListItemText primary={option.nom} />
                                </MenuItem>
                            ))
                            }
                        </Select>
                        </FormControl>
                    </div>
                </Row>   
        </div>
        </DialogContent>
        <DialogActions>
          <div className='dialogBottom'>
            <div>
                {
                    errorText === ""
                    ?<></>
                    :<p className={errorText ? "col-md-10 error" : "col-md-10 valid-champ"}>
                            {errorText}
                        </p>
                }
            </div>
            <div>
                <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon />} onClick={()=>{setadd_campaign_appraisees(null)}} >
                    Annuler
                </Button>
                <Button variant="contained" className="submit_but valide_but" startIcon={<AddIcon />} onClick={submit} >
                    Ajouter
                </Button>  
            </div>
               
          </div>
        </DialogActions>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait_submitting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    );
}

export default Add_campaign_appraisees;