import './update_serv_modal.css'
import React, { useState} from 'react'
import Button from '@material-ui/core/Button';
import swal from 'sweetalert';
import { Row,InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CancelIcon from '@material-ui/icons/Cancel';
import EditIcon from '@mui/icons-material/Edit';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { update_service_req } from '../../../../services/company_admin_service';


  //////////////////////////////
/////////////email validation
function invalid_email_pattern(email){
  var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
  if(email!==""){
      if (!pattern.test(email)) {
          return true ;
        }else{
          return false ;
        }
  }else return true;
}

function Update_serv_modal({update_service,setupdate_service,setUrl_updated}){

    const [errorText,setErrorText] = useState("");

    const [full_name,setFull_name]=useState(update_service.nom);
    const [email,setEmail]=useState(update_service.email);
    const [role,setRole]=useState(update_service.role);
    
    const [cnss,setcnss]= useState(update_service.CNSS);
    const [pays,setPays]= useState(update_service.Pays);
    const [ville,setVille]= useState(update_service.Ville);
    const [adresse,setAdresse]= useState(update_service.Adresse);
    const [codePostal,setCodePostal]= useState(update_service.CodePostal);
    const [tele,setTele]= useState(update_service.tel);

    const [waiting,setWaiting] = useState(false)

    function submit(){
      setErrorText(null);
      if(email==="" || full_name==="" || role==="null"  || pays===""  || ville==="" || adresse==="" || codePostal==="" || tele===""  ){
          setErrorText("Champs invalides !");
      }else{
          if(invalid_email_pattern(email)){
              setErrorText("Email invalid !");
          }else{
            swal({
                title: "Etes-vous sûr ?",
                text: "!!!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then((willDelete) => {
              if (willDelete) {
                setWaiting(true);
                let formData = new FormData();
                formData.append('id',update_service.id);
                formData.append('nom',full_name);
                formData.append('prev_email',update_service.email);
                formData.append('email',email);
                formData.append('pays',pays);
                formData.append('cnss',cnss);
                formData.append('ville',ville);
                formData.append('adresse',adresse);
                formData.append('codePostal',codePostal);
                formData.append('tel',tele);
                formData.append('role',role);
                update_service_req(formData).then((response) => {
                  setWaiting(false);
                    swal("Le service a été modifiée avec succès !", {
                        icon: "success",
                        buttons: true,
                        }).then((willDelete) => {
                            if (willDelete) {
                            }
                        })
                        setupdate_service(null);
                        setUrl_updated(response.data);
                }).catch((error) => {
                    //console.log(error.response);
                    /*let error_data = error.response.data;
                    if(error_data.errors){
                        if(error_data.errors.email) setErrorText("Email déjà existant !");
                        if(error_data.errors.tel) setErrorText("Téléphone déjà existant !");
                    }*/
                    setWaiting(false);
                });
              }
            })
        }
      }
    }

    return (
      <Dialog fullWidth={true} maxWidth={'md'} open={update_service?true:false} onClose={()=>setupdate_service(null)} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">Modifier les infos</DialogTitle>
        <DialogContent>
          <div className="add_services_form" style={{width:'95%'}}>
              <Row className="mb-12">
                      <Form.Group className="col-md-6">
                          <label>Nom complét</label>
                          <Form.Control
                              required
                              autoComplete="new-password"
                              type="text"
                              placeholder="Nom *"
                              value={full_name}
                              onChange={ e =>  {setFull_name(e.target.value)}}
                              className={ errorText && full_name==="" ? "is-invalid":""}
                          />
                      </Form.Group>

                      <Form.Group className="col-md-6">
                          <label>Rôle</label>
                          <Form.Select
                              required
                              value={role}
                              onChange={e => {
                                  setRole(e.target.value);
                              }}
                              className={ errorText && role==="null" ? "is-invalid":""}
                              name="categorie"
                          >
                              <option value={"null"} disabled selected>Rôle</option>
                              <option value={1}>{"service d'achat"}</option>
                              <option value={2}>{"service métier"}</option>
                              <option value={3}>{"service de finance"}</option>
                          </Form.Select>
                      </Form.Group>
                      
                      <Form.Group className="col-md-6">
                          <label>Email</label>
                          <InputGroup hasValidation>
                              <Form.Control
                                  required
                                  autoComplete="new-password"
                                  type="text"
                                  placeholder="E-mail *"
                                  value={email}
                                  onChange={ e =>  {setEmail(e.target.value);}}
                                  className={ errorText && invalid_email_pattern(email) ? "is-invalid":""}
                              />
                              <InputGroup.Text  className={ errorText &&  invalid_email_pattern(email) ? "invalid-inputgroup" : "valid-inputgroup"}> 
                                  <FontAwesomeIcon icon={faEnvelope} />
                              </InputGroup.Text>        
                          </InputGroup>
                      </Form.Group>
                  </Row>
              <Row className="mb-12">

                  <Form.Group className="col-md-6">
                      <label>CNSS</label>
                      <Form.Control
                          required
                          autoComplete="new-password"
                          type="text"
                          placeholder="CNSS *"
                          value={cnss}
                          onChange={ e =>  {setcnss(e.target.value)}}
                          className={ errorText && cnss==="" ? "is-invalid":""}
                      />
                  </Form.Group>

                  <Form.Group className="col-md-6">
                      <label>Téléphone</label>
                      <Form.Control
                          required
                          autoComplete="new-password"
                          type="text"
                          placeholder="Téléphone *"
                          value={tele}
                          onChange={ e =>  {setTele(e.target.value)}}
                          className={ errorText && tele==="" ? "is-invalid":""}
                      />
                  </Form.Group>

                  <Form.Group className="col-md-6">
                      <label>Pays</label>
                      <Form.Control
                          required
                          autoComplete="new-password"
                          type="text"
                          placeholder="Pays *"
                          value={pays}
                          onChange={ e =>  {setPays(e.target.value)}}
                          className={ errorText && pays==="" ? "is-invalid":""}
                      />
                  </Form.Group>

                  <Form.Group className="col-md-6">
                      <label>Ville</label>
                      <Form.Control
                          required
                          autoComplete="new-password"
                          type="text"
                          placeholder="Ville *"
                          value={ville}
                          onChange={ e =>  {setVille(e.target.value)}}
                          className={ errorText && ville==="" ? "is-invalid":""}
                      />
                  </Form.Group>

                  <Form.Group className="col-md-6">
                      <label>Adresse</label>
                      <Form.Control
                          required
                          autoComplete="new-password"
                          type="text"
                          placeholder="Adresse *"
                          value={adresse}
                          onChange={ e =>  {setAdresse(e.target.value)}}
                          className={ errorText && adresse==="" ? "is-invalid":""}
                      />
                  </Form.Group>

                  <Form.Group className="col-md-6">
                      <label>Code postal</label>
                      <Form.Control
                          required
                          autoComplete="new-password"
                          type="text"
                          placeholder="Code postal *"
                          value={codePostal}
                          onChange={ e =>  {setCodePostal(e.target.value)}}
                          className={ errorText && codePostal==="" ? "is-invalid":""}
                      />
                  </Form.Group>
              </Row> 
          </div>
          
        </DialogContent>
        <DialogActions>
          <div className='dialogBottom'>
            <div>
                {
                    errorText === ""
                    ?<></>
                    :<p className={errorText ? "col-md-10 error" : "col-md-10 valid-champ"}>
                            {errorText}
                        </p>
                }
            </div>
            <div>
                <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon />} onClick={()=>setupdate_service(null)} >
                    Annuler
                </Button>
                <Button variant="contained" className="submit_but valide_but" startIcon={<EditIcon />} onClick={submit} >
                    Modifier
                </Button>  
            </div>
               
          </div>
        </DialogActions>

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>

      </Dialog>
    )
}

export default Update_serv_modal
