import './categories_list.css';
import React, { useEffect,useState } from 'react';
import { useHistory } from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "react-js-pagination";
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@mui/material/Tooltip';

import {makeStyles} from '@material-ui/core/styles';
import Loading_animation_view from '../../loading_animation_view/loading_animation_view'
import { archive_categorie, get_categories } from '../../../services/company_admin_service';
import Add_categorie_modal from './add_categorie_modal/add_categorie_modal';
import { Fab } from '@material-ui/core';




const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const columns = [
    { id: 'categorie', label: "Famille", minWidth: 0 },
    { id: 'categorie_stat', label: "Statut de Famille", minWidth: 0 },
  ];
  

function state(categorie_state){
    return(
        <>
        {
            categorie_state === 1
            ?<Button variant="contained" className="status_deny">{"archivée"}</Button>
            :<Button variant="contained" className="status_success">{"non archivée"}</Button>
        }
        </>

    );
}


function Categories({categories,dont_archive,archive,setupdate_categorie,setadd_categorie_modal}){
    
    return(
        <>
        {
            categories.map((categorie,id) => (
                <TableRow key={"categorie"+id}>
                    <TableCell align="left">
                        <div style={{width:'fit-content'}}>
                            {categorie.nom}
                            <div style={{width:'100%',display:'flex'}}>
                                <Tooltip title="modifier" placement="top" >
                                    <span className={"editIcon"}  onClick={() => {setupdate_categorie(categorie);setadd_categorie_modal(true)}} >
                                            <EditIcon className="my_icon" style={{cursor:'pointer'}} />
                                    </span>
                                </Tooltip>
                            </div>
                        </div>
                    </TableCell>
                    <TableCell align="center">
                        <div className='documentsStatus_valide'  >
                          <div>
                            <div><CheckIcon className="checkIcon" onClick={()=>dont_archive(id,categorie.id_categorie)} /></div>
                            <div><CloseSharpIcon className="closeSharpIcon" onClick={()=>archive(id,categorie.id_categorie)} /></div>
                          </div>
                          {state(categorie.archived)}            
                        </div>
                    </TableCell>
                </TableRow>                
             ))
        }
        </>
    );
}



function Categories_list() {

  const classes = useStyles();
  const [url_updated , setUrl_updated] = useState(null);
  let history = useHistory();

  const [categorie_state,setcategorie_state] = useState("default");
  const [categories,setcategories]=useState(null);
  const [waiting, setWaiting] = useState(false);

  const [add_categorie_modal, setadd_categorie_modal] = useState(false);
  const [categorie_added, setCategorie_added] = useState(null);
  const [update_categorie, setupdate_categorie] = useState(null);

    const [currentPage,setCurrentPage]=useState(1);
    const [dataPerPage,setdataPerPage] =useState(6);
    const [data_count,setdata_count]=useState(0);
    
  useEffect(()=>{
    setcategories(null);
    setdata_count(0);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let categorie_state_tmp = "default";

    async function load_data(){
        if(urlParams.get('famille')){
            setcategorie_state(urlParams.get('famille'));
            categorie_state_tmp = urlParams.get('famille');
        }
        let page = (urlParams.get('page')?urlParams.get('page'):1);
        await setCurrentPage(page);
        await get_categories(categorie_state_tmp,page).then((response)=>{
            setcategories(response.data.data);
            setdata_count(response.data.total);
        }).catch((error)=>{
            //console.log(error.response);
        })
    }

    load_data();
    

  },[categorie_added,url_updated,history.location]);


  function select_categorie_stat(value){
    setcategorie_state(value);
    let path = window.location.href.split('?')[0]
    window.location.href = path+"?famille="+value;
  }

  function dont_archive(id,id_categorie){
    setWaiting(true);
    let formData = new FormData();
    formData.append('id_categorie',id_categorie);
    formData.append('archive',0);
    archive_categorie(formData).then((response)=>{
        let tmp_arr = [ ...categories ];
        tmp_arr[id].archived = 0;
        setcategories(tmp_arr);
        setWaiting(false);
    }).catch((error)=>{
        //console.log(error.response);
        setWaiting(false);
    })
  }

  function archive(id,id_categorie){
    setWaiting(true);
    let formData = new FormData();
    formData.append('id_categorie',id_categorie);
    formData.append('archive',1);
    archive_categorie(formData).then((response)=>{
        let tmp_arr = [ ...categories ];
        tmp_arr[id].archived = 1;
        setcategories(tmp_arr);
        setWaiting(false);
    }).catch((error)=>{
        //console.log(error.response);
        setWaiting(false);
    })
  }

  async function change_page(page){
    await setCurrentPage(page);
    let path = history.location.pathname;
    let params = history.location.search;
    let search_params = params.split('&');
    let tmp_params = path;
    if(!params) history.push(path+"?page="+page);
    else{
      for(let i in search_params){
        if(search_params[i].includes("page=")){
          if(i==0) tmp_params=tmp_params+"?page="+page;
          else tmp_params=tmp_params+"&page="+page;
        }else{
          if(i==0) tmp_params=tmp_params+search_params[i];
          else tmp_params=tmp_params+"&"+search_params[i];
        }
      }
      if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
      history.push(tmp_params);
    }
    await setUrl_updated(page);
  }

  return (
    <>
    {
        categories==null
        ?<Loading_animation_view />
        :<div className='categories_list_container'>
            <div className='categories_list_container_header'>
                    <div className='title_container'>
                        <label>Familles d'achat</label>
                        <Tooltip title="Ajouter">
                            <Fab className={waiting ? "add_new_object_fab disabled_button":"add_new_object_fab"} onClick={() => setadd_categorie_modal(true)}>
                                <AddIcon className="add_new_object_icon" />
                            </Fab>
                        </Tooltip>
                    </div>
                    <div className='select_container'>
                        <FormControl className={classes.formControl} style={{width:"225px"}} >
                            <InputLabel>Etat de famille</InputLabel>
                                <Select required
                                    value={categorie_state}
                                    onChange={e => {
                                        select_categorie_stat(e.target.value);
                                    }} name="Etat" >
                                    <MenuItem value={"default"} disabled>
                                        Etat de famille
                                    </MenuItem>
                                    <MenuItem value={"recent"}>Récent</MenuItem>
                                    <MenuItem value={"old"}>Ancien</MenuItem>
                                    <MenuItem value={"archived"}>Archivée</MenuItem>
                                    <MenuItem value={"no_archived"}>Non Archivée</MenuItem>
                                </Select>
                        </FormControl>
                    </div>
            </div>
                    <TableContainer className="categories_list_table">
                            <Table className={classes.table} aria-label="sticky table">
                                <TableHead>
                                <TableRow>
                                    {
                                        columns.map((column) => (
                                            <TableCell key={column.id} align="left">{column.label}</TableCell>
                                        ))
                                    }
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    categories.length!==0
                                    ?
                                    <>
                                        <Categories categories={categories} dont_archive={dont_archive} archive={archive} 
                                        setadd_categorie_modal={setadd_categorie_modal} setupdate_categorie={setupdate_categorie} />
                                    </>
                                    :<div style={{margin:"10px",fontSize:".8rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucune famille disponible</div>
                                }
                                </TableBody> 
                            </Table>
                        </TableContainer>
                        {
                            categories.length!==0
                                ?<div className="pagination_container">
                                    <Pagination
                                        activePage={Number(currentPage)}
                                        itemsCountPerPage={dataPerPage}
                                        totalItemsCount={Number(data_count)}
                                        pageRangeDisplayed={4}
                                        onChange={change_page.bind(this)}
                                    />
                                </div>
                                :<></>
                            }
            </div>
        }

        {
            add_categorie_modal!==false
            ?<Add_categorie_modal add_categorie_modal={add_categorie_modal} 
                    setadd_categorie_modal={setadd_categorie_modal} setCategorie_added={setCategorie_added} update_categorie={update_categorie} setupdate_categorie={setupdate_categorie} />
            :<></>
        }

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </>
  );
}

export default Categories_list;