import './auditors_list.css';
import React, { useEffect,useState } from 'react';
import { useHistory } from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "react-js-pagination";

import {makeStyles} from '@material-ui/core/styles';
import Loading_animation_view from '../../loading_animation_view/loading_animation_view'
import { get_auditors_for_cadmin, archive_auditor } from '../../../services/company_admin_service';




const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const columns = [
    { id: 'depart', label: "Département", minWidth: 0 },
    { id: 'position', label: "Position", minWidth: 0 },
    { id: 'fullName', label: "Nom complet", minWidth: 0 },
    { id: 'cnss', label: "CNSS", minWidth: 0 },
    { id: 'phnumber', label: "Téléphone", minWidth: 0 },
    { id: 'created_at', label: "Date de d'ajout", minWidth: 0 },
    { id: 'auditor_stat', label: "Statut de auditor", minWidth: 0 },
  ];
  

function state(auditor_state){
    return(
        <>
        {
            auditor_state === 1
            ?<Button variant="contained" className="status_deny">{"archivée"}</Button>
            :<Button variant="contained" className="status_success">{"non archivée"}</Button>
        }
        </>

    );
}


function Auditors({auditors,dont_archive,archive}){
    
    return(
        <>
        {
            auditors.map((auditor,id) => (
                <TableRow key={"auditor"+id}>
                    <TableCell align="left">{auditor.departement}</TableCell>
                    <TableCell align="left">{auditor.position}</TableCell>
                    <TableCell align="left">{auditor.fullName}</TableCell>
                    <TableCell align="left">{auditor.CNSS}</TableCell>
                    <TableCell align="left">{auditor.phoneNumber}</TableCell>
                    <TableCell align="left">{(new Date(auditor.created_at)).toLocaleString().split(' ')[0]}</TableCell>
                    <TableCell align="center">
                        <div className='documentsStatus_valide'  >
                          <div>
                            <div><CheckIcon className="checkIcon" onClick={()=>dont_archive(id,auditor.id)} /></div>
                            <div><CloseSharpIcon className="closeSharpIcon" onClick={()=>archive(id,auditor.id)} /></div>
                          </div>
                          {state(auditor.archived)}            
                        </div>
                    </TableCell>
                </TableRow>                
             ))
        }
        </>
    );
}



function Auditors_list() {

  const classes = useStyles();
  const [url_updated , setUrl_updated] = useState(null);
  let history = useHistory();

  const [auditor_role,setauditor_role] = useState("default");
  const [auditor_state,setauditor_state] = useState("default");
  const [auditors,setauditors]=useState(null);
  const [waiting, setWaiting] = useState(false);

  const [currentPage,setCurrentPage]=useState(1);
  const [dataPerPage,setdataPerPage] =useState(6);
  const [data_count,setdata_count]=useState(0);

  useEffect(()=>{
    setdata_count(0);
    setauditors(null);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    let auditor_state_tmp = "default"
    let auditor_dep_tmp = "default"

    async function load_data(){
      if(urlParams.get('mbr_statut')){
            setauditor_state(urlParams.get('mbr_statut'));
            auditor_state_tmp = urlParams.get('mbr_statut');
        }
        if(urlParams.get('mbr')){
            setauditor_role(urlParams.get('mbr'));
            auditor_dep_tmp = urlParams.get('mbr');
        }
        let page = (urlParams.get('page')?urlParams.get('page'):1);
        await setCurrentPage(page);
        await get_auditors_for_cadmin(auditor_dep_tmp,auditor_state_tmp,page).then((response)=>{
            setdata_count(response.data.total);
            setauditors(response.data.data);
        }).catch((error)=>{
            //console.log(error.response);
        })  
    }
    load_data();

    },[url_updated,history.location]);



  function select_auditor_role(value){
    setauditor_role(value);
    let path = window.location.href.split('?')[0]
    window.location.href = path+"?mbr="+value;
  }

  function select_auditor_stat(value){
    setauditor_state(value);
    let path = window.location.href.split('?')[0]
    if( auditor_role === "default" ) window.location.href = path+"?mbr_statut="+value;
    else window.location.href = path+"?mbr="+auditor_role+"&mbr_statut="+value;
  }

  function dont_archive(id,auditor_id){
    setWaiting(true);
    let formData = new FormData();
    formData.append('auditor_id',auditor_id);
    formData.append('archive',0);
    archive_auditor(formData).then((response)=>{
        let tmp_arr = [ ...auditors ];
        tmp_arr[id].archived = 0;
        setauditors(tmp_arr);
        setWaiting(false);
    }).catch((error)=>{
        //console.log(error.response);
        setWaiting(false);
    })
  }

  function archive(id,auditor_id){
    setWaiting(true);
    let formData = new FormData();
    formData.append('auditor_id',auditor_id);
    formData.append('archive',1);
    archive_auditor(formData).then((response)=>{
        let tmp_arr = [ ...auditors ];
        tmp_arr[id].archived = 1;
        setauditors(tmp_arr);
        setWaiting(false);
    }).catch((error)=>{
        //console.log(error.response);
        setWaiting(false);
    })
  }

  async function change_page(page){
    await setCurrentPage(page);
    let path = history.location.pathname;
    let params = history.location.search;
    let search_params = params.split('&');
    let tmp_params = path;
    if(!params) history.push(path+"?page="+page);
    else{
      for(let i in search_params){
        if(search_params[i].includes("page=")){
          if(i==0) tmp_params=tmp_params+"?page="+page;
          else tmp_params=tmp_params+"&page="+page;
        }else{
          if(i==0) tmp_params=tmp_params+search_params[i];
          else tmp_params=tmp_params+"&"+search_params[i];
        }
      }
      if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
      history.push(tmp_params);
    }
    await setUrl_updated(page);
  }


  return (
    <>
    {
        auditors==null
        ?<Loading_animation_view />
        :<div className='auditors_list_container'>
            <div className='auditors_list_container_header'>
                    <div className='title_container'><label>Membres de commission</label></div>
                    <div className='select_container'>
                        <FormControl className={classes.formControl} style={{width:"225px",marginRight:'20px'}} >
                            <InputLabel>Département</InputLabel>
                                <Select required
                                    value={auditor_role}
                                    onChange={e => {
                                        select_auditor_role(e.target.value);
                                    }} name="Etat" >
                                    <MenuItem value={"default"}>
                                            Département
                                    </MenuItem>
                                    <MenuItem value={"technique"}>{"membre technique"}</MenuItem>
                                    <MenuItem value={"finance"}>{"membre de finance"}</MenuItem>
                                    <MenuItem value={"juridique"}>{"membre juridique"}</MenuItem>
                                </Select>
                        </FormControl>

                        <FormControl className={classes.formControl} style={{width:"225px"}} >
                            <InputLabel>Etat de membre</InputLabel>
                                <Select required
                                    value={auditor_state}
                                    onChange={e => {
                                        select_auditor_stat(e.target.value);
                                    }} name="Etat" >
                                    <MenuItem value={"default"} disabled>
                                        Etat de membre
                                    </MenuItem>
                                    <MenuItem value={"recent"}>Récent</MenuItem>
                                    <MenuItem value={"old"}>Ancien</MenuItem>
                                    <MenuItem value={"archived"}>Archivée</MenuItem>
                                    <MenuItem value={"no_archived"}>Non Archivée</MenuItem>
                                </Select>
                        </FormControl>
                    </div>
            </div>
                    <TableContainer className="auditors_list_table">
                            <Table className={classes.table} aria-label="sticky table">
                                <TableHead>
                                <TableRow>
                                    {
                                        columns.map((column) => (
                                            <TableCell key={column.id} align="left">{column.label}</TableCell>
                                        ))
                                    }
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    auditors.length!==0
                                    ?
                                    <>
                                        <Auditors auditors={auditors} dont_archive={dont_archive} archive={archive} />
                                    </>
                                    :<div style={{margin:"10px",fontSize:".8rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucun auditor disponible</div>
                                }
                                </TableBody> 
                            </Table>
                        </TableContainer>
                        {
                            auditors.length!==0
                                ?<div className="pagination_container">
                                    <Pagination
                                        activePage={Number(currentPage)}
                                        itemsCountPerPage={dataPerPage}
                                        totalItemsCount={Number(data_count)}
                                        pageRangeDisplayed={4}
                                        onChange={change_page.bind(this)}
                                    />
                                </div>
                                :<></>
                            }
            </div>
        }
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </>
  );
}

export default Auditors_list;