import './forms_list.css';
import React,{useState, useEffect} from 'react';
import { useHistory } from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "react-js-pagination";
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@mui/material/Tooltip';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Fab from '@mui/material/Fab';


import Loading_animation_view from '../../loading_animation_view/loading_animation_view'
import Questions_list from './questions/questions';
import { get_forms_types_admin, get_forms_models_admin } from '../../../services/company_admin_service';


const columns = [
    { id: 'title', label: "Titre", minWidth: 0 },
    { id: 'forms_type', label: "Type du formulaire", minWidth: 0 },
    { id: 'created_at', label: "Date de création", minWidth: 0 },
    { id: 'updated_at', label: "Date de modification", minWidth: 0 },
    { id: 'archieve', label: "Archiver", minWidth: 0 },
    { id: 'view', label: "Visualiser", minWidth: 0 },
  ];


function state(service_state){
    return(
        <>
        {
            service_state === 1
            ?<Button variant="contained" className="status_deny">{"archivée"}</Button>
            :<Button variant="contained" className="status_success">{"non archivée"}</Button>
        }
        </>

    );
}


const Forms_list = ()=>{

    const [url_updated , setUrl_updated] = useState(null);
    let history = useHistory();

    const [forms_types,setforms_types] = useState([]);

    const [form_type,setform_type] = useState("default");
    const [forms,setforms]=useState(null);

    const [selected_form, setSelected_form] = useState(null);
    const [waiting, setWaiting] = useState(false);

    const [currentPage,setCurrentPage]=useState(1);
    const [dataPerPage,setdataPerPage] =useState(6);
    const [data_count,setdata_count]=useState(0);


    useEffect(()=>{
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let selected_form_tmp = null;
        
        async function load_data(){
            setforms(null);
            await get_forms_types_admin().then((response)=>{
                console.log(response.data);
                let tmp_arr = [...forms_types];
                (response.data).forEach((elem)=>{
                    tmp_arr.push(elem.title);
                });
                setforms_types(tmp_arr);
            }).catch((error)=>{
                //console.log(error.response);
            });

            const forms_tmp = await get_forms_models_admin().then((response)=>{
                setforms(response.data);
                return response.data;
            }).catch((error)=>{
                //console.log(error.response);
            });

            selected_form_tmp = (urlParams.get('id')?urlParams.get('id'):null);
            if(selected_form_tmp==null) setSelected_form(null);
            else{
                forms_tmp.forEach(form => {
                    if(selected_form_tmp == form.id) setSelected_form(form);
                });
            }
        }

        load_data();

    },[history.location,url_updated]);

    history.listen((location, action) => {
        setUrl_updated(location.search);
    },[]);

    function select_form_type(value){
        setform_type(value);
        let path = window.location.href.split('?')[0]
        window.location.href = path+"?serv="+value;
    }

    function view_questions(form){
        let path = history.location.pathname;
        history.push(path+"?id="+form.id);
        setUrl_updated("?id="+form.id);
        setSelected_form(form);
    }
      
    async function change_page(page){
        await setCurrentPage(page);
        let path = history.location.pathname;
        let params = history.location.search;
        let search_params = params.split('&');
        let tmp_params = path;
        if(!params) history.push(path+"?page="+page);
        else{
          for(let i in search_params){
            if(search_params[i].includes("page=")){
              if(i==0) tmp_params=tmp_params+"?page="+page;
              else tmp_params=tmp_params+"&page="+page;
            }else{
              if(i==0) tmp_params=tmp_params+search_params[i];
              else tmp_params=tmp_params+"&"+search_params[i];
            }
          }
          if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
          history.push(tmp_params);
        }
        await setUrl_updated(page);
      }



    return (
        <>
        {
            selected_form==null
            ?<>
            {
            forms==null || forms_types.length==0
            ?<Loading_animation_view />
            :<div className='forms_list_container'>
                <div className='forms_list_container_header'>
                        <div className='title_container'><label>Formulaires</label></div>
                        <div className='select_container'>

                            <FormControl style={{width:"225px",marginRight:'20px'}} >
                                <InputLabel>Formulaires</InputLabel>
                                    <Select required
                                        value={form_type}
                                        onChange={e => {
                                            select_form_type(e.target.value);
                                        }} name="Etat" >
                                        <MenuItem value={"default"}>
                                            Tous les formulaires
                                        </MenuItem>
                                          {
                                            forms_types.map((forms_type,ind) => (
                                                <MenuItem value={ind+1} key={ind}>{forms_type}</MenuItem>
                                            ))
                                          }
                                    </Select>
                            </FormControl>
    
                            {/* <FormControl className={classes.formControl} style={{width:"225px"}} >
                                <InputLabel>Etat de service</InputLabel>
                                    <Select required
                                        value={service_state}
                                        onChange={e => {
                                            select_service_stat(e.target.value);
                                        }} name="Etat" >
                                        <MenuItem value={"default"} disabled>
                                            Etat de service
                                        </MenuItem>
                                        <MenuItem value={"recent"}>Récent</MenuItem>
                                        <MenuItem value={"old"}>Ancien</MenuItem>
                                        <MenuItem value={"archived"}>Archivée</MenuItem>
                                        <MenuItem value={"no_archived"}>Non Archivée</MenuItem>
                                    </Select>
                            </FormControl> */}

                        </div>
                </div>
                        <TableContainer className="forms_list_table">
                                <Table aria-label="sticky table">
                                    <TableHead>
                                    <TableRow>
                                        {
                                            columns.map((column) => (
                                                <TableCell key={column.id} align="left">{column.label}</TableCell>
                                            ))
                                        }
                                    </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {
                                        forms.length!==0
                                        ?
                                        <>
                                        {
                                            forms.map((form,id) => (
                                                <TableRow key={"form"+id}>
                                                    <TableCell align="left">
                                                        <div style={{width:'fit-content'}}>
                                                            {form.title}
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left">{(forms_types[form.form_type - 1])}</TableCell>
                                                    <TableCell align="left">{(new Date(form.created_at)).toLocaleString().split(' ')[0]}</TableCell>
                                                    <TableCell align="left">{(new Date(form.updated_at)).toLocaleString().split(' ')[0]}</TableCell>
                                                    <TableCell align="center">
                                                        <div className='documentsStatus_valide'>
                                                        <div>
                                                            <div><CheckIcon className="checkIcon" /></div>
                                                            <div><CloseSharpIcon className="closeSharpIcon" /></div>
                                                        </div>
                                                            {state(form.archived)}            
                                                        </div>
                                                    </TableCell>
                                                    <TableCell align="left">
                                                        {/* <Fab size="small" color="secondary" style={{backgroundColor:'#ef233c',color:"white"}} onClick={()=>view_questions(form)}>  
                                                        </Fab> */}
                                                        <VisibilityIcon  className="my_icon editIcon" onClick={()=>view_questions(form)} />

                                                    </TableCell>
                                                </TableRow>                
                                            ))
                                        }
                                        </>
                                        :<div style={{margin:"10px",fontSize:".8rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucun formulaire disponible</div>
                                    }
                                    </TableBody> 
                                </Table>
                            </TableContainer>
    
                            {
                                forms.length!==0
                                    ?<div className="pagination_container">
                                        <Pagination
                                            activePage={Number(currentPage)}
                                            itemsCountPerPage={dataPerPage}
                                            totalItemsCount={Number(data_count)}
                                            pageRangeDisplayed={4}
                                            onChange={change_page.bind(this)}
                                        />
                                    </div>
                                    :<></>
                                }
                </div>
            }
            </>
            :<Questions_list selected_form={selected_form} setselected_form={setSelected_form} />
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
}

export default Forms_list;