import './widget_count_elements.css'
import React from 'react';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

function numberWithSpaces(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

const Widget_count_elements = ({setShowing_elements,fontColor,elements_number,progress}) => {
  const show = React.useCallback(() => setShowing_elements(true), []);

  return (
   <div className={elements_number!==0 && elements_number!=="0 jrs" && elements_number!=="0 MAD" ?'widget_count_elems_container':'widget_count_elems_container disabled_button'} 
        style={{color:fontColor?fontColor:"#526c79"}}  onTouchStartCapture={(e)=>{e.stopPropagation();show()}}
        onDoubleClick={show}>
        <div className="number_arrow_container">
          {
              progress === "up"?<span className='arrow_container'><ArrowDropUpIcon className="progress_icon" /></span>
                                :<span className='arrow_container' style={{color:"red"}}><ArrowDropDownIcon className="progress_icon" /></span>
          }
          {numberWithSpaces(elements_number)}
        </div>
        
      </div>
      
  );
}

export default React.memo(Widget_count_elements)