import '../../../../assets/css/setContract_forSubmission.css';
import React, { useState , useEffect } from 'react'
import { lighten, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@mui/material/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Fab from '@mui/material/Fab';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {download_contractPDF} from '../../../../services/purchase_service'

import Loading_animation_view from '../../../loading_animation_view/loading_animation_view'
import 'suneditor/dist/css/suneditor.min.css';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
    input: {
      display: 'none',
    }
  }));


function Contract_content_view({view_subType_contract,close_view,contract_subtype,id_submission,contract_type}){

    const classes = useToolbarStyles();


    const [contract_signed,setContract_signed] = useState(true);


  function download_contract_pdf(){
    const formData = new FormData();
    formData.append('id_submission',id_submission);
    formData.append('contract_id',contract_subtype.contract_id);
    download_contractPDF(formData).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', (id_submission)+"_contrat"+contract_subtype.id+".pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
    }).catch(err=>{
      console.log(err.response);
    });
  }



    return (
      <Dialog disableEnforceFocus fullScreen open={view_subType_contract!=null} onClose={close_view} className="my_full_dialog contract_full_dialog">

        <AppBar className={classes.appBar}>
            <Toolbar className="contract_content_toolbar">
                <div className="contract_content_toolbar_main_container">
                    <div className="contract_content_toolbar_left">
                      <div className='page_title'>
                        <IconButton edge="start" color="inherit" onClick={close_view} aria-label="close" >
                            <CloseIcon className="my_icon" />
                        </IconButton>
                        <span>{"Contrat "+contract_type}</span>
                      </div>
                      <div className='search_filter_container'>
                        <Tooltip title="Sous types de contrat" placement="bottom" >
                          <div className='select_container' >
                            <FormControl variant="standard" className="header_form" disabled>
                                      <Select required value={contract_subtype.contract_id}>
                                          <MenuItem value={contract_subtype.contract_id}>{contract_subtype.contract_subtype}</MenuItem>
                                      </Select>
                              </FormControl>
                          </div>
                        </Tooltip>

                        <Tooltip title="" placement="bottom" >
                            <span style={{marginLeft:'6px'}}>
                                <Button className={ contract_signed ? "status_success" : "status_waiting"}>
                                    { contract_signed ? "signé" : "En cours"}
                                </Button>
                            </span>
                        </Tooltip>
                      </div>
                    </div>
                    <div className="contract_content_toolbar_right">
                        <Tooltip title="Exporter en fichier pdf" placement="bottom" >
                          <span style={{marginLeft:'6px'}}>
                          <Fab onClick={download_contract_pdf} size="small" color="warning" >
                            <GetAppRoundedIcon className="my_icon" />
                          </Fab>
                          </span>
                        </Tooltip>
                    </div>
                </div>
                
            </Toolbar>
        </AppBar>
        {
          view_subType_contract!=null
          ?<div className="text_editor_container">
             <iframe src={view_subType_contract} width="100%" height="100%" />
           </div>
          :<Loading_animation_view />
        }
        
    </Dialog>
    );
}

export default Contract_content_view;
