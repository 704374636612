import './bills_count_byState.css';
import React, { useEffect,useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from "@material-ui/core/Input";

import {makeStyles} from '@material-ui/core/styles';
import { billsWidget_by_business } from '../../../../services/business_service';
import Loading_animation_view from '../../../loading_animation_view/loading_animation_view';



const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
      width: '100%',
    },
    input: {
      display: 'none',
    },
    container: {
      maxHeight: 440,
    },
    select: {
      "&:focus": {
        backgroundColor: "white"
      }
    },
    selectInput: {
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottomWidth: 0
      },
      "&:after": {
        borderBottomWidth: 0
      }
    },
    disabled: {},
    focused: {},
    error: {}
  }));

  

  function setState(bill_state,financeService_accord,purchaseService_accord,businessService_accord){
    return(
      <>
        <Tooltip title="" placement="bottom" >
            <span style={{marginLeft:'2%'}}>
              {
                bill_state==="refusée" || financeService_accord==="0" || purchaseService_accord==="0" || businessService_accord==="0"
                ?<Button className="status_deny" >{"refusée"}</Button>
                :bill_state==="comptabilisée"
                  ?<Button className="status_success" >{bill_state}</Button>
                    :bill_state==="à valider" && (purchaseService_accord===null || businessService_accord===null)
                    ?<Button className="status_success" >{bill_state}</Button>
                      :bill_state==="mis en règlement"
                      ?<Button className="status_success" >{bill_state}</Button>
                      :bill_state==="réglée"
                      ?<Button className="status_success" >{bill_state}</Button>
                      :bill_state==="archivée"
                        ?<Button className="status_success" >{bill_state}</Button>
                        :<Button className="status_waiting" >{"en cours"}</Button>
              }
          </span>
        </Tooltip>
      </>
    );
  }

  
function Widget_count_elements({setShowing_elements,fontColor,elements_number,progress}) {

    return (
      <div className={elements_number!==0 && elements_number!=="0 jrs" && elements_number!=="0 MAD" ?'widget_count_elems_container':'widget_count_elems_container disabled_button'} 
              style={{color:fontColor?fontColor:"#526c79"}}
              onDoubleClick={()=>setShowing_elements(true)}>
            <div className="number_arrow_container">
              {
                  progress === "up"?<span className='arrow_container'><ArrowDropUpIcon className="progress_icon" /></span>
                                    :<span className='arrow_container' style={{color:"red"}}><ArrowDropDownIcon className="progress_icon" /></span>
              }
              {elements_number}
            </div>
            
        </div>
    );
}

 
const markets_bills_columns = [
  { id: 'code', label: "Code de marché", minWidth: 0,align: 'left'  },
  { id: 'amount', label: "Montant d'appel", minWidth: 0 ,align: 'left' },
  { id: 'bills', label: "Factures", minWidth: 0,align: 'left'  },
  { id: 'ht', label: "Montant hors Taxes", minWidth: 0,align: 'left'  },
  { id: 'limite_date', label: "Date d'échéance", minWidth: 0,align: 'left'  },
  { id: 'closed_date', label: "Date de réglement", minWidth: 0,align: 'left'  },
  { id: 'state', label: "Statut", minWidth: 0,align: 'left'  },
];


const orders_bills_columns = [
  { id: 'code', label: "Code de demande", minWidth: 0,align: 'left'  },
  { id: 'requester', label: "Demandeur", minWidth: 0 ,align: 'left' },
  { id: 'serv', label: "Service d'achat", minWidth: 0 ,align: 'left' },
  { id: 'nm', label: "Nm. commande", minWidth: 0 ,align: 'left' },
  { id: 'amount', label: "Montant", minWidth: 0 ,align: 'left' },
  { id: 'bills', label: "Factures", minWidth: 0,align: 'left'  },
  { id: 'ht', label: "Montant hors Taxes", minWidth: 0,align: 'left'  },
  { id: 'limite_date', label: "Date d'échéance", minWidth: 0,align: 'left'  },
  { id: 'closed_date', label: "Date de réglement", minWidth: 0,align: 'left'  },
  { id: 'state', label: "Statut", minWidth: 0,align: 'left'  },
];

function Markets({markets}){
    
    return(
        <>
        {
            markets.map((market,id) => (
                <TableRow key={"market_col"+id}>
                    <TableCell align="left">{market.submission_id}</TableCell>
                    <TableCell align="left">{market.offer_amount+" MAD"}</TableCell>
                    <TableCell align="center">
                      {
                        (market.bills).map((bill,id2) => (
                          <div className='bill_lign' key={id2+id}>
                              {"n° "+bill.bill_number}
                          </div>
                        ))
                      }
                    </TableCell>
                    <TableCell align="center">
                      {
                        (market.bills).map((bill,id2) => (
                          <div className='bill_lign' key={id2+id}>
                            {
                                bill.amount_without_tax!==null
                                ?<>{bill.amount_without_tax+" MAD"}</>
                                :<span style={{opacity:".8"}} >non défini</span>
                              }
                          </div>
                        ))
                      }
                    </TableCell>
                    <TableCell align="center">
                      {
                        (market.bills).map((bill,id2) => (
                          <div className='bill_lign' key={id2+id}>
                              {
                                    bill.expiration_date!==null
                                    ?(new Date(bill.expiration_date)).toLocaleString().split(/[,| ]/)[0]
                                    :<span style={{opacity:".8"}} >non défini</span>
                                  }
                          </div>
                        ))
                      }
                    </TableCell>
                    <TableCell align="center">
                      {
                        (market.bills).map((bill,id2) => (
                          <div className='bill_lign' key={id2+id}>
                                        {bill.paid_date?(new Date(bill.paid_date)).toLocaleString().split(/[,| ]/)[0]:<span style={{opacity:".8"}} >Pas encore</span>}
                          </div>
                        ))
                      }
                    </TableCell>
                    <TableCell align="center">
                      {
                        (market.bills).map((bill,id2) => (
                          <div className='bill_lign' key={id2+id}>
                                        {setState(bill.bill_state,bill.financeService_accord,bill.purchaseService_accord,bill.businessService_accord)}
                          </div>
                        ))
                      }
                    </TableCell>
                </TableRow>                
             ))
        }
        </>
    );
}




function Orders({orders}){
    
  return(
      <>
      {
          orders.map((market,id) => (
              <TableRow key={"market_col"+id}>
                  <TableCell align="left">{market.request_code}</TableCell>
                  <TableCell align="left">{market.requester_fullName}</TableCell>
                  <TableCell align="left">{market.service_fullName}</TableCell>
                  <TableCell align="left">{market.order_number}</TableCell>
                  <TableCell align="left">{market.order_amount+" MAD"}</TableCell>
                  <TableCell align="center">
                    {
                      (market.bills).map((bill,id2) => (
                        <div className='bill_lign' key={id2+id}>
                            {"n° "+bill.bill_number}
                        </div>
                      ))
                    }
                  </TableCell>
                  <TableCell align="center">
                    {
                      (market.bills).map((bill,id2) => (
                        <div className='bill_lign' key={id2+id}>
                           {
                              bill.amount_without_tax!==null
                              ?<>{bill.amount_without_tax+" MAD"}</>
                              :<span style={{opacity:".8"}} >non défini</span>
                            }
                        </div>
                      ))
                    }
                  </TableCell>
                  <TableCell align="center">
                    {
                      (market.bills).map((bill,id2) => (
                        <div className='bill_lign' key={id2+id}>
                             {
                                  bill.expiration_date!==null
                                  ?(new Date(bill.expiration_date)).toLocaleString().split(/[,| ]/)[0]
                                  :<span style={{opacity:".8"}} >non défini</span>
                                }
                        </div>
                      ))
                    }
                  </TableCell>
                  <TableCell align="center">
                    {
                      (market.bills).map((bill,id2) => (
                        <div className='bill_lign' key={id2+id}>
                                      {bill.paid_date?(new Date(bill.paid_date)).toLocaleString().split(/[,| ]/)[0]:<span style={{opacity:".8"}} >Pas encore</span>}
                        </div>
                      ))
                    }
                  </TableCell>
                  <TableCell align="center">
                    {
                      (market.bills).map((bill,id2) => (
                        <div className='bill_lign' key={id2+id}>
                                      {setState(bill.bill_state,bill.financeService_accord,bill.purchaseService_accord,bill.businessService_accord)}
                        </div>
                      ))
                    }
                  </TableCell>
              </TableRow>                
           ))
      }
      </>
  );
}



const Bills_count_byState = ({id_business,time_range})=>{

    const classes = useStyles();
    const selectInputClasses = {
      root: classes.selectInput,
      disabled: classes.disabled,
      focused: classes.focused,
      error: classes.error
    };

    const [selected_bills_count_byState,setSelected_bills_count_byState]=useState("all");
    const [show_elemnts,setShowing_elements] = useState(false);
    const [content_charged,setContent_charged]=useState(null);
    const [filter_bills_src,setFilter_bills_src]=useState("marchés");
    const [data,setData] = useState(false);


    useEffect(()=>{
      setContent_charged(null);
        setShowing_elements(false);
        setSelected_bills_count_byState("all");
      billsWidget_by_business(id_business,time_range,filter_bills_src,selected_bills_count_byState).then((response)=>{
            setData(response.data);
            setContent_charged(true);
        }).catch((error)=>{
            //console.log(error.response);
        });
    },[filter_bills_src]);

    function set_bills_count_byState_to_display(value){
        setContent_charged(null);
        setShowing_elements(false);
        setSelected_bills_count_byState(value);
        billsWidget_by_business(id_business,time_range,filter_bills_src,value).then((response)=>{
            setData(response.data);
            setContent_charged(true);
        }).catch((error)=>{
            //console.log(error.response);
        });
    }


  return (
    <div className='charts_of_bills_count_byState_container'>
     <div className="bills_count_byState_state">
        <Tooltip title="source" placement="left">
            <span>
            <FormControl variant="standard" className="header_form">
                <Select required value={filter_bills_src} inputProps={{ classes: { select: classes.select } }}
                        input={<Input classes={selectInputClasses} />}
                        onChange={e => {setFilter_bills_src(e.target.value) }} >
                        <MenuItem value={"marchés"} >{"Marchés"}</MenuItem>
                        <MenuItem value={"commandes"} >{"Bons de commande"}</MenuItem>
                </Select>
            </FormControl>
            </span>
        </Tooltip>
        <Tooltip title="Status" placement="left">
            <span>
            <FormControl variant="standard"  className="header_form" >
                <Select required value={selected_bills_count_byState} 
                    onChange={e => {set_bills_count_byState_to_display(e.target.value) }}
                    input={<Input classes={selectInputClasses} />}
                    inputProps={{ classes: { select: classes.select } }}
                    >
                    
                    <MenuItem value={"all"}>Tous</MenuItem>
                    <MenuItem value={"en cours"}>En cours</MenuItem>
                    <MenuItem value={"à valider"}>A validée</MenuItem>
                    <MenuItem value={"comptabilisée"}>Comptabilisée</MenuItem>
                    <MenuItem value={"refusée"}>Refusée</MenuItem>
                    <MenuItem value={"réglée"}>Réglée</MenuItem>
                    <MenuItem value={"archivée"}>Archivée</MenuItem>
                    
                </Select>
            </FormControl>
            </span>
        </Tooltip>
    </div>
     <div className='container'>
       {
         content_charged===null
         ?<Loading_animation_view />
         :<>
          {
                show_elemnts!==true 
                ?<Widget_count_elements setShowing_elements={setShowing_elements} elements_number={data.count} fontColor="#51a5cf" progress={data.progress} />
                :<TableContainer className="bills_widget_table_container" component={Paper} onDoubleClick={() => setShowing_elements(false)} style={{cursor:'pointer'}} onTouchStartCapture={(e)=>{e.stopPropagation();setShowing_elements(false)}} >
                        {
                          filter_bills_src==="marchés"
                          ?<Table className={classes.table} aria-label="sticky table">
                                <TableHead>
                                <TableRow>
                                    {
                                        markets_bills_columns.map((column) => (
                                            <TableCell key={column.id} align={column.align}>{column.label}</TableCell>
                                        ))
                                    }
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    data.data.length!==0
                                    ?
                                    <>
                                        <Markets markets={data.data} />
                                    </>
                                            
                                    :<div style={{margin:"10px",fontSize:".75rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucun marché disponible</div>
                                }
                                </TableBody> 
                            </Table>
                            :<Table className={classes.table} aria-label="sticky table">
                                <TableHead>
                                <TableRow>
                                    {
                                        orders_bills_columns.map((column) => (
                                            <TableCell key={column.id} align={column.align}>{column.label}</TableCell>
                                        ))
                                    }
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    data.data.length!==0
                                    ?
                                    <>
                                        <Orders orders={data.data} />
                                    </>
                                            
                                    :<div style={{margin:"10px",fontSize:".75rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucun marché disponible</div>
                                }
                                </TableBody> 
                            </Table>
                        
                        }
                    </TableContainer>
                }
         </>
        }
      </div>
    </div>
  );
}

export default React.memo(Bills_count_byState);
