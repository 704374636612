import './set_refuse_reason.css'
import React, { useState } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import SendIcon from '@mui/icons-material/Send';
import Backdrop from '@mui/material/Backdrop';
import swal from 'sweetalert';
import CircularProgress from '@mui/material/CircularProgress';
import { service_change_off_catalogue_state_request, service_change_order_state } from '../../../../../services/purchase_service';


function Set_refuse_reason({open_reason_modal,setOpen_reason_modal,details,setDetails}){


  const [errorText,setErrorText] = useState("");
  const [waiting, setWaiting] = useState(false);

  const [reason,setReason]=useState('');


  function submit_refuse_reason() {
    setErrorText("");
    if(reason===''){
        setErrorText("Champ vide !");
      }else{
        swal({
            title: "Etes-vous sûr ?",
            text: "!!!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
          setWaiting(true);
          open_reason_modal.append('refuse_reason',reason);
          if(!open_reason_modal.get('id')){
            service_change_off_catalogue_state_request(open_reason_modal).then((response)=>{
                //console.log(response.data);
                setWaiting(false);
                setReason('');
                setOpen_reason_modal(null);
                let tmp = { ...details };
                tmp.request_state = "refus de traitement";
                setDetails(tmp);
                swal("votre réponse a été enregistrée avec succès !", {
                        icon: "success",
                });
            }).catch((error=>{
                //console.log(error.response);
                setWaiting(false);
            }))
          }else{
            service_change_order_state(open_reason_modal).then((response)=>{
              //console.log(response.data);
              setWaiting(false);
              setReason('');
              setOpen_reason_modal(null);
              let tmp = { ...details };
              tmp.selected_suppliers[open_reason_modal.get('id')].order_state = "non retenue";
              tmp.selected_suppliers[open_reason_modal.get('id')].service_refuse_reason = reason;
              setDetails(tmp);
              swal("votre réponse a été enregistrée avec succès !", {
                      icon: "success",
              });
          }).catch((error=>{
              //console.log(error.response);
              setWaiting(false);
          }))
          }
         }
      });
      }      
   }

     

    return (
      <Dialog className="set_refuse_raison_container" fullWidth={true} maxWidth={'md'} open={open_reason_modal!=null?true:false} onClose={()=>setOpen_reason_modal(null)} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">Motif</DialogTitle>
        <DialogContent>
          <div>
                {
                    errorText == "" 
                    ?<></>
                    :<p className={errorText ? "error" : "valid-champ"}>
                            {errorText}
                        </p>
                }

              <div style={{width:"100%",marginTop:"5px"}} >
              <TextField
                  id="outlined-multiline-flexible"
                  label="Motif"
                  variant='outlined'
                  multiline
                  rows={4}
                  value={reason}
                  onChange={(e)=>setReason(e.target.value)} 
                  style={{width:"100%"}}
                />
              </div>
          </div>


          <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
          
     </DialogContent>
        <DialogActions>
          <div className='dialogBottom'>
            <div>
              <Button variant="contained" className={waiting?"submit_but cancel_but disabled_button":"submit_but cancel_but"} startIcon={<CancelIcon className="my_icon" />} onClick={()=>setOpen_reason_modal(null)} >
                  Annuler
              </Button>
              <Button variant="contained" className={waiting?"submit_but valide_but disabled_button":"submit_but valide_but"} startIcon={<SendIcon className="my_icon" />} onClick={submit_refuse_reason} >
                  Envoyer
              </Button> 
            </div>
          </div>
        </DialogActions>

      </Dialog>
    )
}

export default Set_refuse_reason
