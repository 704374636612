import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Demandes from './demandeAppel_View';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }));
  
  


function DemandeAppel_Dialog({open,handleClose,offerClicked}){

    const classes = useStyles();

    return (
        <Dialog disableEnforceFocus fullScreen open={open} onClose={handleClose} className="my_full_dialog contract_full_dialog">
            <AppBar className={classes.appBar}  >
                <Toolbar style={{backgroundColor:'#343a40'}} >
                <div className='page_title'>
                  <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close" >
                      <CloseIcon className="my_icon" />
                  </IconButton>
                  <span>Soumissions</span>
                </div>
                </Toolbar>
            </AppBar>
            <Demandes key={'submissions_table'} offerClicked={offerClicked} />
        </Dialog>
    )
}

export default DemandeAppel_Dialog;
