import '../../../../../assets/css/order_details.css';
import React, { useEffect,useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import swal from 'sweetalert';
import Button from '@material-ui/core/Button';
import Form from 'react-bootstrap/Form'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';

import { pdf } from '@react-pdf/renderer';
import empty from '../../../../empty.jpg'

import {makeStyles} from '@material-ui/core/styles';
import Loading_animation_view from '../../../../loading_animation_view/loading_animation_view';
import { get_categories, service_confirm_with_supplier,get_order_infos_for_file } from '../../../../../services/purchase_service';
import Order_form_header_pdf from './order_form_pdf/order_form_header_pdf';
import Search_prd_modal from './search_prd_modal/search_prd_modal';


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const columns = [
    { id: 'unit_amount', label: "Prix unitaire (MAD)", minWidth: 0,align: 'left'  },
    { id: 'product_promo', label: "Promo (%)", minWidth: 0,align: 'left'  },
    { id: 'count', label: "Quantité", minWidth: 0 ,align: 'left' },
    { id: 'amount', label: "Prix total (MAD)", minWidth: 0,align: 'left'  }
  ];

  function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}
  

function Products({categories,products,set_price_by_unit,order_state,set_promo_by_unit,set_amount_by_unit,disable_updates,delete_product_from_list}){
    

    function getCategorieById(id){
        var categorie="";
        categories.map((catg)=>{
          if(catg.id_categorie === id) categorie = catg.nom;
        })
        return categorie;
    }

    return(
        <>
        {
            products.map((product,id) => (
                <TableRow key={"product_col"+id}>
                    <TableCell align="left">
                        <div style={{display:"flex",alignItems:"center"}}>
                         {
                           order_state==="retenue" || order_state==="validation refusée" 
                              ?<RemoveCircleOutlineIcon className='my_icon' style={{color:"red",cursor:"pointer",marginRight:'7px'}}
                                    onClick={() => delete_product_from_list(id) } />
                              :<></>

                          }
                          
                           <span className='product_cell_img_container'>
                                <img src={product.product_pics.length!==0?`data:image/png;base64,${product.product_pics[0]}`:empty} />
                            </span>
                            <span className='product_cell_title_container'>
                                <span id="title">{product.title}</span>
                                <div className='product_cell_categorie_container'>
                                     <span>Famille d'achat : </span>{getCategorieById(product.id_categorie)}
                                </div> 
                            </span> 
                        </div>
                        
                    </TableCell>
                    <TableCell align="left">
                      <Form.Control
                          className="amount_container"
                          autoComplete="new-password"
                          type="number"
                          placeholder='0'
                          value={product.product_price}
                          onChange={(e)=>set_price_by_unit(e.target.value,id)}
                          readOnly={disable_updates}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Form.Control
                          className="amount_container"
                          autoComplete="new-password"
                          type="number"
                          placeholder='0'
                          value={product.product_promo}
                          onChange={(e)=>set_promo_by_unit(e.target.value,id)}
                          readOnly={disable_updates}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <Form.Control
                          className="amount_container"
                          autoComplete="new-password"
                          type="number"
                          value={product.product_amount}
                          onChange={(e)=>set_amount_by_unit(e.target.value,id)}
                          readOnly={disable_updates}
                      />
                    </TableCell>
                    <TableCell align="left">
                        <Form.Control
                            className="amount_container"
                            autoComplete="new-password"
                            type="number"
                            value={
                              (
                                product.product_price!=='' && !isNaN(product.product_price) 
                                    ?(product.product_amount*(product.product_price - ((product.product_promo!=='' && !isNaN(product.product_promo))?(product.product_price*product.product_promo)/100:0)))
                                    :0
                              )
                            }
                            readOnly
                        />
                    </TableCell>
                </TableRow>                
             ))
        }
        </>
    );
}



function Order_details({selected_products_details,request_code,id_supplier,selected_suppliers,setSelected_suppliers,order_key,order_number}) {

     let service =  JSON.parse(localStorage.getItem('user-info'))

    const classes = useStyles();
    const [categories,setCategories]=useState([]);
    const [products,setProducts]=useState(null);

    const [total_ht,setTotal_ht]=useState(0);
    const [vat,setvat]=useState(0);
    const [total_ttc,setTotal_ttc]=useState(0);
    const [shippingCosts,setshippingCosts]=useState(0);
    const [discount,setdiscount]=useState(0);

    const [disable_updates,setDisable_updates]=useState(false);

    const [order_state,setOrder_state]=useState(null);
    const [open_search_prd_modal,setOpen_search_prd_modal]=useState(false);

    const [wait_submitting,setWait_submitting] = useState(false);


    useEffect(()=>{
        get_categories().then((response)=>{
            setCategories(response.data);
        }).catch((error)=>{
          //console.log(error.response);
        });

        //console.log(selected_products_details);
        async function set_vat_value(val){
          setvat(val);
        }
        async function set_shipping_value(val){
          setshippingCosts(val);
        }
        async function set_discount_value(val){
          setdiscount(val);
        }
        async function set_products_value(vat,shippingCosts,discount,val){
          setProducts(val);
          //console.log(val);
          set_total_amountHT(vat,shippingCosts,discount,val);
        }

        //setService_accord(selected_products_details.service_accord);

        setOrder_state(selected_products_details.order_state?selected_products_details.order_state:"null");

        setDisable_updates(selected_products_details.order_state?
                    selected_products_details.order_state!==null && selected_products_details.order_state!=="refusée" 
                    && selected_products_details.order_state!=="sous réserve de validation" && selected_products_details.order_state!=="validation refusée" 
                    && selected_products_details.order_state!=="retenue"
                    && selected_products_details.order_state!=="refusée par le fournisseur" 
                    ?true:false:false);

        let products = selected_products_details.products;
        if(Array.isArray(products)){
          set_vat_value(selected_products_details.vat);
          set_shipping_value(selected_products_details.shippingCosts);
          set_discount_value(selected_products_details.discount);
          set_products_value(selected_products_details.vat,selected_products_details.shippingCosts,selected_products_details.discount,products);  
        }else{
          set_products_value(0,0,0,[]);  
        }
        
    },[]);


    function set_price_by_unit(price,id){
      let tmp_array = [ ...products ];
      tmp_array[id].product_price = price<0?0:parseInt(price);
      setProducts(tmp_array);
      set_total_amountHT(vat,shippingCosts,discount,tmp_array)
    }

    function set_promo_by_unit(promo,id){
      let tmp_array = [ ...products ];
      tmp_array[id].product_promo = promo<0?0:parseInt(promo);
      setProducts(tmp_array);
      set_total_amountHT(vat,shippingCosts,discount,tmp_array)
    }

    function set_amount_by_unit(amount,id){
      let tmp_array = [ ...products ];
      tmp_array[id].product_amount = amount<0?0:parseInt(amount);
      setProducts(tmp_array);
      set_total_amountHT(vat,shippingCosts,discount,tmp_array)
    }

    async function set_total_amountHT(vat,shippingCosts,discount,products){
      let tmp_total = 0;
      products.map((product) => {
        tmp_total += (product.product_price!=='' && !isNaN(product.product_price) ?(product.product_amount * (product.product_price - ((product.product_promo!=='' && !isNaN(product.product_promo))?(product.product_price*product.product_promo)/100:0))):0);
      });
      setTotal_ht(tmp_total);
      set_total_amountTTC(vat,shippingCosts,discount,tmp_total)
    }


    async function set_total_amountTTC(vat,shippingCosts,discount,total_ht){
      setvat(vat<0?0:parseInt(vat));
      setshippingCosts(shippingCosts<0?0:parseInt(shippingCosts));
      setdiscount(discount<0?0:parseInt(discount));
      let tmp_vat = ((total_ht*(vat!=="" && !isNaN(vat)?vat:0))/100);
      let tmp_shippingCosts = ((total_ht*(shippingCosts!=="" && !isNaN(shippingCosts)?shippingCosts:0))/100);
      let tmp_discount = ((total_ht*(discount!=="" && !isNaN(discount)?discount:0))/100);
      let tmp_total = total_ht + tmp_vat + tmp_shippingCosts - tmp_discount;
      setTotal_ttc(tmp_total.toFixed(2));
    }

    function confirm_with_supplier(id_supplier,order_number){
      swal({
          title: "Etes-vous sûr ?",
          text: "Une fois que vous avez soumis votre réponse, vous ne pourrez plus la modifier !",
          icon: "warning",
          buttons: true,
          dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          async function sendit(){
            setWait_submitting(true);
            let products_tmp = [];
            products.map((product) => {
              products_tmp.push({
                    "product_code" : product.product_code,
                    "title" : product.title,
                    "product_promo" : !isNaN(product.product_promo)?product.product_promo:0,
                    "product_price" : !isNaN(product.product_price)?product.product_price:0,
                    "product_amount": !isNaN(product.product_amount)?product.product_amount:0,
                  })
            });
            let order_infos = {
              "products" : products_tmp,
              "discount" : !isNaN(discount)?discount:0,
              "shippingCosts" : !isNaN(shippingCosts)?shippingCosts:0,
              "vat" : !isNaN(vat)?vat:0,
            }
            var order_data = null;
            let formData2 = new FormData();
            formData2.append('request_code',request_code);
            formData2.append('id_supplier',id_supplier);
            await get_order_infos_for_file(formData2).then((response)=>{
              order_data = response.data;
              order_data['products'] = order_infos["products"];
              order_data['total_ht'] = !isNaN(total_ht)?total_ht:0;
              order_data['vat'] = order_infos["vat"];
              order_data['total_ttc'] = !isNaN(total_ttc)?total_ttc:0;
              order_data['shippingCosts'] = order_infos["shippingCosts"];
              order_data['discount'] = order_infos["discount"];
            }).catch((error)=>{
              //console.log(error.response);
            });

            const blob = await pdf((
                <Order_form_header_pdf order_data={order_data} />
            )).toBlob();

            let formData = new FormData();
            formData.append('request_code',request_code);
            formData.append('id_supplier',id_supplier);
            formData.append('id_service',service.id);
            formData.append('accord',true);
            formData.append('order_blob',blob); 
            formData.append('order_number',order_number); 
            formData.append('products',JSON.stringify(order_infos));
            service_confirm_with_supplier(formData).then((response)=>{
              //console.log(response.data);
              setOrder_state("fournisseur notifié");
              setWait_submitting(false);
              swal("votre réponse a été enregistrée avec succès et le fournisseur sera bientôt notifié !", {
                icon: "success",
                });
              let tmp_products = [ ...selected_suppliers ];
              tmp_products[order_key].service_accord = true;
              tmp_products[order_key].order_state = "fournisseur notifié";
              setSelected_suppliers(tmp_products);
            }).catch((error)=>{
              //console.log(error.response);
              setWait_submitting(false);
            })
          }
          sendit();
        }
      });
    
    }


    function add_product_to_list(product){
      let tmp_array = [ ...products ];
      let tmp_value = product;
      tmp_value['product_promo'] = 0;
      tmp_value['product_amount'] = 1;
      tmp_array.push(tmp_value);
      setProducts(tmp_array);
      setOpen_search_prd_modal(false);
      set_total_amountHT(vat,shippingCosts,discount,tmp_array);
    }

    function delete_product_from_list(id){
      let tmp_array = [ ...products ];
      tmp_array.splice(id,1);
      setProducts(tmp_array);
      set_total_amountHT(vat,shippingCosts,discount,tmp_array);
    }
  



//////////////////////
/////////////////////////////////////////////////////////
///////////////////////////////////

  return (
    <>
    {
      products===null
      ?<Loading_animation_view />
      :<div className="order_plus_details_container">
         <TableContainer className="order_plus_details_table_container">
            <Table className={classes.table}>
                <TableHead>
                <TableRow>
                    <TableCell align="left">
                      <div style={{width:'100%',display:'flex'}}>
                        Produit
                        <span style={{marginLeft:'10px'}}>
                          <Tooltip title="Ajouter un produit" placement="bottom" >
                              <span onClick={()=>setOpen_search_prd_modal(true)} className={disable_updates?'disabled_button':''}>
                                  <AddIcon className="my_icon addIcon" />
                              </span>
                          </Tooltip>
                        </span>
                      </div>
                    </TableCell>
                    {
                        columns.map((column) => (
                            <TableCell key={column.id} align={column.align}>{column.label}</TableCell>
                        ))
                    }
                </TableRow>
                </TableHead>
                <TableBody>
                {
                    products.length!==0
                    ?
                    <>
                        <Products categories={categories} products={products} order_state={order_state} set_price_by_unit={set_price_by_unit} set_promo_by_unit={set_promo_by_unit} 
                                disable_updates={disable_updates} set_amount_by_unit={set_amount_by_unit} delete_product_from_list={delete_product_from_list} />
                    </>
                    :<div style={{margin:"10px",fontSize:".8rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucun produit disponible</div>
                }
                </TableBody> 
            </Table>
        </TableContainer> 
        <div className='total_amount_container'>
          <TableContainer className="total_amount_table_container">
              <Table className={classes.table}>
                  <TableBody>
                  <TableRow>
                        <TableCell align={"left"}>Total HT (MAD)</TableCell>
                        <TableCell align={"right"}>{numberWithSpaces(total_ht)+" MAD"}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align={"left"}>Taux de TVA (%)</TableCell>
                        <TableCell align={"right"}>
                            <Form.Control
                                className="amount_container"
                                autoComplete="new-password"
                                type="number"
                                placeholder='0'
                                value={vat}
                                readOnly={disable_updates}
                                onChange={(e)=>{set_total_amountTTC(e.target.value,shippingCosts,discount,total_ht);}}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align={"left"}>Frais d’expédition (%)</TableCell>
                        <TableCell align={"right"}>
                        <Form.Control
                                className="amount_container"
                                autoComplete="new-password"
                                type="number"
                                placeholder='0'
                                value={shippingCosts}
                                readOnly={disable_updates}
                                onChange={(e)=>{set_total_amountTTC(vat,e.target.value,discount,total_ht);}}
                            />
                          </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align={"left"}>Remise (%)</TableCell>
                        <TableCell align={"right"}>
                          <Form.Control
                                className="amount_container"
                                autoComplete="new-password"
                                type="number"
                                placeholder='0'
                                value={discount}
                                readOnly={disable_updates}
                                onChange={(e)=>{set_total_amountTTC(vat,shippingCosts,e.target.value,total_ht);}}
                            />
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell align={"left"}>Total TTC (MAD)</TableCell>
                        <TableCell align={"right"}>{numberWithSpaces(total_ttc)+" MAD"}</TableCell>
                    </TableRow>
                  </TableBody> 
              </Table>
          </TableContainer> 
        </div>
        <div className="proceed_button_container">
          {
              order_state!==null
              ?<>
                {
                      order_state==="retenue" || order_state==="validation refusée" 
                      ?<Button variant="contained" className={wait_submitting || products.length===0 ?"submit_but disabled_button":"submit_but"} endIcon={<NavigateNextIcon className="my_icon"  />} onClick={() => confirm_with_supplier(id_supplier,order_number)}> 
                              Confirmer avec le fournisseur
                        </Button>
                      :<></>
                }
              </>
              :<></>
            }
        </div>
      </div>
    }

    {
      open_search_prd_modal!==false
      ?<Search_prd_modal open_search_prd_modal={open_search_prd_modal} setOpen_search_prd_modal={setOpen_search_prd_modal} 
                            add_product_to_list={add_product_to_list} id_supplier={id_supplier} />
      :<></>
    }

    <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait_submitting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
    </>
  );
}

export default Order_details;
