import "./questions.css";
import React,{useState, useEffect} from "react";
import { useHistory } from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "react-js-pagination";
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Fab from '@mui/material/Fab';

import Loading_animation_view from '../../../loading_animation_view/loading_animation_view'
import AddUpdate_question_model from "./add_question/addUpdate_question_model";
import { get_questions, get_questions_types, update_form_model } from "../../../../services/company_admin_service";
import Form_model from "../form_model/form_model";
import { Form } from "react-bootstrap";


const columns_form_questions = [
    { id: 'action', label: "", minWidth: 0 },
    { id: 'label', label: "Libellé", minWidth: 0 },
    { id: 'question_type', label: "Type du question", minWidth: 0 },
    { id: 'content', label: "Contenue", minWidth: 0 },
    { id: 'created_at', label: "Date de création", minWidth: 0 },
    { id: 'updated_at', label: "Date de modification", minWidth: 0 },
  ];


const columns = [
    { id: 'action', label: "", minWidth: 0 },
    { id: 'label', label: "Libellé", minWidth: 0 },
    { id: 'question_type', label: "Type du question", minWidth: 0 },
    { id: 'content', label: "Contenue", minWidth: 0 },
    { id: 'created_at', label: "Date de création", minWidth: 0 },
    { id: 'updated_at', label: "Date de modification", minWidth: 0 },
    { id: 'archieve', label: "Archiver", minWidth: 0 },
  ];


function state(service_state){
    return(
        <>
        {
            service_state === 1
            ?<Button variant="contained" className="status_deny">{"archivée"}</Button>
            :<Button variant="contained" className="status_success">{"non archivée"}</Button>
        }
        </>

    );
}


const Questions_list = ({selected_form,setselected_form})=>{
    
    const [url_updated , setUrl_updated] = useState(null);
    let history = useHistory();

    const [questions_types,setquestions_types] = useState([]);
    const [question_type,setquestion_type] = useState("default");
    const [questions,setquestions]=useState(null);

    const [form_questions,setForm_questions] = useState(null); 

    const [waiting, setWaiting] = useState(false);
    const [currentPage,setCurrentPage]=useState(1);
    const [dataPerPage,setdataPerPage] =useState(6);
    const [data_count,setdata_count]=useState(0);

    const [add_question_model,setadd_question_model] = useState(false);
    const [update_question_model,setupdate_question_model] = useState(null);

    const [view_form_model,setview_form_model] = useState(null);

    useEffect(()=>{
        setForm_questions(null);
        setquestions(null);
        
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        async function load_data(){
            let view_model_tmp = false;
            view_model_tmp = (urlParams.get('model')=="view"?true:false);

            await get_questions_types().then((response)=>{
                let tmp_array = [...questions_types];
                response.data.forEach((elem)=>{
                    tmp_array.push(elem.title); 
                });
                setquestions_types(tmp_array);
            }).catch((error)=> console.log(error));

            let questions_tmp = await get_questions().then((response)=>{
                return response.data;
            });

            if(selected_form.content!=""){
                let form_questions_tmp = [];
                let forms_questions_ids = selected_form.content.split(";");
                let key_forms_questions_ids = [];
                forms_questions_ids.forEach((question_id)=>{
                    questions_tmp.forEach((elem,id2)=>{
                        if(question_id == elem.id){
                            form_questions_tmp.push(elem);
                            key_forms_questions_ids.push(id2);
                        }
                    });
                });
                key_forms_questions_ids = key_forms_questions_ids.sort();
                await setForm_questions(form_questions_tmp);
                if(view_model_tmp==true) await setview_form_model(form_questions_tmp);
                let origin_questions_tmp_length = questions_tmp.length;
                let i=0;
                let id_toDelete = 0;
                let prev_id = -1;
                key_forms_questions_ids.forEach((elem)=>{
                    id_toDelete = i==0?elem:((elem>prev_id && elem!=0)?elem-i<0?0:elem-i:elem);
                    if(questions_tmp.length > 1 && elem < (origin_questions_tmp_length-1)) questions_tmp.splice(id_toDelete,1);
                    else questions_tmp.pop();
                    prev_id = elem;
                    i++;
                });
                if(view_model_tmp==false) await setquestions(questions_tmp);
            }else{
                setForm_questions([]);
                if(view_model_tmp==false) await setquestions(questions_tmp);
            }
        }
        load_data();

    },[url_updated]);

    function select_question_type(value){
        setquestion_type(value);
        let path = window.location.href.split('?')[0]
        window.location.href = path+"?serv="+value;
    }

    function open_question_model(){
        setadd_question_model(true);
    }

    async function change_page(page){
        await setCurrentPage(page);
        let path = history.location.pathname;
        let params = history.location.search;
        let search_params = params.split('&');
        let tmp_params = path;
        if(!params) history.push(path+"?page="+page);
        else{
            for(let i in search_params){
            if(search_params[i].includes("page=")){
                if(i==0) tmp_params=tmp_params+"?page="+page;
                else tmp_params=tmp_params+"&page="+page;
            }else{
                if(i==0) tmp_params=tmp_params+search_params[i];
                else tmp_params=tmp_params+"&"+search_params[i];
            }
            }
            if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
            history.push(tmp_params);
        }
        await setUrl_updated(page);
    }


    function add_question_to_form_model(question,id){
        let form_model_tmp = {...selected_form};
        form_model_tmp.content += ";"+question.id;
        setselected_form(form_model_tmp);
        let form_questions_tmp = [...form_questions];
        let questions_tmp = [...questions];
        setWaiting(true);
        let formData = new FormData();
        formData.append('id',form_model_tmp.id);
        formData.append('title',form_model_tmp.title);
        formData.append('form_type',form_model_tmp.form_type);
        formData.append('content',form_model_tmp.content);
        update_form_model(formData).then((response)=>{
            questions_tmp.splice(id,1);
            setquestions(questions_tmp);
            form_questions_tmp.push(question);
            setForm_questions(form_questions_tmp);
            setWaiting(false);
        }).catch((error)=>{
            //console.log(error.response);
            setWaiting(false);
        })
    }


    function remove_question_to_form_model(question,id,id_arr){
        let form_model_tmp = {...selected_form};
        let form_model_content_tmp = form_model_tmp.content.split(';');
        form_model_content_tmp.splice(id_arr,1)
        let tmp_content = (form_model_content_tmp).toString().replaceAll(",",";");
        let form_questions_tmp = [...form_questions];
        let questions_tmp = [...questions];        
        setWaiting(true);
        let formData = new FormData();
        formData.append('id',form_model_tmp.id);
        formData.append('title',form_model_tmp.title);
        formData.append('form_type',form_model_tmp.form_type);
        formData.append('content',tmp_content);
        update_form_model(formData).then((response)=>{
            questions_tmp.push(question);
            setquestions(questions_tmp);
            form_questions_tmp.splice(id_arr,1);
            setForm_questions(form_questions_tmp);
            setWaiting(false);
        }).catch((error)=>{
            //console.log(error.response);
            setWaiting(false);
        });
    }


    function open_form_model(questions){
        //setview_form_model(questions);
        let path = history.location.pathname;
        let params = history.location.search;
        let search_params = params.split('&');
        let tmp_params = path;
        for(let i in search_params){
            if(i==0) tmp_params=tmp_params+search_params[i];
            else tmp_params=tmp_params+"&"+search_params[i];
        }
        tmp_params=tmp_params+"&model=view";
        history.push(tmp_params);
        setUrl_updated(path);
    }


    function reorder_question(old_id, new_id, e){
        if(e.key == "Enter"){
            if(new_id >= 0 && new_id <= (form_questions.length - 1)){
                let selected_form_tmp = {...selected_form};
                let form_content_arr = selected_form_tmp.content.split(";");
                form_content_arr[old_id] = form_content_arr.splice(new_id,1, form_content_arr[old_id])[0];
                selected_form_tmp.content = form_content_arr.toString().replaceAll(",",";");
                setselected_form(selected_form_tmp);
                setWaiting(true);
                let formData = new FormData();
                formData.append('id',selected_form_tmp.id);
                formData.append('title',selected_form_tmp.title);
                formData.append('form_type',selected_form_tmp.form_type);
                formData.append('content',selected_form_tmp.content);
                update_form_model(formData).then((response)=>{
                    let form_questions_tmp = [...form_questions];
                    form_questions_tmp[old_id] = form_questions_tmp.splice(new_id, 1, form_questions_tmp[old_id])[0];
                    console.log(form_questions_tmp);
                    setForm_questions(form_questions_tmp);
                    e.target.value = old_id + 1;
                    setWaiting(false);
                }).catch((error)=>{
                    //console.log(error.response);
                    e.target.value = old_id + 1;
                    setWaiting(false);
                });
            }
            e.target.blur();
        }
    }



    return (
        <>
        {
            questions==null || questions_types.length==0 || form_questions==null
            ?<Loading_animation_view />
            :<div className='questions_list_container'>
                <div className='questions_list_container_header'>
                        <div className='title_container'>
                            <label>{selected_form.title + " - Questions"}</label>
                            <Tooltip title="Ajouter">
                                <Fab className={waiting ? "add_new_object_fab disabled_button":"add_new_object_fab"} onClick={open_question_model} >
                                    <AddIcon className="add_new_object_icon" />
                                </Fab>
                            </Tooltip>
                        </div>
                        <div className='select_container'>

                            <FormControl style={{width:"225px",marginRight:'20px'}} >
                                <InputLabel>Type de question</InputLabel>
                                    <Select required
                                        value={question_type}
                                        onChange={e => {
                                            select_question_type(e.target.value);
                                        }} name="Etat" >
                                        <MenuItem value={"default"}>
                                            Tous les questions
                                        </MenuItem>
                                            {
                                                questions_types.map((question_type,ind)=>(
                                                    <MenuItem value={ind+1} key={ind}>{question_type}</MenuItem>
                                                ))
                                            }
                                    </Select>
                            </FormControl>
    
                            {/* <FormControl className={classes.formControl} style={{width:"225px"}} >
                                <InputLabel>Etat de service</InputLabel>
                                    <Select required
                                        value={service_state}
                                        onChange={e => {
                                            select_service_stat(e.target.value);
                                        }} name="Etat" >
                                        <MenuItem value={"default"} disabled>
                                            Etat de service
                                        </MenuItem>
                                        <MenuItem value={"recent"}>Récent</MenuItem>
                                        <MenuItem value={"old"}>Ancien</MenuItem>
                                        <MenuItem value={"archived"}>Archivée</MenuItem>
                                        <MenuItem value={"no_archived"}>Non Archivée</MenuItem>
                                    </Select>
                            </FormControl> */}

                        </div>
                </div>

                    <div style={{marginBottom:'25px',marginTop:'20px'}}>
                        <span className='zone_title' >
                            Contenu du formulaire
                            <VisibilityIcon className="editIcon"  style={{marginLeft:"15px"}}
                                onClick={()=>open_form_model(form_questions)} />
                        </span>
                        <TableContainer className="questions_list_table">
                            <Table aria-label="sticky table">
                                <TableHead>
                                <TableRow>
                                    {
                                        columns_form_questions.map((column) => (
                                            <TableCell key={column.id} align="left">{column.label}</TableCell>
                                        ))
                                    }
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    form_questions.length!==0 && question_type.length!==0
                                    ?
                                    <>
                                    {
                                        form_questions.map((question,id) => (
                                            <TableRow key={"question"+id}>
                                                <TableCell align="left">
                                                    <div style={{width:'100%',display:'flex',alignItems:"center"}}>
                                                        <Tooltip title="Supprimer du formulaire" placement="top" >
                                                            <span className={"editIcon"} onClick={()=>remove_question_to_form_model(question,question.id,id)} >
                                                                    <RemoveIcon className="my_icon" style={{cursor:'pointer'}} />
                                                            </span>
                                                        </Tooltip>

                                                        <Form.Control
                                                            className="reordering_input"
                                                            autoComplete="new-password"
                                                            type="number"
                                                            id={"order_value_"+id}
                                                            onBlur={(e) => e.target.value = id+1 }
                                                            onKeyUp={(e) => reorder_question(id,((e.target.value)-1),e)}
                                                            defaultValue={(id+1)}
                                                        />
                                                    </div>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <div style={{width:'fit-content'}}>{question.label}</div>
                                                </TableCell>
                                                <TableCell align="left">{(questions_types[question.question_type-1])}</TableCell>
                                                <TableCell align="left">{(question.content)}</TableCell>
                                                <TableCell align="left">{(new Date(question.created_at)).toLocaleString()}</TableCell>
                                                <TableCell align="left">{(new Date(question.updated_at)).toLocaleString()}</TableCell>
                                            </TableRow>                
                                        ))
                                    }
                                    </>
                                    :<div style={{margin:"10px",fontSize:".8rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucune Question disponible</div>
                                }
                                </TableBody> 
                            </Table>
                        </TableContainer>
                        {
                            form_questions.length!==0
                            ?<div className="pagination_container">
                                <Pagination
                                    activePage={Number(currentPage)}
                                    itemsCountPerPage={dataPerPage}
                                    totalItemsCount={Number(data_count)}
                                    pageRangeDisplayed={4}
                                    onChange={change_page.bind(this)}
                                />
                            </div>
                            :<></>
                        }
                    </div>


{/* 
    ///////////////////////////////////////////////
    ///////////////////////////////////////////////
 */}
                    <div>
                        <span className='zone_title' > Questions disponibles </span>
                        <TableContainer className="questions_list_table">
                            <Table aria-label="sticky table">
                                <TableHead>
                                <TableRow>
                                    {
                                        columns.map((column) => (
                                            <TableCell key={column.id} align="left">{column.label}</TableCell>
                                        ))
                                    }
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    questions.length!==0 && question_type.length!==0
                                    ?
                                    <>
                                    {
                                        questions.map((question,id) => (
                                            <TableRow key={"question"+id}>
                                                <TableCell align="left">
                                                    <div style={{width:'100%',display:'flex'}}>
                                                        <Tooltip title="Ajouter au formulaire" placement="top" >
                                                            <span className={"editIcon"} onClick={()=>add_question_to_form_model(question,id)} >
                                                                    <AddIcon className="my_icon" style={{cursor:'pointer'}} />
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="left">
                                                    <div style={{width:'fit-content'}}>
                                                        {question.label}
                                                        <div style={{width:'100%',display:'flex'}}>
                                                        <Tooltip title="modifier" placement="top" >
                                                            <span className={"editIcon"} >
                                                                    <EditIcon className="my_icon" style={{cursor:'pointer'}} />
                                                            </span>
                                                        </Tooltip>
                                                    </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="left">{(questions_types[question.question_type-1])}</TableCell>
                                                <TableCell align="left">{(question.content)}</TableCell>
                                                <TableCell align="left">{(new Date(question.created_at)).toLocaleString()}</TableCell>
                                                <TableCell align="left">{(new Date(question.updated_at)).toLocaleString()}</TableCell>
                                                <TableCell align="center">
                                                    <div className='documentsStatus_valide'>
                                                    <div>
                                                        <div><CheckIcon className="checkIcon" /></div>
                                                        <div><CloseSharpIcon className="closeSharpIcon" /></div>
                                                    </div>
                                                        {state(question.archived)}            
                                                    </div>
                                                </TableCell>
                                            </TableRow>                
                                        ))
                                    }
                                    </>
                                    :<div style={{margin:"10px",fontSize:".8rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucune Question disponible</div>
                                }
                                </TableBody> 
                            </Table>
                        </TableContainer>
                        {
                            questions.length!==0
                            ?<div className="pagination_container">
                                <Pagination
                                    activePage={Number(currentPage)}
                                    itemsCountPerPage={dataPerPage}
                                    totalItemsCount={Number(data_count)}
                                    pageRangeDisplayed={4}
                                    onChange={change_page.bind(this)}
                                />
                            </div>
                            :<></>
                        }
                    </div>




                </div>
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
            {
                add_question_model!=false
                ?<AddUpdate_question_model questions={questions} setquestions={setquestions} questions_types={questions_types}
                        add_question_model={add_question_model} setadd_question_model={setadd_question_model}
                        update_question_model={update_question_model}  setupdate_question_model={setupdate_question_model} />
                :<></>
            }

            {
                view_form_model!=null
                ?<Form_model form_questions={view_form_model} setform_questions={setview_form_model} setUrl_updated={setUrl_updated} />
                :<></>
            }
        </>
    );
}


export default Questions_list;