import './offers_number_byMonth.css'
import React, { useState, useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';

import Chart from "react-apexcharts";
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from "@material-ui/core/Input";

import { get_ChartsData_offers_number_byMonth } from '../../../../services/charts_service'
import Loading_animation_view from '../../../loading_animation_view/loading_animation_view';



const useStyles = makeStyles((theme) => ({
  button: { margin: theme.spacing(1),},
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    width: '100%',
  },
  input: {
    display: 'none',
  },
  container: {
    maxHeight: 440,
  },
  select: {
    "&:focus": {
      backgroundColor: "white"
    }
  },
  selectInput: {
    "&:hover:not($disabled):not($focused):not($error):before": {
      borderBottomWidth: 0
    },
    "&:after": {
      borderBottomWidth: 0
    }
  },
  disabled: {},
  focused: {},
  error: {}
}));



function Offers_number_byMonth({categories,time_range}){
  const classes = useStyles();
  const selectInputClasses = {
    root: classes.selectInput,
    disabled: classes.disabled,
    focused: classes.focused,
    error: classes.error
  };

  const [selected_categorie,setSelected_categorie]=useState("all");
  const [content_charged,setContent_charged]=useState(false);
  const [chart_data,setChart_data]=useState({
    options: {
        chart: {
          id: "basic-bar",
          events: {
            mounted: (chart) => {
              chart.windowResizeHandler();
            }
          },
          toolbar: {
            show: false
         }
        },
        xaxis: {
          categories: ["janvier", "février", "mars", "avril", "mai", "juin", "juilet", "août", "septembre","octobre","novembre","décembre"]
        },
        yaxis: {
            title: {
                text: time_range.split("_")[0]==="annee" && time_range.split("_").length!==1?time_range.split("_")[1]:(new Date()).getFullYear()
            }
        }
      },
      series: [
        {
          name: "Appels d'offre",
          data: [0,0,0,0,0,0,0,0,0,0,0,0]
        }
      ]
    }
  )


  useEffect(()=>{

    get_ChartsData_offers_number_byMonth("all",time_range).then((response)=>{
        let tmp_data = {...chart_data};
        response.data.map((elem)=>{
            tmp_data.series[0].data[elem.month - 1] = elem.total;
        });
        //console.log(tmp_data);
        setChart_data(tmp_data);
        setContent_charged(true);
    }).catch((error)=>{
        //console.log(error.response);
    });

  },[])

  function getCategorieById(id){
    var categorie="";
    categories.map((catg)=>{
      if(catg.id_categorie === id) categorie = catg.nom;
    })
    return categorie;
  }


  function set_categorie_to_display(value){
    setSelected_categorie(value);
    setContent_charged(false);
    get_ChartsData_offers_number_byMonth(value,time_range).then((response)=>{
        let tmp_data = {...chart_data};
        tmp_data.series[0].data = [0,0,0,0,0,0,0,0,0,0,0,0];
        response.data.map((elem)=>{
            tmp_data.series[0].data[elem.month - 1] = elem.total;
        });
        if(value!=null) tmp_data.series[0].name = getCategorieById(value);
        //console.log(tmp_data);
        setChart_data(tmp_data);
        setContent_charged(true);
    }).catch((error)=>{
        //console.log(error.response);
    });
  }
      

  return (
      <div className="offers_number_byMonth_chart_container">
          <div className="select_offers_categories_container">
           <Tooltip title="Famille d'achat" placement="left">
              <span>
                <FormControl variant="standard" className="header_form" >
                  <Select required value={selected_categorie} 
                        onChange={e => {set_categorie_to_display(e.target.value) }}
                        input={<Input classes={selectInputClasses} />}
                        inputProps={{ classes: { select: classes.select } }}
                        >
                      
                      <MenuItem value={"all"}>Toutes les familles d'achat</MenuItem>
                      {
                        categories.map((categorie,id)=>(
                            <MenuItem value={categorie.id_categorie} key={id} >{categorie.nom}</MenuItem>
                        ))
                      }
                      
                  </Select>
                </FormControl>
               </span>
            </Tooltip>
          </div>
          <div style={{flex:1,width:'100%'}}>
            {
                content_charged!==false
                ?<Chart
                  options={chart_data.options}
                  series={chart_data.series}
                  type="bar"
                  width="98%"
                  height="100%"
                  style={{margin:"auto"}}
                  />
                :<Loading_animation_view />
            }
          </div>
          
            
      </div>
    );
}

export default React.memo(Offers_number_byMonth);