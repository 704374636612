import '../../assets/css/navBar.css';
import { useHistory } from "react-router-dom";

import {Navbar,Form,NavDropdown} from 'react-bootstrap';
import React, {useState,useEffect } from 'react';
import pa_logo from './PA_logo.jpeg';
import defaultProfil_pic from './defaultProfil_pic.png';
import ShoppingCart from '@material-ui/icons/ShoppingCart';

import Avatar from '@material-ui/core/Avatar';
import { get_serviceIdentity_profileImg, logout_request } from '../../services/for_allServices';
import { Link } from 'react-router-dom';


function NavBar({menu_color,company_logo}) {
  let history = useHistory();

  const [info,setInfo]=useState(null);
  const [userprofile , setUserprofile] = useState(null)
  const [products_number , setProducts_number] = useState(0)
  
  let service=JSON.parse(localStorage.getItem('user-info'))


  function count_products_added_to_card(data){
    var count_tmp = 0;
    Object.keys(data).map((supplier) => {
      count_tmp += data[supplier].length;
    })
    return count_tmp;
  }

  window.addEventListener("storage",(e) => {
      let products_selected = sessionStorage.getItem('products_selected');
      if(products_selected)  setProducts_number(count_products_added_to_card(JSON.parse(products_selected)));
  });

  useEffect(()=>{
    
      async function load_data(){
        let products_selected = sessionStorage.getItem('products_selected');
        if(products_selected) await setProducts_number(count_products_added_to_card(JSON.parse(products_selected)));
        await get_serviceIdentity_profileImg(service.id).then((response)=>{
          if(response.data !==null ) setUserprofile(URL.createObjectURL(response.data));
          else setUserprofile(defaultProfil_pic);
        }).catch((error)=>{
          //console.log(error.response);
        });

        await setInfo(service);
      }
      
      load_data();

  },[]);

  async function logout(){
      await logout_request(service.id).then((response)=>{
        localStorage.clear();
        window.dispatchEvent(new Event("storage"));
      }).catch((error)=>{
        //console.log(error.response);
      })
      history.push("/");
  }

  async function view_profil(){
    let path = (history.location.pathname).split('/')[1];
    history.push('/'+path+"/Fiche_Identite");
  }
  
  return (
    <div id='myTopnav' className='service_topnav topnav' style={{width:"100%"}}>
      <Navbar bg="dark" variant="dark" style={{width:"100%",margin:'0px'}}>
      <div className="navbar_content_container"  style={{ background:menu_color }}>
          <div className="company_logo_container">
              {
                  company_logo!==null
                  ?<>
                    <img src={  company_logo!==""?`data:image/*;base64,${company_logo}`:pa_logo } />
                   </>
                   :<></> 
              }
          </div>
          <div className="selection">
            {
               service.role===2?<Link className={products_number !== 0  ?'basket_container':'basket_container disabled_button'} 
                to={"/"+window.location.href.split('/')[3]+"/panier"}>
                    <ShoppingCart className="my_icon" />
                    <>
                      {
                        products_number !== 0 
                        ?<div id="products_number"> {products_number} </div>
                        :<></>
                      }
                    </>
              </Link>
              :<></>
            }
             
             <Form inline >
                <div className='user_name_container'>
                <NavDropdown title={info!=null?info.fullName:""} >
                  <NavDropdown.Item onClick={view_profil} >Profil</NavDropdown.Item>
                  <NavDropdown.Item onClick={logout}>Se déconnecter</NavDropdown.Item>
                </NavDropdown>
                <Avatar src={userprofile} className="my_avatar"/>
                  </div>
              </Form>
          </div>
         
      </div>
      
    </Navbar>
  </div>


  );
}

export default NavBar;
