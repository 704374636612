import './contracts_table.css';
import React, { useEffect,useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from "@material-ui/core/Input";

import {makeStyles} from '@material-ui/core/styles';
import { contratsWdiget_by_supplier } from '../../../../services/supplier_service';
import Loading_animation_view from '../../../loading_animation_view/loading_animation_view';



const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
      width: '100%',
    },
    input: {
      display: 'none',
    },
    container: {
      maxHeight: 440,
    },
    select: {
      "&:focus": {
        backgroundColor: "white"
      }
    },
    selectInput: {
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottomWidth: 0
      },
      "&:after": {
        borderBottomWidth: 0
      }
    },
    disabled: {},
    focused: {},
    error: {}
  }));

  

function contract_treated(stat){
    return(
      <>
        {
         stat === false
         ?<Button variant="contained" className="status_waiting">Pas encore</Button>
         :<Button variant="contained" className="status_success">Signée</Button>
        }
      </>
  
    );
  }


const columns = [
    { id: 'Objet', label: "Objet d'appel", minWidth: 0,align: 'left'  },
    { id: 'service', label: "Service d'achat", minWidth: 0 ,align: 'left' },
    { id: 'contract_type', label: "Type de contrat", minWidth: 0,align: 'left'  },
    { id: 'contract_subType', label: "Nbr. de Sous types de contrats", minWidth: 0 ,align: 'left' },
    { id: 'treated', label: "Signée", minWidth: 0 ,align: 'center' }
  ];
  
function Widget_count_elements({setShowing_elements,fontColor,elements_number,progress}) {

    return (
      <div className={elements_number!==0 && elements_number!=="0 jrs" && elements_number!=="0 MAD" ?'widget_count_elems_container':'widget_count_elems_container disabled_button'} 
            style={{color:fontColor?fontColor:"#526c79"}}
            onDoubleClick={()=>setShowing_elements(true)}>
          <div className="number_arrow_container">
            {
                progress === "up"?<span className='arrow_container'><ArrowDropUpIcon className="progress_icon" /></span>
                                  :<span className='arrow_container' style={{color:"red"}}><ArrowDropDownIcon className="progress_icon" /></span>
            }
            {elements_number}
          </div>
          
      </div>
    );
}

function Contracts({contracts}){
    
    return(
        <>
        {
            contracts.map((contract,index1) => (
                <TableRow key={"contract_col"+index1}>
                    <TableCell align="left">{contract.offer_object}</TableCell>
                    <TableCell align="left">{contract.service_name}</TableCell>
                    <TableCell align="left">{contract.contract_type}</TableCell>
                    <TableCell align="center">{contract.contract_subTypes}</TableCell>
                    <TableCell align="center">
                        {contract_treated(contract.signed)}
                    </TableCell>
                </TableRow>                
             ))
        }
        </>
    );
}



const Contracts_table = ({id_supplier,time_range}) => {

    const classes = useStyles();
    const selectInputClasses = {
      root: classes.selectInput,
      disabled: classes.disabled,
      focused: classes.focused,
      error: classes.error
    };

    const [selected_contractState,setSelected_contractState]=useState("signed");
    const [show_elemnts,setShowing_elements] = useState(false);
    const [content_charged,setContent_charged]=useState(null);
    const [data,setData] = useState(false);


    useEffect(()=>{
        contratsWdiget_by_supplier(id_supplier,time_range,"signed").then((response)=>{
            //console.log(response.data);
            setData(response.data);
            setContent_charged(true);
        }).catch((error)=>{
            //console.log(error.response);
        });
    },[]);

    function set_contractState_to_display(value){
        setContent_charged(null);
        setSelected_contractState(value);
        setShowing_elements(false);
        contratsWdiget_by_supplier(id_supplier,time_range,value).then((response)=>{
            //console.log(response.data);
            setData(response.data);
            setContent_charged(true);
        }).catch((error)=>{
            //console.log(error.response);
        });
    }


  return (
    <div className='charts_of_contract_container'>
     <div className="contract_state">
        <Tooltip title="Contrats" placement="left">
            <span>
            <FormControl variant="standard" className="header_form">
                <Select required value={selected_contractState} 
                    onChange={e => {set_contractState_to_display(e.target.value) }}
                    input={<Input classes={selectInputClasses} />}
                    inputProps={{ classes: { select: classes.select } }}
                    >
                    
                    <MenuItem value={"signed"}>signé</MenuItem>
                    <MenuItem value={"in_progress"}>en cours</MenuItem>
                    
                </Select>
            </FormControl>
            </span>
        </Tooltip>
    </div>
     <div className='container'>
       {
         content_charged===null
         ?<Loading_animation_view />
         :<>
          {
                show_elemnts!==true 
                ?<Widget_count_elements setShowing_elements={setShowing_elements} elements_number={data.count} fontColor="#51a5cf" progress={data.progress} />
                :<TableContainer className="contracts_table_container" component={Paper} onDoubleClick={() => setShowing_elements(false)} style={{cursor:'pointer'}} onTouchStartCapture={(e)=>{e.stopPropagation();setShowing_elements(false)}} >
                        <Table className={classes.table} aria-label="sticky table">
                            <TableHead>
                            <TableRow>
                                {
                                    columns.map((column) => (
                                        <TableCell key={column.id} align={column.align}>{column.label}</TableCell>
                                    ))
                                }
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {
                                data.contracts_list.length!==0
                                ?
                                <>
                                    <Contracts contracts={data.contracts_list} />
                                </>
                                        
                                :<div style={{margin:"10px",fontSize:".75rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucun offre disponible</div>
                            }
                            </TableBody> 
                        </Table>
                    </TableContainer>
                }
         </>
        }
      </div>
    </div>
  );
}

export default React.memo(Contracts_table);
