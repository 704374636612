import './charts_of_the_bestSuppliers.css'
import React,{useState,useEffect} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@mui/material/Tooltip';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from "@material-ui/core/Input";
import { bestSuppliers_bySubmissions_widgetCharts,bestSuppliers_byAccord_widgetCharts, bestSuppliers_byNotes_widgetCharts } from '../../../../services/purchase_service';
import Loading_animation_view from '../../../loading_animation_view/loading_animation_view';


const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
      width: '100%',
    },
    input: {
      display: 'none',
    },
    container: {
      maxHeight: 440,
    },
    select: {
      "&:focus": {
        backgroundColor: "white"
      }
    },
    selectInput: {
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottomWidth: 0
      },
      "&:after": {
        borderBottomWidth: 0
      }
    },
    disabled: {},
    focused: {},
    error: {}
  }));

  
  
const Charts_of_the_bestSuppliers = ({time_range,categories,data})=>{
    const classes = useStyles();
    const selectInputClasses = {
      root: classes.selectInput,
      disabled: classes.disabled,
      focused: classes.focused,
      error: classes.error
    };
  
    const [selected_categorie,setSelected_categorie]=useState("all");
    const [content_charged,setContent_charged]=useState(null);

    useEffect(()=>{
      //console.log('suppliers_charts')
      if(data==="charts_of_the_bestActiveSuppliers"){
        bestSuppliers_bySubmissions_widgetCharts(time_range,selected_categorie).then((response)=>{
          setContent_charged(response.data);
        }).catch((error)=>{
          //console.log(error.response);
        })
      }
      if(data==="charts_of_the_bestAccordedSuppliers"){
        bestSuppliers_byAccord_widgetCharts(time_range,selected_categorie).then((response)=>{
          setContent_charged(response.data);
        }).catch((error)=>{
          //console.log(error.response);
        })
      }
      if(data==="charts_of_the_bestRatedSuppliers"){
        bestSuppliers_byNotes_widgetCharts(time_range,selected_categorie).then((response)=>{
          setContent_charged(response.data);
        }).catch((error)=>{
          //console.log(error.response);
        })
      }

    },[])

    function set_categorie_to_display(value){
        setSelected_categorie(value);
        setContent_charged(null);
        if(data==="charts_of_the_bestActiveSuppliers"){
          bestSuppliers_bySubmissions_widgetCharts(time_range,value).then((response)=>{
            setContent_charged(response.data);
          }).catch((error)=>{
            //console.log(error.response);
          })
        }
        if(data==="charts_of_the_bestAccordedSuppliers"){
          bestSuppliers_byAccord_widgetCharts(time_range,value).then((response)=>{
            setContent_charged(response.data);
          }).catch((error)=>{
            //console.log(error.response);
          })
        }
        if(data==="charts_of_the_bestRatedSuppliers"){
          bestSuppliers_byNotes_widgetCharts(time_range,value).then((response)=>{
            setContent_charged(response.data);
          }).catch((error)=>{
            //console.log(error.response);
          })
        }
    }

  return (
    <div className='charts_of_the_bestSuppliers_container'>
     <div className="select_supplierCategories">
        <Tooltip title="Famille d'achat" placement="left">
            <span>
            <FormControl variant="standard" className="header_form" >
                <Select required value={selected_categorie} 
                    onChange={e => {set_categorie_to_display(e.target.value) }}
                    input={<Input classes={selectInputClasses} />}
                    inputProps={{ classes: { select: classes.select } }}
                    >
                    
                    <MenuItem value={"all"}>Toutes les familles d'achat</MenuItem>
                    {
                    categories.map((categorie,id)=>(
                        <MenuItem value={categorie.id_categorie} key={id} >{categorie.nom}</MenuItem>
                    ))
                    }
                    
                </Select>
            </FormControl>
            </span>
        </Tooltip>
    </div>
     <div className='container'>
       {
         content_charged===null
         ?<Loading_animation_view />
         :<>
            <div className="categorie_container">
                <div className="order_of_the_bestSuppliers" style={{backgroundColor:"rgb(243 184 62)"}} ><span>{content_charged[0].count}</span></div>
                <div className="name_of_the_bestSuppliers" style={{backgroundColor: "#b8c6db",backgroundImage: "linear-gradient(220deg, rgb(243 184 62) 0%, rgb(246 222 172) 74%)"}}>{content_charged[0].supplier_name}</div>
            </div>
            <div className="categorie_container">
                <div className="order_of_the_bestSuppliers" style={{backgroundColor:"#b8c6db"}} ><span>{content_charged[1].count}</span></div>
                <div className="name_of_the_bestSuppliers" style={{backgroundColor: "#b8c6db",backgroundImage: "linear-gradient(220deg, #b8c6db 0%, #eff7fa 74%)"}}>{content_charged[1].supplier_name}</div>
            </div>
            <div className="categorie_container">
                <div className="order_of_the_bestSuppliers" style={{backgroundColor:"#CD7F32"}} ><span>{content_charged[2].count}</span></div>
                <div className="name_of_the_bestSuppliers" style={{backgroundColor: "#b8c6db",backgroundImage: "linear-gradient(220deg, #CD7F32 0%, rgb(246 211 177) 74%)"}}>{content_charged[2].supplier_name}</div>
            </div>
         </>
       }
     </div>        
    </div>
  );
}


export default React.memo(Charts_of_the_bestSuppliers)