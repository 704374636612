import React, { useState,useEffect } from 'react'

import   './set_supplier.css'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SaveIcon from '@material-ui/icons/Save';
import CancelIcon from '@material-ui/icons/Cancel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from "@material-ui/core/Checkbox";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import swal from 'sweetalert';

import {makeStyles} from '@material-ui/core/styles';

import { add_proposal_serv,update_proposal_serv } from '../../../../services/purchase_service';


  const useStyles = makeStyles((theme) => ({
    indeterminateColor: {
      color: "#f50057"
    },
    selectAllText: {
      fontWeight: 500
    },
    selectedAll: {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.08)"
      }
    }
  }));
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250
      }
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center"
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center"
    },
    variant: "menu"
  };
  

function Set_supplier({fournisseursEdit,handleClose,open,formData,update,setOffers,clickOffre,offers,suppliers,setFormData,handleCloseAdd,setOffers_added}){

    const classes = useStyles();
    let user = JSON.parse(localStorage.getItem('user-info'));

    const [valide, setValid] = useState(true);
    const [submitted, setSubmitted] = useState(false);

    const [selected, setSelected] = useState([]);
    const [selectedId, setSelectedId] = useState([]);

    const [waiting, setWaiting] = useState(false);

    useEffect(()=>{
      if(fournisseursEdit!=""){
        let tmp_array = [];
        fournisseursEdit.split(',').map((fournisseurId) => {
          suppliers.map((supplier) => {
            if(supplier.id == fournisseurId) tmp_array.push(supplier.nom);
          });
        });
        setSelected(tmp_array);
        setSelectedId(fournisseursEdit.split(','));
      }

      //console.log(offers);

    },[])


    function fournisseurId_parNom(noms){
      let id=[];
      suppliers.map((supplier) => {
        noms.map((nom) => {
          if(supplier.nom == nom) id.push(supplier.id);
        });
          
      });
      return id;
    }

    function validated_elem(){
        return submitted && valide;
    }

    const isAllSelected =
        suppliers.length > 0 && selected.length === suppliers.length;

    const handleChange = (event) => {
        const value = event.target.value;
        if (value[value.length - 1] === "all") {
          let tmp_array = [];
          let tmp_id_array = [];
          suppliers.map((supplier) => {
              tmp_array.push(supplier.nom);
              tmp_id_array.push(supplier.id);
          })
          setSelected(selected.length === suppliers.length ? [] : tmp_array);
          setSelectedId(selected.length === suppliers.length ? [] : tmp_id_array);
          return;
        }
        setSelected(value);
        //console.log(value);
        setSelectedId(fournisseurId_parNom(value));
    };

    async function add_proposal() {   
        setWaiting(true); 
        setSubmitted(true);
        setValid(true);
        if (selected.length==0) {
          setWaiting(false); 
             setValid(false);
        }else{
          let tmp_form = formData;
          tmp_form.append('fournisseurs',selectedId);
          setFormData(tmp_form);
          if( update !== true ){
            add_proposal_serv(tmp_form).then((response) => {
              setWaiting(false); 
              swal("L'appel d'offre a été créé avec succès, les fournisseurs seront bientôt notifiés !", {
                icon: "success",
                buttons: true,
              }).then((willDelete) => {
                  if (willDelete) { setOffers_added(response.data);
              }})      
              handleClose();
              handleCloseAdd();
            }).catch((error) => {
              //console.log(error.response);
              setWaiting(false); 
            });
          }else{
            update_proposal_serv(tmp_form).then((response) => {
                setWaiting(false);
                swal("L'appel d'offre a été mis à jour avec succès, les fournisseurs seront bientôt notifiés !", {
                  icon: "success",
                  buttons: true,
                }).then((willDelete) => {
                    if (willDelete) { 
                      const arr=[...offers];
                      arr[clickOffre]=response.data;
                      setOffers(arr);
                    }
                  })
                
                handleClose();
            }).catch((error) => {
                //console.log(error.response);
                setWaiting(false);
            });
          }
          
        }
     }

    

    return (
      <>
        <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose} aria-labelledby="form-dialog-title" >
            <DialogTitle id="form-dialog-title">Liste des fournisseurs</DialogTitle>
            <DialogContent>
            <FormControl className="multi_form_select">
                <InputLabel id="mutiple-select-label">Fournisseurs</InputLabel>
                <Select
                    labelId="mutiple-select-label"
                    multiple
                    value={selected}
                    onChange={handleChange}
                    renderValue={(selected) => selected.join(", ")}
                    MenuProps={MenuProps}
                >
                    <MenuItem
                      value="all"
                      classes={{
                          root: isAllSelected ? classes.selectedAll : ""
                      }}
                      >
                      <ListItemIcon>
                          <Checkbox
                          classes={{ indeterminate: classes.indeterminateColor }}
                          checked={isAllSelected}
                          indeterminate={
                              selected.length > 0 && selected.length < suppliers.length
                          }
                          />
                      </ListItemIcon>
                      <ListItemText
                          classes={{ primary: classes.selectAllText }}
                          primary="Select All"
                      />
                    </MenuItem>
                    {
                      suppliers.map((option) => (
                        <MenuItem key={option.id} value={option.nom}>
                            <ListItemIcon>
                              <Checkbox checked={selected.indexOf(option.nom) > -1} />
                            </ListItemIcon>
                            <ListItemText primary={option.nom} />
                        </MenuItem>
                      ))
                    }
                </Select>
                </FormControl>
                
            </DialogContent>
        <DialogActions>
            <div className='dialogBottom'>
                <div>
                    {
                        validated_elem() 
                        ?<></>
                        :<p className={submitted ? "col-md-10 error" : "col-md-10 valid-champ"}>
                                {submitted ? "Sélectionner au moin un fournisseur !" : ""}
                            </p>
                    }
                </div>
              <div className='dialogSuppliersBottom'>
                  <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon />} onClick={handleClose} >
                      Annuler
                  </Button>
                  <Button variant="contained" className="submit_but valide_but" startIcon={<SaveIcon />} onClick={add_proposal} >
                      { update ? "Enregister" : "Ajouter" }
                  </Button>
              </div>
            </div>
        </DialogActions>
         <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
     
    </>
    )
}

export default Set_supplier
