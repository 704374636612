import './suppliers_table.css'

import React, { useState , useEffect, useRef } from 'react'
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import Widget_count_elements from '../widget_count_elements/widget_count_elements'


const status = ['En Cours','Refusé','Accepté'];


const columns = [
  { id: 'Nom', label: 'Nom', minWidth: 0 , align: 'left'},
  { id: 'MaisonMere', label: "Maison mère", minWidth: 0 , align: 'left'},
  { id: 'FamilleAchat', label: "Famille d'achat", minWidth: 0, align: 'left' },
  { id: 'reference', label: "reference", minWidth: 0 , align: 'center'},
];


function setStatus(stat){
  return(
    <>
      {
       stat === status[0]
       ?<Button variant="contained" className="status_waiting">{status[0]}</Button>
       :stat === status[1]
          ?<Button variant="contained" className="status_deny">{status[1]}</Button>
          :<Button variant="contained" className="status_success">{status[2]}</Button>
      }
    </>

  );
}

const useStyles = makeStyles((theme) => ({
  button: { margin: theme.spacing(1),},
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));



const Suppliers_table = ({categories,lastSuppliers}) => {

  const classes = useStyles();
  const [show_elemnts,setShowing_elements] = useState(false);


//////////////////////
/////////////////////////////////////////////////////////'
///////////////////////////////////

  const getCategorieById = (categorie)=>{
    var tmp_categorie="";
    categories.map((catg)=>{
      categorie.map((id)=>{
        if(catg.id_categorie === parseInt(id)){
          if(tmp_categorie==="") tmp_categorie = tmp_categorie+catg.nom;
          else tmp_categorie = tmp_categorie+", "+catg.nom;
        }
      })
    })
    return tmp_categorie;
  }


  //////////////////////
/////////////////////////////////////////////////////////
///////////////////////////////////

  function createData(Nom, MaisonMere,FamilleAchat,reference) {
    return { Nom, MaisonMere,FamilleAchat,reference};
  }

  const rows = [
    lastSuppliers.data.map((supplier, i) => createData(supplier.nom,supplier.maisonMere,getCategorieById((supplier.familleAchat).split(',')),setStatus(supplier.archive)
      )
    )
  ]

  return (
    <>
      {
        show_elemnts!==true
          ?<Widget_count_elements setShowing_elements={setShowing_elements} elements_number={lastSuppliers.count} progress={lastSuppliers.progress}/>
          :<TableContainer className="suppliers_table" component={Paper}>
      <Table className={classes.table} id="suppliers_table"  onDoubleClick={() => setShowing_elements(false)} style={{cursor:'pointer'}} onTouchStartCapture={(e)=>{e.stopPropagation();setShowing_elements(false)}} >
        <TableHead>
          <TableRow>
            <TableCell align="left">Nom Complet</TableCell>
            <TableCell align="left">Maison mère</TableCell>
            <TableCell align="left">Famille d'achat</TableCell>
            <TableCell align="left">Etat de referencement</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {

          categories.length!==0 && lastSuppliers.data.length!==0
            ?rows[0].map((row,index) => {
              return (
                <TableRow key={index} role="checkbox" tabIndex={-1} className="rowsbody">
                  {
                    columns.map((column) => {
                      const value = row[column.id];
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {value}
                        </TableCell>
                      );
                    })
                  }
                </TableRow>
              );
            })
            :<></>
          }
        </TableBody> 
      </Table>
    </TableContainer>
    }

    </>
  )

}

export default Suppliers_table;
