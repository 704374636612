import './form_model.css'
import React,{useEffect,useState} from "react";
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-bootstrap';

import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import { IconButton, Toolbar } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
}));




const ResponseText_question = ({question,id}) => {
    const [response,setresponse] = useState(null);

    return (
        <div className="question_container">
            <div className="question_label_container"> {(id+1)+". "+question.label} <span style={{color:"red"}}>*</span> </div>
            <div className="question_content_container">
                <Form.Control as="textarea" placeholder="Réponse *" rows={2}
                    value={response} onChange={(e)=>setresponse(e.target.value)} />
            </div>
        </div>
    );
}

const TrueFalse_question = ({question,id}) => {
    const [response,setresponse] = useState("");
    return (
        <div className="question_container">
            <div className="question_label_container">{(id+1)+". "+question.label} <span style={{color:"red"}}>*</span> </div>
            <div className="question_content_container">
                <Form.Check 
                    type="radio"
                    id="True/False"
                    name={"True/False_"+id}
                    label="Vrai"
                />
                <Form.Check 
                    type="radio"
                    id="True/False"
                    name={"True/False_"+id}
                    label="Faux"
                />
            </div>
        </div>
    );
}

const CheckBox_question = ({question,id}) => {
    const [content_tmp,setcontent_tmp] = useState("");
    const [response,setresponse] = useState("");

    return (
        <div className="question_container">
            <div className="question_label_container">{(id+1)+". "+question.label} <span style={{color:"red"}}>*</span> </div>
            <div className="question_content_container">
                {
                    (question.content.split(";")).map((elem,id_elem)=>(
                        <Form.Check 
                            type="checkbox"
                            id={id+"_"+id_elem}
                            key={id+"_"+id_elem}
                            name={"Checkbox_"+id}
                            label={elem}
                        />
                    ))
                }
            </div>
        </div>
    )
}


const SelectChoice_question = ({question,id}) => {
    const [content_tmp,setcontent_tmp] = useState("");
    const [response,setresponse] = useState("");

    return (
        <div className="question_container">
            <div className="question_label_container">{(id+1)+". "+question.label} <span style={{color:"red"}}>*</span> </div>
            <div className="question_content_container">
                <Form.Select
                    required
                    name={"select_"+id}
                    defaultValue={"null"}
                >
                    <option value={"null"} disabled>Sélectionner</option>
                    <>
                    {
                        (question.content.split(";")).map((elem,id_elem)=>(
                            <option value={elem} key={"select_"+id+"_value_"+id_elem}>{elem}</option>
                        ))
                    }
                    </>
                </Form.Select>
            </div>
        </div>
    )
}


const Form_model = ({form_questions,setform_questions,setUrl_updated})=>{

    const classes = useStyles();
    let history = useHistory();

    useEffect(()=>{
        console.log(form_questions);
    },[]);

    async function close_form_model(){
        let path = history.location.pathname;
        let params = history.location.search;
        let search_params = params.split('&');
        let tmp_params = path;
        for(let i in search_params){
            if(!search_params[i].includes("model")){
                if(i==0) tmp_params=tmp_params+search_params[i];
                else tmp_params=tmp_params+"&"+search_params[i];
            }
        }
        await setform_questions(null);
        history.push(tmp_params);
        setUrl_updated(history);
    }

    return (
    <Dialog disableEnforceFocus fullScreen open={form_questions?true:false} onClose={() => close_form_model()} className="my_full_dialog contract_full_dialog">
        <AppBar className={classes.appBar}  >
            <Toolbar style={{backgroundColor:'#343a40'}} >
                <div className='page_title'>
                    <IconButton edge="start" color="inherit" onClick={() => close_form_model()} aria-label="close" >
                        <CloseIcon className="my_icon" />
                    </IconButton>
                    <span> {"Formulaire"} </span>
                </div>
            </Toolbar>
        </AppBar>
        <div className="form_model_global_container">
            <div className="form_model_container">

                {
                    form_questions.map((question,id)=>{
                        if(question.question_type == 1) return ( <ResponseText_question question={question} id={id} />)
                        else{
                            if(question.question_type == 2) return ( <TrueFalse_question question={question} id={id} />)
                            else{
                                if(question.question_type == 3) return ( <CheckBox_question question={question} id={id} />)
                                else{
                                    if(question.question_type == 4) return ( <SelectChoice_question question={question} id={id} />)
                                }
                            }
                        } return ""
                    })
                }

            </div>
        </div>
      </Dialog>
    );
}


export default Form_model;