import './add_infos_toBill.css'
import React, { useState } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import PaymentIcon from '@material-ui/icons/Payment';
import InputAdornment from '@material-ui/core/InputAdornment';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import DoneIcon from '@mui/icons-material/Done';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { add_infos_torequest_order_Bill, add_infos_toBill } from '../../../../services/finance_service';


const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    }
  }));

function setDateTime(date){
    let day = date.toISOString();
    let day_comp = day.split(":");
    return day_comp[0];
}
  

function Add_infos_toBill({open_addInfo_toBill_modal,setOpen_addInfo_toBill_modal,id_submission,request_code,bills_numbers,markets,setMarkets}){

    const classes = useStyles();

    const [errorText,setErrorText] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [waiting, setWaiting] = useState(false);

    const [expiration_date,setExpiration_date]=useState('');
    const [amount_without_tax,setAmount_without_tax]=useState('');


  function submit_refuse_reason() {
    setSubmitted(true);
    setErrorText("");
    if(expiration_date==='' || amount_without_tax===''){
      setErrorText("Champ vide !");
    }else{
        setWaiting(true);
        let formData = new FormData();
        if(request_code) formData.append('request_code',request_code);
        else formData.append('id_submission',id_submission);
        formData.append('bills_numbers',JSON.stringify(bills_numbers));
        formData.append('expiration_date',expiration_date);
        formData.append('amount_without_tax',amount_without_tax);
        
        if(request_code){
          add_infos_torequest_order_Bill(formData).then((response)=>{
              //console.log(response.data);
              let tmp_array = [...markets];
              tmp_array[open_addInfo_toBill_modal].bills = response.data;
              setMarkets(tmp_array);
              setOpen_addInfo_toBill_modal(null);
              setWaiting(false);
          }).catch((error)=>{
              setWaiting(false);
              //console.log(error.response);
          })
        }else{
          add_infos_toBill(formData).then((response)=>{
              //console.log(response.data);
              let tmp_array = [...markets];
              tmp_array[open_addInfo_toBill_modal].bills = response.data;
              setMarkets(tmp_array);
              setOpen_addInfo_toBill_modal(null);
              setWaiting(false);
          }).catch((error)=>{
              setWaiting(false);
              //console.log(error.response);
          })
        }

        
    }
        
     }

     

    return (
      <Dialog fullWidth={true} maxWidth={'md'} open={open_addInfo_toBill_modal!=null?true:false} onClose={()=>setOpen_addInfo_toBill_modal(null)} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">Informations Facture N°{bills_numbers[0]}</DialogTitle>
        <DialogContent>
          <div className='add_infos_toBill_container'>  
            
            <TextField className={classes.margin} label="Montant hors taxe (DH) " type="number" style={{width:"250px"}}
                  InputProps={{ startAdornment: ( <InputAdornment position="start"> <PaymentIcon /> </InputAdornment> ), }} onChange={(e)=>setAmount_without_tax(e.target.value)} />
            
            <TextField
                    type="date"
                    className={classes.margin} label="Date d'échéance" min={new Date().toDateString()} style={{width:"250px"}}
                    InputProps={{ startAdornment: ( <InputAdornment position="start"> <EventBusyIcon /> </InputAdornment> )}} onChange={(e)=>setExpiration_date(e.target.value)}
                />
                
          </div>
          
     </DialogContent>
        <DialogActions>
          <div className='dialogBottom'>
            <div>
            {
                errorText == "" 
                ?<></>
                :<p className={submitted ? "error" : "valid-champ"}>
                        {errorText}
                    </p>
            }
            </div>
            <div>
              <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon />} onClick={()=>setOpen_addInfo_toBill_modal(null)} >
                  Annuler
              </Button>
              <Button variant="contained" className="submit_but valide_but" startIcon={<DoneIcon />} onClick={submit_refuse_reason} >
                  Valider
              </Button> 
            </div>
          </div>
        </DialogActions>

        <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

      </Dialog>
    )
}

export default Add_infos_toBill
