import './import_files_page.css'
import React, { useCallback, useState } from "react";
import * as XLSX from "xlsx";

import Radio from '@mui/material/Radio';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddIcon from '@mui/icons-material/Add';
import Backdrop from '@mui/material/Backdrop';
import swal from 'sweetalert';
import CircularProgress from '@mui/material/CircularProgress';

import My_dropzone from "./drag_drop_files_zone/drag_drop_files_zone";
import Files_table from './files_table/files_table';
import { load_catalogue_fromFiles, get_imported_files } from '../../../services/supplier_service';


function bytesToSize(bytes) {
    var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    if (bytes == 0) return '0 Byte';
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
 }



function Import_reason_container({import_reason,change_import_reason,reason_elem}){
    return(
        <div className={import_reason!==reason_elem?'imp_res_container':'imp_res_container imp_res_container_slected'}
            onClick={() => change_import_reason(reason_elem)} >
            <div className='imp_res_radio'> <Radio size="small" color="info" checked={import_reason===reason_elem?true:false}/> </div>
            <div className='imp_res_icon'> <AddBusinessIcon /> </div>
            <div className='imp_res_title'> Importer le catalogue de vos produits </div>
        </div>
    );
}

  
  const columns = [
    { id: 'type', label: "Type de fichier", minWidth: 0 ,align: 'left' },
    { id: 'name', label: "Nom de fichier", minWidth: 0 ,align: 'left' },
    { id: 'size', label: "Taille de fichier", minWidth: 0,align: 'left'  },
   ];
    
  
  
  function Selected_files_table({files_data}) {

    return (
      <>
      <TableContainer className="files_table_container" component={Paper}>
              <Table aria-label="sticky table">
                  <TableHead>
                  <TableRow>
                      {
                          columns.map((column) => (
                              <TableCell key={column.id} align={column.align}>{column.label}</TableCell>
                          ))
                      }
                  </TableRow>
                  </TableHead>
                  <TableBody>
                  {
                      files_data.length!==0
                      ?
                      <>
                          {
                            files_data.map((file,id) => (
                               <TableRow key={"file_col"+id}>
                                      <TableCell align="left">{"fichier "+file.path.split('.')[file.path.split('.').length - 1]}</TableCell>
                                      <TableCell align="left">{file.path}</TableCell>
                                      <TableCell align="left">{bytesToSize(file.size)}</TableCell>
                                  </TableRow>                
                              ))
                          }
                      </>
                      :<div style={{margin:"10px",fontSize:".8rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucun fichier déposer</div>
                  }
                  </TableBody> 
              </Table>
          </TableContainer>
      </>
    );
  }


  


function Import_files_page() {

    const excel_header_fr = ["code produit","titre","prix du produit","Famille d'achat","description"];
    const excel_header_eng = ["product code","title","product price","categorie","description"];

    let supplier =  JSON.parse(localStorage.getItem('user-info'))

    const [import_reason,setImport_reason] = useState(null);
    const [selected_files, setSelected_files] = useState([]);
    const [existing_files, setExisting_files] = useState([]);

    const [errorText,setErrorText] = useState("");
    const [waiting, setWaiting] = useState(false);


    const onDrop = useCallback((acceptedFiles) => {
        setErrorText('');
        let tmp_array = [ ...selected_files ];
        if(acceptedFiles.length !== 0){
           acceptedFiles.map((selected_file) => {
                //console.log(selected_file.path)
                tmp_array.push(selected_file);
            })
            setSelected_files(tmp_array); 
        }
    },[]);

    function change_import_reason(reason){
        setImport_reason(reason);
        setExisting_files([]);
        let formData = new FormData();
        formData.append('id_login',supplier.id);
        formData.append('imports_reason',reason);        
        get_imported_files(formData).then((response)=>{
            //console.log(response.data);
            setExisting_files(response.data);
        }).catch((error) => {
            //console.log(error.response);
        })
        setSelected_files([]);
    }

    function read_excel_data(data) {
        var wb = XLSX.read(data, { type: 'binary' });
        for (var i = 0; i < wb.SheetNames.length; i++) {
            const ws = wb.Sheets[wb.SheetNames[i]];
            const content = XLSX.utils.sheet_to_csv(ws, { header: 1 });
            const content_headers = (content.split('\n')[0]).split(',');
            for (var j = 0; j < content_headers.length; j++) {
                if( !excel_header_fr.includes(content_headers[j])  && !excel_header_eng.includes(content_headers[j]) ){
                    return false;
                }
            }
        }
        return true;
    }




    async function submit_to_save_files(files){
        setErrorText('');
            var error = "";
            const filePromises = files.map((file) => {
            const reader = new FileReader();
            reader.onerror = (function(file,error){
                return function(e){
                    error = error+" \n la lecture du fichier ( "+file.name+" ) a échoué !";  
                }
            })(file,error);   
            
            reader.onload = function(file,error){
                console.log(error);
                return async function(e){
                    let valide_to_save = true;
                    const binaryStr = e.target.result
                    valide_to_save = read_excel_data(binaryStr);
                    if(valide_to_save===false){
                        setErrorText(error+' les en-têtes de tableau ne sont pas acceptables fichier : '+file.name);
                        error+='les en-têtes de tableau ne sont pas acceptables fichier : '+file.name;
                    }else{
                        setWaiting(true);
                        let formData = new FormData();
                        formData.append('files',file);
                        formData.append('id_supplier',supplier.id);
                        await load_catalogue_fromFiles(formData).then((response) => {
                            let data = response.data;
                            if(Array.isArray(data)){
                                setErrorText(error+' <br /> '+data[0]);
                                error+=data[0]; 
                            }else{
                                let tmp_array_files = [ ...selected_files ];
                                let tmp_existing_files = [ ...existing_files ];
                                tmp_existing_files.push(data.imported);
                                setExisting_files(tmp_existing_files);
                                for( let j in tmp_array_files ){
                                    if(file.name === tmp_array_files[j].path ) tmp_array_files.splice(j,1);
                                }
                                swal("votre réponse a été enregistrée avec succès !", {
                                    icon: "success",
                                    buttons: true,
                                }).then((willDelete) => {
                                    if (willDelete) {}
                                })
                                setSelected_files(tmp_array_files);
                            }
                            setWaiting(false);
                        }).catch((error)=>{
                            //console.log(error.response);
                            setWaiting(false);
                        })
                    }
                };
              }(file,error);   
              reader.readAsBinaryString(file);
            })
            console.log(error);
    }

  return (
    <div className="import_files_page_container">
        <div className='all_imp_res_container'>
            <div style={{width:'100%'}}><span className='page_title zone_title' style={{marginBottom:'10px'}}> Quel est l'objectif de votre importation ?</span></div>
            <Import_reason_container import_reason={import_reason} change_import_reason={change_import_reason} reason_elem="produits" />
        </div>

        <div className="drop_zone_container">
                 <My_dropzone onDrop={onDrop} import_reason={import_reason} />
        </div> 

        <div  className='files__container'>
            <span className='zone_title' > Fichiers que vous ajouterez </span>
            <Selected_files_table files_data={selected_files} />
        </div>

        <div  className='files__container' style={{flexDirection:'row',justifyContent:'flex-end'}}>
            {
                errorText === "" 
                ?<></>
                :<p className={errorText ? "error" : "valid-champ"}>
                        {errorText}
                    </p>
            }
            <Button variant="contained" className={selected_files.length!==0?"submit_but":'submit_but disabled_button'} 
                    onClick={()=>submit_to_save_files(selected_files)}  startIcon={<AddIcon className="my_icon" />}> 
                    Valider
            </Button>
        </div>

        <div className='files__container' style={{margin:'35px auto'}}>
            <span className='zone_title'> Vos fichiers existants </span>
            <Files_table files_data={existing_files} />
        </div>

        <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        
    </div>
    
  );
}
export default Import_files_page;