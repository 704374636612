import '../../../assets/css/card_products.css';
import React, { useEffect,useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import Button from '@material-ui/core/Button';
import Form from 'react-bootstrap/Form'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import empty from '../../empty.jpg'


import {makeStyles} from '@material-ui/core/styles';
import Loading_animation_view from '../../loading_animation_view/loading_animation_view';
import { get_products_byCode } from '../../../services/for_allServices';
import { get_categories } from '../../../services/purchase_service';
import Order_form from './order_form/order_form';


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const columns = [
    { id: 'supplier', label: "Fournisseur -- Maison mère", minWidth: 0,align: 'left'  },
    { id: 'product', label: "Produit", minWidth: 0,align: 'left'  },
    { id: 'amount', label: "Quantité", minWidth: 0 ,align: 'left' },
    { id: 'delete', label: "", minWidth: 0,align: 'left'  }
  ];
  

function Products({categories,products,update_card_products,delete_card_products}){
    
    function getCategorieById(id){
        var categorie="";
        categories.map((catg)=>{
          if(catg.id_categorie === id) categorie = catg.nom;
        })
        return categorie;
    }

    return(
        <>
        {
            Object.keys(products).map((elem,id_ss) => (
                products[elem].products.map((product,id) => (
                    <TableRow key={"product_col"+id}>
                        <TableCell align="left">{product.id_supplier !== (id!==0?(products[elem].products)[id].id_supplier:-1) ?elem.split("*")[1]:""}</TableCell>                    
                        <TableCell align="left">
                            <div style={{display:"flex",alignItems:"center"}}>
                            <span className='product_cell_img_container'>
                                    <img src={product.product_pics.length!==0?`data:image/png;base64,${product.product_pics[0]}`:empty} />
                                </span>
                                <span className='product_cell_title_container'>
                                    <span id="title">{product.title}</span>
                                    <div className='product_cell_categorie_container'>
                                        <span>Famille d'achat : </span>{getCategorieById(product.id_categorie)}
                                    </div> 
                                </span> 
                            </div>
                            
                        </TableCell>
                        <TableCell align="left">
                        <Form.Control
                            className="amount_container"
                            autoComplete="new-password"
                            type="number"
                            value={product.product_amount}
                            onChange={(e) => update_card_products(elem,id,product.product_code,e.target.value)}
                        />
                        </TableCell>
                        <TableCell align="left">
                            <RemoveCircleOutlineIcon className='my_icon' style={{color:"red",cursor:"pointer",fontSize:'1.5rem'}} onClick={() => delete_card_products(elem,id,product.product_code)} />
                        </TableCell>
                    </TableRow>                
                ))
             ))
            }
        </>
    );
}



function Card_products() {

    const classes = useStyles();
    const [selected_products,setSelected_products] = useState(null);
    const [selected_products_details,setSelected_products_details] = useState(null);
    const [proceed_order,setProceed_order] = useState(false);
    const [categories,setCategories]=useState([]);

    useEffect(()=>{

        async function load_data(){
            let tmp_selected_products = sessionStorage.getItem('products_selected')?JSON.parse(sessionStorage.getItem('products_selected')):{};
            await setSelected_products(tmp_selected_products);

            await get_categories().then((response)=>{
                setCategories(response.data);
            }).catch((error)=>{
              //console.log(error.response);
            });

            let tmp_products_bysupplier = {};
            if( Object.keys(tmp_selected_products).length!==0 ){
                for(const id_supplier in tmp_selected_products){
                    let products = tmp_selected_products[id_supplier];
                    let selected_products_tmp_array = [];
                    products.map((product) => {
                        selected_products_tmp_array.push(product.product_code);
                    });
                    let selected_products_details_tmp_array = [];
                    let formData = new FormData();
                    formData.append('products_codes',JSON.stringify(selected_products_tmp_array));
                    const result_tmp = await get_products_byCode(formData).then((response)=>{
                        let data = response.data;
                        for(let key in data){
                            data[key].products.map((element) => {
                                products.map((selected_product,id) => {
                                    if(selected_product.product_code === element.product_code){
                                        element.product_amount = element.product_amount?element.product_amount:selected_product.product_amount;
                                        element.product_price = element.product_price?element.product_price:0;
                                        element.product_promo = element.product_promo?element.product_promo:0;
                                    } 
                                })
                                if(element) selected_products_details_tmp_array.push(element); 
                            })
                            if(selected_products_details_tmp_array){
                                tmp_products_bysupplier[key] = {"products":selected_products_details_tmp_array};
                            }
                        }
                        return tmp_products_bysupplier;
                    }).catch((error)=>{
                        //console.log(error.response);
                    });
                    tmp_products_bysupplier = {
                        ...tmp_products_bysupplier,
                        ...result_tmp
                    };
                }
                setSelected_products_details(tmp_products_bysupplier);
            }else { 
                setSelected_products_details({}); 
                //console.log("dddd");
            }
        }

        load_data();

    },[])


    function update_card_products(id_supplier,id,product_code,new_product_amount){
        let tmp_products_selected_details = { ...selected_products_details };
        let tmp_products_selected = { ...selected_products };
        //console.log(tmp_products_selected_details)
        tmp_products_selected_details[id_supplier].products[id].product_amount = new_product_amount;
        setSelected_products_details(tmp_products_selected_details);
        tmp_products_selected[id_supplier.split("*")[0]][id] = {
            "product_code" : product_code,
            "product_amount": new_product_amount
        };
        setSelected_products(tmp_products_selected);
        sessionStorage.setItem('products_selected',JSON.stringify(tmp_products_selected));
      }


      function delete_card_products(id_supplier,id,product_code){
        let tmp_products_selected_details = { ...selected_products_details };
        let tmp_products_selected = { ...selected_products };
        tmp_products_selected_details[id_supplier].products.splice(id, 1);
        if(tmp_products_selected_details[id_supplier].products.length === 0) delete tmp_products_selected_details[id_supplier];
        setSelected_products_details(tmp_products_selected_details);
        tmp_products_selected[id_supplier.split("*")[0]].splice(id, 1);
        if(tmp_products_selected[id_supplier.split("*")[0]].length === 0) delete tmp_products_selected[id_supplier.split("*")[0]]; 
        setSelected_products(tmp_products_selected);

        //console.log(tmp_products_selected_details); 
        //console.log(tmp_products_selected); 

        sessionStorage.setItem('products_selected',JSON.stringify(tmp_products_selected));
        window.dispatchEvent(new Event("storage"));
      }

//////////////////////
/////////////////////////////////////////////////////////
///////////////////////////////////

  return (
    <>
    {
    proceed_order===false
    ?
    <>
    {
      selected_products_details===null || selected_products===null || Object.keys(selected_products_details).length !==  Object.keys(selected_products).length
      ?<Loading_animation_view />
      :<div className="card_container_modal">
         <TableContainer className="card_products_table_container" component={Paper}>
            <Table className={classes.table} aria-label="sticky table">
                <TableHead>
                <TableRow>
                    {
                        columns.map((column) => (
                            <TableCell key={column.id} align={column.align}>{column.label}</TableCell>
                        ))
                    }
                </TableRow>
                </TableHead>
                <TableBody>
                {
                    Object.keys(selected_products_details).length!==0
                    ?
                    <>
                        <Products categories={categories} products={selected_products_details} update_card_products={update_card_products} delete_card_products={delete_card_products}/>
                    </>
                            
                    :<div style={{margin:"10px",fontSize:".8rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucun produit disponible</div>
                }
                </TableBody> 
            </Table>
        </TableContainer> 
        <div className="proceed_button_container">
            <Button variant="contained" className={Object.keys(selected_products_details).length!==0?"submit_but":"submit_but disabled_button"}
                 endIcon={<NavigateNextIcon />} onClick={() => setProceed_order(true)}> 
                        Poursuivre la commande
            </Button>
        </div>
      </div>
    }
    </>
    :<Order_form setProceed_order={setProceed_order} setSelected_products_details={setSelected_products_details} selected_products={selected_products} setSelected_products={setSelected_products} />
    }
    </>
  );
}

export default Card_products;
