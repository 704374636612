import '../../../../assets/css/request_details.css'
import React, { useState , useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { Accordion } from 'react-bootstrap';

import Loading_animation_view from '../../../loading_animation_view/loading_animation_view';
import { get_supplier_applicant_request_details } from '../../../../services/supplier_service';
import { get_order_productsInfos, download_order_file } from '../../../../services/for_allServices';
import Order_details_modal from './order_details/order_details';
import View_refuse_reason from '../view_refuse_reason/view_refuse_reason';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }));
  

function Request_detail({title,details}){
    return (
      <div>
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                     <div className='accordion_header_content'> <span>{title}</span> </div>
                </Accordion.Header>
                <Accordion.Body>
                <div className="order_form_details_container">   
                 <div className="order_form_infos_container">
                    <div className='order_form_infos_title'>1 - Infos de livraison</div>
                    <Row className="mb-12">
                            <Form.Group className="col-6">
                                <label>Pays</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Pays *"
                                    value={details.delivery_country}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Ville</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Ville *"
                                    value={details.delivery_city}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Adresse</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Adresse *"
                                    value={details.delivery_address}
                                    readOnly
                                />           
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Code postal</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Code postal *"
                                    value={details.delivery_postCode}
                                    readOnly
                                />           
                            </Form.Group>

                            <Form.Group className="col-6">
                                <label>Date de livraison souhaitée</label>
                                <Form.Control
                                    type="date"
                                    min={(new Date()).toISOString().split('T')[0]}
                                    placeholder="Date de livraison souhaitée *"
                                    value={details.delivery_supposedDate}
                                    readOnly
                                />
                            </Form.Group>
                    </Row>
                    <div className='order_form_infos_title'>2 - Autres informations</div>
                    <Row className="mb-12">
                        {
                            details.off_catalogue!=null
                            ?<Form.Group className="col-md-12">
                                <label>Description de la demande</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    as="textarea"
                                    rows={5}
                                    placeholder="Description de la demande *"
                                    value={details.off_catalogue}
                                    readOnly
                                />
                            </Form.Group>
                            :<Form.Group className="col-md-12">
                                <label>Projet</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    rows={3}
                                    placeholder="Projet *"
                                    value={details.project}
                                    readOnly
                                />
                            </Form.Group>
                        }
                            <Form.Group className="col-md-12">
                                <label>Objet de la demande</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    as="textarea" rows="3" 
                                    placeholder="Objet de la demande *"
                                    value={details.object}
                                    readOnly
                                />
                            </Form.Group>
                      </Row>
                    </div>
                </div>
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
     </div>
    )
}


function state(request_state){
    return(
      <>
        {
         request_state === null || request_state === "demander à être changée"
         ?<Button variant="contained" className="status_waiting">{request_state?request_state:'En cours'}</Button>
         :request_state === "refusée" || request_state === "validation refusée" || request_state === "annulée"  || request_state === "refusée par le fournisseur" || request_state === "non conforme"
            ?<Button variant="contained" className="status_deny">{request_state}</Button>
            :request_state === "sous réserve de validation"
              ?<Button variant="contained" className="status_waiting">{request_state}</Button>
              :<Button variant="contained" className="status_success">{request_state}</Button>
        }
      </>
    );
  }


function Order_detail({title,id_supplier,request_code,supplier,details,init_products_bysupplier
                ,products_bysupplier,setProducts_bysupplier,order_key}){

    const [open_reason_modal,setOpen_reason_modal]=useState(null);

    function download_order_details_file(e,request_code,id_supplier){
        e.stopPropagation();
        let request_code_tmp_array = request_code.split(' - ');
        const formData = new FormData();
        formData.append('id_supplier',id_supplier);
        formData.append('request_code',request_code);
        download_order_file(formData).then((response) => {
            var fileURL = window.URL.createObjectURL(new Blob([response.data]));
            var fileLink = document.createElement('a');
            fileLink.href = fileURL;
            fileLink.setAttribute('download', request_code_tmp_array[0]+"_"+request_code_tmp_array[1]+"_"+id_supplier+".pdf");
            document.body.appendChild(fileLink);
            fileLink.click();
        });
    }

    return (
      <div>
        <Accordion>
            <Accordion.Item eventKey="0">
                <Accordion.Header>
                  <div className='order_accordion_header'>
                    <div className='accordion_header_content'>
                            <span>{title}</span>
                    </div>
                    <div className='accordion_header_actions'>
                            <span className="order_state">
                                <div className='request_state_container'>
                                    {state(details.order_state)}
                                    <>
                                        {
                                        details.order_state === "sous réserve de validation" || details.order_state === "validation refusée" || details.order_state === "refusée" || details.order_state === "annulée"  || details.order_state === "refusée par le fournisseur"  || details.order_state === "non conforme" 
                                        ?<span className='view_reason_button' onClick={(e) => { e.stopPropagation(); setOpen_reason_modal(details.service_refuse_reason && details.order_state !== "refusée" && details.order_state !== "sous réserve de validation" && details.order_state !== "refusée par le fournisseur"?details.service_refuse_reason:details.refuse_reason)}}>Motif</span>
                                        :<></>
                                        }
                                    </>
                                </div>
                            </span>
                            <Tooltip title="Exporter en fichier pdf" placement="bottom" >
                                <span style={{marginLeft:'8px'}}>
                                <Fab size="small" onClick={(e) => download_order_details_file(e,request_code,id_supplier)} color='default' style={{backgroundColor:'#efefef'}} className={details.order_state!=null  && details.order_state!=="annulée" 
                                                                                    && details.order_state!=="refusée" && details.order_state!=="validation refusée" && details.order_state!=="sous réserve de validation"  ?"round_button":"round_button disabled_button"} >
                                    <GetAppRoundedIcon className="my_icon" />
                                </Fab>
                                </span>
                            </Tooltip>
                        </div>
                 </div>
                </Accordion.Header>
                <Accordion.Body>
                    <Order_details_modal id_supplier={id_supplier} selected_products_details={details} 
                                init_products={init_products_bysupplier} request_code={request_code}
                                products_bysupplier={products_bysupplier} setProducts_bysupplier={setProducts_bysupplier}
                                order_key={order_key} />
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>
        {
            open_reason_modal!==null
            ?<View_refuse_reason open_reason_modal={open_reason_modal} setOpen_reason_modal={setOpen_reason_modal} />
            :<></>
        }
     </div>
    )
}

function Request_details({request_code,setRequest_code,id_supplier}){

    const [products_bysupplier,setProducts_bysupplier]=useState(null);
    let [init_products_bysupplier,setinit_products_bysupplier]=useState(null);

    const [details,setDetails]=useState(null);
    const classes = useStyles();

    useEffect(()=>{

        async function get_orders_products(orders,request_code,order_number,order_state,service_accord,id_supplier){
            var tmp_products_bysupplier = {};
            //console.log(orders);
            let selected_products = orders.products;
            let selected_products_tmp_array = [];
            selected_products.map((selected_product) => {
                selected_products_tmp_array.push(selected_product.product_code);
            })
            let formData = new FormData();
            formData.append('products_codes',JSON.stringify(selected_products_tmp_array));
            formData.append('request_code',request_code);
            formData.append('id_supplier',id_supplier);
            var selected_products_details_tmp_array = [];
            await get_order_productsInfos(formData).then((response)=>{
                //console.log(response.data);
                let data = response.data;
                for(const id_supplier_name in data){
                    data[id_supplier_name].products.map((element,id) => {
                        selected_products.map((selected_product,id) => {
                            if(selected_product.product_code === element.product_code){
                                    element.product_amount = element.product_amount?element.product_amount:selected_product.product_amount;
                            } 
                        })
                        selected_products_details_tmp_array.push(element);
                    });
                    tmp_products_bysupplier[id_supplier_name] = response.data[id_supplier_name];
                    tmp_products_bysupplier[id_supplier_name]["order_number"] = order_number;
                    tmp_products_bysupplier[id_supplier_name]["order_state"] = order_state;
                    tmp_products_bysupplier[id_supplier_name]["service_accord"] = service_accord;
                    tmp_products_bysupplier[id_supplier_name]["products"] = selected_products_details_tmp_array;
                }
            }).catch((error)=>{
                //console.log(error.response);
            });
            //console.log(tmp_products_bysupplier);
            setProducts_bysupplier(tmp_products_bysupplier);
        } 

        const formData = new FormData();
        formData.append('request_code',request_code);
        formData.append('id_supplier',id_supplier);
        get_supplier_applicant_request_details(formData).then((response)=>{
            //console.log(response.data);
            setinit_products_bysupplier(JSON.parse(response.data.products));
            setDetails(response.data);
            get_orders_products(JSON.parse(response.data.products),request_code,response.data.order_number,response.data.order_state,response.data.service_accord,id_supplier);
        }).catch((error)=>{
            //console.log(error.response);
        })
    },[]);


    return (
        <Dialog disableEnforceFocus fullScreen open={request_code?true:false} onClose={() => setRequest_code(null)} className="my_full_dialog contract_full_dialog">
            <AppBar className={classes.appBar}  >
                <Toolbar style={{backgroundColor:'#343a40'}} >
                    <div className='page_title'>
                        <IconButton edge="start" color="inherit" onClick={() => setRequest_code(null)} aria-label="close" >
                            <CloseIcon className="my_icon" />
                        </IconButton>
                        <span> Details de demande : {request_code} </span>
                    </div>
                </Toolbar>
            </AppBar>

          {
            details!==null && products_bysupplier!==null && init_products_bysupplier!==null
            ?<div className="off_catalogue_requestDetails_container request_details_container">
                <Request_detail 
                    title="Détails" 
                    details={details} />
                <>
                {
                    Object.keys(products_bysupplier).length!==0
                    ?<>
                    {   Object.keys(products_bysupplier).map((key, id) => (
                             <Order_detail 
                                title={"Commande"}
                                id_supplier={key.split("*")[0]} 
                                supplier={key.split("*")[1]} 
                                request_code={request_code}
                                details={products_bysupplier[key]} init_products_bysupplier={init_products_bysupplier} 
                                products_bysupplier={products_bysupplier}
                                setProducts_bysupplier={setProducts_bysupplier}  order_key={key} key={key} />
                        ))   
                    }
                    </>
                    :<></>
                }
                </>
                <div className="articles_container">
                    {
                        JSON.parse(details.products).articles?JSON.parse(details.products).articles.map((article,id)=>(
                            <div className='articles_view_container' key={'articles'+id}>
                                <div className="article_view_header_container">
                                    <span>Article n°{id+1}</span>
                                </div>
                                <div>{article}</div>
                            </div>
                        )):<></>
                    }
                </div>
             </div>
            :<Loading_animation_view />
          }
            
        </Dialog>
    )
}

export default Request_details;
