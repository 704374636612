import './form_content.css'
import React,{useEffect,useState} from "react";
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Form, Row } from 'react-bootstrap';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import { Backdrop, CircularProgress, IconButton, Toolbar } from '@material-ui/core';

import { get_forms_models_admin, get_questions} from '../../../../../services/company_admin_service';

import { Check } from '@material-ui/icons';
import { update_form_content } from '../../../../../services/for_allServices';
import Loading_animation_view from '../../../../loading_animation_view/loading_animation_view';
import { get_categories } from '../../../../../services/purchase_service';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
}));




const ResponseText_question = ({question,id,set_question_response_value}) => {

    return (
        <div className="question_container">
            <div className="question_label_container"> {(id+1)+". "+question.label} <span style={{color:"red"}}>*</span> </div>
            <div className="question_content_container">
                <Form.Control as="textarea" placeholder="Réponse *" rows={2} 
                    value={question.response!=null && question.response!=""?question.response:""}
                    onChange={(e)=>set_question_response_value(question,id,e.target.value)} />
            </div>
        </div>
    );
}

const TrueFalse_question = ({question,id,set_question_response_value}) => {
    return (
        <div className="question_container">
            <div className="question_label_container">{(id+1)+". "+question.label} <span style={{color:"red"}}>*</span> </div>
            <div className="question_content_container">
                <Form.Check 
                    type="radio"
                    id={"True_"+id}
                    value="True"
                    checked={question.response=="True"?true:false}
                    name={"True/False_"+id}
                    label="Vrai"
                    onChange={(e)=>set_question_response_value(question,id,e.target.value)}
                />
                <Form.Check 
                    type="radio"
                    id={"False_"+id}
                    value="False"
                    checked={question.response=="False"?true:false}
                    name={"True/False_"+id}
                    label="Faux"
                    onChange={(e)=>set_question_response_value(question,id,e.target.value)}
                />
            </div>
        </div>
    );
}

const CheckBox_question = ({question,id,set_question_response_value}) => {

    return (
        <div className="question_container">
            <div className="question_label_container">{(id+1)+". "+question.label} <span style={{color:"red"}}>*</span> </div>
            <div className="question_content_container">
                {
                    (question.content.split(";")).map((elem,id_elem)=>(
                        <Form.Check 
                            multiple
                            type="checkbox"
                            id={id+"_"+id_elem}
                            key={id+"_"+id_elem}
                            name={"Checkbox_"+id}
                            checked={question.response?(question.response).split(';').includes(elem)?true:false:false}
                            value={elem}
                            label={elem}
                            onChange={(e)=>set_question_response_value(question,id,e.target)}
                        />
                    ))
                }
            </div>
        </div>
    )
}


const SelectChoice_question = ({question,id}) => {

    return (
        <div className="question_container">
            <div className="question_label_container">{(id+1)+". "+question.label} <span style={{color:"red"}}>*</span> </div>
            <div className="question_content_container">
                <Form.Select
                    required
                    name={"select_"+id}
                    defaultValue={"null"}
                >
                    <option value={"null"} disabled>Sélectionner</option>
                    <>
                    {
                        (question.content.split(";")).map((elem,id_elem)=>(
                            <option value={elem} key={"select_"+id+"_value_"+id_elem}>{elem}</option>
                        ))
                    }
                    </>
                </Form.Select>
            </div>
        </div>
    )
}


const Form_content = ({forms_models,view_appraisee_form_content,setview_appraisee_form_content,selected_appraisee_infos,setselected_appraisee_infos})=>{

    const classes = useStyles();
    let history = useHistory();
    const [form_questions,setForm_questions] = useState(null);
    const [selected_form,setSelected_form] = useState(null);
    const [errorText, seterrorText] = useState(null);
    const [categories,setCategories]=useState(null);
    const [wait_submitting,setwait_submitting]=useState(false);

    useEffect(()=>{
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        async function load_data(){
            get_categories().then((response)=> {
                //console.log(response.data);
                setCategories(response.data);
              }).catch((error)=>{
                //console.log(error.response);
              });
            let view_model_tmp = false;
            view_model_tmp = (urlParams.get('model')=="editor"?true:false);

            let selected_form_id_tmp = null;
            selected_form_id_tmp = (urlParams.get('id')?urlParams.get('id'):null);

            //get form model infos
            /*const tmp_selected_form_model = forms_models.filter((elem)=>{
                return view_appraisee_form_content.form_model == elem.id;
            });*/
            if(view_appraisee_form_content !=null && view_appraisee_form_content.content!=""){
                setSelected_form(view_appraisee_form_content);

                if(!view_appraisee_form_content.content.includes("{")){
                    let questions_tmp = await get_questions().then((response)=>{
                        return response.data;
                    });
                    let form_questions_tmp = [];
    
                    let forms_questions_ids = view_appraisee_form_content.content.split(";");
                    let key_forms_questions_ids = [];
                    let response_value = {
                        response : null
                    }
                    forms_questions_ids.forEach((question_id)=>{
                        questions_tmp.forEach((elem,id2)=>{
                            if(question_id == elem.id){
                                elem = {
                                    ...elem,
                                    ...response_value
                                  };
                                form_questions_tmp.push(elem);
                                key_forms_questions_ids.push(id2);
                            }
                        });
                    });
                    key_forms_questions_ids = key_forms_questions_ids.sort();
                    if(view_model_tmp==true) await setForm_questions(form_questions_tmp);
                    else setForm_questions([]);
                }else{
                    setForm_questions(JSON.parse(view_appraisee_form_content.content));
                }
                
            }else{
                setForm_questions([]);
            }
        }
        load_data();
    },[]);

    async function close_form_model(){
        let path = history.location.pathname;
        let queryString = window.location.search;
        let urlParams = new URLSearchParams(queryString);
        await setview_appraisee_form_content(null);
        await setselected_appraisee_infos(null);
        urlParams.delete("appraisee");
        urlParams.delete("model");
        history.replace({
            search: urlParams.toString(),
        });
        await setForm_questions(null);
        history.push(urlParams);
        //setUrl_updated(history);
    }

    function set_question_response_value(question,id,returned_value){
        let form_questions_tmp = [...form_questions];
        if(question.question_type == 3){
            let returned_value_tmp = "";
            const values = document.getElementsByName('Checkbox_'+id);
            values.forEach((checkbox_elem) => {
                if(checkbox_elem.checked){
                    if(returned_value_tmp.length == 0) returned_value_tmp += checkbox_elem.value;
                    else returned_value_tmp += ";"+checkbox_elem.value;
                }
            })
            returned_value = returned_value_tmp;
        }
        form_questions[id].response = returned_value;
        setForm_questions(form_questions_tmp);
        console.log(form_questions_tmp);
    }

    const getCategorieById = (categorie)=>{
        var tmp_categorie="";
        categories.map((catg)=>{
          categorie.map((id)=>{
            if(catg.id_categorie === parseInt(id)){
              if(tmp_categorie==="") tmp_categorie = tmp_categorie+catg.nom;
              else tmp_categorie = tmp_categorie+", "+catg.nom;
            }
          })
        })
        return tmp_categorie;
      }


    function reset_data(){
        let form_questions_tmp = [...form_questions];
        for(let i=0; i<form_questions.length; i++){
            form_questions_tmp[i].response = null;
        }
        setForm_questions(form_questions_tmp);
        console.log(form_questions_tmp);
    }
    
    function submit_data(){
        setwait_submitting(true);
        let formData = new FormData();
        formData.append("id_campaign",view_appraisee_form_content.id_campaign);
        formData.append("id_appraiser",view_appraisee_form_content.id_appraiser);
        formData.append("id_appraisee",view_appraisee_form_content.id_appraisee);
        formData.append("content",JSON.stringify(form_questions));
        console.log(form_questions);
        update_form_content(formData).then((response)=>{
            console.log(response.data);
            setwait_submitting(false);
        }).catch((error)=>{
            //console.log(error.response);
            setwait_submitting(false);
        });
    }

    return (
    <Dialog disableEnforceFocus fullScreen open={(form_questions && view_appraisee_form_content && selected_appraisee_infos)?true:false} onClose={() => close_form_model()} className="my_full_dialog contract_full_dialog">
        <AppBar className={classes.appBar}  >
            <Toolbar style={{backgroundColor:'#343a40'}} >
                <div className='page_title'>
                    <IconButton edge="start" color="inherit" onClick={() => close_form_model()} aria-label="close" >
                        <CloseIcon className="my_icon" />
                    </IconButton>
                    <span> {"Formulaire"} </span>
                </div>
            </Toolbar>
        </AppBar>
        <div className="form_content_global_container">
            {
                form_questions==null || selected_form==null
                ?<Loading_animation_view />
                :<div className="form_content_container">
                    <div className="question_container appraisee_infos_container">
                        <Row className="mb-12">
                            <Form.Group className="col-6">
                                <label>Nom complet</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Nom *"
                                    value={selected_appraisee_infos.nom}
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Maison-mère</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Maison-mère"
                                    value={selected_appraisee_infos.maisonMere}
                                    readOnly
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-12">
                            <Form.Group className="col-6">
                                <label>Famille d'achat</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Famille d'achat"
                                    value={categories!==null?getCategorieById(selected_appraisee_infos.familleAchat.split(',')):""} 
                                    readOnly
                                />
                            </Form.Group>
                            <Form.Group className="col-6">
                                <label>Date de traitement</label>
                                <Form.Control
                                    required
                                    autoComplete
                                    type="text"
                                    placeholder="Date de traitement"
                                    value={selected_appraisee_infos.dateTraitement}
                                    //value={categories!==null?getCategorieById(supplier.familleAchat):""} 
                                    readOnly
                                />
                            </Form.Group>
                        </Row>
                    </div>
                    {
                        form_questions.map((question,id)=>{
                            if(question.question_type == 1) return ( <ResponseText_question question={question} id={id} set_question_response_value={set_question_response_value} key={"question_type_"+id} />)
                            else{
                                if(question.question_type == 2) return ( <TrueFalse_question question={question} id={id} set_question_response_value={set_question_response_value} key={"question_type_"+id} />)
                                else{
                                    if(question.question_type == 3) return ( <CheckBox_question question={question} id={id} set_question_response_value={set_question_response_value} key={"question_type_"+id} />)
                                    else{
                                        if(question.question_type == 4) return ( <SelectChoice_question question={question} id={id} set_question_response_value={set_question_response_value} key={"question_type_"+id} />)
                                    }
                                }
                            } return ""
                        })
                    }
                    <div className='dialogBottom'>
                        <div>
                            {
                                errorText === ""
                                ?<></>
                                :<p className={errorText ? "col-md-12 error" : "col-md-12 valid-champ"}>
                                        {errorText}
                                    </p>
                            }
                        </div>
                        <div>
                            <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon />} onClick={() => reset_data()} >
                                Réinitialiser
                            </Button>
                            <Button variant="contained" className="submit_but valide_but" startIcon={<Check />} onClick={submit_data} >
                                Valider
                            </Button>  
                        </div>
                        
                    </div>
                </div>
            }
        </div>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait_submitting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    );
}


export default Form_content;