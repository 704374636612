import './auditor_commissions.css';
import { useHistory } from "react-router-dom";
import React, { useEffect,useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Fab from '@mui/material/Fab';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Button from '@material-ui/core/Button';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Pagination from "react-js-pagination";

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import swal from 'sweetalert';

import {makeStyles} from '@material-ui/core/styles';
import { get_auditor_byIdLogin } from '../../../services/auditor_service';
import { get_commissions_by_id_auditor, validate_commission_by_id_auditor } from '../../../services/commission_service';
import { get_categories } from '../../../services/purchase_service';
import Modal_dates_proposition from './modal_dates_proposition/modal_dates_proposition';
import Offer_submissions from './offer_submissions/offer_submissions';
import Loading_animation_view from '../../loading_animation_view/loading_animation_view';


const status = ['En Cours','Refusé','Accepté'];

function setStatus(stat){
    return(
      <>
        {
         stat == null
         ?<Button variant="contained" className="status_waiting">{status[0]}</Button>
         :stat == 0
            ?<Button variant="contained" className="status_deny">{status[1]}</Button>
            :<Button variant="contained" className="status_success">{status[2]}</Button>
        }
      </>
  
    );
  }

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const columns = [
    { id: 'Objet', label: "Objet d'appel", minWidth: 0 },
    { id: 'direction', label: "Direction", minWidth: 0 },
    { id: 'categorie', label: "Famille d'achat", minWidth: 0 },
    { id: 'limiteDate', label: "Date limite", minWidth: 0 },
    { id: 'openingDate', label: "Date d'ouverture des plis", minWidth: 0 },
    { id: 'otherMembers', label: "Autres membres", minWidth: 0 },
    { id: 'accord', label: "Votre accord", minWidth: 0 },
    { id: 'submissions', label: "Soumissions", minWidth: 0 },
  ];





  function CompareOpeningDate_current(openingDate){
    let day = new Date(openingDate);
    let current_date = new Date();
    if(day.getTime() > current_date.getTime()) return false;
    else return true;
  }



function Commissions({offers_commissions,departement,getCategorieById,accept_proposition,setDate_proposition,setview_submissions}){
    return(
        <>
        {
            offers_commissions.map((commission,id) => (
                <TableRow key={commission.id+"col"+id}>
                    <TableCell align="left">{commission.object}</TableCell>
                    <TableCell align="left">{commission.direction}</TableCell>
                    <TableCell align="left">{getCategorieById(commission.familleAchat)}</TableCell>
                    <TableCell align="left">{(new Date(commission.dateLimite)).toLocaleString()}</TableCell>
                    <TableCell align="left">{(new Date(commission.dateOuvertureOffre)).toLocaleString()}</TableCell>
                    <TableCell align="left">
                     <div className='documentsContainer'>
                         {
                             departement!="juridique"
                             ?<div style={{display:'flex',marginBottom:'10px'}}>
                                <div className='documentsDownload'>
                                    <span className='documentsTitle'>Juridique</span>
                                </div>
                                <Tooltip disableFocusListener disableTouchListener title="L'accord !">
                                    <div className='documentsStatus_valide' >
                                        { setStatus(commission.validated_legal)}
                                    </div>
                                </Tooltip>
                                
                            </div>
                            :<></>
                         }
                         {
                             departement!="finance"
                             ?<div style={{display:'flex',marginBottom:'10px'}}>
                                <div className='documentsDownload'>
                                    <span className='documentsTitle'>Finance</span>
                                </div>
                                <Tooltip disableFocusListener disableTouchListener title="L'accord !">
                                    <div className='documentsStatus_valide' >
                                        { setStatus(commission.validated_financial)}
                                    </div>
                                </Tooltip>
                                
                            </div>
                            :<></>
                         }
                         {
                             departement!="technique"
                             ?<div style={{display:'flex',marginBottom:'10px'}}>
                                <div className='documentsDownload'>
                                    <span className='documentsTitle'>Technique</span>
                                </div>
                                <Tooltip disableFocusListener disableTouchListener title="L'accord !">
                                  <div className='documentsStatus_valide'>
                                        {setStatus(commission.validated_technical)}                
                                    </div>  
                                </Tooltip>
                                
                            </div>
                            :<></>
                         }
                     </div>
                    </TableCell>
                    <TableCell align="center">
                        <div className='AccordCtrl_container' style={{display:'flex',justifyContent:'center'}} >
                            <div style={{display:'flex',marginBottom:'5px',justifyContent:'center'}} >
                                {
                                    departement=="technique"
                                    ?commission.validated_technical==1 || commission.proposed_meet_date2!=null || CompareOpeningDate_current(commission.proposed_meet_date2!=null?commission.proposed_meet_date2:commission.proposed_meet_date1) !== false
                                        ?<CheckIcon  className="checkIcon disabled_button" size="small" />
                                        :<CheckIcon  className="checkIcon" size="small" onClick={()=>{accept_proposition(1,commission,id,departement)}}/>
                                    :departement=="finance"
                                        ?commission.validated_financial==1 || commission.proposed_meet_date2!=null || CompareOpeningDate_current(commission.proposed_meet_date2!=null?commission.proposed_meet_date2:commission.proposed_meet_date1) !== false
                                            ?<CheckIcon  className="checkIcon disabled_button" size="small" />
                                            :<CheckIcon  className="checkIcon" size="small" onClick={()=>{accept_proposition(1,commission,id,departement)}}/>
                                        :commission.validated_legal==1 || commission.proposed_meet_date2!=null || CompareOpeningDate_current(commission.proposed_meet_date2!=null?commission.proposed_meet_date2:commission.proposed_meet_date1) !== false
                                            ?<CheckIcon  className="checkIcon disabled_button" size="small" />
                                            :<CheckIcon  className="checkIcon" size="small" onClick={()=>{accept_proposition(1,commission,id,departement)}}/>
                                }
                                {
                                    departement=="technique"
                                    ?commission.validated_technical==0 || commission.proposed_meet_date2!=null || CompareOpeningDate_current(commission.proposed_meet_date2!=null?commission.proposed_meet_date2:commission.proposed_meet_date1) !== false
                                        ?<CloseSharpIcon  className="closeSharpIcon disabled_button" size="small" />
                                        :<CloseSharpIcon  className="closeSharpIcon" size="small" onClick={()=>{setDate_proposition(commission,id)}}/>
                                    :departement=="finance"
                                        ?commission.validated_financial==0 || commission.proposed_meet_date2!=null || CompareOpeningDate_current(commission.proposed_meet_date2!=null?commission.proposed_meet_date2:commission.proposed_meet_date1) !== false
                                            ?<CloseSharpIcon  className="closeSharpIcon disabled_button" size="small" />
                                            :<CloseSharpIcon  className="closeSharpIcon" size="small" onClick={()=>{setDate_proposition(commission,id)}}/>
                                        :commission.validated_legal==0 || commission.proposed_meet_date2!=null || CompareOpeningDate_current(commission.proposed_meet_date2!=null?commission.proposed_meet_date2:commission.proposed_meet_date1) !== false
                                            ?<CloseSharpIcon  className="closeSharpIcon disabled_button" size="small" />
                                            :<CloseSharpIcon  className="closeSharpIcon" size="small" onClick={()=>{setDate_proposition(commission,id)}}/>
                                }
                            </div>
                            <div>
                                {
                                    departement=="technique"
                                    ?setStatus(commission.validated_technical)
                                    :departement=="finance"
                                        ?setStatus(commission.validated_financial)
                                        :setStatus(commission.validated_legal)
                                }
                            </div>
                        </div>
                    </TableCell>
                    <TableCell align="center">
                         {
                          CompareOpeningDate_current(commission.proposed_meet_date2!=null?commission.proposed_meet_date2:commission.proposed_meet_date1) == false
                          ?<Tooltip disableFocusListener disableTouchListener title="Date d'ouverture pas encore atteinte !">
                            <span>
                                <VisibilityIcon  className="my_icon editIcon disabled_button" />
                            </span>
                            
                          </Tooltip>
                          :<VisibilityIcon  className="my_icon editIcon" onClick={()=>{setview_submissions(commission)}}  />
                         }
                    </TableCell>
                </TableRow>
             ))
        }
        </>
    );
}

function Auditor_commissions() {

    const classes = useStyles();

    let auditor = JSON.parse(localStorage.getItem('user-info'))
    let history = useHistory();
    const [url_updated , setUrl_updated] = useState(null);
    const [auditor_infos,setAuditor_infos] = useState(null);
    const [departement,setDepartement] = useState(null);
    const [offers_commissions,setOffers_commissions] = useState(null);

    const [selected_commission,setSelected_commission]=useState(null);
    const [open_setDate_propositionModal,setOpen_setDate_propositionModal]=useState(null);

    const [categories,setCategories]=useState([]);

    const [filter_categorie,setFilter_categorie]=useState("all");
    const [search_value,setsearch_value]=useState("");
    const [search_filter,setsearch_filter]=useState('all');

    const [view_submissions,setview_submissions] = useState(null);
    const [waiting, setWaiting] = useState(false);

    const [currentPage,setCurrentPage]=useState(1);
    const [dataPerPage,setdataPerPage] =useState(6);
    const [data_count,setdata_count]=useState(0);


    useEffect(()=>{
        setOffers_commissions(null);
        setdata_count(0);
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        var tmp_searchFilter ="all";
        var tmp_searchValue = null;
        var tmp_filterCategorie = "all";

        async function load_data(){
            if(urlParams.get('filtre')){
                tmp_searchFilter = urlParams.get('filtre');
            }
            if(urlParams.get('valeur')){
                tmp_searchValue = urlParams.get('valeur');
            }if(urlParams.get('categorie')){
                tmp_filterCategorie = urlParams.get('categorie');
            }
            await setsearch_filter(tmp_searchFilter);
            await setsearch_value(tmp_searchValue);
            await setFilter_categorie(tmp_filterCategorie);
            let page = (urlParams.get('page')?urlParams.get('page'):1);
            await setCurrentPage(page);

            await get_categories().then((response)=> {
                setCategories(response.data);
              }).catch((error)=>{
                //console.log(error.response);
              });

            await get_auditor_byIdLogin(auditor.id).then((response)=>{
                setAuditor_infos(response.data);
                setDepartement(response.data.departement);
                get_commissions_by_id_auditor(response.data.departement,response.data.id,tmp_filterCategorie,tmp_searchFilter,tmp_searchValue,page).then((response_commissions)=>{
                    setdata_count(response_commissions.data.total);
                    setOffers_commissions(response_commissions.data.data);
                }).catch((error)=>{
                    //console.log(error.response);
                })
            }).catch((error)=>{
                //console.log(error.response);
            }) 
        }

        load_data();
        
    },[url_updated,history.location])


    function getCategorieById(id){
        var categorie="";
        categories.map((catg)=>{
          if(catg.id_categorie == id) categorie = catg.nom;
        })
        return categorie;
    }

  
  
    /////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////



function setDate_proposition(commission,index){
    setSelected_commission(commission);
    setOpen_setDate_propositionModal(index);
}

function close_setDate_propositionModal(){
    setSelected_commission(null);
    setOpen_setDate_propositionModal(null);
}

function accept_proposition(choice,commission,index,depart) {
    swal({
        title: "Etes-vous sûr ?",
        text: "!!!!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
        setWaiting(true);
        let formData = new FormData();
        formData.append("choice",choice);
        formData.append("id_commission",commission.id);
        formData.append("departement",depart);
        formData.append("id_auditor",auditor_infos.id);
        validate_commission_by_id_auditor(auditor_infos.id,formData).then((response) => {
            setWaiting(false);
            swal("Votre réponse a été sauvegardée avec succès !", {
                icon: "success",
                buttons: true,
            }).then((willDelete) => {
                if (willDelete) { 
                    let tmp_commissions = [...offers_commissions];
                    tmp_commissions[index]=response.data;
                    setOffers_commissions(tmp_commissions);
            }}) 
        }).catch((error)=>{
            setWaiting(false);
            //console.log(error.response);
        })
    }}) 

 }

//////////////////////
/////////////////////////////////////////////////////////
///////////////////////////////////


    function search(){
        let path = history.location.pathname;
        if(filter_categorie!=="all") history.push(path+"?categorie="+filter_categorie+"&filtre="+search_filter+"&valeur="+search_value);
        else history.push(path+"?filtre="+search_filter+"&valeur="+search_value);
        setUrl_updated(search_value);
    }


    function set__categorie(value){
        setFilter_categorie(value);
        let path = history.location.pathname;
        if(value!=="all") history.push(path+"?categorie="+value);
        else history.push(path+"?categorie=all");
        setUrl_updated(value);
    }

    function set__filter(value){
        if(value!=="Direction" && value!=="Object"){
            setsearch_filter(value);
            let path = history.location.pathname;
            if(filter_categorie!=="all") history.push(path+"?categorie="+filter_categorie+"&filtre="+value);
            else history.push(path+"?filtre="+value);
            setUrl_updated(value);
        }else setsearch_filter(value);
    }


    
  async function change_page(page){
    await setCurrentPage(page);
    let path = history.location.pathname;
    let params = history.location.search;
    let search_params = params.split('&');
    let tmp_params = path;
    if(!params) history.push(path+"?page="+page);
    else{
      for(let i in search_params){
        if(search_params[i].includes("page=")){
          if(i==0) tmp_params=tmp_params+"?page="+page;
          else tmp_params=tmp_params+"&page="+page;
        }else{
          if(i==0) tmp_params=tmp_params+search_params[i];
          else tmp_params=tmp_params+"&"+search_params[i];
        }
      }
      if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
      history.push(tmp_params);
    }
    await setUrl_updated(page);
  }


  return (
    <>
    {
      offers_commissions===null
      ?<Loading_animation_view />
      : <div className="commissions_container" >
        <div className='three_forms_header_container'>
            <div className='page_title'>Mes commissions</div>
            <div className='search_filter_container'>
                <FormControl variant="standard"  className="header_form">
                    <InputLabel id="demo-simple-select-helper-label">Famille d'achat</InputLabel>
                    <Select required value={filter_categorie} 
                            onChange={e => {set__categorie(e.target.value) }} >
                            
                            <MenuItem value={"all"}>Toutes les familles d'achat</MenuItem>
                            {
                            categories.map((categorie,id)=>(
                                <MenuItem value={categorie.nom} key={id} >{categorie.nom}</MenuItem>
                            ))
                            }
                            
                    </Select>
                </FormControl>

                <FormControl  className="header_form" >
                    <InputLabel id="demo-simple-select-helper-label">Filtre</InputLabel>
                    <Select value={search_filter}  onChange={(e)=>{set__filter(e.target.value)}}>
                        <MenuItem value="all">Aucun</MenuItem>
                        <MenuItem value='Object'>Objet d'appel</MenuItem>
                        <MenuItem value='Direction'>Direction</MenuItem>
                        <MenuItem value='valider'>Commission validée</MenuItem>
                        <MenuItem value='refuser'>Commission refusée</MenuItem>
                        <MenuItem value='encours'>Commission en cours de traitement</MenuItem>
                    </Select>
                    </FormControl>

                    <Grid container spacing={1} alignItems="flex-start" className={search_filter!=="Direction" && search_filter!=="Object"?"search_bar disabled_button":"search_bar" } >
                        <Grid item>
                            <SearchIcon onClick={search} className={search_value==""?"my_icon disabled_button":"my_icon" } style={{cursor:"pointer"}}  />
                        </Grid>
                        <Grid>
                            <TextField label="Chercher" value={search_value} onChange={(e)=>{setsearch_value(e.target.value)}}/>
                        </Grid>
                    </Grid>
            </div>
        </div>
        <div className="commissions_table_container">
            <TableContainer>
            <Table className={classes.table} aria-label="sticky table">
                <TableHead>
                <TableRow>
                    {
                        columns.map((column) => (
                            <TableCell key={column.id} align="left">{column.label}</TableCell>
                        ))
                    }
                </TableRow>
                </TableHead>
                <TableBody>
                    {
                    offers_commissions.length!==0
                        ?
                        <>
                            <Commissions offers_commissions={offers_commissions} departement={departement} getCategorieById={getCategorieById}
                                    accept_proposition={accept_proposition} setDate_proposition={setDate_proposition} setview_submissions={setview_submissions}/>

                        </>
                                
                        :<div style={{margin:"10px", textAlign:"center",alignContent:"center"}}>Aucune commission</div>   
                    }
                </TableBody> 
            </Table>
            </TableContainer>
            {
                offers_commissions.length!==0
                    ?<div className="pagination_container">
                        <Pagination
                            activePage={Number(currentPage)}
                            itemsCountPerPage={dataPerPage}
                            totalItemsCount={Number(data_count)}
                            pageRangeDisplayed={4}
                            onChange={change_page.bind(this)}
                        />
                    </div>
                    :<></>
                }
        </div>  


            {
                selected_commission!=null && open_setDate_propositionModal!=null
                ?<Modal_dates_proposition selected_commission={selected_commission} 
                        open_setDate_propositionModal={open_setDate_propositionModal} 
                        close_setDate_propositionModal={close_setDate_propositionModal} auditor_infos={auditor_infos}
                        offers_commissions={offers_commissions}
                        setOffers_commissions={setOffers_commissions} />
                :<></>
            }

            {
                view_submissions!=null
                ?<Offer_submissions view_submissions={view_submissions} setview_submissions={setview_submissions} departement={departement}/>
                :<></>
            }
        <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

        </div>
      }
    </>
  );
}

export default Auditor_commissions;
