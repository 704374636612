import '../../../../assets/css/bills.css'
import React, {useEffect,useState} from 'react';
import { useHistory } from "react-router-dom";

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Fab from '@mui/material/Fab';
import Tooltip from '@mui/material/Tooltip';
import Button from '@material-ui/core/Button';
import DownloadIcon from '@mui/icons-material/Download';
import Pagination from "react-js-pagination";

import { getAll_purchaseService_market, get_categories } from '../../../../services/purchase_service';

import State_details from '../state_details/state_details';
import Loading_animation_view from '../../../loading_animation_view/loading_animation_view';
import { download_bills_docs } from '../../../../services/for_allServices';

function setState(bill_state,financeService_accord,purchaseService_accord,businessService_accord){
  return(
    <>
      <Tooltip title="" placement="bottom" >
          <span style={{marginLeft:'2%'}}>
              {
                financeService_accord==="0" || purchaseService_accord==="0" || businessService_accord==="0"
                ?<Button className="status_deny" >{"refusée"}</Button>
                :bill_state==="comptabilisée"
                  ?<Button className="status_success" >{bill_state}</Button>
                    :bill_state==="à valider" && (purchaseService_accord===null || businessService_accord===null)
                    ?<Button className="status_success" >{bill_state}</Button>
                      :bill_state==="mis en règlement"
                      ?<Button className="status_success" >{bill_state}</Button>
                      :bill_state==="réglée"
                      ?<Button className="status_success" >{bill_state}</Button>
                      :bill_state==="archivée"
                        ?<Button className="status_success" >{bill_state}</Button>
                        :<Button className="status_waiting" >{"en cours"}</Button>
              }
          </span>
      </Tooltip>
    </>
  );
}


function verify_if_able_to_seeDetails(bill){
  if(bill.bill_state==="à valider" || bill.bill_state==="refusée" ) return true;
  return false;
}


function Bill_column({bill,id,id2}){
  
  const [view_state_details,setview_state_details] = useState(null);

  function download_billDocs(id_submission,bill_number,choice) {
    const formData = new FormData();
    formData.append('id_submission',id_submission);
    formData.append('bill_number',bill_number);
    formData.append('choice',choice);
    download_bills_docs(formData).then((response) => {
       var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', id_submission+"_"+choice+bill_number+".pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
    }).catch(err=>{
      console.log(err,err.response);
    });
  }

  return (
        <div className='bill_container'>
          <div className='bill_infos_container'>
            <div className='bill_infos'>
              <span className='download_bill_button' onClick={()=>download_billDocs(bill.id_submission,bill.bill_number,"bill_content")} >
                  <Fab size="small" color="default" >
                      <DownloadIcon className="my_icon" />
                  </Fab>
              </span>
              <div className='bill_name'>Facture N°{bill.bill_number}</div>
            </div>
            <div className='bill_amount'>
              {
                bill.amount_without_tax!==null
                ?<>{bill.amount_without_tax+" MAD"}</>
                :<span style={{opacity:".8"}} >non défini</span>
              }
            </div>
            <div className='bill_treatedDate'>
              {
                bill.expiration_date!==null
                ?(new Date(bill.expiration_date)).toLocaleString().split(/[,| ]/)[0]
                :<span style={{opacity:".8"}} >non défini</span>
              }
            </div>
            <div className='bill_treatedDate'>
              {(new Date(bill.limit_date)).toLocaleString().split(/[,| ]/)[0]}
            </div>
            <div className='bill_state_container'>
              <div className='bill_state'>{ setState(bill.bill_state,bill.financeService_accord,bill.purchaseService_accord,bill.businessService_accord) }
                {
                  verify_if_able_to_seeDetails(bill)
                  ?<div className="refuse_raison_button" 
                        onClick={()=>setview_state_details(
                                  [
                                    [bill.financeService_refuse_reason,bill.purchaseService_refuse_reason,bill.businessService_refuse_reason],
                                    [bill.financeService_accord,bill.purchaseService_accord,bill.businessService_accord]
                                  ]
                                )}>
                    Details
                  </div>
                  :<div></div>
                }   
              </div>
            </div>
          </div>

          <div className='bill_compDocs'>
              <div className='bill_compDoc'>
                  <span style={{marginRight:'7px'}} onClick={()=>download_billDocs(bill.id_submission,bill.bill_number,"reception_pv")} >
                      <Fab size="small" color="default" >
                          <DownloadIcon className="my_icon"  />
                      </Fab>
                  </span>
                  <span className='docs_name'>PV de réception</span>
              </div>
              <div className='bill_compDoc'>
                  <span style={{marginRight:'7px'}} onClick={()=>download_billDocs(bill.id_submission,bill.bill_number,"delivery_pv")} >
                      <Fab size="small" color="default" >
                          <DownloadIcon className="my_icon" />
                      </Fab>
                  </span>
                  <span className='docs_name'>PV de livraison</span>
              </div>
          </div>
          {
            view_state_details!==null
            ?<State_details view_state_details={view_state_details} setview_state_details={setview_state_details} />
            :<></>
          }
      </div>
  );
}

function Markets_list({markets}){

  return(
      <>
      {
          markets.map((market,id) => (
            <React.Fragment key={"market"+id}>
              <TableRow>
                  <TableCell align="left">{market.submission_id}</TableCell>
                  <TableCell align="left">{market.supplier_name}</TableCell>
                  <TableCell align="left">{market.offer_object}</TableCell>
                  <TableCell align="left">{market.offer_amount+" MAD"}</TableCell>
                  <TableCell align="left">
                    <div>
                    {
                          (market.bills).length!==0
                          ?<>
                            <div className='bill_container_titles'>
                              <div className='bill_infos_container'>
                                <div className='bill_infos'>
                                    <div className='bill_name'></div>
                                </div>
                                <div className='bill_amount'> Montant <br /> hors Tax</div>
                                <div className='bill_treatedDate'>Date <br /> d'échéance</div>
                                <div className='bill_treatedDate'>Date limite</div>
                                
                                <div className='bill_state'>Status</div>
                              </div>
                              <div className='bill_compDocs'>
                                    <span className='docs_name'>Docs complémentaires</span>
                              </div>
                            </div>
                            {
                              market.bills.map((bill,id2) => (
                                  <Bill_column bill={bill} id={id} id2={id2} key={"market_bill"+id+id2} />
                              ))
                            }
                          </>
                          :<></>
                        }
                        
                        <div className='add_bill_container'>
                            {
                              (market.bills).length==0
                              ?<span>Aucune facture déposée</span>
                              :<span></span>
                            }
                        </div>  
                    </div>
                  </TableCell>
              </TableRow>      
             </React.Fragment>          
          ))
      }
      </>
  );
}


function Markets_bills({filter_bills_src,url_updated , setUrl_updated}) {

  let purchase_service=JSON.parse(localStorage.getItem('user-info'))
  let history = useHistory();

  const [markets, setMarkets] = useState(null);
  
  const [categories,setCategories]=useState([]);

  const [filter_categorie,setFilter_categorie]=useState("all");
  const [search_value,setsearch_value]=useState("");
  const [search_filter,setsearch_filter]=useState('all');
  
  const [currentPage,setCurrentPage]=useState(1);
  const [dataPerPage,setdataPerPage] =useState(5);
  const [data_count,setdata_count]=useState(0);

  useEffect(()=>{
    setMarkets(null);
    setdata_count(0);

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var tmp_searchFilter ="all";
    var tmp_searchValue = null;
    var tmp_filterCategorie = "all";

    async function load_data(){
      if(urlParams.get('filtre') && urlParams.get('valeur')){
        tmp_searchFilter = urlParams.get('filtre');
        tmp_searchValue = urlParams.get('valeur');
      }if(urlParams.get('categorie')){
        tmp_filterCategorie = urlParams.get('categorie');
      }
      await setsearch_filter(tmp_searchFilter);
        await setsearch_value(tmp_searchValue);
        await setFilter_categorie(tmp_filterCategorie);

      await get_categories().then((response)=>{
        setCategories(response.data);
      }).catch((error)=>{
        //console.log(error.response);
      });

      let page = (urlParams.get('page')?urlParams.get('page'):1);
      await setCurrentPage(page)
      await getAll_purchaseService_market(purchase_service.id,tmp_filterCategorie,tmp_searchFilter,tmp_searchValue,page).then((response)=> {
            setMarkets(response.data.data);
            setdata_count(response.data.total);
      }).catch(err=>{
            console.log(err,err.response);
      });
    }
    
    load_data();

  },[]);


  function search(){
    let path = history.location.pathname;
    if(filter_categorie!=="all") history.push(path+"?src="+filter_bills_src+"&categorie="+filter_categorie+"&filtre="+search_filter+"&valeur="+search_value);
    else history.push(path+"?src="+filter_bills_src+"&filtre="+search_filter+"&valeur="+search_value);
    setUrl_updated(search_value);
  }

  function set__categorie(value){
    setFilter_categorie(value);
    let path = history.location.pathname;
    if(value!=="all") history.push(path+"?src="+filter_bills_src+"&categorie="+value);
    else history.push(path+"?src="+filter_bills_src+"&categorie=all");
    setUrl_updated(value);
  }

  function set__filter(value){
    if(value==="all"){
      setsearch_filter(value);
      let path = history.location.pathname;
      if(filter_categorie!=="all") history.push(path+"?src="+filter_bills_src+"&categorie="+filter_categorie+"&filtre="+value);
      else history.push(path+"?src="+filter_bills_src+"&filtre="+value);
      setUrl_updated(value);
    }else setsearch_filter(value);
  }


  function set_bills_src(value){
    let path = history.location.pathname;
    history.push(path+"?src="+value);
    setUrl_updated(value);
  }

  async function change_page(page){
    await setCurrentPage(page);
    let path = history.location.pathname;
    let params = history.location.search;
    let search_params = params.split('&');
    let tmp_params = path;
    if(!params) history.push(path+"?page="+page);
    else{
      for(let i in search_params){
        if(search_params[i].includes("page=")){
          if(i==0) tmp_params=tmp_params+"?page="+page;
          else tmp_params=tmp_params+"&page="+page;
        }else{
          if(i==0) tmp_params=tmp_params+search_params[i];
          else tmp_params=tmp_params+"&"+search_params[i];
        }
      }
      if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
      history.push(tmp_params);
    }
    await setUrl_updated(page);
  }

  return (
    <>
    {
        markets==null
        ?<Loading_animation_view />
        :<div className='purchase_bills_container'>
          <div className='bills_src_header'>
            <FormControl variant="standard" className="header_form" >
                <InputLabel id="demo-simple-select-helper-label">Factures/paiement</InputLabel>
                <Select required value={filter_bills_src} 
                        onChange={e => {set_bills_src(e.target.value) }} >
                        <MenuItem value={"marchés"} >{"Marchés"}</MenuItem>
                        <MenuItem value={"commandes"} >{"Bons de commande"}</MenuItem>
                </Select>
            </FormControl>
            </div>
            <div className='three_forms_header_container'>
            <div className='page_title'>Marchés</div>
              <div className='search_filter_container'>
                <FormControl variant="standard" className="header_form" >
                      <InputLabel id="demo-simple-select-helper-label">Famille d'achat</InputLabel>
                      <Select required value={filter_categorie} 
                            onChange={e => {set__categorie(e.target.value) }} >
                            
                            <MenuItem value={"all"}>Toutes les familles d'achat</MenuItem>
                            {
                            categories.map((categorie,id)=>(
                                <MenuItem value={categorie.nom} key={id} >{categorie.nom}</MenuItem>
                            ))
                            }
                            
                      </Select>
                  </FormControl>

                  <FormControl className="header_form"  >
                    <InputLabel id="demo-simple-select-helper-label">Filtre</InputLabel>
                      <Select value={search_filter}  onChange={(e)=>{set__filter(e.target.value)}}>
                        <MenuItem value="all">Aucun</MenuItem>
                        <MenuItem value='Code'>Code de marché</MenuItem>
                        <MenuItem value='Object'>Objet d'appel</MenuItem>
                      </Select>
                    </FormControl>

                    <Grid container spacing={1} alignItems="flex-start" className={search_filter==="all"?"search_bar disabled_button":"search_bar" } >
                      <Grid item>
                        <SearchIcon onClick={search} className={search_value==""?"my_icon disabled_button":"my_icon" } style={{cursor:"pointer"}}  />
                      </Grid>
                      <Grid>
                        <TextField label="Chercher" value={search_value} onChange={(e)=>{setsearch_value(e.target.value)}}/>
                      </Grid>
                    </Grid>
              </div>
            </div>
            <div className="bills_table_container">
            <TableContainer className="bills_table">
              <Table aria-label="sticky table">
                <TableHead>
                  <TableRow>
                      <TableCell>Code de marché</TableCell>
                      <TableCell>Fournisseur</TableCell>
                      <TableCell>Objet d'appel</TableCell>
                      <TableCell>Montant</TableCell>
                      <TableCell>Factures</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                    markets.length!==0
                    ?<Markets_list markets={markets} />
                    :<div style={{margin:"10px",fontSize:".8rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucune facture disponible</div>
                  }
                </TableBody>
              </Table>
            </TableContainer>
            </div>
            {
              markets.length!==0
                  ?<div className="pagination_container">
                      <Pagination
                          activePage={Number(currentPage)}
                          itemsCountPerPage={dataPerPage}
                          totalItemsCount={Number(data_count)}
                          pageRangeDisplayed={4}
                          onChange={change_page.bind(this)}
                      />
                  </div>
                  :<></>
              }
        
       </div>
      }
    </>
  );
}

export default Markets_bills;
