import axios from "axios"
import './request_interceptor'

const end_point1 = "https://com.portailachats.ma/api/serviceFinance/";
////const end_point1 = "http://localhost:8000/api/serviceFinance/";




////////////////////////////////////////////////////
//// bills
////////////////////////////////////////////////////

export const add_infos_toBill = async(data) => {
    return await axios.post(end_point1+'add_infos_toBill',data);
}

export const count_bill = async(data) => {
    return await axios.post(end_point1+'count_bill',data);
}

export const refuse_bill = async(data) => {
    return await axios.post(end_point1+'refuse_bill',data);
}

export const getAll_financeService_market = async(id_financeServ,filterCategorie,searchFilter,searchValue,page) => {
    return await axios.get(end_point1+'getAll_financeService_market/'+id_financeServ+'/'+filterCategorie+'/'+searchFilter+'/'+searchValue+"?page="+page);
}

export const getAll_financeService_orderBills = async(data,searchFilter,searchValue,page) => {
    return await axios.post(end_point1+'getAll_financeService_orderBills/'+searchFilter+'/'+searchValue+"?page="+page,data);
}

export const notify_businessService_toView_bills = async(data)  => {
    return await axios.post(end_point1+'notify_businessService_toView_bills',data);
}

export const paye_bill = async(data)  => {
    return await axios.post(end_point1+'paye_bill',data);
}

export const archive_bill = async(data)  => {
    return await axios.post(end_point1+'archive_bill',data);
}



export const add_infos_torequest_order_Bill = async(data) => {
    return await axios.post(end_point1+'add_infos_torequest_order_Bill',data);
}

export const count_request_order_bill = async(data) => {
    return await axios.post(end_point1+'count_request_order_bill',data);
}

export const refuse_request_order_bill = async(data) => {
    return await axios.post(end_point1+'refuse_request_order_bill',data);
}
 
export const notify_businessService_toView_request_order_bills = async(data)  => {
    return await axios.post(end_point1+'notify_businessService_toView_request_order_bills',data);
}

export const paye_request_order_bill = async(data)  => {
    return await axios.post(end_point1+'paye_request_order_bill',data);
}

export const archive_request_order_bill = async(data)  => {
    return await axios.post(end_point1+'archive_request_order_bill',data);
}


////////////////////////////////////////////////////
//// MainWidget
////////////////////////////////////////////////////

export const billsWidget_by_finance = async(id,time_range,bills_src,filter) => {
    return await axios.get(end_point1+'billsWidget_by_finance/'+id+"/"+time_range+"/"+bills_src+"/"+filter);
}

export const charts_ofBillsAmount_payed_unpayed_byMonth = async(id,time_range) => {
    return await axios.get(end_point1+'charts_ofBillsAmount_payed_unpayed_byMonth/'+id+"/"+time_range);
}

export const delay_between_bills_expired_payed_dates = async(id,time_range,bills_src) => {
    return await axios.get(end_point1+'delay_between_bills_expired_payed_dates/'+id+"/"+time_range+"/"+bills_src);
}