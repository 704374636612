import '../../../assets/css/offers_calls.css';
import React, { useState , useEffect } from 'react';
import { useHistory } from "react-router-dom";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {makeStyles} from '@material-ui/core/styles';
import Pagination from "react-js-pagination";

import { delete_proposal_serv, get_proposals, get_categories, getPurchaseService_infos } from '../../../services/purchase_service';

import Add_proposal from './add_proposal/add_proposal';
import Update_proposal from './add_proposal/update_propsal';
import Loading_animation_view from '../../loading_animation_view/loading_animation_view';
import { Fab, Tooltip } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  button: { margin: theme.spacing(1),},
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));


function setDateTime(date){
  let day = date.toISOString();
  let day_comp = day.split(":");
  return day_comp[0]+":"+day_comp[1];
}


function CompareFinalDate_current(finalDate){
  let day = new Date(finalDate);
  let current_date = new Date();
  if(day.getTime() <= current_date.getTime()) return false;
  else return true;
}


function Offers (){

  let user = JSON.parse(localStorage.getItem('user-info'));
  let history = useHistory();

  const classes = useStyles();
  const dateToday = setDateTime(new Date());
  const [url_updated , setUrl_updated] = useState(null);

  const [clickOffre, setClickOffre] = useState(null);
  
  const [offers , setOffers] = useState(null);
  const [offers_added , setOffers_added] = useState(null);

  const [chosenOffer,setchosenOffer]=useState("");
  const [update , setUpdate] = useState(false);
  const [categories,setCategories]=useState([]);

  const [filter_categorie,setFilter_categorie]=useState("all");
  const [search_value,setsearch_value]=useState("");
  const [search_filter,setsearch_filter]=useState('all');
  const [ProfilInfo,setProfilInfo]=useState({});

  const [currentPage,setCurrentPage]=useState(1);
  const [dataPerPage,setdataPerPage] =useState(6);
  const [data_count,setdata_count]=useState(0);


  const [open, setOpen] = useState(false);
  const [openAdd, setOpenAdd] = useState(false);

  const handleClose = () => { 
    setOpen(false);
    setchosenOffer("");
    setClickOffre(null);
   };
  

   const handleCloseAdd = () => {
    setOpenAdd(false); 
  };
  function HandleClickAdd(){
    setUpdate(false);
    setOpenAdd(true);
    //console.log(openAdd);
  };

  const HandleClickOpen = (appel,index) => {
    setchosenOffer(appel);
    setClickOffre(index);
    setOpen(true);
    setUpdate(true);
  };

  useEffect(()=>{
    setdata_count(0);
    setOffers(null);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var tmp_searchFilter ="all";
    var tmp_searchValue = null;
    var tmp_filterCategorie = "all";
    async function set_filter(){
      if(urlParams.get('filtre') && urlParams.get('valeur')){
        tmp_searchFilter = urlParams.get('filtre');
        tmp_searchValue = urlParams.get('valeur');
      }if(urlParams.get('categorie')){
        tmp_filterCategorie = urlParams.get('categorie');
      }
      await setsearch_filter(tmp_searchFilter);
        await setsearch_value(tmp_searchValue);
        await setFilter_categorie(tmp_filterCategorie);
    }
    
    async function load_data(){
        await get_categories().then((response)=> {
          setCategories(response.data);
        }).catch((error)=>{
          //console.log(error.response);
        });
        let page = (urlParams.get('page')?urlParams.get('page'):1);
        await setCurrentPage(page)

        await get_proposals(user.id,tmp_filterCategorie,tmp_searchFilter,tmp_searchValue,page).then((response)=> {
          setdata_count(response.data.total);
          setOffers(response.data.data);
        }).catch((error) => {
          //console.log(error.response);
        });

        await getPurchaseService_infos(user.id).then((response)=> {
          setProfilInfo(response.data)
        }).catch((error) => {
          //console.log(error.response);
        });
    }

    set_filter();
    load_data();

  },[url_updated,offers_added]);

 
  function getCategorieById(id){
    var categorie="";
    categories.map((catg)=>{
      if(catg.id_categorie == id) categorie = catg.nom;
    })
    return categorie;
  }

  async function delete_proposal($id){
    const formData = new FormData();
    formData.append('id_appel',offers[$id].id_appel);
    formData.append('id_service',user.id);
    delete_proposal_serv(formData).then((response) => {
      setOffers(response.data);
    }).catch((error) => {
      //console.log(error.response);
    });
  }



  function search(){
    setsearch_value(search_value);
    let path = window.location.href.split('?')[0]
    if(filter_categorie!=="all") history.push("/serviceAchat/mesAppelsOffres?categorie="+filter_categorie+"&filtre="+search_filter+"&valeur="+search_value);
    else history.push("/serviceAchat/mesAppelsOffres?filtre="+search_filter+"&valeur="+search_value);
    setOffers_added(search_value);
  }


  function set__categorie(value){
    setFilter_categorie(value);
    let path = window.location.href.split('?')[0]
    if(value!=="all") history.push("/serviceAchat/mesAppelsOffres?categorie="+value);
    else history.push("/serviceAchat/mesAppelsOffres?categorie=all");
    setOffers_added(value);
  }

  function set__filter(value){
    if(value==="all"){
      setsearch_filter(value);
      let path = window.location.href.split('?')[0]
      if(filter_categorie!=="all") history.push("/serviceAchat/mesAppelsOffres?categorie="+filter_categorie+"&filtre="+value);
      else history.push("/serviceAchat/mesAppelsOffres?filtre="+value);
      setOffers_added(value);
    }else setsearch_filter(value);
  }

    async function change_page(page){
      await setCurrentPage(page);
      let path = history.location.pathname;
      let params = history.location.search;
      let search_params = params.split('&');
      let tmp_params = path;
      if(!params) history.push(path+"?page="+page);
      else{
        for(let i in search_params){
          if(search_params[i].includes("page=")){
            if(i==0) tmp_params=tmp_params+"?page="+page;
            else tmp_params=tmp_params+"&page="+page;
          }else{
            if(i==0) tmp_params=tmp_params+search_params[i];
            else tmp_params=tmp_params+"&"+search_params[i];
          }
        }
        if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
        history.push(tmp_params);
      }
      await setUrl_updated(page);
    }
    
return (
  <>
    {
      offers===null
      ?<Loading_animation_view />
      :  <div className="my_offers_container">
          <div className="my_offers_container_header">
              <div className='addButton_container'>
                <Tooltip title="Ajouter">
                  <Fab className="add_new_object_fab add_new_object_fab_big" onClick={HandleClickAdd} >
                    <AddIcon className="add_new_object_icon" />
                  </Fab>
                </Tooltip>
              </div>
              <div className='search_filter_container'>
                <FormControl className="header_form" >
                    <InputLabel id="demo-simple-select-helper-label">Famille d'achat</InputLabel>
                    <Select required value={filter_categorie} 
                        onChange={e => {set__categorie(e.target.value) }} >
                        
                        <MenuItem value={"all"}>Toutes les familles d'achat</MenuItem>
                        {
                        categories.map((categorie,id)=>(
                            <MenuItem value={categorie.nom} key={id} >{categorie.nom}</MenuItem>
                        ))
                        }
                        
                    </Select>
                </FormControl>

                <FormControl className="header_form">
                  <InputLabel id="demo-simple-select-helper-label">Filtre</InputLabel>
                  <Select value={search_filter}  onChange={(e)=>{set__filter(e.target.value)}}>
                    <MenuItem value="all">Aucun</MenuItem>
                    <MenuItem value='Object'>Objet d'appel</MenuItem>
                    <MenuItem value='Direction'>Direction</MenuItem>
                  </Select>
                </FormControl>

                  <Grid container spacing={1} alignItems="flex-start" className={search_filter==="all"?"search_bar disabled_button":"search_bar" } >
                    <Grid item>
                      <SearchIcon onClick={search} className={search_value==""?"my_icon disabled_button":"my_icon" } style={{cursor:"pointer"}}  />
                    </Grid>
                    <Grid>
                      <TextField label="Chercher" value={search_value} onChange={(e)=>{setsearch_value(e.target.value)}}/>
                    </Grid>
                  </Grid>
                </div>
            </div>
            <div className="offers_contents_pagination_container">
                {
                  offers.length !== 0
                  ?<><div className="offers_contents_container">
                    {
                      offers.map((val,index,i=0) => {
                        return (
                            <div className="main_card_container" key={val.id}>
                                <div className="main_card_infos_container">
                                        <div className="main_card_offer_object">{val.object}</div>
                                        <div className="main_card_infos_content">
                                          <div className="main_card_infos_elements">
                                              <div> <span>Famille d'achat : </span>{getCategorieById(val.familleAchat)}</div>
                                              <div><span>Estimation : </span>{val.estimation} DH</div>
                                          </div>
                                          <div className="main_card_infos_elements">
                                              <div><span>Direction :</span> {val.direction} </div>
                                              <div><span>Date Offre : </span>{(new Date(val.dateOffre)).toLocaleString()}</div>
                                          </div>
                                          <div className="main_card_infos_element"><span>Date Limite :</span> {(new Date(val.dateLimite)).toLocaleString()}</div>
                                          <div className="main_card_infos_element"> <span>Date d'ouverture des plis : </span>{(new Date(val.dateOuvertureOffre)).toLocaleString()}</div>
                                        </div>
                                </div>
                  
                                <div className="card_buttons_container">
                                        <Tooltip title="Archiver">
                                          <Fab className={classes.button+" "+(CompareFinalDate_current(val.dateLimite) === false  ? "cancel_object_fab disabled_button" : "cancel_object_fab")} onClick={()=>delete_proposal(index)} >
                                            <DeleteIcon className="my_icon" />
                                          </Fab>
                                        </Tooltip>
                                        <Tooltip title="Modifier">
                                          <Fab className={classes.button+" "+(CompareFinalDate_current(val.dateLimite) === false  ? "success_object_fab disabled_button" : "success_object_fab")} onClick={()=>HandleClickOpen(val,index)} >
                                            <EditIcon className="my_icon" />
                                          </Fab>
                                        </Tooltip>
                                </div>
                            </div>
                  
                           );
                        }
                      )
                    }
                  </div>
                  <div className="pagination_container">
                          <Pagination
                              activePage={Number(currentPage)}
                              itemsCountPerPage={dataPerPage}
                              totalItemsCount={Number(data_count)}
                              pageRangeDisplayed={4}
                              onChange={change_page.bind(this)}
                          />
                      </div>
                  </>:<>Aucun appel d'offre disponible</>
                }
          </div>

      </div>
    }
  

    {
      openAdd!==false
      ?<Add_proposal 
            open={openAdd} onClose={handleCloseAdd} 
            dateToday={dateToday} handleCloseAdd={handleCloseAdd} openAdd={openAdd} offers={offers}
            setOffers={setOffers} ProfilInfo={ProfilInfo} update={update} setOffers_added={setOffers_added} />
      :<></>
    }
      
      {
        clickOffre!=null
        ?<Update_proposal handleClose={handleClose} onClose={handleClose} open={open} chosenOffer={chosenOffer} offers={offers}
            clickOffre={clickOffre} setOffers={setOffers} ProfilInfo={ProfilInfo} update={update} />
        :<></>
      }
        
    </>
  )
}
export default Offers
