import './view_refuse_reason.css'
import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

export default function View_refuse_reason({open_reason_modal,setOpen_reason_modal}){

    return (
      <Dialog className="set_refuse_raison_container" fullWidth={true} maxWidth={'md'} open={open_reason_modal!=null?true:false} onClose={()=>setOpen_reason_modal(null)} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">Motif</DialogTitle>
        <DialogContent>
          <div>
              <div style={{width:"100%",marginTop:"2px",marginBottom:"15px"}} >
              <TextField
                  id="outlined-multiline-flexible"
                  label="Motif"
                  variant='outlined'
                  multiline
                  rows={4}
                  value={open_reason_modal}
                  style={{width:"100%"}}
                />
              </div>
          </div>
        </DialogContent>
      </Dialog>
    )
}