import "./campaigns.css";
import react,{useState, useEffect} from "react";
import { useHistory } from "react-router-dom";

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import CheckIcon from '@material-ui/icons/Check';
import CloseSharpIcon from '@mui/icons-material/CloseSharp';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "react-js-pagination";
import EditIcon from '@material-ui/icons/Edit';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import VisibilityIcon from '@mui/icons-material/Visibility';
import Fab from '@mui/material/Fab';

import { Form } from "react-bootstrap";
import Loading_animation_view from "../../loading_animation_view/loading_animation_view";
import Add_campaign from "./add_campaign/add_campaign";
import { get_forms_models, get_forms_types, get_user_specific_campaigns } from "../../../services/for_allServices";
import Campaign_details from "./campaign_details/campaign_details";

const columns_form_campaigns = [
    { id: 'code', label: "Code", minWidth: 0 },
    { id: 'title', label: "Titre", minWidth: 0 },
    { id: 'form_type', label: "Type de formulaire", minWidth: 0 },
    { id: 'form_model', label: "Modèle du formulaire", minWidth: 0 },
    { id: 'created_at', label: "Date de création", minWidth: 0 },
    { id: 'updated_at', label: "Date de modification", minWidth: 0 },
    { id: 'details', label: "Détails", minWidth: 0 },
  ];


const Campaigns = () => {

    let user = JSON.parse(localStorage.getItem('user-info'));
    const [url_updated , setUrl_updated] = useState(null);
    let history = useHistory();
    const [campaigns,setcampaigns] = useState(null);
    const [campaign_type,setcampaign_type] = useState("default");
    const [forms_types,setforms_types] = useState([]);
    const [forms_models,setforms_models] = useState([]);

    const [waiting, setWaiting] = useState(false);
    const [currentPage,setCurrentPage]=useState(1);
    const [dataPerPage,setdataPerPage] =useState(6);
    const [data_count,setdata_count]=useState(0);

    const [view_campaign_details,setview_campaign_details] = useState(null);
    
    const [add_campaign_model,setadd_campaign_model] = useState(false);
    const [update_campaign_model,setupdate_campaign_model] = useState(false);

    useEffect(()=>{

        setcampaigns(null);
        setview_campaign_details(null);
        console.log("test");

        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        let tmp_campaign_code_toView = null; 

        async function load_data(){
            tmp_campaign_code_toView = urlParams.get('code')?urlParams.get('code'):null;

            get_forms_types().then((response)=>{
                setforms_types(response.data);
            }).catch((error)=>{
                //console.log(error.response);
            })

            get_forms_models().then((response)=>{
                setforms_models(response.data);
            }).catch((error)=>{
                //console.log(error.response);
            });

            const tmp_campaigns = await get_user_specific_campaigns(user.id).then((response)=>{
                return response.data;
            }).catch((error)=>{
                //console.log(error.response);
            });

            if(tmp_campaign_code_toView){
                const tmp_selected_campaign_toView = tmp_campaigns.filter((elem)=>{
                    return elem.code == tmp_campaign_code_toView;
                });
                if(tmp_selected_campaign_toView.length!=0) setview_campaign_details(tmp_selected_campaign_toView[0]);
                else{
                    urlParams.delete("code");
                    history.replace({
                        search: urlParams.toString(),
                      })
                }
            }
            setcampaigns(tmp_campaigns);
        }
        load_data();

    },[history.location,url_updated]);

    history.listen((location, action) => {
        setUrl_updated(location.search);
    },[]);

    function select_campaign_type(value){
        console.log(value);
    }

    function open_new_campaign_model(){
        setadd_campaign_model(true);
    }

    const get_form_type_title_byID = (id)=>{
        let tmp_title = "";
        forms_types.forEach((forms_type) => {
            if(forms_type.id == id) tmp_title = forms_type.title;
        })
        return tmp_title;
    }

    const get_form_model_title_byID = (id)=>{
        let tmp_title = "";
        forms_models.forEach((forms_model) => {
            if(forms_model.id == id) tmp_title = forms_model.title;
        })
        return tmp_title;
    }

    const view_campaign_by_code = (camp) => {
        let path = history.location.pathname;
        history.push(path+"?code="+camp.code);
        setUrl_updated("?code="+camp.code);
        setview_campaign_details(camp);
    }

    async function change_page(page){
        await setCurrentPage(page);
        let path = history.location.pathname;
        let params = history.location.search;
        let search_params = params.split('&');
        let tmp_params = path;
        if(!params) history.push(path+"?page="+page);
        else{
            for(let i in search_params){
                if(search_params[i].includes("page=")){
                    if(i==0) tmp_params=tmp_params+"?page="+page;
                    else tmp_params=tmp_params+"&page="+page;
                }else{
                    if(i==0) tmp_params=tmp_params+search_params[i];
                    else tmp_params=tmp_params+"&"+search_params[i];
                }
            }
            if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
            history.push(tmp_params);
        }
        await setUrl_updated(page);
    }


    return (
        <>
        {
            campaigns==null
            ?<Loading_animation_view />
            :<>{
                view_campaign_details!=null
                ?<Campaign_details view_campaign_details={view_campaign_details} 
                        setview_campaign_details={setview_campaign_details} forms_models={forms_models} />
                :<>
                <div className='campaigns_list_container'>
                <div className='campaigns_list_container_header'>
                        <div className='title_container'>
                            <label>Campagnes</label>
                            <Tooltip title="Ajouter">
                                <Fab className={waiting ? "add_new_object_fab disabled_button":"add_new_object_fab"} 
                                    onClick={open_new_campaign_model} >
                                    <AddIcon className="add_new_object_icon" />
                                </Fab>
                            </Tooltip>
                        </div>
                        <div className='select_container'>

                            <FormControl style={{width:"225px",marginRight:'20px'}} >
                                <InputLabel>Type de campaign</InputLabel>
                                    <Select required
                                        value={campaign_type}
                                        onChange={e => {
                                            select_campaign_type(e.target.value);
                                        }} name="Etat" >
                                        <MenuItem value={"default"}>
                                            Tous les campagnes
                                        </MenuItem>
                                            <MenuItem value="active">Actifs</MenuItem>
                                            <MenuItem value="inactive">Inactifs</MenuItem>
                                    </Select>
                            </FormControl>
                        </div>
                </div>

                    <div>
                        <TableContainer className="campaigns_list_table">
                            <Table aria-label="sticky table">
                                <TableHead>
                                <TableRow>
                                    {
                                        columns_form_campaigns.map((column) => (
                                            <TableCell key={column.id} align="left">{column.label}</TableCell>
                                        ))
                                    }
                                </TableRow>
                                </TableHead>
                                <TableBody>
                                {
                                    campaigns.length!==0
                                    ?
                                    <>
                                    {
                                        campaigns.map((campaign,id) => (
                                            <TableRow key={"campaign"+id}>
                                                <TableCell align="left">
                                                    <div style={{width:'fit-content'}}>
                                                        {campaign.code}
                                                        <div style={{width:'100%',display:'flex'}}>
                                                            <Tooltip title="Modifier" placement="top" >
                                                                <span className={"editIcon"} >
                                                                        <EditIcon className="my_icon" style={{cursor:'pointer'}} />
                                                                </span>
                                                            </Tooltip>
                                                        </div>
                                                    </div>
                                                </TableCell>
                                                <TableCell align="left">{(campaign.title)}</TableCell>
                                                <TableCell align="left">{get_form_type_title_byID(campaign.form_type)}</TableCell>
                                                <TableCell align="left">{get_form_model_title_byID(campaign.form_model)}</TableCell>
                                                <TableCell align="left">{(new Date(campaign.created_at)).toLocaleString()}</TableCell>
                                                <TableCell align="left">{(new Date(campaign.updated_at)).toLocaleString()}</TableCell>
                                                <TableCell align="left">
                                                    <VisibilityIcon className="editIcon" onClick={()=>view_campaign_by_code(campaign)} />
                                                </TableCell>
                                            </TableRow>                
                                        ))
                                    }
                                    </>
                                    :<div style={{margin:"10px",fontSize:".8rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucune Campagne disponible</div>
                                }
                                </TableBody> 
                            </Table>
                        </TableContainer>
                        {
                            campaigns.length!==0
                            ?<div className="pagination_container">
                                <Pagination
                                    activePage={Number(currentPage)}
                                    itemsCountPerPage={dataPerPage}
                                    totalItemsCount={Number(data_count)}
                                    pageRangeDisplayed={4}
                                    onChange={change_page.bind(this)}
                                />
                            </div>
                            :<></>
                        }
                    </div>
                </div>
            {
                add_campaign_model!=false
                ?<Add_campaign campaigns={campaigns} setcampaigns={setcampaigns}
                    add_campaign_model={add_campaign_model} setadd_campaign_model={setadd_campaign_model}
                    update_campaign_model={update_campaign_model} setupdate_campaign_model={setupdate_campaign_model}
                    forms_types={forms_types} forms_models={forms_models} />
                :<></>
            }
            </>
            }
            </>
            }
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );

};

export default Campaigns;