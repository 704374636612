import './navVerical.css'
import React,{useState,useEffect} from 'react';
import { Link,useHistory } from 'react-router-dom';

import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import DnsIcon from '@material-ui/icons/Dns';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import Tooltip from '@mui/material/Tooltip';
import { PersonAdd } from '@material-ui/icons';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import ListAltIcon from '@mui/icons-material/ListAlt';
import {makeStyles} from '@material-ui/core/styles';
import EditIcon from '@mui/icons-material/Edit';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import SendIcon from '@mui/icons-material/Send';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import FeedIcon from '@mui/icons-material/Feed';

import nav_logo from '../../img/logoKertys.png';
import NavBar from '../navBar/navBar';
import { update_company_admin_profil } from '../../../services/company_admin_service';
import { get_company_logo, logout_request } from '../../../services/for_allServices';

const useStyles = makeStyles((theme) => ({
  button: { margin: theme.spacing(1),},
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));


function NavVertical({Component}) {
  let history = useHistory();
  let user = JSON.parse(localStorage.getItem('user-info'))
  const classes = useStyles();

  const [company_nav_logo,setcompany_nav_logo]=useState(null);
  const [company_logo,setcompany_logo]=useState(null);

  const [ nav_color,setNav_color ] = useState('');
  const [ menu_color,setMenu_color ] = useState('');
  const [waiting, setWaiting] = useState(false);

  useEffect(()=>{
    async function load_data(){
      await setNav_color(user.company_color.split(',')[0]);
      await setMenu_color(user.company_color.split(',')[1]);
      await get_company_logo().then((response)=>{
        setcompany_nav_logo(response.data.company_nav_logo);
        setcompany_logo(response.data.company_logo);
      }).catch((error)=>{
        //console.log(error.response);
      })
    }
    load_data();
  },[]);

    async function logout(){
      await logout_request(user.id).then((response)=>{
        localStorage.clear();
        window.dispatchEvent(new Event("storage"));
      }).catch((error)=>{
        //console.log(error.response);
      })
      history.push("/");
  }

    function updatecompany_nav_logo(file){
      if(file){
        setWaiting(true);
        const formData = new FormData();
        formData.append('id_login',user.id);
        formData.append('company_nav_logo',file);
        update_company_admin_profil(formData).then((response)=>{
          setWaiting(false);
          setcompany_nav_logo(response.data);
        }).catch((error) => {
          setWaiting(false);
          //console.log(error.response);
        })
      } 
    }


    const load_component = React.useMemo(() => {
      return (
        <>
        {
                  Component 
                  ? <>
                      <Component menu_color={menu_color} nav_color={nav_color} setNav_color={setNav_color} setMenu_color={setMenu_color} /> 
                  </>
                  :<></>
                }
          </>
      )
    },[Component,menu_color,nav_color])

  return (
  <>
    <NavBar menu_color={menu_color} company_logo={company_logo} setcompany_logo={setcompany_logo} />
    <div className="menu_company_admin">
      <div className="service_content show" style={{background:nav_color}}>
          
          <div className="company_nav_logo_container">
              {
                  company_nav_logo!==null
                  ?<><img src={company_nav_logo!==""?`data:image/*;base64,${company_nav_logo}`:nav_logo } />
                    <Tooltip title="modifier le logo" placement="bottom" >
                    <span className="edit_container">
                        <input accept=".jpg,.png,.jpeg" className={classes.input} name="company_nav_logo" id="company_nav_logo" multiple type="file" onChange={(e)=>updatecompany_nav_logo(e.target.files[0])} />
                        <label htmlFor="company_nav_logo">
                          <span style={{cursor:'pointer'}}>
                            <EditIcon className="edit_but" />
                          </span>
                        </label>
                    </span>
                  </Tooltip>
                  </>
                :<></>
              }
          </div>
           
          <div className='menu_items'>
            <div>
              <Tooltip title="Fiche d'identité" placement="right" arrow>
                <Link className={window.location.pathname==="/eadmin/fiche_identité"|| window.location.pathname==="/eadmin"?"menu_item menu_item_selected":"menu_item"} to="/eadmin/fiche_identité">
                  <div><AccountCircleIcon /></div>
                </Link>
              </Tooltip>
              <Tooltip title="Ajouter service" placement="right" arrow>
                <Link className={window.location.pathname==="/eadmin/ajouter_service"?"menu_item menu_item_selected":"menu_item"} to="/eadmin/ajouter_service">
                  <div><PersonAdd/></div>
                </Link>
              </Tooltip>
              {/* <Tooltip title="Ajouter commissaire" placement="right" arrow>
                <Link className={window.location.pathname==="/eadmin/ajouter_commissaire"?"menu_item menu_item_selected":"menu_item"} to="/eadmin/ajouter_commissaire">
                  <div><PersonAddAltIcon/></div>
                </Link>
              </Tooltip> */}
              <Tooltip title="Services" placement="right" arrow>
                <Link className={window.location.pathname==="/eadmin/services"?"menu_item menu_item_selected":"menu_item"} to="/eadmin/services">
                  <div><DnsIcon /></div>
                </Link>
              </Tooltip>
              {/* <Tooltip title="Commissaires" placement="right" arrow>
                <Link className={window.location.pathname==="/eadmin/commissaires"?"menu_item menu_item_selected":"menu_item"} to="/eadmin/commissaires">
                  <div><ListAltIcon /></div>
                </Link>
              </Tooltip> */}
              <Tooltip title="Familles d'achat" placement="right" arrow>
                <Link className={window.location.pathname==="/eadmin/famille_achat"?"menu_item menu_item_selected":"menu_item"} to="/eadmin/famille_achat">
                  <div><FormatListBulletedIcon /></div>
                </Link>
              </Tooltip>
              <Tooltip title="Contrats" placement="right" arrow>
                <Link className={window.location.pathname==="/eadmin/contrats"?"menu_item menu_item_selected":"menu_item"} to="/eadmin/contrats">
                  <div><ContentPasteIcon /></div>
                </Link>
              </Tooltip>
              {/* <Tooltip title="Formulaires" placement="right" arrow>
                <Link className={window.location.pathname==="/eadmin/formulaires"?"menu_item menu_item_selected":"menu_item"} to="/eadmin/formulaires">
                  <div><FeedIcon /></div>
                </Link>
              </Tooltip> */}
              <Tooltip title="Envoyer un email" placement="right" arrow>
                  <Link className={window.location.pathname==="/eadmin/envoyer_email"?"menu_item menu_item_selected":"menu_item"} to="/eadmin/envoyer_email" >
                    <div><SendIcon /></div>
                  </Link>
                </Tooltip>
            </div>
            <div className="menu-connection">
              <Tooltip title="Logout" placement="right" arrow>
                    <div className="menu_item" onClick={logout}>
                        <div><ExitToAppIcon/></div>
                      </div>
                </Tooltip>
            </div>
            </div>
        </div>
        <div className="service_body">
          {load_component}
      </div>
    </div>
    <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={waiting}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
    </>  
    
  );
}

export default NavVertical;
