import React, { useState,Fragment,useEffect } from 'react';
import './company_admin_profil.css'
import {makeStyles} from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import swal from 'sweetalert';
import Form from 'react-bootstrap/Form'
import Button from '@material-ui/core/Button';
import SaveIcon from '@mui/icons-material/Save';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import empty from '../../empty.jpg'
import Loading_animation_view from '../../loading_animation_view/loading_animation_view'
import { company_admin_profil, update_company_admin_profil } from '../../../services/company_admin_service';
import Color_palette_modal from './color_palette_modal/color_palette_modal';

const useStyles = makeStyles((theme) => ({
  button: { margin: theme.spacing(1),},
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));


function Company_admin_profil({nav_color,setNav_color,menu_color,setMenu_color}) {

  const classes = useStyles();

  const [serviceInfo,setserviceInfo]=useState(null);

  const [fullName,setfullName]= useState("");
  
  const [country,setcountry]= useState("");
  const [city,setcity]= useState("");
  const [address,setaddress]= useState("");
  const [postcode	,setpostcode]= useState("");

  const [company_logo,setcompany_logo]= useState(null);
  const [company_name,setcompany_name]= useState("");
  const [phoneNumber,setphoneNumber]= useState("");
  const [businessRegister	,setbusinessRegister]= useState("");
  const [Fiscal,setFiscal]= useState("");
  const [licenseNumber,setlicenseNumber]= useState("");
  const [iceNumbner,setIceNumbner]= useState("");

  const [disabled , setDisabled] =useState(true);

  const [error,setError]=useState(null);
  const [editing,setediting]=useState(false);
  const [waiting, setWaiting] = useState(false);
  const [color_palette_modal, setcolor_palette_modal] = useState(false);

  let user = JSON.parse(localStorage.getItem('user-info'));

  useEffect(()=>{
    company_admin_profil(user.id)
        .then((response)=> {
          //console.log(response.data);
          setfullName(response.data.fullName);
          setcountry(response.data.country);
          setcity(response.data.city);
          setaddress(response.data.address);
          setpostcode(response.data.postcode);
          setphoneNumber(response.data.phoneNumber);
          setcompany_name(response.data.company_name);
          setbusinessRegister(response.data.businessRegister);
          setFiscal(response.data.fiscalIdentifier);
          setlicenseNumber(response.data.licenseNumber);
          setIceNumbner(response.data.iceNumbner);
          setcompany_logo(response.data.logo);
          setserviceInfo(response.data);
      }).catch((error)=>{
        //console.log(error.response);
      });
  },[]);

  function update_alertMsg(){
    setError(null);
    if( country==="" || city==="" || address==="" || postcode==="" || phoneNumber==="" 
        || businessRegister==="" || Fiscal==="" || licenseNumber==="" || iceNumbner==="" ){
        setError("Champs invalids !")
    }else{
      swal({
          title: "Etes-vous sûr ?",
          text: "Une fois sauvegardées, vous ne pourrez plus récupérer les données précédentes !",
          icon: "warning",
          buttons: true,
          dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          update_infos(serviceInfo.id);
          setDisabled(!disabled);
        }
      });
    }
  }

  

  function updatecompany_logo(file){
    if(file){
      setWaiting(true);
      const formData = new FormData();
      formData.append('id_login',user.id);
      formData.append('company_logo',file);
      update_company_admin_profil(formData).then((response)=>{
        setWaiting(false);
        setcompany_logo(response.data);
        window.location.reload();
      }).catch((error) => {
        setWaiting(false);
        //console.log(error.response);
      })
    } 
  }

   async function update_infos() {
      setWaiting(true);
      const formData = new FormData();
      formData.append('id_login',user.id);
      formData.append('fullName',fullName);
      formData.append('country',country);
      formData.append('city',city);
      formData.append('address',address);
      formData.append('postcode',postcode);
      formData.append('phoneNumber',phoneNumber);
      formData.append('businessRegister',businessRegister);
      formData.append('fiscalIdentifier',Fiscal);
      formData.append('licenseNumber',licenseNumber);
      formData.append('iceNumbner',iceNumbner);
      update_company_admin_profil(formData).then((response)=>{
        setWaiting(false);
        let admin = localStorage.getItem('user-info');
        let admin_tmp = JSON.parse(admin);
        admin_tmp['fullName'] = fullName;
        localStorage.setItem('user-info',JSON.stringify(admin_tmp));
        swal("Votre fiche d'identité a été modifiée avec succès !", {
          icon: "success",
          buttons: true,
          }).then((willDelete) => {
              if (willDelete) {
                  window.location.reload()
              }
          })
        setediting(false);
      }).catch((error)=>{
        //console.log(error.response);
        setWaiting(false);
      })
  }


  return (
    <div className="cp_admin_identity_container">
      {
        serviceInfo===null
        ?<Loading_animation_view />
        :<div className="service_identity_infos_container">
          <div className="service_identity_infos">
            <div className="identity_element">
                <span>Nom complet</span>
                <span>
                    <Form.Control
                          required
                          value={fullName} 
                          autoComplete="new-password"
                          type="text"
                          placeholder="Nom complet *"
                          disabled = { disabled }
                          onChange={(e)=>setfullName(e.target.value)} 
                      />   
                </span>
            </div>
            <div className="identity_element">
              <span>Téléphone</span>
              <span>
                  <Form.Control
                        required
                        value={phoneNumber}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Téléphone *"
                        disabled = { disabled }
                        onChange={(e)=>setphoneNumber(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="identity_element">
              <span>Pays</span>
              <span>
                  <Form.Control
                        required
                        value={country} 
                        autoComplete="new-password"
                        type="text"
                        placeholder="Pays *"
                        disabled = { disabled }
                        onChange={(e)=>setcountry(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="identity_element">
              <span>Ville</span>
              <span>
                  <Form.Control
                        required
                        value={city}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Ville *"
                        disabled = { disabled }
                        onChange={(e)=>setcity(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="identity_element">
              <span>Adresse</span>
              <span>
                  <Form.Control
                        required
                        value={address}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Adresse *"
                        disabled = { disabled }
                        onChange={(e)=>setaddress(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="identity_element">
              <span>Code Postal</span>
              <span>
                  <Form.Control
                        required
                        value={postcode}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Code Postal *"
                        disabled = { disabled }
                        onChange={(e)=>setpostcode(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="identity_element">
              <span>Date de constitution</span>
              <span>
                  <Form.Control
                        required
                        value={(new Date(serviceInfo.created_at)).toLocaleString().split(' ')[0]}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Date de constitution *"
                        disabled = { true }
                    />   
              </span>
            </div>
          </div>
          
          <div className='company_infos_header'>Fiche d'entreprise : </div>
          <div className="service_identity_infos">
            <div className="comapny_logo">
                <div className='img_container' >
                  <img src={company_logo!==""?`data:image/*;base64,${company_logo}`:empty} alt=''  />
                </div>
                <div>
                  <div>
                    <input accept=".jpg,.png,.jpeg" className={classes.input} name="company_logo" id="company_logo" multiple type="file" onChange={(e)=>updatecompany_logo(e.target.files[0])} />
                    <label htmlFor="company_logo">
                      <span className="submit_but edit_but"  >
                        <EditIcon /> Logo d'entreprise
                      </span>
                    </label>
                  </div>
                  <div className='change_color_container' onClick={()=>setcolor_palette_modal(true)}>
                  <span className="color_container" style={{background:nav_color}}></span>
                  <span className="color_container" style={{background:menu_color}}></span>
                      <span>Couleur de menu</span>
                  </div>
                </div>
            </div>
            <div className="identity_element">
              <span>Entreprise</span>
              <span>
                  <Form.Control
                        required
                        value={company_name}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Entreprise *"
                        disabled = { true }
                   />   
              </span>
            </div>
            <div className="identity_element">
              <span>Registre du commerce <br/> (Ou équivalent)</span>
              <span>
                  <Form.Control
                        required
                        value={businessRegister}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Registre du commerce (Ou équivalent) *"
                        disabled = { disabled }
                        onChange={(e)=>setbusinessRegister(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="identity_element">
              <span>Identifiant fiscal <br/>  (Ou équivalent)</span>
              <span>
                  <Form.Control
                        required
                        value={Fiscal}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Identifiant fiscal (Ou équivalent) *"
                        disabled = { disabled }
                        onChange={(e)=>setFiscal(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="identity_element">
              <span>N° Patente  <br/>  (Ou équivalent)</span>
              <span>
                  <Form.Control
                        required
                        value={licenseNumber}
                        autoComplete="new-password"
                        type="text"
                        placeholder="N° Patente (Ou équivalent) *"
                        disabled = { disabled }
                        onChange={(e)=>setlicenseNumber(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="identity_element">
              <span>N° ICE  <br/> (Ou équivalent)</span>
              <span>
                  <Form.Control
                        required
                        value={iceNumbner}
                        autoComplete="new-password"
                        type="text"
                        placeholder="N° ICE (Ou équivalent) *"
                        disabled = { disabled }
                        onChange={(e)=>setIceNumbner(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="identity_element"></div>

          </div>
          <div className="service_identity_infos_config" >
              <div>
                  {
                      error==null
                      ?<></>
                      :<p className={error ? "col-md-10 error" : "col-md-10 valid-champ"}>
                              {error ? error : ""}
                          </p>
                  }
               </div>
              <div>
                <Button variant="contained" className="submit_but edit_but" startIcon={<EditIcon />} onClick={() => {setDisabled(!disabled);setediting(true)}} >
                    Editer
                </Button>
                <Button variant="contained" className={editing===true?"submit_but valide_but":"submit_but valide_but disabled_button"} startIcon={<SaveIcon />} onClick={update_alertMsg} >
                    Sauvegarder
                </Button> 
              </div>
             
          </div>
      </div>
      }
      {
        color_palette_modal!==false
        ?<Color_palette_modal  color_palette_modal={color_palette_modal} setcolor_palette_modal={setcolor_palette_modal}
                 nav_color={nav_color} setNav_color={setNav_color} menu_color={menu_color} setMenu_color={setMenu_color} />
        :<></>
      }
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={waiting}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Company_admin_profil;
