import React, { useState , useEffect } from 'react'
import { lighten, makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import Paper from '@material-ui/core/Paper';
import Tooltip from '@material-ui/core/Tooltip';
import FilterListIcon from '@material-ui/icons/FilterList';
import Button from '@material-ui/core/Button';
import Fab from '@mui/material/Fab';
import DownloadIcon from '@mui/icons-material/Download';
import Rating from '@mui/material/Rating';
import Box from '@mui/material/Box';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { downloadDocAdminServ, downloadDocTechServ, downloadDocFinanceServ, get_offer_submissions } from '../../../../services/purchase_service';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
  }));

  
  
  function etat(stat){
    return(
      <>
        {
         stat == 1
         ?<Button variant="contained" className="status_success">{'Accepté'}</Button>
         :stat == 0
            ?<Button variant="contained" className="status_deny">{'Refusé'}</Button>
            :<Button variant="contained" className="status_waiting">{'En cours'}</Button>
        }
      </>
    );
  }
  
  
  function etatDoc(doc,etat){
    var v_etat;
    if(etat===0)v_etat='Refusé'
    else if(etat===1)v_etat='Accepté'
    else v_etat='En Cours'
    return v_etat;
  }
  
  
  function setStatus(stat){
    return(
      <>
        {
         stat == 'En Cours'
         ?<Button variant="contained" className="status_waiting">{'En cours'}</Button>
         :stat == 'Refusé'
            ?<Button variant="contained" className="status_deny">{'Refusé'}</Button>
            :<Button variant="contained" className="status_success">{'Accepté'}</Button>
        }
      </>
  
    );
  }
  
  
  function createData(nom,score_financier,score_technique,dateDemande,docs,details,complement,accord) {
    return { nom,score_financier,score_technique,dateDemande,docs,details,complement,accord};
  }
  const rows = [
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Donut', 452, 25.0, 51, 4.9),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
  ];
  
  
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  
  const headCells = [
    { id: 'nom', numeric: false, disablePadding: false, label: 'Nom Complet' },
    { id: 'score_financier', numeric: true, disablePadding: false, label: 'Score financier' },
    { id: 'score_technique', numeric: true, disablePadding: false, label: 'Score technique' },
    { id: 'dateDemande', numeric: true, disablePadding: false, label: 'Date de soumission' },
    { id: 'docs', numeric: true, disablePadding: false, label: 'Documents' },
    { id: 'accord', numeric: true, disablePadding: false, label: 'Accord' },
  ];
  
  function EnhancedTableHead(props) {
    const { classes, order, orderBy, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell key={headCell.id} align='left' padding={headCell.disablePadding ? 'none' : 'normal'} sortDirection={orderBy === headCell.id ? order : false} >
              <TableSortLabel active={orderBy === headCell.id} direction={orderBy === headCell.id ? order : 'asc'} onClick={createSortHandler(headCell.id)} >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }));
  
  
  function EnhancedTableToolbar(props){
    const classes = useToolbarStyles();
    const { numSelected } = props;
  
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
  
          </Typography>
        )}
  
        {numSelected > 0 ? (
          <Tooltip title="Delete">
  
          </Tooltip>
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  };
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };
  
  
  
  function Submissions({setsubmissions,submissions,departement}) {
  
    const [order, setOrder] = React.useState('asc');
    const [orderBy, setOrderBy] = React.useState('calories');
    const [selected, setSelected] = React.useState([]);
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
      
    const [ratesTech, setRatesTech] = React.useState([]);
    const [ratesFinance, setRatesFinance] = React.useState([]);
    
    
  
    useEffect(()=>{
  
      let tmp_scores_tech = [];
      let tmp_scores_finance = [];
      submissions.map((produit) => {
        tmp_scores_finance.push(produit.score_financier);
        tmp_scores_tech.push(produit.score_technique);
      })
  
      setRatesTech(tmp_scores_tech);
      setRatesFinance(tmp_scores_finance);
  
    },[submissions]);
  
  
     function downloadDocAdmin(name,index) {
      const formData = new FormData();
      formData.append('id_demande',submissions[index].id_demande);
      downloadDocAdminServ(formData).then((response) => {
         var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', name);
          document.body.appendChild(fileLink);
          fileLink.click();
      }).catch(err=>{
        console.log(err,err.response);
      });
    }
  
  
     function downloadDocTech(name,index) {
      const formData = new FormData();
      formData.append('id_demande',submissions[index].id_demande);
      downloadDocTechServ(formData).then((response) => {
         var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', name);
          document.body.appendChild(fileLink);
          fileLink.click();
      });
    }
  
     function downloadDocFinance(name,index) {
      const formData = new FormData();
      formData.append('id_demande',submissions[index].id_demande);
      downloadDocFinanceServ(formData).then((response) => {
         var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement('a');
          fileLink.href = fileURL;
          fileLink.setAttribute('download', name);
          document.body.appendChild(fileLink);
          fileLink.click();
      });
    }
    

  
    const handleRequestSort = (event, property) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelecteds = submissions.map((n) => n.name);
        setSelected(newSelecteds);
        return;
      }
      setSelected([]);
    };
    
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
    const isSelected = (code) => selected.indexOf(code) !== -1;
  
    const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  
  
    return (
      <div className="demandeAppel_container" >
       <Paper>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table aria-labelledby="tableTitle" size={'medium'} aria-label="enhanced table" >
            <EnhancedTableHead numSelected={selected.length} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} submissionCount={submissions.length}/>
            <TableBody>
                {stableSort(submissions, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.code);
                    if (row.code!=='null') {
                    return (
                      <TableRow role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={index} selected={isItemSelected}>
                        <TableCell align="left">{row.nom}</TableCell>
                        <TableCell align="center" >
                        <Rating
                            name="score_financier" 
                            value={ratesFinance[index]+0} 
                            readOnly
                          />
                            {
                              ratesFinance[index] != 0 
                              ?<Box style={{alignContent:"center" , fontWeight:"600"}}>{ratesFinance[index]}</Box>
                              :<Box sx={{ ml: 2 }}>0</Box>
                            }

                      </TableCell>
                      <TableCell align="center">
                        <Rating
                            name="score_technique" 
                            value={ratesTech[index]+0} 
                            readOnly
                          />
                            {
                              ratesTech[index] != 0 
                              ?<Box style={{alignContent:"center" , fontWeight:"600"}}>{ratesTech[index]}</Box>
                              :<Box sx={{ ml: 2 }}>0</Box>
                            }
                      </TableCell>
                        <TableCell align="left">{(new Date(row.date)).toLocaleString()}</TableCell>
                        <TableCell align="left" >
                        <div className='documentsContainer'>
                            <div style={{display:'flex',marginBottom:'10px'}}>
                                <div className='documentsDownload'>
                                    {
                                        departement=="juridique"
                                        ?<Fab size="small" color="default" onClick={()=>downloadDocAdmin("DocAdmin.pdf",index)}><DownloadIcon className="my_icon" /></Fab>
                                        :<Fab size="small" color="default" className='disabled_button'><DownloadIcon className="my_icon"  /></Fab>
                                    }
                                    <span className='documentsTitle'>Administrative</span>
                                </div>
                                <div className='documentsStatus_valide' >
                                    {setStatus(etatDoc('admin',submissions[index].accordAdmin))}
                                </div>
                            </div>
  
                            <div style={{display:'flex',marginBottom:'10px'}}>
                                <div className='documentsDownload'>
                                    {
                                        departement=="technique"
                                        ?<Fab size="small" color="default" onClick={()=>downloadDocTech("DocTech.pdf",index)}><DownloadIcon className="my_icon" /></Fab>
                                        :<Fab size="small" color="default" className='disabled_button'><DownloadIcon className="my_icon"  /></Fab>
                                    }
                                    <span className='documentsTitle'>Technique</span>
                                </div>
                                    <div className='documentsStatus_valide'  >
                                      {setStatus(etatDoc('tech',submissions[index].accordTech))}               
                                </div>
                            </div>
  
                            <div style={{display:'flex',marginBottom:'10px'}}>
                                <div className='documentsDownload'>
                                    {
                                        departement=="finance"
                                        ?<Fab size="small" color="default" onClick={()=>downloadDocFinance("DocFinance.pdf",index)}><DownloadIcon className="my_icon"  /></Fab>
                                        :<Fab size="small" color="default" className='disabled_button'><DownloadIcon className="my_icon"  /></Fab>
                                    }
                                    <span className='documentsTitle'>Finance</span>
                                </div>
                                    <div className='documentsStatus_valide'  >
                                    {setStatus(etatDoc('finance',submissions[index].accordFinance))}             
                                </div>
                            </div>
                          </div>
                        </TableCell>
                        <TableCell align="left">
                        <div className='AccordCtrl_container'>
                            {etat(row.accord)}
                        </div>
                      </TableCell>
                      </TableRow>
                    );
                    }
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
  
      </div>
    );
  }  





  
  


function Offer_submissions({view_submissions,setview_submissions,departement}){

    const [submissions,setsubmissions]=useState([]);

    const classes = useStyles();

    useEffect(()=>{
      const formData = new FormData();
      formData.append('id_appel',view_submissions.offer_id);
      get_offer_submissions(formData)
        .then(response => {
           setsubmissions(response.data);
           //console.log(response.data);
        })
        .catch(err=>{
          console.log(err,err.response);
        });
    },[]);

    return (
        <Dialog disableEnforceFocus fullScreen open={view_submissions!=null?true:false} onClose={() => setview_submissions(null)} className="my_full_dialog" >
            <AppBar className={classes.appBar}  >
                <Toolbar style={{backgroundColor:'#343a40'}} >
                <div className='page_title'>
                  <IconButton edge="start" color="inherit" onClick={() => setview_submissions(null)}  aria-label="close" >
                      <CloseIcon className="my_icon" />
                  </IconButton>
                  <span>Soumissions</span>
                </div>
                </Toolbar>
            </AppBar>

            <Submissions setsubmissions={setsubmissions} submissions={submissions} departement={departement} />

        </Dialog>
    )
}

export default Offer_submissions;
