import axios from "axios"


const end_point1 = "https://com.portailachats.ma/api/gadmin/";
////const end_point1 = "http://localhost:8000/api/gadmin/";

  
axios.interceptors.request.use(
    function(config) {
        config.headers["Content-Type"] = "application/json";
        if(JSON.parse(localStorage.getItem('admin-info'))){
            config.headers["company"] = JSON.parse(localStorage.getItem('admin-info')).company;  
            config.headers["Authorization"] = "Bearer "+JSON.parse(localStorage.getItem('admin-info')).token;  
        }
        config.headers["withCredentials"] = true;
        return config;
    },
    function(error) {
        return Promise.reject(error);
    }
);


axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error.response.status === 401) {
        //console.log("401");
        localStorage.clear();
        window.dispatchEvent(new Event("storage"));
       // window.location.href="/";
    }
    return Promise.reject(error);
});



////////////////////////////////////////////////////
////
////////////////////////////////////////////////////

export const create_new_tenant = async(data) => {
    return await axios.post(end_point1+'create_new_tenant',data);
} 

export const archive_tenant = async(data) => {
    return await axios.post(end_point1+'archive_tenant',data);
} 

export const get_tenants = async(company_state) => {
    return await axios.get(end_point1+'get_tenants/'+company_state);
} 

export const logout_request = async(id) => {
    return await axios.post(end_point1+'logout/'+id);
}
