import React, { useState,useEffect} from 'react'

import './set_commissions_view.css'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import SendIcon from '@material-ui/icons/Send';
import CancelIcon from '@material-ui/icons/Cancel';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {makeStyles} from '@material-ui/core/styles';
import swal from 'sweetalert';

import Tooltip from '@mui/material/Tooltip';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { create_commission, get_commission_by_offre } from '../../../../services/commission_service';




const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }));

function setDateTime(date){
    let day = date.toISOString();
    let day_comp = day.split(":");
    return day_comp[0]+":"+day_comp[1];
}
  

function Set_commission({openCommission,handleCloseCommission,auditors,offre,profilInfo}){

    const classes = useStyles();

    const [valide, setValid] = useState(true);
    const [submitted, setSubmitted] = useState(false);

    const [commission,setCommission] = useState(null);
    
    const [auditors_legal,setAuditors_legal] = useState([]);
    const [auditors_financial,setAuditors_financial] = useState([]);
    const [auditors_technique,setAuditors_technique] = useState([]);

    const [auditor_legal,setAuditor_legal] = useState("membre");
    const [auditor_financial,setAuditor_financial] = useState("membre");
    const [auditor_technique,setAuditor_technique] = useState("membre");

    const [waiting, setWaiting] = useState(false);
    const [errorText,setErrorText] = useState("");

    useEffect(()=>{

        async function load_auditors(){
            setAuditors_financial(auditors.finance);
            setAuditors_legal(auditors.legal);
            setAuditors_technique(auditors.technical);
            //console.log(2);
        }
        
        
        async function get_commissions(){
            //console.log(1);
            get_commission_by_offre(offre.id_appel).then((response) => {
                if(response.data != []){
                    setCommission(response.data);
                    setAuditor_legal(response.data.legal_direction);
                    setAuditor_financial(response.data.finance_direction);
                    setAuditor_technique(response.data.technical_direction);
                }
            }).catch((error) => {
                //console.log(error.response);
            });
        }
        
        load_auditors();
        get_commissions();

    },[])

    function validated_elem(){
        return submitted && valide;
    }

    async function propose_commission() {    
        setSubmitted(true);
        setValid(true);
        setErrorText("");
        setWaiting(true);
        if ( auditor_legal=="membre" || auditor_financial== "membre"  || auditor_technique=="membre" ) {
            setErrorText("Champs invalides !");
            setValid(false);
            setWaiting(false);
        }else{
            let formData = new FormData();
            formData.append("service_id",profilInfo.id);
            formData.append("offer_id",offre.id_appel);
            formData.append("legal_direction",auditor_legal);
            formData.append("finance_direction",auditor_financial);
            formData.append("technical_direction",auditor_technique);
            formData.append("proposed_meet_date1",offre.dateOuvertureOffre);
            create_commission(formData).then((response) => {
                setWaiting(false);
                swal("Les membres seront bientôt notifiés !", {
                    icon: "success",
                    buttons: true,
                  }).then((willDelete) => {
                      if (willDelete) { 
                  }}) 
                handleCloseCommission();
            }).catch((error) => {
                //console.log(error.response);
                setWaiting(false);
            });
        }
     }

    return (
        <Dialog className="setCommission_dialog" fullWidth={true} maxWidth={'md'} open={openCommission} onClose={handleCloseCommission} aria-labelledby="form-dialog-title" >
            <DialogTitle id="form-dialog-title">Membres de commission</DialogTitle>
             <DialogContent>
                <div style={{margin:"10px 0px 15px 0px"}}>
                    <FormControl className="header_form">
                       <InputLabel htmlFor='direction'>Juridique</InputLabel>
                        <Select name="direction" id="direction" onChange={(e)=>{setAuditor_legal(e.target.value)}} value={auditor_legal}>
                            <MenuItem value="membre" selected disabled>
                                Membre
                            </MenuItem>
                            {
                                auditors_legal.map((auditor,index) => (
                                    <MenuItem key={auditor.id} value={auditor.id}>{auditor.fullName}</MenuItem>
                                ))
                            }
                        </Select> 

                    </FormControl>

                    <FormControl className="header_form">
                        <InputLabel htmlFor='finance'>Finance</InputLabel>
                        <Select name="finance" id="finance" onChange={(e)=>{setAuditor_financial(e.target.value)}} value={auditor_financial}>
                            <MenuItem value={"membre"} selected disabled>
                                Membre
                            </MenuItem>
                            {
                                auditors_financial.map((auditor,index) => (
                                    <MenuItem key={auditor.id} value={auditor.id}>{auditor.fullName}</MenuItem>
                                ))
                            }
                        </Select>  
                    </FormControl>


                    <FormControl className="header_form">
                        <InputLabel htmlFor='technique'>Technique</InputLabel>
                        <Select name="technique" id="technique" onChange={(e)=>{setAuditor_technique(e.target.value)}} value={auditor_technique}>
                            <MenuItem value="membre" disabled>
                                Membre
                            </MenuItem>
                            {
                                auditors_technique.map((auditor,index) => (
                                    <MenuItem key={auditor.id} value={auditor.id}>{auditor.fullName}</MenuItem>
                                ))
                            }
                        </Select>  
                    </FormControl>

                </div>
            <div style={{margin:"10px 0px 0px 0px"}}>
                <TextField
                        type="datetime-local"
                        label="Date d'ouverture des plis" className="header_form" defaultValue={offre.dateOuvertureOffre} inputProps={{min: setDateTime(new Date(offre.dateOuvertureOffre))}}
                        InputProps={{ startAdornment: ( <InputAdornment position="start"> <EventAvailableIcon className="my_icon" /> </InputAdornment> )}}
                        readOnly disabled
                    />
            </div>

            </DialogContent>
            <DialogActions>
            <div className='dialogBottom'>
                <div>
                    {
                        validated_elem() 
                        ?<></>
                        :<p className={submitted ? "col-md-10 error" : "col-md-10 valid-champ"}>
                                {submitted ? errorText : ""}
                            </p>
                    }
                </div>
                <div>
                   <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon className="my_icon" />} onClick={handleCloseCommission} >
                        Annuler
                    </Button>

                    {
                        commission==null
                        ?<Button variant="contained" className="submit_but valide_but" startIcon={<SendIcon className="my_icon" />} onClick={propose_commission} >
                                Envoyer
                            </Button>  
                        :<Tooltip htmlFor={"disableedbutton"} title="Déja envoyer !">
                            <Button variant="contained" id="disableedbutton" name="disableedbutton" className="submit_but valide_but disabled_button" startIcon={<SendIcon className="my_icon" />} onClick={propose_commission} >
                                Envoyer
                            </Button> 
                        </Tooltip>
                        
                    }
                
                </div>
            </div>      
                
            </DialogActions>

            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    )
}

export default Set_commission
