import '../../../../../assets/css/product_details.css'
import React, { useState , useEffect } from 'react'
import { useParams } from "react-router-dom";

import Button from '@material-ui/core/Button';
import AddShoppingCartIcon from '@mui/icons-material/AddShoppingCart';
import Form from 'react-bootstrap/Form';
import empty from '../../../../empty.jpg'

import Loading_animation_view from '../../../../loading_animation_view/loading_animation_view';
import { get_categories } from '../../../../../services/purchase_service';
import { get_products_byCode } from '../../../../../services/for_allServices';

import { Carousel } from 'react-bootstrap';



function Product_details (){

  let { product_code } = useParams();

  const [product,setProduct] = useState(null);
  const [supplier,setSupplier] = useState(null);
  const [product_amount,setProduct_amount] = useState(1);
  const [add_product,setAdd_product] = useState(false);
  
  const [categories,setCategories]=useState([]);

 
  useEffect(()=>{

    let formData = new FormData();
    formData.append('products_codes',JSON.stringify([product_code]));
    get_products_byCode(formData).then((response)=>{
        for(let key in response.data){
          setProduct(response.data[key].products[0]);
          setSupplier(key.split('*')[1])
        }
    }).catch((error)=>{
      //console.log(error.response);
    });

    get_categories().then((response)=>{
        setCategories(response.data);
    }).catch((error)=>{
      //console.log(error.response);
    })

  },[]);
    

  
    
  function getCategorieById(id){
    var categorie="";
    categories.map((catg)=>{
      if(catg.id_categorie === id) categorie = catg.nom;
    })
    return categorie;
  }

  function add_product_to_cart(id_supplier,product_code,product_amount){
      setAdd_product(true);
      let products_selected = sessionStorage.getItem('products_selected');
      let products_selected_tmp = {};
      if(products_selected)  products_selected_tmp = JSON.parse(products_selected);
      var id_product_exist = null;
      if(Object.keys(products_selected_tmp).includes(id_supplier.toString())){
          products_selected_tmp[id_supplier].map((product,id) => {
            if(product.product_code === product_code){
              id_product_exist = id;
              product.product_amount = product.product_amount + product_amount;
            }
          });
          if(id_product_exist===null){
            products_selected_tmp[id_supplier].push({
                "product_code" : product_code,
                "product_amount": product_amount
            })
          }
      }else{
          products_selected_tmp[id_supplier] = [{
            "product_code" : product_code,
            "product_amount": product_amount
          }];
      }
      sessionStorage.setItem('products_selected',JSON.stringify(products_selected_tmp));
      window.dispatchEvent(new Event("storage"));
      setProduct_amount(1);
      setAdd_product(false);
    }

  return (
    <>
      {
        product===null && supplier==null
        ?<Loading_animation_view />
        :<div className="product_details_modal_container">
            <div className="product_details_carousel_container">
              <Carousel>
                {
                    product.product_pics.length!==0
                    ?(product.product_pics).map( (item, i) => (
                            <Carousel.Item key={i} slide={true}>
                                <img src={`data:image/png;base64,${item}`} />
                            </Carousel.Item>
                      ))
                    : <Carousel.Item key={1} slide={true}>
                                <img src={empty} />
                            </Carousel.Item>
                  
                }
              </Carousel>
            </div>
            <div className="product_details_infos_container">
                <div className="card_title_descr">{product.title}</div>
                <div>
                  <div className="card_categorie_descr"><span>Famille d'achat : </span>{getCategorieById(product.id_categorie)}</div>
                  <div className="card_categorie_descr"><span>Fournisseur : </span>{supplier}</div>
                </div>
                <div className="card_description_details">{product.description}</div>
                <div className="view_more_buttons_cont">
                      <Form.Control
                          className="amount_container"
                          autoComplete="new-password"
                          type="number"
                          value={product_amount}
                          onChange={ e =>  {setProduct_amount(parseInt(e.target.value))}}
                      />
                  <Button variant="contained" className={add_product===true || product_amount=="" || product_amount<1 ?"submit_but disabled_button":"submit_but"}  
                      startIcon={<AddShoppingCartIcon className='my_icon' />} onClick={() => add_product_to_cart(product.id_supplier,product_code,product_amount)} > 
                          Ajouter au panier 
                  </Button>
                </div>
            </div>
            <div className="details_container">
              <div  className="container_title">Description</div>
              <div className="description">{product.description}</div>
            </div>

          </div>
      }
      </>
  )

}

export default Product_details;
