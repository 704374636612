import '../../../assets/css/all_services_profil.css'
import React, { useState,useEffect, useCallback } from 'react';
import Form from 'react-bootstrap/Form'
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import SaveIcon from '@mui/icons-material/Save';
import swal from 'sweetalert';
import {makeStyles} from '@material-ui/core/styles';
import defaultProfil_pic from './defaultProfil_pic.png';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { get_categories } from '../../../services/purchase_service';
import { getSupplier_infos_byId, get_supplierIdentity_profileImg,update_supplierIdentity_profileImg,update_supplierIdentity_infos } from '../../../services/supplier_service'

import Loading_animation_view from '../../loading_animation_view/loading_animation_view'

const useStyles = makeStyles((theme) => ({
  button: { margin: theme.spacing(1),},
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));


function Supplier_profil() {

  const classes = useStyles();

  const [serviceInfo,setserviceInfo]=useState(null);

  const [categories,setCategories]=useState(null);

  const [categorie,setCategorie]=useState(null);
  const [nom,setNom]= useState("");
  
  const [CNSS,setCNSS]= useState("");
  const [Pays,setPays]= useState("");
  const [Ville,setVille]= useState("");
  const [Adresse,setAdresse]= useState("");
  const [CodePostal	,setCodePostal]= useState("");

  const [MaisonMere,setMaisonMere]= useState("");
  const [AnneConst,setAnneConst]= useState("");
  const [Tele,setTele]= useState("");
  const [RegCommenrce	,setRegCommenrce]= useState("");
  const [Fiscal,setFiscal]= useState("");
  const [NumPatente,setNumPatente]= useState("");
  const [ICE,setICE]= useState("");

  const [disabled , setDisabled] =useState(true);
  const [userprofile , setUserprofile] = useState(null)

  const [error,setError]=useState(null);
  const [editing,setediting]=useState(false);
  const [waiting, setWaiting] = useState(false);

  let user = JSON.parse(localStorage.getItem('user-info'));

  useEffect(()=>{
    get_categories().then((response)=> {
      setCategories(response.data);
    }).catch((error)=>{
      //console.log(error.response);
    });


    getSupplier_infos_byId(user.id).then((response)=> {
        setCategorie((response.data.values[0].familleAchat).split(','));
        setNom(response.data.values[0].nom);
        setCNSS(response.data.values[0].CNSS);
        setPays(response.data.values[0].Pays);
        setVille(response.data.values[0].Ville);
        setAdresse(response.data.values[0].Adresse);
        setCodePostal(response.data.values[0].CodePostal);
        setMaisonMere(response.data.values[0].maisonMere);
        setAnneConst((new Date(response.data.values[0].created_at)).toLocaleString().split(/[,| ]/)[0]);
        setTele(response.data.values[0].tel);
        setRegCommenrce(response.data.values[0].RegistreCommerce);
        setFiscal(response.data.values[0].IdentifiantFiscal);
        setNumPatente(response.data.values[0].numPatente);
        setICE(response.data.values[0].numICE);
        setserviceInfo(response.data.values[0]);
    });

    get_supplierIdentity_profileImg(user.id).then((response)=>{
      setUserprofile(URL.createObjectURL(response.data));
    }).catch((error)=>{
      //console.log(error.response);
      setUserprofile(defaultProfil_pic);
    });

  },[]);

  const getCategorieById = useCallback(()=>{
    var tmp_categorie="";
    categories.map((catg)=>{
      categorie.map((id)=>{
        if(catg.id_categorie === parseInt(id)){
          if(tmp_categorie==="") tmp_categorie = tmp_categorie+catg.nom;
          else tmp_categorie = tmp_categorie+", "+catg.nom;
        }
      })
    })
    return tmp_categorie;
  },[categories,categorie]);


  function update_alertMsg(){
    setError(null);
    if(CNSS==="" || Pays==="" || Ville==="" || Adresse==="" || CodePostal==="" || MaisonMere===""
      || AnneConst==="" || Tele==="" || RegCommenrce==="" || Fiscal==="" || NumPatente==="" || ICE===""){
        setError("Champs invalids !")
    }else{
      swal({
          title: "Etes-vous sûr ?",
          text: "Une fois sauvegardées, vous ne pourrez plus récupérer les données précédentes !",
          icon: "warning",
          buttons: true,
          dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          update_infos();
          setDisabled(!disabled);
        }
      });
    }
  }

  function updateProfil_pics(file){
    setWaiting(true);
    const formData = new FormData();
    formData.append('id',user.id);
    formData.append('profile_img',file);
    update_supplierIdentity_profileImg(formData).then((response)=>{
      //console.log(response.data);
      setWaiting(false);
      setUserprofile(URL.createObjectURL(file));
    }).catch((error) => {
      //console.log(error.response);
    })
  }


   async function update_infos() {
      setWaiting(true);
      const formData = new FormData();
      formData.append('id',user.id);
      formData.append('nom',nom);
      formData.append('Pays',Pays);
      formData.append('CNSS',CNSS);
      formData.append('Ville',Ville);
      formData.append('Adresse',Adresse);
      formData.append('CodePostal',CodePostal);
      formData.append('maisonMere',MaisonMere);
      formData.append('tel',Tele);
      formData.append('RegistreCommerce',RegCommenrce);
      formData.append('IdentifiantFiscal',Fiscal);
      formData.append('numPatente',NumPatente);
      formData.append('numICE',ICE);
      update_supplierIdentity_infos(formData).then((response)=>{
        setWaiting(false);
        setediting(false);
        swal("Votre fiche d'identité a été modifiée avec succès !", {
          icon: "success",
        });
      }).catch((error)=>{
        //console.log(error.response);
      })
  }


  return (
    <div className="all_services_identity_container">
      {
        serviceInfo===null || userprofile===null
        ?<Loading_animation_view />
        :<div className="all_services_identity_infos_container">
          <div className="all_services_identity_infos">
            <div className="srv_identity_img">
                <div >
                  <img src={userprofile} alt=''  />
                </div>
                <div>
                    <input accept=".jpg,.png,.jpeg" className={classes.input} name="profil_img" id="profil_img" multiple type="file" onChange={(e)=>updateProfil_pics(e.target.files[0])} />
                    <label htmlFor="profil_img">
                      <span className="submit_but edit_but"  >
                        <EditIcon className="my_icon" /> Image de profil
                      </span>
                        
                    </label>
                </div>
            </div>
            <div className="srv_identity_element">
                <span>Famille d'achat</span>
                <span>
                    <Form.Control
                          required
                          value={getCategorieById()} 
                          autoComplete="new-password"
                          type="text"
                          placeholder="Famille d'achat"
                      />   
                </span>
            </div>
            <div className="srv_identity_element">
                <span>Nom complet</span>
                <span>
                    <Form.Control
                          required
                          value={nom} 
                          autoComplete="new-password"
                          type="text"
                          placeholder="Nom complet *"
                          disabled = { disabled }
                          onChange={(e)=>setNom(e.target.value)} 
                      />   
                </span>
            </div>
            <div className="srv_identity_element">
                <span>CNSS</span>
                <span>
                    <Form.Control
                          required
                          value={CNSS} 
                          autoComplete="new-password"
                          type="text"
                          placeholder="CNSS *"
                          disabled = { disabled }
                          onChange={(e)=>setCNSS(e.target.value)} 
                      />   
                </span>
            </div>
            <div className="srv_identity_element">
              <span>Pays</span>
              <span>
                  <Form.Control
                        required
                        value={Pays} 
                        autoComplete="new-password"
                        type="text"
                        placeholder="Pays *"
                        disabled = { disabled }
                        onChange={(e)=>setPays(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Ville</span>
              <span>
                  <Form.Control
                        required
                        value={Ville}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Ville *"
                        disabled = { disabled }
                        onChange={(e)=>setVille(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Adresse</span>
              <span>
                  <Form.Control
                        required
                        value={Adresse}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Adresse *"
                        disabled = { disabled }
                        onChange={(e)=>setAdresse(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Code Postal</span>
              <span>
                  <Form.Control
                        required
                        value={CodePostal}
                        autoComplete="new-password"
                        type="number"
                        placeholder="Code Postal *"
                        disabled = { disabled }
                        onChange={(e)=>setCodePostal(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Maison-mère</span>
              <span>
                  <Form.Control
                        required
                        value={MaisonMere}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Maison-mère *"
                        disabled = { disabled }
                        onChange={(e)=>setMaisonMere(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Date de constitution</span>
              <span>
                  <Form.Control
                        required
                        value={AnneConst}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Date de constitution *"
                        disabled = { disabled }
                        onChange={(e)=>setAnneConst(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Téléphone</span>
              <span>
                  <Form.Control
                        required
                        value={Tele}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Téléphone *"
                        disabled = { disabled }
                        onChange={(e)=>setTele(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Registre du commerce <br/> (Ou équivalent)</span>
              <span>
                  <Form.Control
                        required
                        value={RegCommenrce}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Registre du commerce (Ou équivalent) *"
                        disabled = { disabled }
                        onChange={(e)=>setRegCommenrce(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Identifiant fiscal <br/>  (Ou équivalent)</span>
              <span>
                  <Form.Control
                        required
                        value={Fiscal}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Identifiant fiscal (Ou équivalent) *"
                        disabled = { disabled }
                        onChange={(e)=>setFiscal(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>N° Patente  <br/>  (Ou équivalent)</span>
              <span>
                  <Form.Control
                        required
                        value={NumPatente}
                        autoComplete="new-password"
                        type="text"
                        placeholder="N° Patente (Ou équivalent) *"
                        disabled = { disabled }
                        onChange={(e)=>setNumPatente(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>N° ICE  <br/> (Ou équivalent)</span>
              <span>
                  <Form.Control
                        required
                        value={ICE}
                        autoComplete="new-password"
                        type="text"
                        placeholder="N° ICE (Ou équivalent) *"
                        disabled = { disabled }
                        onChange={(e)=>setICE(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element"></div>

          </div>
          <div className="all_services_identity_infos_config" >
              <div>
                  {
                      error==null
                      ?<></>
                      :<p className={error ? "col-md-10 error" : "col-md-10 valid-champ"}>
                              {error ? error : ""}
                          </p>
                  }
               </div>
              <div>
                <Button variant="contained" className="submit_but edit_but" startIcon={<EditIcon className="my_icon" />} onClick={() => {setDisabled(!disabled);setediting(true)}} >
                    Editer
                </Button>
                <Button variant="contained" className={editing===true?"submit_but valide_but":"submit_but valide_but disabled_button"} startIcon={<SaveIcon className="my_icon" />} onClick={update_alertMsg} >
                    Sauvegarder
                </Button> 
              </div>
             
          </div>
      </div>
      }
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={waiting}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Supplier_profil;