import '../../../../../assets/css/setContract_forSubmission.css';
import React, { useState , useEffect } from 'react'
import { lighten, makeStyles } from '@material-ui/core/styles';
import Tooltip from '@mui/material/Tooltip';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import Fab from '@mui/material/Fab';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Input from "@material-ui/core/Input";
import swal from 'sweetalert';


import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import {get_contract_last_content,download_contractPDF, finalize_contract, contract_check, contract_is_being_edited,
       save_contract_content,sign_contract, upload_contract, notify_supplier_with_contractContent} from '../../../../../services/purchase_service'

import Loading_animation_view from '../../../../loading_animation_view/loading_animation_view'


import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
    input: {
      display: 'none',
    },
    select: {
      "&:focus": {
        backgroundColor: "white"
      }
    },
    selectInput: {
      "&:hover:not($disabled):not($focused):not($error):before": {
        borderBottomWidth: 0
      },
      "&:after": {
        borderBottomWidth: 0
      }
    },
    disabled: {},
    focused: {},
    error: {}
  }));


function Contract_content_view({contract_type,contract_subtypes,close_contact_type,chosenSubmission,purchase_service}){

    const classes = useToolbarStyles();
    const selectInputClasses = {
      root: classes.selectInput,
      disabled: classes.disabled,
      focused: classes.focused,
      error: classes.error
    };
  

    const [waiting,setWaiting] = useState(false);

    const [contract_id,setcontract_id] = useState(contract_subtypes[0].id);
    const [contract_signed,setContract_signed] = useState(false);

    const [able_toload_contract,setAble_toload_contract]=useState(false);
    const [able_touploadContract,setAble_touploadContract]=useState(false);
    const [supplier_accepted_terms,setSupplier_accepted_terms]=useState(false);
    const [ReadOnlyContrat,setReadOnlyContrat]=useState(false);
    const [editing_contract,setEditing_contract]=useState(false);
    const [editorDefaultContent, seteditorDefaultContent] = useState(null);
    const [editorContent, seteditorContent] = useState(null);
    const [contentChanged, setContentChanged] = useState(false);
    const [contentSaved, setcontentSaved] = useState(true);

    const [content_charged, setContent_charged] = useState(false);
    const [contract_form_pdf, setcontract_form_pdf] = useState(null);

    useEffect(()=>{
        seteditorDefaultContent(null);
        seteditorContent(null);
        setcontract_form_pdf(null);
        setReadOnlyContrat(false);
        setEditing_contract(false);
        setAble_toload_contract(false);
        setAble_touploadContract(false);
        setContent_charged(false);
        setSupplier_accepted_terms(false);
        setContract_signed(false);

        contract_check(chosenSubmission.id_demande,contract_id).then((response)=>{
          console.log(response.data);
          if(response.data!=""){
            let submission_contract = response.data[contract_type][0];
            if( submission_contract.contract_being_edited !== purchase_service.id ){
              if( submission_contract.contract_being_edited !== 0) setReadOnlyContrat(true);
            }else{
              setReadOnlyContrat(false);
              setEditing_contract(true);
            }
            if(submission_contract.contract_content){
              setAble_toload_contract(true);
              setAble_touploadContract(true);
              const formData = new FormData();
              formData.append('id_submission',chosenSubmission.id_demande);
              formData.append('contract_id',contract_id);
              download_contractPDF(formData).then((response_blob) => {
                  var file = new Blob([response_blob.data], {type: 'application/pdf'});
                  var fileURL = URL.createObjectURL(file);
                  setcontract_form_pdf(fileURL);
              }).catch(err=>{
                console.log(err.response);
              });
            }else{
              get_contract_last_content(chosenSubmission.id_demande,contract_id).then((response_content)=>{
                seteditorDefaultContent(response_content.data.contract_content);
                seteditorContent(response_content.data.contract_content);
              }).catch((error)=>{
                //console.log(error.response);
              })
      
            }
            setSupplier_accepted_terms(submission_contract.supplier_accord);
            setContract_signed(submission_contract.signed);
          }else{
            seteditorDefaultContent("<p></p>");
            seteditorContent("<p></p>");
          }
          setContent_charged(true);
        }).catch((error)=>{
          //console.log(error.response);
        });

    },[contract_id]);


    const handleEditorChange = (content) => {
      seteditorContent(content);
      //console.log(content);
      if(editorDefaultContent!=content && content!="<p><br></p>" && content!="<p></p>"){
        setContentChanged(true);
        setcontentSaved(false);
      }
      else setContentChanged(false);
    }

    function edit_contract(){
      setWaiting(true);
      let formData = new FormData();
      if(editing_contract == false) formData.append("being_edited",1);
      if(editing_contract == true) formData.append("being_edited",0);
      formData.append("id_login",purchase_service.id);
      formData.append('contract_id',contract_id);
      contract_is_being_edited(chosenSubmission.id_demande,formData).then((response)=>{
        setEditing_contract(!editing_contract);
        setWaiting(false);
      }).catch((error)=>{
        ////console.log(error.response);
      })
    }


    function isContentExist(){
      if(editorContent!="<p></p>" && editorContent!="<p><br></p>" && editorContent!=null) return true;
      else return false;
    }


    function SaveContratContent(){
      setWaiting(true);
      let formData = new FormData();
      formData.append('content',editorContent);
      formData.append('contract_type',contract_type);
      formData.append('contract_id',contract_id);
      formData.append('id_submission',chosenSubmission.id_demande);
      formData.append('id_login',purchase_service.id);
      save_contract_content(formData).then(response => {
        setcontentSaved(true);
        setContentChanged(false);
          let formData2 = new FormData();
          formData2.append("being_edited",false);
          formData2.append('contract_id',contract_id);
          contract_is_being_edited(chosenSubmission.id_demande,formData2).then((response)=>{
            setEditing_contract(false);
            setWaiting(false);
          }).catch((error)=>{
            //console.log(error.response);
          })
      })
      .catch((error)=>{
        ////console.log(error.response);
      });
    }


  function finalizeContract(){
    swal({
      title: "Etes-vous sûr ?",
      text: "!!!!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {
      setWaiting(true);
      let formData = new FormData();
      formData.append('content',editorContent);
      formData.append('contract_type',contract_type);
      formData.append('contract_id',contract_id);
      formData.append('id_submission',chosenSubmission.id_demande);
      finalize_contract(formData).then(response => {
            setWaiting(false);
            setAble_toload_contract(true);
            setAble_touploadContract(true);
            setEditing_contract(false);
            seteditorDefaultContent(null);
            seteditorContent(null);
            swal("Votre réponse a été enregistrée avec succès !", {
              icon: "success",
              buttons: true,
            }).then((willDelete) => {
                if (willDelete) { 
                }})
            download_contractPDF(formData).then((response_blob) => {
                var file = new Blob([response_blob.data], {type: 'application/pdf'});
                var fileURL = URL.createObjectURL(file);
                setcontract_form_pdf(fileURL);
            }).catch(err=>{
              console.log(err.response);
            }); 
        })
        .catch(err=>{
          setWaiting(false);
          console.log(err.response);
        });
      }})
   }


   function signContract(){
    swal({
      title: "Etes-vous sûr ?",
      text: "!!!!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
  })
  .then((willDelete) => {
    if (willDelete) {
      setWaiting(true);
      let formData = new FormData();
      formData.append('contract_id',contract_id);
      formData.append('id_submission',chosenSubmission.id_demande);
      sign_contract(formData).then(response => {
            setWaiting(false);
            swal("Votre réponse a été enregistrée avec succès !", {
              icon: "success",
              buttons: true,
            }).then((willDelete) => {
                if (willDelete) { 
                  setContract_signed(true);
                }})
      }).catch(err=>{
          setWaiting(false);
          console.log(err.response);
        });
    }})
   }


  function download_contract_pdf(){
    const formData = new FormData();
    formData.append('id_submission',chosenSubmission.id_demande);
    formData.append('contract_id',contract_id);
    download_contractPDF(formData).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', (chosenSubmission.id_demande)+"_contrat.pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
    }).catch(err=>{
      console.log(err.response);
    });
  }


  function uploadContract(pdf){
    setWaiting(true);
    let formData = new FormData();
    formData.append('contract',pdf);
    formData.append('id_submission',chosenSubmission.id_demande)
    formData.append('contract_id',contract_id);
    upload_contract(formData).then((response => {
      //console.log(response.data);
      setcontract_form_pdf(URL.createObjectURL(pdf));
      setWaiting(false);
    })).catch((error) => {
      //console.log(error.response);
    }); 
  }
   
   function sendContractToSupplier(){
      setWaiting(true);
      const formData = new FormData();
      formData.append('id_submission',chosenSubmission.id_demande);
      formData.append('contract_id',contract_id);
      formData.append('contract_type',contract_type);
      notify_supplier_with_contractContent(formData).then((response => {
        swal("Le fournisseur sera bientôt notifié !", {
          icon: "success",
          buttons: true,
        }).then((willDelete) => {
            if (willDelete) { 
        }}) 
        setWaiting(false);
      })).catch((error) => {
        //console.log(error.response);
        setWaiting(false);
      });
  }



    return (
      <Dialog disableEnforceFocus fullScreen open={contract_type!=null} onClose={close_contact_type} className="my_full_dialog contract_full_dialog">

        <AppBar className={classes.appBar}>
            <Toolbar className="contract_content_toolbar">
                <div className="contract_content_toolbar_main_container">
                    <div className="contract_content_toolbar_left">
                    <div className='page_title'>
                        <IconButton edge="start" color="inherit" onClick={close_contact_type} aria-label="close" >
                            <CloseIcon className="my_icon" />
                        </IconButton>
                        <span>{contract_type}</span>
                      </div>
                      <div className='search_filter_container'>
                        <Tooltip title="Sous types de contrat" placement="right" >
                          <div className='select_container' >
                            <FormControl className={!editing_contract ?"header_form":"header_form disabled_button"}  variant="standard" >
                                      <Select required
                                            value={contract_id}
                                            input={<Input classes={selectInputClasses} />}
                                            inputProps={{ classes: { select: classes.select } }}
                                            style={{backgroundColor:"inherit"}}
                                            onChange={e => {
                                                setcontract_id(e.target.value);
                                            }} >
                                          {
                                            contract_subtypes.map((sub_types,id)=>{
                                              return <MenuItem key={sub_types.contract_subtype} value={sub_types.id}>{sub_types.contract_subtype}</MenuItem>
                                            })
                                          }
                                      </Select>
                              </FormControl>
                          </div>
                        </Tooltip>

                        {
                          content_charged==true
                          ?<Tooltip title="" placement="bottom" >
                            <span style={{marginLeft:'6px'}}>
                              <Button className={ contract_signed ? "status_success" : "status_waiting"}>
                                    { contract_signed ? "signé" : "En cours"}
                              </Button>
                            </span>
                          </Tooltip>
                          :<></>
                        }
                      </div>
                    </div>
                    {
                      content_charged == true
                      ?<div className="contract_content_toolbar_right">
                        <Tooltip title={
                                    ReadOnlyContrat ?'En cours de modification par fournisseur'
                                    :editing_contract ? "Annuler la modification" : "Modifier le contrat"
                                } placement="bottom" >
                          <span style={{marginLeft:'6px'}}>
                            <Button className={
                              ReadOnlyContrat?"submit_but disabled_button contract_being_edited"
                              :editing_contract ? "submit_but unable_to_changeContract" 
                                      :supplier_accepted_terms? "submit_but disabled_button able_to_changeContract":"submit_but able_to_changeContract"
                            } onClick={edit_contract}>
                                  {
                                      ReadOnlyContrat?'En cours de modification'
                                      :editing_contract ? "annuler" : "modifier"
                                  }
                            </Button>
                          </span>
                          
                        </Tooltip>

                        <Tooltip title="Enregistrer le contrat" placement="bottom" >
                        <span style={{marginLeft:'6px'}}>
                          <Button autoFocus className={contentChanged?"submit_but border_button":"submit_but border_button disabled_button"} color="inherit"  onClick={SaveContratContent}>
                              Enregistrer
                          </Button>
                          </span>
                        </Tooltip>


                        {
                          contract_form_pdf
                          ?<Tooltip title="Signer le contrat" placement="bottom" >
                              <span style={{marginLeft:'6px'}}>
                                <Button autoFocus className={supplier_accepted_terms && !contract_signed ?"submit_but border_button":"submit_but border_button disabled_button"} style={{color:"rgb(83 236 151)",fontWeight:"600"}} onClick={signContract}>
                                    signer
                                </Button>
                              </span>
                            </Tooltip>
                          :<Tooltip title="Mis en signature le contrat" placement="bottom" >
                              <span style={{marginLeft:'6px'}}>
                                <Button autoFocus className={supplier_accepted_terms && contentSaved && !able_touploadContract?"submit_but border_button":"submit_but border_button disabled_button"} style={{color:"rgb(83 236 151)",fontWeight:"600"}} onClick={finalizeContract}>
                                    mis en signature
                                </Button>
                              </span>
                            </Tooltip>
                        }
                        
                        <span className="icon_buttons_container"> 
                          <Tooltip title="Exporter en fichier pdf" placement="bottom" >
                            <span style={{marginLeft:'8px'}}>
                            <Fab onClick={download_contract_pdf} size="small" color="warning" className={able_touploadContract?"":"disabled_button"} >
                              <GetAppRoundedIcon className="my_icon" />
                            </Fab>
                            </span>
                          </Tooltip>
                          
                          <Tooltip title="Importer un fichier pdf" placement="bottom" >
                            <span style={{marginLeft:'6px'}}>
                              <input accept='application/pdf' className={classes.input} name='uploadContract' id='uploadContract' multiple type='file'  
                                  onChange={(e)=>uploadContract(e.target.files[0])}/>
                              <label htmlFor='uploadContract' className={able_toload_contract && !contract_signed?"":"disabled_button"} style={{margin:'0'}}>
                                <Fab variant="contained" color="warning" component="span" size="small" >
                                    <CloudUploadIcon className="my_icon"  />
                                </Fab>  
                              </label>
                            </span>
                          </Tooltip>
                          
                          <Tooltip title="Notifier le fournisseur" placement="bottom" >
                            <span style={{marginLeft:'6px'}}>
                              <Fab size="small" color="warning" className={contentSaved?"":"disabled_button"} onClick={sendContractToSupplier} ><NavigateNextIcon className="my_icon" /></Fab>  
                            </span>
                          </Tooltip>
                        </span>
                    </div>
                    :<></>
                  }
                    
                    
                </div>
                
            </Toolbar>
        </AppBar>
        {
          editorDefaultContent!=null || contract_form_pdf!=null
          ?<div className="text_editor_container">
              {
                  contract_form_pdf!=null
                  ?<iframe src={contract_form_pdf} width="100%" height="100%" />
                  :<SunEditor
                      lang="fr"
                      width="100%"
                      height="100%"
                      disable={ReadOnlyContrat || able_touploadContract || !editing_contract}
                      setOptions={{
                        buttonList: [
                          [
                          'undo', 'redo',
                          'bold', 'underline', 'italic', 'strike', 'subscript', 'superscript',
                          'font', 'fontSize',
                          'paragraphStyle', 'blockquote',
                          'fontColor', 'hiliteColor', 'textStyle',
                          'removeFormat',
                          'outdent', 'indent',
                          'align', 'horizontalRule', 'list', 'lineHeight','table',
                          'link', 'image',
                          'fullScreen','print'
                          ]
                      ]
                      }}
                      defaultValue={editorDefaultContent}
                      onChange={handleEditorChange}
                    /> 
                }
                
            </div>
          :<Loading_animation_view />
        }
        

        <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
    </Dialog>
    );
}

export default Contract_content_view;
