import './set_comments.css'
import React, { useEffect, useState } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import SendIcon from '@mui/icons-material/Send';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import swal from 'sweetalert';

import { MenuReferencementSend } from '../../../../services/purchase_service';


function Set_comments({open_comment_modal,setOpen_comment_modal,commChoix,setCommChoix}){


  const [errorText,setErrorText] = useState("");
  const [waiting, setWaiting] = useState(false);

  const [comment,setcomment]=useState('');

  useEffect(()=>{
    //console.log(open_comment_modal.get('commentaire'));
    setcomment(open_comment_modal.get('commentaire') && open_comment_modal.get('commentaire')!=="null"?open_comment_modal.get('commentaire'):'');
  },[])

  function submit_refuse_comment() {
    setErrorText("");
    if(comment===''){
      setErrorText("Champ vide !");
    }else{
      setWaiting(true);
      open_comment_modal.set('commentaire',comment);
      MenuReferencementSend(open_comment_modal)
        .then((response) => {
          setWaiting(false);
          swal("Le fournisseur sera bientôt notifié !", {
            icon: "success",
            buttons: true,
          }).then((willDelete) => {
              if (willDelete) {
          }})
          setOpen_comment_modal(null);
          let tmp = [...commChoix];
          tmp[open_comment_modal.get('id')] = comment;
          setCommChoix(tmp);
        }).catch(err=>{
            console.log(err.response);
            setWaiting(false);   
        });
    }
        
  }

     

    return (
      <Dialog className="set_refuse_raison_container" fullWidth={true} maxWidth={'md'} open={open_comment_modal!=null?true:false} onClose={()=>setOpen_comment_modal(null)} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">Votre message</DialogTitle>
        <DialogContent>
          <div>
                {
                    errorText == "" 
                    ?<></>
                    :<p className={errorText ? "error" : "valid-champ"}>
                            {errorText}
                        </p>
                }

              <div style={{width:"100%",marginTop:"5px"}} >
              <TextField
                  id="outlined-multiline-flexible"
                  label="Message"
                  variant='outlined'
                  multiline
                  rows={4}
                  defaultValue={comment}
                  onChange={(e)=>setcomment(e.target.value)} 
                  style={{width:"100%"}}
                />
              </div>
          </div>
          
     </DialogContent>
        <DialogActions>
          <div className='dialogBottom'>
            <div>
              <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon className="my_icon" />} onClick={()=>setOpen_comment_modal(null)} >
                  Annuler
              </Button>
              <Button variant="contained" className="submit_but valide_but" startIcon={<SendIcon className="my_icon" />} onClick={submit_refuse_comment} >
                  Envoyer
              </Button> 
            </div>
          </div>
        </DialogActions>

        <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>

      </Dialog>
    )
}

export default Set_comments
