import axios from "axios"
import './request_interceptor'


const end_point1 = "https://com.portailachats.ma/api/servicePrincipale/";
//const end_point1 = "http://localhost:8000/api/servicePrincipale/";



////////////////////////////////////////////////////
////
////////////////////////////////////////////////////

export const getPurchaseService_infos = async(id_service) => {
    return await axios.get(end_point1+"profilService/"+id_service);
}


export const create_supplier = async(data) => {
    return await axios.post(end_point1+'ajouterFournisseur',data);
} 

export const get_referencing = async(id,filter,searchValue,page,sortBy,sortOrder) => {
    return await axios.get(end_point1+"MenuReferencement/"+id+"/"+filter+"/"+searchValue+"?page="+page+"&sortBy="+sortBy+"&sortOrder="+sortOrder);
} 

export const get_suppliers_for_services = async(filter,searchValue,page,sortBy,sortOrder) => {
    return await axios.get(end_point1+"get_suppliers_for_services/"+filter+"/"+searchValue+"?page="+page+"&sortBy="+sortBy+"&sortOrder="+sortOrder);
}

export const downloadBilanRC = async(data) => {
    return await axios(
        {   url: end_point1+'downloadBilanRC',
            method:'POST',
            data:data,
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/pdf',
            },
        });
}

export const validating_rc = async(data) => {
    return await axios.post(end_point1+'validerFournisseurRc',data);
}

export const refusing_rc = async(data) => {
    return await axios.post(end_point1+'refuserFournisseurRc',data);
}

export const validating_bilan = async(data) => {
    return await axios.post(end_point1+'validerFournisseurBilan',data);
}

export const refusing_bilan = async(data) => {
    return await axios.post(end_point1+'refuserFournisseurBilan',data);
}

export const validating_compte = async(data) => {
    return await axios.post(end_point1+'validerFournisseurCompte',data);
}

export const refusing_compte = async(data) => {
    return await axios.post(end_point1+'refuserFournisseurCompte',data);
}

export const archiever_compte = async(data) => {
    return await axios.post(end_point1+'archiverFournisseurCompte',data);
}

export const valider_fournisseur = async(data) => {
    return await axios.post(end_point1+'validerFournisseur',data);
}

export const MenuReferencementSend = async(data) => {
    return await axios.post(end_point1+'MenuReferencementSend',data);
}

export const archivate_supplier = async(data) => {
    return await axios.post(end_point1+'archiverFournisseur',data);
}

export const get_categories = async() => {
    return await axios.get(end_point1+'categories');
} 


export const load_chat_fournisseur = async(id,id_serv) => {
    return await axios.get(end_point1+'getChat_Fournisseur/'+id+"/"+id_serv);
}

export const countmsg_non_lue_fournisseur = async(id,id_serv) => {
    return await axios.get(end_point1+'countmsg_non_lue_fournisseur/'+id+'/'+id_serv);
}


export const countmsg_non_lue_service_fournisseur = async(id_service,id_fournisseur) => {
    return await axios.get(end_point1+'countmsg_non_lue_service_fournisseur/'+id_service+'/'+id_fournisseur);
}

export const load_chat_service_fournisseur = async(id_service,id_fournisseur) => {
    return await axios.get(end_point1+'load_chat_service_fournisseur/'+id_service+'/'+id_fournisseur);
}



////////////////////////////////////////////////////
//// Offers
////////////////////////////////////////////////////

export const add_proposal_serv = async(data) => {
    return await axios.post(end_point1+'addAppel',data);
}

export const get_proposals = async(id,filterCategorie,searchFilter,searchValue,page) => {
    return await axios.get(end_point1+'appelOffreService/'+id+'/'+filterCategorie+'/'+searchFilter+'/'+searchValue+"?page="+page);
}

export const update_proposal_serv = async(data) => {
    return await axios.post(end_point1+'editAppel',data);
}

export const delete_proposal_serv = async(data) => {
    return await axios.post(end_point1+'deleteAppel',data);
}

export const search_proposal_serv = async(data) => {
    return await axios.post(end_point1+'searchAppel',data);
}

export const get_suppliers_by_categorie = async(id_categorie) => {
    return await axios.get(end_point1+'fournisseursParCategorie/'+id_categorie);
}

export const download_proposal_files = async(data) => {
    return await axios({
        url: end_point1+'downloadProposalFiles',
        method:'POST',
          data:data,
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/pdf',
          },
      });
}

export const rate_supplier_proposel = async(data) => {
    return await axios.post(end_point1+'rate_supplier_proposel',data);
}

export const accord_supplier_proposel_document = async(data) => {
    return await axios.post(end_point1+'accord_supplier_proposel_document',data);
}

export const upload_complement_docs = async(data) => {
    return await axios.post(end_point1+'upload_complement_docs',data);
}


export const download_complement_docs = async(data) => {
    return await axios({
        url: end_point1+'download_complement_docs',
        method:'POST',
          data:data,
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/pdf',
          },
      });
}

export const send_complements_via_email = async(data) => {
    return await axios.post(end_point1+'send_complements_via_email',data);
}

export const accord_supplier_soumission = async(data) => {
    return await axios.post(end_point1+'accord_supplier_soumission',data);
}


export const get_submitted_offers = async(id_service,filterCategorie,searchFilter,searchValue,page) => {
    return await axios.get(end_point1+'get_submitted_offers/'+id_service+'/'+filterCategorie+'/'+searchFilter+'/'+searchValue+"?page="+page);
}

export const get_offer_submissions = async(data,page) => {
    return await axios.post(end_point1+'listDemandeAppels2'+"?page="+page,data);
}

export const downloadDocAdminServ = async(data) => {
    return await axios(
        {   url: end_point1+'downloadDocAdmin',
            method:'POST',
            data:data,
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/pdf',
            },
        });
}

export const downloadDocTechServ = async(data) => {
    return await axios(
        {   url: end_point1+'downloadDocTech',
            method:'POST',
            data:data,
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/pdf',
            },
        });
}

export const downloadDocFinanceServ = async(data) => {
    return await axios(
        {   url: end_point1+'downloadDocFinance',
            method:'POST',
            data:data,
            responseType: 'blob',
            headers: {
                'Content-Type': 'application/pdf',
            },
        });
}


////////////////////////////////////////////////////
//// OfferContract
////////////////////////////////////////////////////

export const getContrats_by_service = async(id_service,filter,page) => {
    if(filter) return await axios.get(end_point1+'getContrats_by_service/'+id_service+"/"+filter+"?page="+page);
    else return await axios.get(end_point1+'getContrats_by_service/'+id_service+"/recent"+"?page="+page);
}
export const save_contract_content = async(data) => {
    return await axios.post(end_point1+'save_contract_content',data);
}

export const contract_check = async(id_submission,contract_subtype) => {
    return await axios.get(end_point1+'contract_check/'+id_submission+"/"+contract_subtype);
}

export const notify_supplier_with_contractContent = async(data) => {
    return await axios.post(end_point1+'notify_supplier_with_contractContent',data);
}

export const contract_is_being_edited = async(id_submission,data) => {
    return await axios.post(end_point1+'contract_is_being_edited/'+id_submission,data);
}

export const accept_contract_terms = async(data) => {
    return await axios.post(end_point1+'accept_contract_terms_byservice',data);
}


export const finalize_contract = async(data) => {
    return await axios.post(end_point1+'finalizeContract',data);
}

export const sign_contract = async(data) => {
    return await axios.post(end_point1+'sign_contract',data);
}
export const get_contract_last_content = async(id_submission,contract_subtype) => {
    return await axios.get(end_point1+'get_contract_last_content/'+id_submission+"/"+contract_subtype);
}

export const download_contractPDF = async(data) => {
    return await axios({
        url: end_point1+'download_contract_pdf',
        method:'POST',
          data:data,
          responseType: 'blob',
          headers: {
            'Content-Type': 'application/pdf',
          },
      });
}

export const upload_contract = async(data) => {
    return await axios.post(end_point1+'upload_contract',data);
}


////////////////////////////////////////////////////
//// Market_bills
////////////////////////////////////////////////////

export const getAll_purchaseService_market = async(id,filterCategorie,searchFilter,searchValue,page) => {
    return await axios.get(end_point1+'getAll_purchaseService_market/'+id+'/'+filterCategorie+'/'+searchFilter+'/'+searchValue+"?page="+page);
}

export const getAll_purchaseService_orders_bills = async(data,searchFilter,searchValue,page) => {
    return await axios.post(end_point1+'getAll_purchaseService_orders_bills/'+searchFilter+'/'+searchValue+"?page="+page,data);
}


////////////////////////////////////////////////////
//// Applicant_request
////////////////////////////////////////////////////


export const get_products_byCode_forPurchaseServ = async(data) => {
    return await axios.post(end_point1+'get_products_byCode_forPurchaseServ',data);
}

export const get_product_by_infos = async(data) => {
    return await axios.post(end_point1+'get_product_by_infos',data);
}

export const get_purchase_requests = async(data,purchaseRequest_type,searchFilter,searchValue,page) => {
    return await axios.post(end_point1+'get_purchase_requests/'+purchaseRequest_type+'/'+searchFilter+'/'+searchValue+"?page="+page,data);
}

export const get_applicant_request_details = async(data) => {
    return await axios.post(end_point1+'get_applicant_request_details',data);
}

export const get_order_infos_for_file = async(data) => {
    return await axios.post(end_point1+'get_order_infos_for_file',data);
}

export const notify_requester_with_orders = async(data) => {
    return await axios.post(end_point1+'notify_requester_with_orders',data);
}

export const change_applicant_purchase_requests_supplier = async(data) => {
    return await axios.post(end_point1+'change_applicant_purchase_requests_supplier',data);
}

export const service_change_off_catalogue_state_request = async(data) => {
    return await axios.post(end_point1+'service_change_off_catalogue_state_request',data);
}

export const notify_initially_suppliers_with_articles = async(data) => {
    return await axios.post(end_point1+'notify_initially_suppliers_with_articles',data);
}

export const add_quote_to_orderRequest = async(data) => {
    return await axios.post(end_point1+'add_quote_to_orderRequest',data);
}

export const service_change_order_state = async(data) => {
    return await axios.post(end_point1+'service_change_order_state',data);
}

export const service_confirm_with_supplier = async(data) => {
    return await axios.post(end_point1+'service_confirm_with_supplier',data);
}

export const add_product = async(data) => {
    return await axios.post(end_point1+'add_product',data);
}


//////////////////////////////////////////////////////////////////////////////////////////////////
////////////////////////////MainPage


export const get_latest_suppliers = async(time_range) => {
    return await axios.get(end_point1+'latest_suppliers/'+time_range);
}

export const getlatest_offers = async(time_range) => {
    return await axios.get(end_point1+'latest_offers/'+time_range);
}


export const get_offers_opening_time = async(id) => {
    return await axios.get(end_point1+'get_offers_opening_time/'+id);
}

export const count_AllpurchaseService_markets_bills_forWidget = async(time_range) => {
    return await axios.get(end_point1+'count_AllpurchaseService_markets_bills_forWidget/'+time_range);
}

export const count_AllpurchaseService_markets_contracts_forWidget = async(time_range) => {
    return await axios.get(end_point1+'count_AllpurchaseService_markets_contracts_forWidget/'+time_range);
}

export const delay_between_offerCreation_awardedContract = async(time_range) => {
    return await axios.get(end_point1+'delay_between_offerCreation_awardedContract/'+time_range);
}

export const count_allOffers_byCategorie_widget = async(time_range) => {
    return await axios.get(end_point1+'count_allOffers_byCategorie_widget/'+time_range);
}

export const count_allSuppliers_byCategorie_widget = async(time_range) => {
    return await axios.get(end_point1+'count_allSuppliers_byCategorie_widget/'+time_range);
}


export const count_allSubmissions = async(time_range) => {
    return await axios.get(end_point1+'count_allSubmissions/'+time_range);
}

export const count_allAwardedOffers = async(time_range) => {
    return await axios.get(end_point1+'count_allAwardedOffers/'+time_range);
}

export const count_allOffers_total_amount = async(time_range) => {
    return await axios.get(end_point1+'count_allOffers_total_amount/'+time_range);
}

export const count_allAwardedOffers_total_amount = async(time_range) => {
    return await axios.get(end_point1+'count_allAwardedOffers_total_amount/'+time_range);
}

export const bestOffers_widgetCharts = async(time_range) => {
    return await axios.get(end_point1+'bestOffers_widgetCharts/'+time_range);
}

export const bestSuppliers_bySubmissions_widgetCharts = async(time_range,categorie) => {
    return await axios.get(end_point1+'bestSuppliers_bySubmissions_widgetCharts/'+categorie+'/'+time_range);
}

export const bestSuppliers_byAccord_widgetCharts = async(time_range,categorie) => {
    return await axios.get(end_point1+'bestSuppliers_byAccord_widgetCharts/'+categorie+'/'+time_range);
}

export const bestSuppliers_byNotes_widgetCharts = async(time_range,categorie) => {
    return await axios.get(end_point1+'bestSuppliers_byNotes_widgetCharts/'+categorie+'/'+time_range);
}

export const getrequests_with_catalogue_number_byState = async(time_range,state) => {
    return await axios.get(end_point1+'getrequests_with_catalogue_number_byState/'+state+'/'+time_range);
}

export const getrequests_without_catalogue_number_byState = async(time_range,state) => {
    return await axios.get(end_point1+'getrequests_without_catalogue_number_byState/'+state+'/'+time_range);
}

export const count_AllpurchaseService_orders_bills_forWidget = async(time_range) => {
    return await axios.get(end_point1+'count_AllpurchaseService_orders_bills_forWidget/'+time_range);
}

export const total_price_requests_with_catalogue_byState = async(id,time_range,state) => {
    return await axios.get(end_point1+'total_price_requests_with_catalogue_byState/'+id+"/"+time_range+"/"+state);
}

export const total_price_requests_without_catalogue_byState = async(id,time_range,state) => {
    return await axios.get(end_point1+'total_price_requests_without_catalogue_byState/'+id+"/"+time_range+"/"+state);
}

export const delay_between_delivery_supposedDate = async(id,time_range) => {
    return await axios.get(end_point1+'delay_between_delivery_supposedDate/'+id+"/"+time_range);
}

export const chart_of_the_bestSuppliers_for_orders = async(id,time_range) => {
    return await axios.get(end_point1+'chart_of_the_bestSuppliers_for_orders/'+id+"/"+time_range);
}
