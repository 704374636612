import './form_content.css'
import React,{useEffect,useState} from "react";
import { useHistory } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Form } from 'react-bootstrap';
import AddIcon from '@mui/icons-material/Add';
import Button from '@material-ui/core/Button';
import CancelIcon from '@material-ui/icons/Cancel';

import Dialog from '@material-ui/core/Dialog';
import CloseIcon from '@material-ui/icons/Close';
import AppBar from '@material-ui/core/AppBar';
import { IconButton, Toolbar } from '@material-ui/core';
import Loading_animation_view from '../../../loading_animation_view/loading_animation_view';
import { get_forms_models_admin, get_questions } from '../../../../services/company_admin_service';
import { Check } from '@material-ui/icons';
import { create_form, get_form_content } from '../../../../services/for_allServices';


const useStyles = makeStyles((theme) => ({
    root: {
      width: '100%',
    },
    paper: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
    table: {
      minWidth: 750,
    },
    visuallyHidden: {
      border: 0,
      clip: 'rect(0 0 0 0)',
      height: 1,
      margin: -1,
      overflow: 'hidden',
      padding: 0,
      position: 'absolute',
      top: 20,
      width: 1,
    },
}));




const ResponseText_question = ({question,id,set_question_response_value}) => {

    return (
        <div className="question_container">
            <div className="question_label_container"> {(id+1)+". "+question.label} <span style={{color:"red"}}>*</span> </div>
            <div className="question_content_container">
                <Form.Control as="textarea" placeholder="Réponse *" rows={2} 
                    value={question.response!=null && question.response!=""?question.response:""}
                    onChange={(e)=>set_question_response_value(question,id,e.target.value)} />
            </div>
        </div>
    );
}

const TrueFalse_question = ({question,id,set_question_response_value}) => {
    return (
        <div className="question_container">
            <div className="question_label_container">{(id+1)+". "+question.label} <span style={{color:"red"}}>*</span> </div>
            <div className="question_content_container">
                <Form.Check 
                    type="radio"
                    id={"True_"+id}
                    value="True"
                    checked={question.response=="True"?true:false}
                    name={"True/False_"+id}
                    label="Vrai"
                    onChange={(e)=>set_question_response_value(question,id,e.target.value)}
                />
                <Form.Check 
                    type="radio"
                    id={"False_"+id}
                    value="False"
                    checked={question.response=="False"?true:false}
                    name={"True/False_"+id}
                    label="Faux"
                    onChange={(e)=>set_question_response_value(question,id,e.target.value)}
                />
            </div>
        </div>
    );
}

const CheckBox_question = ({question,id,set_question_response_value}) => {

    return (
        <div className="question_container">
            <div className="question_label_container">{(id+1)+". "+question.label} <span style={{color:"red"}}>*</span> </div>
            <div className="question_content_container">
                {
                    (question.content.split(";")).map((elem,id_elem)=>(
                        <Form.Check 
                            multiple
                            type="checkbox"
                            id={id+"_"+id_elem}
                            key={id+"_"+id_elem}
                            name={"Checkbox_"+id}
                            checked={question.response?(question.response).split(';').includes(elem)?true:false:false}
                            value={elem}
                            label={elem}
                            onChange={(e)=>set_question_response_value(question,id,e.target)}
                        />
                    ))
                }
            </div>
        </div>
    )
}


const SelectChoice_question = ({question,id}) => {

    return (
        <div className="question_container">
            <div className="question_label_container">{(id+1)+". "+question.label} <span style={{color:"red"}}>*</span> </div>
            <div className="question_content_container">
                <Form.Select
                    required
                    name={"select_"+id}
                    defaultValue={"null"}
                >
                    <option value={"null"} disabled>Sélectionner</option>
                    <>
                    {
                        (question.content.split(";")).map((elem,id_elem)=>(
                            <option value={elem} key={"select_"+id+"_value_"+id_elem}>{elem}</option>
                        ))
                    }
                    </>
                </Form.Select>
            </div>
        </div>
    )
}


const Form_content = ()=>{

    const classes = useStyles();
    let history = useHistory();
    const [form_questions,setForm_questions] = useState(null);
    const [selected_form,setSelected_form] = useState(null);
    const [errorText, seterrorText] = useState(null);

    useEffect(()=>{
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);

        async function load_form_content(){
            let view_model_tmp = false;
            view_model_tmp = (urlParams.get('model')=="editor"?true:false);

            let selected_form_id_tmp = null;
            selected_form_id_tmp = (urlParams.get('id')?urlParams.get('id'):null);

            let selected_form_id_creator_tmp = null;
            selected_form_id_creator_tmp = (urlParams.get('id_creator')?urlParams.get('id_creator'):null);

            const forms_tmp = await get_forms_models_admin().then((response)=>{
                return response.data;
            }).catch((error)=>{
                //console.log(error.response);
            });

            const form_content_tmp = await get_form_content(selected_form_id_creator_tmp).then((response)=>{
                return response.data;
            });

            setSelected_form(form_content_tmp);
            setForm_questions(JSON.parse(form_content_tmp.content));
        }

        async function load_data(){
            let view_model_tmp = false;
            view_model_tmp = (urlParams.get('model')=="editor"?true:false);

            let selected_form_id_tmp = null;
            selected_form_id_tmp = (urlParams.get('id')?urlParams.get('id'):null);

            const forms_tmp = await get_forms_models_admin().then((response)=>{
                return response.data;
            }).catch((error)=>{
                //console.log(error.response);
            });

            let selected_form_tmp = null;
            if(selected_form_id_tmp!=null){
                forms_tmp.forEach(form => {
                    if(selected_form_id_tmp == form.id){
                        selected_form_tmp = form;
                        setSelected_form(form);
                    }
                });
            }

            if(selected_form_tmp !=null && selected_form_tmp.content!=""){
                let questions_tmp = await get_questions().then((response)=>{
                    return response.data;
                });
                let form_questions_tmp = [];

                let forms_questions_ids = selected_form_tmp.content.split(";");
                let key_forms_questions_ids = [];
                let response_value = {
                    response : null
                }
                forms_questions_ids.forEach((question_id)=>{
                    questions_tmp.forEach((elem,id2)=>{
                        if(question_id == elem.id){
                            elem = {
                                ...elem,
                                ...response_value
                              };
                            form_questions_tmp.push(elem);
                            key_forms_questions_ids.push(id2);
                        }
                    });
                });
                key_forms_questions_ids = key_forms_questions_ids.sort();
                if(view_model_tmp==true) await setForm_questions(form_questions_tmp);
                else setForm_questions([]);
            }else{
                setForm_questions([]);
            }

            console.log(selected_form);
        }

        //load_data();
        load_form_content();

    },[]);

    async function close_form_model(){
        let path = history.location.pathname;
        let params = history.location.search;
        let search_params = params.split('&');
        let tmp_params = path;
        for(let i in search_params){
            if(!search_params[i].includes("model")){
                if(i==0) tmp_params=tmp_params+search_params[i];
                else tmp_params=tmp_params+"&"+search_params[i];
            }
        }
        await setForm_questions(null);
        history.push(tmp_params);
        //setUrl_updated(history);
    }

    function set_question_response_value(question,id,returned_value){
        let form_questions_tmp = [...form_questions];
        if(question.question_type == 3){
            let returned_value_tmp = "";
            const values = document.getElementsByName('Checkbox_'+id);
            values.forEach((checkbox_elem) => {
                if(checkbox_elem.checked){
                    if(returned_value_tmp.length == 0) returned_value_tmp += checkbox_elem.value;
                    else returned_value_tmp += ";"+checkbox_elem.value;
                }
            })
            returned_value = returned_value_tmp;
        }
        form_questions[id].response = returned_value;
        setForm_questions(form_questions_tmp);
        console.log(form_questions_tmp);
    }


    function reset_data(){
        let form_questions_tmp = [...form_questions];
        for(let i=0; i<form_questions.length; i++){
            form_questions_tmp[i].response = null;
        }
        setForm_questions(form_questions_tmp);
        console.log(form_questions_tmp);
    }
    
    function submit_data(){
        let formData = new FormData();
        formData.append("form_model",selected_form.id);
        formData.append("id_creator",7);
        formData.append("content",JSON.stringify(form_questions));
        console.log(form_questions);
        create_form(formData).then((response)=>{
            console.log(response.data);
        }).catch((error)=>{
            //console.log(error.response);
        });
    }

    return (
    <Dialog disableEnforceFocus fullScreen open={form_questions?true:false} onClose={() => close_form_model()} className="my_full_dialog contract_full_dialog">
        <AppBar className={classes.appBar}  >
            <Toolbar style={{backgroundColor:'#343a40'}} >
                <div className='page_title'>
                    <IconButton edge="start" color="inherit" onClick={() => close_form_model()} aria-label="close" >
                        <CloseIcon className="my_icon" />
                    </IconButton>
                    <span> {"Formulaire"} </span>
                </div>
            </Toolbar>
        </AppBar>
        <div className="form_content_global_container">
            {
                form_questions==null || selected_form==null
                ?<Loading_animation_view />
                :<div className="form_content_container">
                    {
                        form_questions.map((question,id)=>{
                            if(question.question_type == 1) return ( <ResponseText_question question={question} id={id} set_question_response_value={set_question_response_value} key={"question_type_"+id} />)
                            else{
                                if(question.question_type == 2) return ( <TrueFalse_question question={question} id={id} set_question_response_value={set_question_response_value} key={"question_type_"+id} />)
                                else{
                                    if(question.question_type == 3) return ( <CheckBox_question question={question} id={id} set_question_response_value={set_question_response_value} key={"question_type_"+id} />)
                                    else{
                                        if(question.question_type == 4) return ( <SelectChoice_question question={question} id={id} set_question_response_value={set_question_response_value} key={"question_type_"+id} />)
                                    }
                                }
                            } return ""
                        })
                    }
                    <div className='dialogBottom'>
                        <div>
                            {
                                errorText === ""
                                ?<></>
                                :<p className={errorText ? "col-md-12 error" : "col-md-12 valid-champ"}>
                                        {errorText}
                                    </p>
                            }
                        </div>
                        <div>
                            <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon />} onClick={() => reset_data()} >
                                Réinitialiser
                            </Button>
                            <Button variant="contained" className="submit_but valide_but" startIcon={<Check />} onClick={submit_data} >
                                Valider
                            </Button>  
                        </div>
                        
                    </div>
                </div>
            }
        </div>
      </Dialog>
    );
}


export default Form_content;