import React, { useState } from 'react'
import './set_new_bill.css'
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import DownloadIcon from '@mui/icons-material/Download';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import TextField from '@material-ui/core/TextField';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import InputAdornment from '@material-ui/core/InputAdornment';
import swal from 'sweetalert';

import Fab from '@mui/material/Fab';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { submit_new_bill, submit_new_order_bill } from '../../../../services/supplier_service';




const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }));

function setDateTime(date){
    let day = date.toISOString();
    let day_comp = day.split(":");
    return day_comp[0]+":"+day_comp[1];
  }

function Set_new_bill({add_bill,setAdd_bill,id_submission,request_code,submit_new_bills,id_supplier}){

    const classes = useStyles();

    
    const [bill_content,setbill_content]=useState(null);
    const [reception_pv_doc,setreception_pv_doc]=useState(null);
    const [delivery_pv_doc,setdelivery_pv_doc]=useState(null);

    const [adminFileLoaded,setAdminFileLoaded]=useState();
    const [techFileLoaded,setTechFileLoaded]=useState();
    const [finFileLoaded,setFinFileLoaded]=useState();

    const [limiteDate,setLimiteDate]=useState("");

    const [errorText,setErrorText] = useState("");
    const [waiting, setWaiting] = useState(false);

    function DocNull(){
        setreception_pv_doc(null);
        setbill_content(null);
        setdelivery_pv_doc(null);
    }

    function upload_admin(event){
        setbill_content(event.target.files[0]);
        setAdminFileLoaded(URL.createObjectURL(event.target.files[0]));
    }

    function upload_tech(event){
        setreception_pv_doc(event.target.files[0]);
        setTechFileLoaded(URL.createObjectURL(event.target.files[0]));
    }

    function upload_fin(event){
        setdelivery_pv_doc(event.target.files[0]);
        setFinFileLoaded(URL.createObjectURL(event.target.files[0]));
    }


    function downloadSupplierBillsDocs(choix){
        var fileLink = document.createElement('a');
        if(choix==="bill"){
            fileLink.href = adminFileLoaded;
            fileLink.setAttribute('download', "facture");
            document.body.appendChild(fileLink);
            fileLink.click();
        }
        if(choix==="reception"){
            fileLink.href = techFileLoaded;
            fileLink.setAttribute('download', "pv_reception");
            document.body.appendChild(fileLink);
            fileLink.click();
        }
        if(choix==="delivery"){
            fileLink.href = finFileLoaded;
            fileLink.setAttribute('download', "pv_livraison");
            document.body.appendChild(fileLink);
            fileLink.click();
        }
    }

    function submit(){
        setErrorText('');
        if(bill_content==null || reception_pv_doc==null || delivery_pv_doc==null){
            setErrorText('Importez Tout Les Documents !!');
        }else{
            if(limiteDate===""){
                setErrorText('fixer la date limite !!');    
            }else{
                swal({
                    title: "Etes-vous sûr ?",
                    text: "!!!!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                .then((willDelete) => {
                  if (willDelete) {
                    setWaiting(true);
                    //console.log(request_code);
                    if(request_code===undefined){
                            let formData = new FormData();
                            formData.append('id_submission',id_submission);
                            formData.append('limit_date',limiteDate);
                            formData.append('bill_content',bill_content);
                            formData.append('reception_pv',reception_pv_doc);
                            formData.append('delivery_pv',delivery_pv_doc);
                            submit_new_bill(formData).then((response)=>{
                                setWaiting(false);
                                submit_new_bills(response.data,add_bill);
                                setAdd_bill(null);
                                DocNull();
                            }).catch((error)=>{
                                setWaiting(false);
                                //console.log(error.response);
                            })   
                    }else{
                            let formData = new FormData();
                            formData.append('request_code',request_code);
                            formData.append('id_supplier',id_supplier);
                            formData.append('limit_date',limiteDate);
                            formData.append('bill_content',bill_content);
                            formData.append('reception_pv',reception_pv_doc);
                            formData.append('delivery_pv',delivery_pv_doc);
                            submit_new_order_bill(formData).then((response)=>{
                                setWaiting(false);
                                submit_new_bills(response.data,add_bill);
                                setAdd_bill(null);
                                DocNull();
                            }).catch((error)=>{
                                setWaiting(false);
                                //console.log(error.response);
                            });  
                    }
                }})
            }
            
        }
        
    }


    return (
     <Dialog fullWidth={true} maxWidth={'md'} open={add_bill!=null?true:false} onClose={() => {setAdd_bill(null);setErrorText('');}} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">Importez Vos Document !!</DialogTitle>
        <DialogContent>
        <div className='documentsContainer_BillsPage'>
            <div className='documentsDownload'>
                <label htmlFor="bill_content"> 
                    <Fab size="small" color="default" component="span" >
                         <CloudUploadIcon className="my_icon" />
                      </Fab>
                        <input accept="application/pdf" className={classes.input} name="bill_content" id="bill_content" multiple type="file" onChange={(e)=>upload_admin(e)} />
                </label>
                   <Fab size="small" className={bill_content!=null?"":" disabled_button"} onClick={()=>downloadSupplierBillsDocs("bill")}><DownloadIcon  className="my_icon"  /></Fab>
                <span className='documentsTitle'>Facture</span>
            </div>
            <TextField
                type="date"
                label="Date limite" min={new Date()} className="header_form" inputProps={{min: setDateTime(new Date())}}
                InputProps={{ startAdornment: ( <InputAdornment position="start"> <EventBusyIcon  className="my_icon"  /> </InputAdornment> )}} onChange={(e)=>setLimiteDate(e.target.value)}
            />
            <div className='comp_docs_container'>
               <div className='documentsDownload'>
                  <label htmlFor="reception_pv_doc"> 
                     <Fab size="small" color="default" component="span" >
                         <CloudUploadIcon className="my_icon" />
                      </Fab>
                        <input accept="application/pdf" className={classes.input} name="reception_pv_doc" id="reception_pv_doc" multiple type="file" onChange={(e)=>upload_tech(e)} />
                  </label>
                    <Fab size="small" className={reception_pv_doc!=null?"":" disabled_button"} onClick={()=>downloadSupplierBillsDocs("reception")}><DownloadIcon  className="my_icon" /></Fab>
                    <span className='documentsTitle'>PV réception</span>
                </div>

                <div className='documentsDownload'>
                    <label htmlFor="delivery_pv_doc"> 
                        <Fab size="small" color="default" component="span" >
                            <CloudUploadIcon className="my_icon" />
                        </Fab>
                        <input accept="application/pdf" className={classes.input} name="delivery_pv_doc" id="delivery_pv_doc" multiple type="file" onChange={(e)=>upload_fin(e)} />
                    </label>
                    <Fab size="small" className={delivery_pv_doc!=null?"":" disabled_button"} onClick={()=>downloadSupplierBillsDocs("delivery")}><DownloadIcon  className="my_icon"  /></Fab>
                    <span className='documentsTitle'>PV livraison</span>
                </div> 
            </div>
            
            
        </div>
        </DialogContent>
        <DialogActions>
            <div className='dialogBottom'>
                <div>
                    {
                        errorText === "" 
                        ?<></>
                        :<p className={errorText !== "" ? "col-md-10 error" : "col-md-10 valid-champ"}>
                                {errorText}
                            </p>
                    }
                </div>
                <div>
                    <Button variant="contained" className="submit_but cancel_but" startIcon={<ClearIcon className="my_icon" />} onClick={()=>{setAdd_bill(null);DocNull();setErrorText('');}} >
                        Annuler
                    </Button>
                    <Button variant="contained" className="submit_but valide_but" startIcon={<DoneIcon className="my_icon" />} onClick={() => submit()} >
                        Déposer
                    </Button>
                </div>
            </div>
        </DialogActions>
        <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
    );
}

export default Set_new_bill
