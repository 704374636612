import './offers_requests_view.css';

import React, { useState , useEffect } from 'react';
import { useHistory } from "react-router-dom";

import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Fab from '@mui/material/Fab';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DownloadIcon from '@mui/icons-material/Download';
import Tooltip from '@mui/material/Tooltip';
import GroupIcon from '@mui/icons-material/Group';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "react-js-pagination";

import Loading_animation_view from '../../loading_animation_view/loading_animation_view'
import DemandeAppel_Dialog from './demandeAppel_View/demandeAppel_Dialog'
import Set_commission from './set_commissions_View/set_commissions_view'

import { get_submitted_offers,get_categories, download_proposal_files, update_proposal_serv } from '../../../services/purchase_service';
import { get_auditors_for_service } from '../../../services/auditor_service';




const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  input: {
    display: 'none',
  }
}));


const headCells = [
  { id: 'object', numeric: true, disablePadding: false, label: 'Object' },
  { id: 'categorie', numeric: true, disablePadding: false, label: "Famille d'achat" },
  { id: 'dateOffre', numeric: true, disablePadding: false, label: 'Date offre' },
  { id: 'dateLimite', numeric: true, disablePadding: false, label: 'Date limite' },
  { id: 'dateOuverture', numeric: true, disablePadding: false, label: 'Date d\'ouverture des plis' },
  { id: 'estimation', numeric: true, disablePadding: false, label: 'Estimation' },
  { id: 'commission', numeric: true, disablePadding: false, label: 'Commission' },
  { id: 'pv', numeric: true, disablePadding: false, label: 'PV' },
  { id: 'visualiser', numeric: true, disablePadding: false, label: 'Visualiser' },
];


function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>

        {
          headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align='left'
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};


function CompareFinalDate_current(finalDate){
  let day = new Date(finalDate);
  let current_date = new Date();
  if(day.getTime() > current_date.getTime()) return false;
  else return true;
}



function Offers_requests_view() {

  let user=JSON.parse(localStorage.getItem('user-info'))
  let history = useHistory();
  const [url_updated , setUrl_updated] = useState(null);

  const classes = useStyles();
  const [categories, setCategories] = React.useState([]);

  const [DemandeList, setDemandeList] = useState(null);
  const [offerClicked,SetofferClicked]=useState(null);

  const [waiting, setWaiting] = useState(false);
  const [open, setOpen] = useState(false);

  const [auditors, setAuditors] = useState(null);
  const [openCommission, setOpenCommission] = useState(false);

  const [filter_categorie,setFilter_categorie]=useState("all");
  const [search_value,setsearch_value]=useState("");
  const [search_filter,setsearch_filter]=useState('all');

  const [currentPage,setCurrentPage]=useState(1);
  const [dataPerPage,setdataPerPage] =useState(6);
  const [data_count,setdata_count]=useState(0);

  const handleClose = () => {
    setOpen(false);
    SetofferClicked(null);
  };

  const handleCloseCommission = () => {
    setOpenCommission(false);
    SetofferClicked(null);
    setAuditors(null);
  };

  useEffect(()=>{
    setDemandeList(null);
    setdata_count(0);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var tmp_searchFilter ="all";
    var tmp_searchValue = null;
    var tmp_filterCategorie = "all";
    async function load_data(){
      if(urlParams.get('filtre') && urlParams.get('valeur')){
        tmp_searchFilter = urlParams.get('filtre');
        tmp_searchValue = urlParams.get('valeur');
      }if(urlParams.get('categorie')){
        tmp_filterCategorie = urlParams.get('categorie');
      }
      await setsearch_filter(tmp_searchFilter);
        await setsearch_value(tmp_searchValue);
        await setFilter_categorie(tmp_filterCategorie);

      await get_categories().then((response)=> {
        setCategories(response.data);
      }).catch((error)=>{
        //console.log(error.response);
      });
    
      let page = (urlParams.get('page')?urlParams.get('page'):1);
        await setCurrentPage(page)

      await get_submitted_offers(user.id,tmp_filterCategorie,tmp_searchFilter,tmp_searchValue,page)
        .then((response)=> {
          //console.log(response.data);
          setdata_count(response.data.total);
          setDemandeList(response.data.data);
        }).catch((error) => {
          //console.log(error.response);
        })      
    }
    
    load_data();

  },[url_updated,history.location]);
  

  function getCategorieById(id){
    var categorie="";
    categories.map((catg)=>{
      if(catg.id_categorie == id) categorie = catg.nom;
    })
    return categorie;
  }

  function load_commission_infos(row){
    setOpenCommission(true);
    SetofferClicked(row);
    get_auditors_for_service().then((response) => {
      //console.log(response.data);
      setAuditors(response.data);
    }).catch((error) => {
        //console.log(error.response);
    });
  }

  

  function downloadProposalDocsForSupplier(choix,id_appel){
    const formData = new FormData();
    formData.append('id_appel',id_appel);
    formData.append('choix',choix);
    download_proposal_files(formData).then((response) => {
        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
        var fileLink = document.createElement('a');
        fileLink.href = fileURL;
        fileLink.setAttribute('download', id_appel+choix+".pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
    });
  }

  function upload_pv_docs(event,choice, id_appel,index){
    setWaiting(true);
    let formData = new FormData();
    formData.append(choice,event.target.files[0]);
    formData.append("id_appel",id_appel);
    update_proposal_serv(formData).then((response)=>{
      setWaiting(false);
      //console.log(response.data);
      const arr=[...DemandeList];
      arr[index]=response.data;
      setDemandeList(arr);
    }).catch((error)=>{
      //console.log(error.response);
    });
  }

  function search(){
    if(filter_categorie!=="all") history.push("/serviceAchat/soumissions?categorie="+filter_categorie+"&filtre="+search_filter+"&valeur="+search_value);
    else history.push("/serviceAchat/soumissions?filtre="+search_filter+"&valeur="+search_value);
    setUrl_updated(search_value);
  }


  function set__categorie(value){
    setFilter_categorie(value);
    if(value!=="all") history.push("/serviceAchat/soumissions?categorie="+value);
    else history.push("/serviceAchat/soumissions?categorie=all");
    setUrl_updated(value);
  }

  function set__filter(value){
    if(value==="all"){
      setsearch_filter(value);
      if(filter_categorie!=="all") history.push("/serviceAchat/soumissions?categorie="+filter_categorie+"&filtre="+value);
      else history.push("/serviceAchat/soumissions?filtre="+value);
      setUrl_updated(value);
    }else setsearch_filter(value);
  }


  async function change_page(page){
    await setCurrentPage(page);
    let path = history.location.pathname;
    let params = history.location.search;
    let search_params = params.split('&');
    let tmp_params = path;
    if(!params) history.push(path+"?page="+page);
    else{
      for(let i in search_params){
        if(search_params[i].includes("page=")){
          if(i==0) tmp_params=tmp_params+"?page="+page;
          else tmp_params=tmp_params+"&page="+page;
        }else{
          if(i==0) tmp_params=tmp_params+search_params[i];
          else tmp_params=tmp_params+"&"+search_params[i];
        }
      }
      if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
      history.push(tmp_params);
    }
    await setUrl_updated(page);
  }

  return (
    <>
      {   
        DemandeList==null
        ?<Loading_animation_view />
        : <div className="offresAppel_SubmitView" >
          <div className='three_forms_header_container' >
             <div className='page_title'>Soumissions</div>
              <div className='search_filter_container'>
                <FormControl  className="header_form" >
                      <InputLabel id="demo-simple-select-helper-label">Famille d'achat</InputLabel>
                      <Select required value={filter_categorie} 
                            onChange={e => {set__categorie(e.target.value) }} >
                            
                            <MenuItem value={"all"}>Toutes les familles d'achat</MenuItem>
                            {
                            categories.map((categorie,id)=>(
                                <MenuItem value={categorie.nom} key={id} >{categorie.nom}</MenuItem>
                            ))
                            }
                            
                      </Select>
                  </FormControl>

                  <FormControl className="header_form">
                    <InputLabel id="demo-simple-select-helper-label">Filtre</InputLabel>
                      <Select value={search_filter}  onChange={(e)=>{set__filter(e.target.value)}}>
                        <MenuItem value="all">Aucun</MenuItem>
                        <MenuItem value='Object'>Objet d'appel</MenuItem>
                        <MenuItem value='Direction'>Direction</MenuItem>
                      </Select>
                    </FormControl>

                      <Grid container spacing={1} alignItems="flex-start" className={search_filter==="all"?"search_bar disabled_button":"search_bar" } >
                        <Grid item>
                          <SearchIcon onClick={search} className={search_value===""?"my_icon disabled_button":"my_icon" } style={{cursor:"pointer"}}  />
                        </Grid>
                        <Grid>
                          <TextField label="Chercher" value={search_value} onChange={(e)=>{setsearch_value(e.target.value)}}/>
                        </Grid>
                      </Grid>
              </div>
        </div>
            <TableContainer>
              <Table className="submittions_table" aria-labelledby="tableTitle"  aria-label="enhanced table" >
                <TableHead>
                  <TableRow>
                      {
                          headCells.map((column) => (
                              <TableCell key={column.id} align="left">{column.label}</TableCell>
                          ))
                      }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {
                   Object.keys(DemandeList).length!==0
                    ?Object.keys(DemandeList).map((row, index) => (
                          <TableRow tabIndex={-1} key={index}>
    
                            <TableCell align="left" component="th" scope="row">
                              {DemandeList[row][0].object}
                            </TableCell>
                            <TableCell align="left">{getCategorieById(DemandeList[row][0].familleAchat)}</TableCell>
                            <TableCell align="left">{(new Date(DemandeList[row][0].dateOffre)).toLocaleString()}</TableCell>
                            <TableCell align="left">{(new Date(DemandeList[row][0].dateLimite)).toLocaleString()}</TableCell>
                            <TableCell align="left">{(new Date(DemandeList[row][0].dateOuvertureOffre)).toLocaleString()}</TableCell>
                            <TableCell align="center"><b>{DemandeList[row][0].estimation+" DH"}</b></TableCell>
                            <TableCell align="center">
                              {
                                CompareFinalDate_current(DemandeList[row][0].dateOuvertureOffre) != false
                                ?<Tooltip disableFocusListener disableTouchListener title="Date d'ouverture est dépassée !">
                                  <span>
                                    <Fab className="disabled_button" size="small" color="secondary" style={{backgroundColor:'rgb(41,189,141)',color:"white"}} disabled>
                                      <GroupIcon className="my_icon" />
                                    </Fab>
                                  </span>
                                  
                                </Tooltip>
                                :<Fab onClick={()=>{load_commission_infos(DemandeList[row][0])}} size="small" color="secondary" style={{backgroundColor:'rgb(41,189,141)'}}>
                                    <GroupIcon className="my_icon" />
                                  </Fab>
                              }
                            </TableCell>
                            <TableCell align="center">
                                <div className='documentsContainer'>
                                  <div style={{display:'flex',marginBottom:'10px'}}>
                                    <div className='documentsDownload'>
                                      {
                                      CompareFinalDate_current(DemandeList[row][0].dateOuvertureOffre) == false
                                      ?<Tooltip htmlFor={"pvO"+DemandeList[row][0].id_demande} title="Date d'ouverture pas encore atteinte !">
                                        <label htmlFor={"pvO"+DemandeList[row][0].id_demande}> 
                                           <Fab size="small" color="default" component='span' className={CompareFinalDate_current(DemandeList[row][0].dateOuvertureOffre) == false  ? "disabled_button" : ""}><CloudUploadIcon className="my_icon"  /></Fab>
                                            { 
                                              CompareFinalDate_current(DemandeList[row][0].dateOuvertureOffre) == false ? <></>
                                              :<input accept="application/pdf" className={classes.input} name={"pvO"+DemandeList[row][0].id_demande} id={"pvO"+DemandeList[row][0].id_demande} multiple type="file" onChange={(e)=>upload_pv_docs(e,"pvOuverture",DemandeList[row][0].id_appel,index)}  />
                                            }
                                        </label>
                                        </Tooltip>
                                        :<label htmlFor={"pvO"+DemandeList[row][0].id_demande}> 
                                            <Fab size="small" color="default" component='span' className={CompareFinalDate_current(DemandeList[row][0].dateOuvertureOffre) == false  ? "disabled_button" : ""}><CloudUploadIcon className="my_icon"  /></Fab>

                                            { 
                                              CompareFinalDate_current(DemandeList[row][0].dateOuvertureOffre) == false ? <></>
                                              :<input accept="application/pdf" className={classes.input} name={"pvO"+DemandeList[row][0].id_demande} id={"pvO"+DemandeList[row][0].id_demande} multiple type="file" onChange={(e)=>upload_pv_docs(e,"pvOuverture",DemandeList[row][0].id_appel,index)}  />
                                            }
                                        </label>
                                       }
                                          <Fab size="small" className={DemandeList[row][0].pvOuverture == null  ? "disabled_button" : ""} onClick={()=>downloadProposalDocsForSupplier("pvOuverture",DemandeList[row][0].id_appel)} color="default"><DownloadIcon className="my_icon" /></Fab>
                                          <span className='documentsTitle'>PV d'ouverture</span>
                                      </div>
                                   </div>
                                   <div style={{display:'flex',marginBottom:'10px'}}>
                                     <div className='documentsDownload'>
                                     {
                                      CompareFinalDate_current(DemandeList[row][0].dateOuvertureOffre) == false
                                      ?<Tooltip htmlFor={"pvR"+DemandeList[row][0].id_demande} title="Date d'ouverture pas encore atteinte !">
                                          <label htmlFor={"pvR"+DemandeList[row][0].id_demande}> 
                                            <Fab size="small" component='span' color="default" className={CompareFinalDate_current(DemandeList[row][0].dateOuvertureOffre) == false  ? "disabled_button" : ""}><CloudUploadIcon className="my_icon"  /></Fab>
                                            { 
                                            CompareFinalDate_current(DemandeList[row][0].dateOuvertureOffre) == false? <></>
                                            :<input accept="application/pdf" className={classes.input} name={"pvR"+DemandeList[row][0].id_demande} id={"pvR"+DemandeList[row][0].id_demande} multiple type="file" onChange={(e)=>upload_pv_docs(e,"pvResultat",DemandeList[row][0].id_appel,index)}  />
                                            }
                                          </label>
                                       </Tooltip>
                                       :<label htmlFor={"pvR"+DemandeList[row][0].id_demande}>
                                          <Fab size="small" component='span' color="default" className={CompareFinalDate_current(DemandeList[row][0].dateOuvertureOffre) == false  ? "disabled_button" : ""}><CloudUploadIcon className="my_icon"  /></Fab>
                                          { 
                                          CompareFinalDate_current(DemandeList[row][0].dateOuvertureOffre) == false? <></>
                                          :<input accept="application/pdf" className={classes.input} name={"pvR"+DemandeList[row][0].id_demande} id={"pvR"+DemandeList[row][0].id_demande} multiple type="file" onChange={(e)=>upload_pv_docs(e,"pvResultat",DemandeList[row][0].id_appel,index)}  />
                                          }
                                        </label>
                                      }
                                          <Fab size="small" color="default" className={DemandeList[row][0].pvResultat == null  ? "disabled_button" : ""} onClick={()=>downloadProposalDocsForSupplier("pvResultat",DemandeList[row][0].id_appel)} ><DownloadIcon className="my_icon"  /></Fab>
                                          <span className='documentsTitle'>PV resultat</span>
                                     </div>
                                    </div>
                                </div>
                            </TableCell>
                            <TableCell align="center">
                             {
                              CompareFinalDate_current(DemandeList[row][0].dateOuvertureOffre) == false
                              ?<Tooltip disableFocusListener disableTouchListener title="Date d'ouverture pas encore atteinte !">
                                <span>
                                  <VisibilityIcon  className="my_icon editIcon disabled_button" />
                                </span>
                                
                              </Tooltip>
                              :<VisibilityIcon  className="my_icon editIcon" onClick={()=>{setOpen(true);SetofferClicked(DemandeList[row][0]);}}  />
                             }
                            </TableCell>
                          </TableRow>
                        ))
                      :<div style={{margin:"10px", textAlign:"center",alignContent:"center"}}>Aucune soumission trouvée</div>
                    }
                </TableBody>
              </Table>
            </TableContainer>
             {
                DemandeList.length!==0
                  ?<div className="pagination_container">
                      <Pagination
                          activePage={Number(currentPage)}
                          itemsCountPerPage={dataPerPage}
                          totalItemsCount={Number(data_count)}
                          pageRangeDisplayed={4}
                          onChange={change_page.bind(this)}
                      />
                  </div>
                  :<></>
              }
          {
            offerClicked!=null && open==true
            ?<DemandeAppel_Dialog open={open} onClose={handleClose} handleClose={handleClose} setOpen={setOpen} offerClicked={offerClicked} />
            :<></>
          }
    
          {
            auditors!=null && openCommission==true
            ?<Set_commission openCommission={openCommission} handleCloseCommission={handleCloseCommission} auditors={auditors} offre={offerClicked} profilInfo={user}/>
            :<></>
          }
    
    
    
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </div>
      }
    </>
    
   
  );
}
export default Offers_requests_view
