import './order_form.css';
import React, { useEffect,useState } from 'react';
import Button from '@material-ui/core/Button';
import Row from 'react-bootstrap/Row'
import Form from 'react-bootstrap/Form'
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import swal from 'sweetalert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { getPurchaseService_infos } from '../../../../services/purchase_service';
import Loading_animation_view from '../../../loading_animation_view/loading_animation_view';
import { add_purchase_request, get_some_purchaseServiceInfos_by_idSubmission } from '../../../../services/for_allServices';


function Order_form({setProceed_order,setSelected_products_details,selected_products,setSelected_products}) {

    let user=JSON.parse(localStorage.getItem('user-info'))
    const id_service=user.id;
    const [serviceInfo,setserviceInfo]=useState(null);


    const [firstName,setFirstName]=useState('');
    const [lastName,setLastName]=useState('');
    const [departement,setDepartement]=useState('');

    const [email,setEmail]=useState('');
    const [phoneNumber,setPhoneNumber]=useState('');

    const [country,setCountry]=useState('');
    const [city,setCity]=useState('');
    const [address,setAddress]=useState('');
    const [postCode,setPostCode]=useState('');
    const [supposedDelivery_date,setSupposedDelivery_date]=useState('');

    const [object,setObject]=useState('');
    const [project,setProject]=useState('');

    const [errorText,setErrorText] = useState(null);
    const [errorEmail,setErrorEmail] = useState(null);
    const [waiting, setWaiting] = useState(false);  

    useEffect(()=>{
        getPurchaseService_infos(user.id)
            .then((response)=> {
              //console.log(response.data);
              setFirstName(response.data.nom.split(' ')[0]);
              setLastName(response.data.nom.replace((response.data.nom.split(' ')[0])+' ', ''));
              setEmail(response.data.email);
              setCountry(response.data.Pays);
              setCity(response.data.Ville);
              setAddress(response.data.Adresse);
              setPostCode(response.data.CodePostal);
              setPhoneNumber(response.data.tel);
              setserviceInfo(response.data);
          }).catch((error)=>{
            //console.log(error.response);
          });

      },[]);

    //////////////////////////////
    /////////////email validation
    function invalid_email_pattern(email){
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if(email!==""){
            if (!pattern.test(email)) {
                return true ;
            }else{
                return false ;
            }
        }else return true;
    }

    function submit(){
        setErrorText(null);
        setErrorEmail(null);
        if(firstName==="" || lastName==="" || departement==="" || email==="" || phoneNumber===""
            || country==="" || city==="" || address==="" || postCode==="" || supposedDelivery_date==="" || object===""){
            setErrorText("Champs invalid !");
        }else{
            if(invalid_email_pattern(email)){
                setErrorEmail("Email invalid !");
            }else{
                swal({
                    title: "Etes-vous sûr ?",
                    text: "!!!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                .then((willDelete) => {
                  if (willDelete) {
                    let formData = new FormData();
                    formData.append('requester_firstName',firstName);
                    formData.append('requester_lastName',lastName);
                    formData.append('requester_departement',departement);
                    formData.append('requester_email',email);
                    formData.append('requester_phoneNumber',phoneNumber);
                    formData.append('delivery_country',country);
                    formData.append('delivery_city',city);
                    formData.append('delivery_address',address);
                    formData.append('delivery_postCode',postCode);
                    formData.append('delivery_supposedDate',supposedDelivery_date);
                    formData.append('object',object);
                    formData.append('requested_by',id_service);
                    formData.append('products',JSON.stringify(selected_products));
                    setWaiting(true);
                    add_purchase_request(formData).then((response) => {
                        //console.log(response.data);
                        sessionStorage.setItem('products_selected',JSON.stringify({}));
                        window.dispatchEvent(new Event("storage"));
                        swal("votre demande d'achat a été sauvegardée avec succès !", {
                            icon: "success",
                            });
                        setSelected_products({})
                        setSelected_products_details({});
                        setProceed_order(false);
                        setWaiting(false);
                        sessionStorage.clear();
                    }).catch((error) => {
                        setWaiting(false);
                        //console.log(error.response);
                    });
                  }});
            }
        }
    }

//////////////////////
/////////////////////////////////////////////////////////
///////////////////////////////////

  return (
    <>
      {
        serviceInfo===null
        ?<Loading_animation_view />
        :<div className="order_form_page_container">   
        <div className="order_form_container">   
          <div className="order_form_title">Demande d'achat</div>
          <div className="order_form_infos_container">
            <div className='order_form_infos_title'> 1 - Infos personnel</div>
            <Row className="mb-12">
                  <Form.Group className="col-6">
                      <label>Nom</label>
                      <Form.Control
                          required
                          autoComplete
                          type="text"
                          placeholder="Nom *"
                          value={lastName}
                          onChange={ e =>  {setLastName(e.target.value)}}
                          className={ errorText && lastName==="" ? "is-invalid":""}
                      />
                  </Form.Group>
                  <Form.Group className="col-6">
                      <label>Prénom</label>
                      <Form.Control
                          required
                          autoComplete
                          type="text"
                          placeholder="Prénom *"
                          value={firstName}
                          onChange={ e =>  {setFirstName(e.target.value)}}
                          className={ errorText && firstName==="" ? "is-invalid":""}
                      />
                  </Form.Group>
                  <Form.Group className="col-6">
                      <label>Département</label>
                      <Form.Control
                          required
                          autoComplete
                          type="text"
                          placeholder="Département *"
                          value={departement}
                          onChange={ e =>  {setDepartement(e.target.value)}}
                          className={ errorText && departement===""  ? "is-invalid":""}
                      />           
                  </Form.Group>
                  <Form.Group className="col-6"> </Form.Group>
                  <Form.Group className="col-6">
                      <label>Email</label>
                      <Form.Control
                          required
                          autoComplete
                          type="email"
                          placeholder="Email *"
                          value={email}
                          onChange={ e =>  {setEmail(e.target.value)}}
                          className={ errorText && email==="" ? "is-invalid":""}
                      />
                  </Form.Group>
                  <Form.Group className="col-6">
                      <label>Numéro de téléphone</label>
                      <Form.Control
                          required
                          autoComplete
                          type="text"
                          placeholder="Numéro de téléphone *"
                          value={phoneNumber}
                          onChange={ e =>  {setPhoneNumber(e.target.value)}}
                          className={ errorText && phoneNumber==="" ? "is-invalid":""}
                      />
                  </Form.Group>
            </Row>
            <div className='order_form_infos_title'>2 - Infos de livraison</div>
            <Row className="mb-12">
                  <Form.Group className="col-6">
                      <label>Pays</label>
                      <Form.Control
                          required
                          autoComplete
                          type="text"
                          placeholder="Pays *"
                          value={country}
                          onChange={ e =>  {setCountry(e.target.value)}}
                          className={ errorText && country==="" ? "is-invalid":""}
                      />
                  </Form.Group>
                  <Form.Group className="col-6">
                      <label>Ville</label>
                      <Form.Control
                          required
                          autoComplete
                          type="text"
                          placeholder="Ville *"
                          value={city}
                          onChange={ e =>  {setCity(e.target.value)}}
                          className={ errorText && city==="" ? "is-invalid":""}
                      />
                  </Form.Group>
                  <Form.Group className="col-6">
                      <label>Adresse</label>
                      <Form.Control
                          required
                          autoComplete
                          type="text"
                          placeholder="Adresse *"
                          value={address}
                          onChange={ e =>  {setAddress(e.target.value)}}
                          className={ errorText && address===""  ? "is-invalid":""}
                      />           
                  </Form.Group>
                  <Form.Group className="col-6">
                      <label>Code postal</label>
                      <Form.Control
                          required
                          autoComplete
                          type="text"
                          placeholder="Code postal *"
                          value={postCode}
                          onChange={ e =>  {setPostCode(e.target.value)}}
                          className={ errorText && postCode===""  ? "is-invalid":""}
                      />           
                  </Form.Group>
  
                  <Form.Group className="col-6">
                      <label>Date de livraison souhaitée</label>
                      <Form.Control
                          type="date"
                          min={(new Date()).toISOString().split('T')[0]}
                          placeholder="Date de livraison souhaitée *"
                          value={supposedDelivery_date}
                          onChange={ e =>  {setSupposedDelivery_date(e.target.value)}}
                          className={ errorText && supposedDelivery_date===""  ? "is-invalid":""}
                      />
                  </Form.Group>
            </Row>
            <div className='order_form_infos_title'>3 - Autres informations</div>
            <Row className="mb-12">
                  <Form.Group className="col-md-12">
                      <label>Projet</label>
                      <Form.Control
                          required
                          autoComplete
                          type="text"
                          rows={3}
                          placeholder="Projet *"
                          value={project}
                          onChange={ e =>  {setProject(e.target.value)}}
                          disabled
                      />
                  </Form.Group>
                  <Form.Group className="col-md-12">
                      <label>Objet de la demande</label>
                      <Form.Control
                          required
                          autoComplete
                          as="textarea" rows="3" 
                          placeholder="Objet de la demande *"
                          value={object}
                          onChange={ e =>  {setObject(e.target.value)}}
                          className={ errorText && object==="" ? "is-invalid":""}
                      />
                  </Form.Group>
                  {
                      <p className={errorText || errorEmail ? "col-md-11 error" : "col-md-11 valid-champ"} style={{margin:'auto'}}>
                              {errorText || errorEmail}
                      </p>
                  }
            </Row>
          </div>
        </div>
        <div className="order_form_page_buttons_container">
              <Button variant="contained" className="submit_but return_button" startIcon={<NavigateBeforeIcon className="my_icon" />} onClick={() => setProceed_order(false)}> 
                      Retour
              </Button>
              <Button variant="contained" className={"submit_but"} endIcon={<NavigateNextIcon className="my_icon" />} onClick={submit}> 
                      Valider
              </Button>
          </div>
      </div>
    }
    <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
    </>
  );
}

export default Order_form;
