import React, { useState,useEffect} from 'react'
import './add_proposal.css'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import PaymentIcon from '@material-ui/icons/Payment';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import LabelIcon from '@material-ui/icons/Label';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {makeStyles} from '@material-ui/core/styles';
import DownloadIcon from '@mui/icons-material/Download';
import Fab from '@mui/material/Fab';


import { get_suppliers_by_categorie,add_proposal_serv, get_categories, update_proposal_serv, download_proposal_files } from '../../../../services/purchase_service';
import Set_supplier from '../set_suppliers/set_supplier';


const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }));



function setDateTime(date){
    let day = date.toISOString();
    let day_comp = day.split(":");
    return day_comp[0]+":"+day_comp[1];
}
  

function getFileName(event) {
    const name = event.target.files[0].name;
    const lastDot = name.lastIndexOf('.');

    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);
    return fileName+"."+ext;
}
  


function Update_proposal({handleClose,open,chosenOffer,update,dateToday,clickOffre,offers,setOffers,ProfilInfo}){

    const classes = useStyles();
    let user = JSON.parse(localStorage.getItem('user-info'));

    const [formData, setFormData] = useState(null);
    const [valide, setValid] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [nextStep, setNextStep] = useState(false);

    const [ObjectEdit,setObjectEdit]=useState("");
    const [DateOffreEdit,setDateOffreEdit]=useState("");
    const [DateLimiteEdit,setDateLimiteEdit]=useState("");
    const [dateOuvertureOffreAdd,setdateOuvertureOffreEdit]=useState("");
    const [EstimationEdit,setEstimationEdit]=useState("");
    const [DirectionEdit,setDirectionEdit]=useState("");
    const [FournisseursEdit,setFournisseursEdit]=useState("");

    const [cahierChargeEdit,setcahierChargeEdit]=useState(null);
    const [rcEdit,setrcEdit]=useState(null);
    const [complementDocsEdit,setcomplementDocsEdit]=useState(null);

    const [cahierChargeLoaded,setcahierChargeLoaded]=useState("");
    const [rcLoaded,setrcLoaded]=useState("");
    const [complementDocsLoaded,setcomplementDocsLoaded]=useState("");

    const [cahierChargeFile,setcahierChargeFile]=useState("");
    const [rcFile,setrcFile]=useState("");
    const [complementDocsFile,setcomplementDocsFile]=useState("");

    const [categories,setcategories] = useState([]);
    const [categorie, setcategorie] = useState(null);
    const [suppliers, setSuppliers] = useState([]);

    const [errorText,setErrorText] = useState("");

    useEffect(()=>{
        get_categories().then((response)=>{
            //console.log(response.data);
            setcategories(response.data);
        }).catch((error)=>{
            console.log(error);
        });

        if(chosenOffer.cahierCharge !== "null") setcahierChargeFile(chosenOffer.cahierCharge);
        if(chosenOffer.rc !== "null") setrcFile(chosenOffer.rc);
        if(chosenOffer.complementDocs !== "null") setcomplementDocsFile(chosenOffer.complementDocs);

        setObjectEdit(chosenOffer.object);
        setDateOffreEdit(chosenOffer.dateOffre);
        setDateLimiteEdit(chosenOffer.dateLimite);
        setdateOuvertureOffreEdit(chosenOffer.dateOuvertureOffre);
        setEstimationEdit(chosenOffer.estimation);
        setDirectionEdit(chosenOffer.direction);
        setcategorie(chosenOffer.familleAchat)

    },[])

    function validated_elem(){
        return submitted && valide;
    }

    async function update_proposal() {
        setSubmitted(true);
        setValid(true);
        setErrorText("");

        if (ObjectEdit==="" ||DateOffreEdit==="" ||DateLimiteEdit==="" 
         ||EstimationEdit==="" || DirectionEdit==="" || categorie=="" ) {
             setErrorText("Champs invalides !");
             setValid(false);
        }else{
            let tmp_form = new FormData();
            tmp_form.append('id_appel',chosenOffer.id_appel);
            tmp_form.append('id_service',user.id);
            tmp_form.append('NomTuteurEdit',ProfilInfo.nom);
            tmp_form.append('ObjectEdit',ObjectEdit);
            tmp_form.append('DateOffreEdit',DateOffreEdit);
            tmp_form.append('DateLimiteEdit',DateLimiteEdit);
            tmp_form.append('dateOuvertureOffreEdit',dateOuvertureOffreAdd);
            tmp_form.append('EstimationEdit',EstimationEdit);
            tmp_form.append('DirectionEdit',DirectionEdit);
            tmp_form.append('familleAchat',categorie);
            if(cahierChargeLoaded !=null) tmp_form.append('CahierChargeEdit',complementDocsEdit);
            if(rcLoaded !=null) tmp_form.append('rcEdit',rcEdit);
            if(complementDocsLoaded !=null) tmp_form.append('complementDocsEdit',complementDocsEdit);

            setFormData(tmp_form);

            if(categorie == chosenOffer.familleAchat) setFournisseursEdit(chosenOffer.fournisseurs);

            get_suppliers_by_categorie(categorie).then((response)=>{
                //console.log(response.data);
                setSuppliers(response.data);
                if(response.data.length==0){
                    setErrorText("Aucun fournisseur disponible !");
                    setValid(false);
                }else setNextStep(true);
            }).catch((error)=>{
                //console.log(error.response);
            });
        }
        
     }

     function download(choix){
        if(cahierChargeLoaded!="" && choix=="cahierCharge"){
            var fileLink = document.createElement('a');
            fileLink.href = cahierChargeLoaded;
            fileLink.setAttribute('download', cahierChargeFile);
            document.body.appendChild(fileLink);
            fileLink.click();
        }else{
    
          if(rcLoaded!="" && choix=="rc"){
              var fileLink = document.createElement('a');
              fileLink.href = rcLoaded;
              fileLink.setAttribute('download', rcFile);
              document.body.appendChild(fileLink);
              fileLink.click();
          }else{
                if(complementDocsLoaded!="" && choix=="docsComplementaire"){
                    var fileLink = document.createElement('a');
                    fileLink.href = complementDocsLoaded;
                    fileLink.setAttribute('download', complementDocsFile);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }else{
                    const formData = new FormData();
                    formData.append('id_appel',chosenOffer.id_appel);
                    formData.append('choix',choix);
                    download_proposal_files(formData).then((response) => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', chosenOffer.id_appel+choix+".pdf");
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    }).catch((error) => {
                        //console.log(error.response);
                    }) 
            }
              
            }
        }
        
      }


      function upload_cahierCharge(event){
        setcahierChargeEdit(event.target.files[0]);
        setcahierChargeFile(getFileName(event));
        setcahierChargeLoaded(URL.createObjectURL(event.target.files[0]));
      }
    
      function upload_result(event){
        setrcEdit(event.target.files[0]);
        setrcFile(getFileName(event));
        setrcLoaded(URL.createObjectURL(event.target.files[0]));
      }

      function upload_complementDocs(event){
        setcomplementDocsEdit(event.target.files[0]);
        setcomplementDocsFile(getFileName(event));
        setcomplementDocsLoaded(URL.createObjectURL(event.target.files[0]));
      }
      

    return (
        <Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose} aria-labelledby="form-dialog-title" className="update_porpose" >
            <DialogTitle id="form-dialog-title">Modifier appel d'offre </DialogTitle>
            <DialogContent>
            <div className="inputs_container">
                <TextField label="Objet" defaultValue={chosenOffer.object} className="input_form"
                    InputProps={{ startAdornment: ( <InputAdornment position="start"> <LabelIcon className="my_icon" /> </InputAdornment> ), }} onChange={(e)=>setObjectEdit(e.target.value)}
                />
                <TextField label="Estimation (MAD) " type="number" defaultValue={chosenOffer.estimation} className="input_form"
                    InputProps={{ startAdornment: ( <InputAdornment position="start"> <PaymentIcon className="my_icon" /> </InputAdornment> ), }} onChange={(e)=>setEstimationEdit(e.target.value)}
                />
                <FormControl className="input_form" >
                    <InputLabel>Famille D'achat</InputLabel>

                        <Select onChange={(e)=>{setcategorie(e.target.value)}} defaultValue={chosenOffer.familleAchat}>
                            <MenuItem value="null" disabled>
                                <em>None</em>
                            </MenuItem>
                            {
                                categories.map((categorie) => (
                                    <MenuItem key={categorie.id_categorie} value={categorie.id_categorie}>{categorie.nom}</MenuItem>
                                ))
                            }
                        </Select>

                    <FormHelperText>choisissez votre Famille d'achat</FormHelperText>
                </FormControl>
            </div>
            <div className="inputs_container">
                <TextField
                        type="datetime-local"
                        label="Date offre" defaultValue={chosenOffer.dateOffre} className="input_form"
                        InputProps={{ startAdornment: ( <InputAdornment position="start"> <EventAvailableIcon className="my_icon" /> </InputAdornment> ), }} onChange={(e)=>setDateOffreEdit(e.target.value)}
                 />

                <TextField
                        type="datetime-local"
                        label="Date limite"  defaultValue={chosenOffer.dateLimite} className="input_form"
                        InputProps={{ startAdornment: ( <InputAdornment position="start"> <EventBusyIcon className="my_icon" /> </InputAdornment> ), }} onChange={(e)=>setDateLimiteEdit(e.target.value)}
                    />
                {
                    (DateLimiteEdit) != "" 
                    ?<TextField
                        disabled = { DateLimiteEdit==null ? true : false}
                        type="datetime-local"
                        defaultValue={dateOuvertureOffreAdd} label="Date d'ouverture des plis" className="input_form"  inputProps={{min: setDateTime(new Date(DateLimiteEdit))}}
                        InputProps={{ startAdornment: ( <InputAdornment position="start"> <EventBusyIcon className="my_icon" /> </InputAdornment> )}} onChange={(e)=>setdateOuvertureOffreEdit(e.target.value)}
                    />
                    :<TextField
                        disabled = {true}
                        type="datetime-local"
                        label="Date d'ouverture des plis" className="input_form"
                        InputProps={{ startAdornment: ( <InputAdornment position="start"> <EventBusyIcon className="my_icon" /> </InputAdornment> )}} onChange={(e)=>setdateOuvertureOffreEdit(e.target.value)}
                    />
                }
            </div>

            <div className="inputs_container">
                <FormControl className="input_form" >
                <InputLabel>Direction</InputLabel>
                <Select onChange={(e)=>{setDirectionEdit(e.target.value)}} defaultValue={chosenOffer.direction}>
                    <MenuItem value="null" disabled>
                    <em>None</em>
                    </MenuItem>
                    <MenuItem value="Direction juridique">Direction financiere</MenuItem>
                    <MenuItem value="Direction financiere">Direction juridique</MenuItem>
                    <MenuItem value="Direction informatique">Direction informatique </MenuItem>
                </Select>
                <FormHelperText>choisissez votre Direction</FormHelperText>
                </FormControl>
            </div>
            
            <div className='inputs_container'>
                <div className='documentsDownload'>
                    <label htmlFor="cahierCharge" style={{marginRight:'7px'}}> 
                        <Fab size="small" component='span' ><CloudUploadIcon className="my_icon"/> </Fab>
                        <input accept="application/pdf" className={classes.input} name="cahierCharge" id="cahierCharge" multiple type="file" onChange={(e)=>upload_cahierCharge(e)} />
                    </label>
                    {
                        cahierChargeFile!=""
                        ?<Fab size="small" onClick={()=>download('cahierCharge')} ><DownloadIcon className="my_icon" /> </Fab>
                        :<Fab size="small" className="disabled_button" ><DownloadIcon className="my_icon" /></Fab>
                    
                    }
                    <span className='documentsTitle'>Cahier De Charge </span>
                </div>

                <div className='documentsDownload'>
                    <label htmlFor="rc" style={{marginRight:'7px'}}> 
                        <Fab size="small" component='span'><CloudUploadIcon className="my_icon"/> </Fab>
                            <input accept="application/pdf" className={classes.input} name="rc" id="rc" multiple type="file" onChange={(e)=>upload_result(e)} />
                    </label>
                    {
                        rcFile!=""
                        ?<Fab size="small" onClick={()=>download('rc')} ><DownloadIcon className="my_icon"/> </Fab>
                        :<Fab size="small" className="disabled_button" ><DownloadIcon className="my_icon" /></Fab>
                    
                    }
                    <span className='documentsTitle'>RC</span>
                </div>

                <div className='documentsDownload'>
                    <label htmlFor="complementDocs"  style={{marginRight:'7px'}}>
                            <Fab size="small" component='span' ><CloudUploadIcon className="my_icon"/> </Fab>
                            <input accept="application/pdf" className={classes.input} name="complementDocs" id="complementDocs" multiple type="file" onChange={(e)=>upload_complementDocs(e)} />
                    </label>
                    {
                        complementDocsFile!=""
                        ?<Fab size="small" onClick={()=>download('docsComplementaire')} ><DownloadIcon className="my_icon"/> </Fab>
                        :<Fab size="small" className="disabled_button" ><DownloadIcon className="my_icon" /></Fab>
                    
                    }
                    <span className='documentsTitle'> Docs complémen... </span>
                </div>
                
            </div>
            </DialogContent>
            <DialogActions>
                <div className='dialogBottom'>
                    <div>
                        {
                            validated_elem() 
                            ?<></>
                            :<p className={submitted ? "col-md-10 error" : "col-md-10 valid-champ"}>
                                    {submitted ? errorText : ""}
                                </p>
                        }
                    </div>
                    <div>
                        <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon className="my_icon" />} onClick={handleClose} >
                            Annuler
                        </Button>
                        <Button variant="contained" className="submit_but valide_but" startIcon={<AddIcon className="my_icon" />} onClick={update_proposal} >
                            Suivant
                        </Button>  
                    </div>
                </div>
            </DialogActions>
            {
                nextStep == true?
                <Set_supplier fournisseursEdit={FournisseursEdit} handleClose={()=>{setNextStep(false);handleClose();}} update={update} open={nextStep} formData={formData} setFormData={setFormData} setOffers={setOffers} offers={offers} clickOffre={clickOffre} suppliers={suppliers}/>
                :<></>
            }
     </Dialog>
    )
}

export default Update_proposal
