import '../../../../../assets/css/catalogue_modal.css'
import '../../../../../assets/css/products_gallery.css'
import React, { useState,useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';

import empty from '../../../../empty.jpg'

import { get_categories } from '../../../../../services/purchase_service';
import Loading_animation_view from '../../../../loading_animation_view/loading_animation_view';
import { get_supplier_products, get_suppliers_by_categorie } from '../../../../../services/for_allServices';
import Product_details from './product_details/product_details';
import Card_products from './card_products/card_products';

function Products_gallery_modal({ changeSupplier, setChangeSupplier, setChangeSupplier_done }) {

  const [products,setProducts] = useState(null);

  const [categories,setCategories]=useState([]);
  const [suppliers,setSuppliers]=useState([]);

  const [filter_categorie,setFilter_categorie]=useState("all");
  const [filter_supplier,setFilter_supplier]=useState("null");
  const [search_value,setsearch_value]=useState("");

  const [view_details,setView_details] = useState(null);
  const [order_products,setOrder_products] = useState(null);
  const [view_cart,setView_cart] = useState(null);

  useEffect(()=>{
    get_categories().then((response)=> {
      //console.log(response.data);
      setCategories(response.data);
    }).catch((error)=>{
      //console.log(error.response);
    });
    if(filter_categorie !== "all"){
        get_suppliers_by_categorie(filter_categorie).then((response)=>{
            setSuppliers(response.data);
            setFilter_supplier(response.data[0].id);
            get_supplier_products(response.data[0].id,filter_categorie,"null").then((response)=>{
                setProducts(response.data.data);
            }).catch((error)=>{
                //console.log(error.response);
            })      
        }).catch((error)=>{
            //console.log(error.response);
        })
    }else setProducts([]);

  },[]);

    function search(){
        setProducts(null);
        get_supplier_products(filter_supplier,filter_categorie,search_value).then((response)=>{
            //console.log(response.data);
            setProducts(response.data);
        }).catch((error)=>{
            //console.log(error.response);
        })
    }

    function reset_search(){
        setProducts(null);
        setsearch_value("");
        get_supplier_products(filter_supplier,filter_categorie,"null").then((response)=>{
            //console.log(response.data);
            setProducts(response.data);
        }).catch((error)=>{
            //console.log(error.response);
        })
    }

    function set__supplier(value){
        setProducts(null);
        setFilter_supplier(value);
        setsearch_value("");
        get_supplier_products(value,filter_categorie,"null").then((response)=>{
            setProducts(response.data);
        }).catch((error)=>{
            //console.log(error.response);
        })
    }

    function set__categorie(value){
        setProducts(null);
        setFilter_categorie(value);
        setsearch_value("");
        get_suppliers_by_categorie(value).then((response)=>{
            setSuppliers(response.data);
            setFilter_supplier(response.data[0].id);
            get_supplier_products(response.data[0].id,value,"null").then((response)=>{
                setProducts(response.data);
            }).catch((error)=>{
                //console.log(error.response);
            })
        }).catch((error)=>{
            //console.log(error.response);
        });
    }

    function count_products_added_to_card(data){
        var count_tmp = 0;
        Object.keys(data).map((supplier) => {
          count_tmp += data[supplier]['products'].length;
        })
        return count_tmp;
      }

  return (
    <Dialog className="products_gallery_modal products_gallery_modal_container" fullWidth={true} maxWidth={'xl'}  open={changeSupplier?true:false} centered>
      <DialogTitle className='modal_header_container'>
            {
                view_details===null && view_cart===null
                ?<span onClick={()=>setChangeSupplier(null)} style={{cursor:'pointer'}}>
                    <CloseIcon className="my_icon" />
                </span>
                :<>
                    <span>
                      <span onClick={()=>setChangeSupplier(null)} style={{cursor:'pointer'}}>
                            <CloseIcon className="my_icon" />
                        </span>
                        <span onClick={()=>{view_details && !view_cart?setView_details(null):setView_cart(null)}} style={{cursor:'pointer',marginLeft:'8px'}}>
                            <ArrowBackIcon className="my_icon" />
                        </span>  
                    </span>
                    
                </>
            }
            <span className={count_products_added_to_card(order_products?order_products:[]) !== 0 ?'basket_container':'basket_container disabled_button'} onClick={() => setView_cart(order_products)} >
                <LocalGroceryStoreIcon className="my_icon" />
                {
                    count_products_added_to_card(order_products?order_products:[]) !== 0 
                    ?<div id="products_number"> {count_products_added_to_card(order_products?order_products:[])} </div>
                    :<></>
                }
            </span>
      </DialogTitle>
      <DialogContent>
        {
            view_details===null && view_cart===null
            ?<div className="products_gallery_modal_container">
            <div className="products_gallery_modal_header_container">
                <div className='search_filter_container'>
                    <FormControl variant="standard" className="header_form" >
                        <InputLabel id="demo-simple-select-helper-label">Famille d'achat</InputLabel>
                        <Select required value={filter_categorie} 
                            onChange={e => {set__categorie(e.target.value) }} >
                            
                            <MenuItem value={"all"} disabled>Familles d'achat</MenuItem>
                            {
                            categories.map((categorie,id)=>(
                                <MenuItem value={categorie.nom} key={id} >{categorie.nom}</MenuItem>
                            ))
                            }
                            
                        </Select>
                    </FormControl>
                    <FormControl variant="standard" className={filter_categorie==="all" || suppliers.length===0 ?"header_form disabled_button":"header_form" } >
                        <InputLabel id="demo-simple-select-helper-label">Fournisseurs disponible</InputLabel>
                        <Select required value={filter_supplier} 
                            onChange={e => {set__supplier(e.target.value) }} >
                            
                            <MenuItem value={"null"} disabled>Fournisseur -- Maison mère</MenuItem>
                            {
                            suppliers.map((supplier,id)=>(
                                <MenuItem value={supplier.id} key={id} >{supplier.nom+" -- "+supplier.maisonMere}</MenuItem>
                            ))
                            }
                            
                        </Select>
                    </FormControl>
                    <Grid container spacing={1} alignItems="flex-start" className="search_bar">
                        <Grid item>
                            <SearchIcon onClick={search} className={search_value===""?"my_icon disabled_button":"my_icon" } style={{cursor:"pointer"}}  />
                        </Grid>
                        <Grid>
                            <TextField label="Chercher"  value={search_value} onChange={(e)=>{setsearch_value(e.target.value)}} />
                        </Grid>
                        <Grid item style={{marginLeft:"5px"}}>
                            <CloseIcon onClick={reset_search} className={search_value===""?"my_icon disabled_button":"my_icon" }  style={{cursor:"pointer"}}  />
                        </Grid>
                    </Grid>
                    </div>
                </div>
                <div className="products_gallery_modal_contents_container">
                <>
                  {
                    products===null
                    ?<Loading_animation_view />
                    :<>{
                        products.length !== 0
                        ?<>
                        <div className="products_contents_container">
                            {
                                products.map((val,id) => {
                                return (
                                    <React.Fragment key={id}>
                                        <div className="main_card_container">
                                            <div className="main_card_img_container">
                                                <img src={val.product_pic!==""?`data:image/png;base64,${val.product_pic}`:empty}/>
                                            </div>
                                            <div className="main_card_infos_container">
                                                <div className="card_title">{val.title}</div>
                                                <div className="card_description">{val.description}</div>
                                                <div className="card_title">{val.product_price+" MAD"}</div>
                                                <div className="view_more_button_container">
                                                    <Button variant="contained" className="submit_but edit_but" onClick={() => {setView_details(val.product_code)}} >
                                                        Plus de détails
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </React.Fragment>
                            
                                    );
                                })
                            }
                        </div>
                        </>:<div className='emptytable_msg_container'>Aucun produit disponible</div>
                    }
                    </>    
                  }
                </>
                </div>
            </div>
            :view_cart===null 
              ?<Product_details view_details={view_details} order_products={order_products} setOrder_products={setOrder_products} />
             :<Card_products order_products={order_products} setOrder_products={setOrder_products} 
                        changeSupplier={changeSupplier} setChangeSupplier={setChangeSupplier} setChangeSupplier_done={setChangeSupplier_done} />
        }    
      </DialogContent>
    </Dialog>
  );
}

export default Products_gallery_modal;
