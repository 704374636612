import React, { useState,useEffect} from 'react'

import './add_proposal.css'
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import AddIcon from '@material-ui/icons/Add';
import CancelIcon from '@material-ui/icons/Cancel';
import PaymentIcon from '@material-ui/icons/Payment';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import EventBusyIcon from '@material-ui/icons/EventBusy';
import LabelIcon from '@material-ui/icons/Label';
import InputAdornment from '@material-ui/core/InputAdornment';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import {makeStyles} from '@material-ui/core/styles';

import { get_suppliers_by_categorie,add_proposal_serv, get_categories } from '../../../../services/purchase_service';
import Set_supplier from '../set_suppliers/set_supplier';
import { Fab } from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }));

function setDateTime(date){
    let day = date.toISOString();
    let day_comp = day.split(":");
    return day_comp[0]+":"+day_comp[1];
}
  

function Add_proposal({openAdd,handleCloseAdd,dateToday,offers,setOffers,ProfilInfo,update,setOffers_added}){

    const classes = useStyles();
    const [formData, setFormData] = useState(null);
    let user = JSON.parse(localStorage.getItem('user-info'));

    

    const [valide, setValid] = useState(true);
    const [submitted, setSubmitted] = useState(false);
    const [nextStep, setNextStep] = useState(false);
    const [categorie, setcategorie] = useState(null);
    const [suppliers, setSuppliers] = useState([]);

    const [categories,setcategories] = useState([]);
    const [objectAdd,setObjectAdd]=useState(null);
    const [dateOffreAdd,setDateOffreAdd]=useState(dateToday);
    const [dateLimiteAdd,setDateLimiteAdd]=useState(null);
    const [dateOuvertureOffreAdd,setDateOuvertureOffreAdd]=useState(null);
    
    const [estimationAdd,setEstimationAdd]=useState(null);
    const [directionAdd,setDirectionAdd]=useState(null);
    const [cahierChargeAdd,setCahierChargeAdd]=useState(null);
    const [RCAdd,setRCAdd]=useState(null);
    const [complementDocsAdd,setcomplementDocsAdd]=useState(null);

    const [errorText,setErrorText] = useState("");

    useEffect(()=>{
        get_categories().then((response)=>{
            //console.log(response.data);
            setcategories(response.data);
        }).catch((error)=>{
            console.log(error);
        })
    },[])

    function validated_elem(){
        return submitted && valide;
    }

    async function add_proposal() {    
        setSubmitted(true);
        setValid(true);
        setErrorText("");
        //console.log(dateLimiteAdd);
        if (objectAdd===null ||dateLimiteAdd===null ||estimationAdd===null ||
            dateOuvertureOffreAdd === null || directionAdd===null || categorie===null ) {
            setErrorText("Champs invalides !");
            setValid(false);
        }else{
            if (cahierChargeAdd===null || RCAdd===null || complementDocsAdd===null) {
                setErrorText("Importez tout Les Document !!");
                setValid(false);
            }else{
               let tmp_form = new FormData();
                tmp_form.append('id',user.id);
                tmp_form.append('nomTuteur',ProfilInfo.nom);
                tmp_form.append('object',objectAdd);
                tmp_form.append('id_service',user.id);
                tmp_form.append('familleAchat',categorie);
                tmp_form.append('dateOffre',dateToday);
                tmp_form.append('dateLimite',dateLimiteAdd);
                tmp_form.append('dateOuvertureOffre',dateOuvertureOffreAdd);
                tmp_form.append('estimation',estimationAdd);
                tmp_form.append('direction',directionAdd);
                tmp_form.append('cahierCharge',cahierChargeAdd);
                tmp_form.append('rc',RCAdd);
                tmp_form.append('docsComplementaire',complementDocsAdd);
                tmp_form.append('facturation',0);
                
                setFormData(tmp_form);

                get_suppliers_by_categorie(categorie).then((response)=>{
                    //console.log(response.data);
                    setSuppliers(response.data);
                    if(response.data.length==0){
                        setErrorText("Aucun fournisseur disponible !");
                        setValid(false);
                    }else setNextStep(true);
                }).catch((error)=>{
                    //console.log(error.response);
                }); 
            }
            
        }
     }

    return (
        <Dialog fullWidth={true} maxWidth={'md'} open={openAdd} onClose={handleCloseAdd} aria-labelledby="form-dialog-title" className="update_porpose" >
            <DialogTitle id="form-dialog-title">Ajouter appel d'offre</DialogTitle>
            <DialogContent>
            <div className="inputs_container">
                <TextField label="Objet" className="input_form"
                    InputProps={{ startAdornment: ( <InputAdornment position="start"> <LabelIcon className="my_icon" /> </InputAdornment> ), }} onChange={(e)=>setObjectAdd(e.target.value)}
                />
                <TextField label="Estimation (MAD) " type="number" className="input_form"
                    InputProps={{ startAdornment: ( <InputAdornment position="start"> <PaymentIcon className="my_icon" /> </InputAdornment> ), }} onChange={(e)=>setEstimationAdd(e.target.value)}
                />
                <FormControl className="input_form">
                    <InputLabel>Famille D'achat</InputLabel>

                        <Select onChange={(e)=>{setcategorie(e.target.value)}}>
                            <MenuItem value="null" disabled>
                                <em>None</em>
                            </MenuItem>
                            {
                                categories.map((categorie) => (
                                    <MenuItem key={categorie.id_categorie} value={categorie.id_categorie}>{categorie.nom}</MenuItem>
                                ))
                            }
                        </Select>

                    <FormHelperText>choisissez votre Famille d'achat</FormHelperText>
                </FormControl>
            </div>
            <div className="inputs_container">
                <TextField
                    type="datetime-local"
                    className="input_form" label="Date offre" readOnly value={dateToday}
                    InputProps={{ startAdornment: ( <InputAdornment position="start"> <EventAvailableIcon className="my_icon"  /> </InputAdornment> ), }}
                />
                <TextField
                    type="datetime-local"
                    className="input_form" label="Date limite" min={new Date()}  inputProps={{min: dateToday}}
                    InputProps={{ startAdornment: ( <InputAdornment position="start"> <EventBusyIcon className="my_icon"  /> </InputAdornment> )}} onChange={(e)=>setDateLimiteAdd(e.target.value)}
                />
                {
                    (dateLimiteAdd) != "" 
                    ?<TextField
                        disabled = { dateLimiteAdd==null ? true : false}
                        type="datetime-local"
                        className="input_form" label="Date d'ouverture des plis"  inputProps={{min: setDateTime(new Date(dateLimiteAdd))}}
                        InputProps={{ startAdornment: ( <InputAdornment position="start"> <EventBusyIcon className="my_icon"  /> </InputAdornment> )}} onChange={(e)=>setDateOuvertureOffreAdd(e.target.value)}
                    />
                    :<TextField
                        disabled = {true}
                        type="datetime-local"
                        className="input_form" label="Date d'ouverture des plis"
                        InputProps={{ startAdornment: ( <InputAdornment position="start"> <EventBusyIcon className="my_icon"  /> </InputAdornment> )}} onChange={(e)=>setDateOuvertureOffreAdd(e.target.value)}
                    />
                }

                
            </div>
            <div className="inputs_container">
                 <FormControl className="input_form">
                    <InputLabel>Direction</InputLabel>
                    <Select onChange={(e)=>{setDirectionAdd(e.target.value)}}>
                        <MenuItem value="null" disabled>
                        <em>None</em>
                        </MenuItem>
                        <MenuItem value="Direction financiere">Direction financiere</MenuItem>
                        <MenuItem value="Direction juridique">Direction juridique</MenuItem>
                        <MenuItem value="Direction informatique">Direction informatique </MenuItem>
                    </Select>
                    <FormHelperText>choisissez votre Direction</FormHelperText>
                </FormControl>
            </div>

            <div className="inputs_container">
                <div className='documentsDownload'>
                    <label htmlFor="cahierCharge" style={{marginRight:'7px'}}> 
                        <Fab size="small" component='span' ><CloudUploadIcon className="my_icon"/> </Fab>
                        <input accept="application/pdf" className={classes.input} name="cahierCharge" id="cahierCharge" multiple type="file" onChange={(e)=>setCahierChargeAdd(e.target.files[0])} />
                    </label>
                    <span className='documentsTitle'>Cahier De Charge </span>
                </div>

                <div className='documentsDownload'>
                    <label htmlFor="rc" style={{marginRight:'7px'}}> 
                        <Fab size="small" component='span'><CloudUploadIcon className="my_icon"/> </Fab>
                            <input accept="application/pdf" className={classes.input} name="rc" id="rc" multiple type="file" onChange={(e)=>setRCAdd(e.target.files[0])} />
                    </label>
                    <span className='documentsTitle'>RC</span>
                </div>

                <div className='documentsDownload'>
                    <label htmlFor="complementDocs"  style={{marginRight:'7px'}}>
                            <Fab size="small" component='span' ><CloudUploadIcon className="my_icon"/> </Fab>
                            <input accept="application/pdf" className={classes.input} name="complementDocs" id="complementDocs" multiple type="file" onChange={(e)=>setcomplementDocsAdd(e.target.files[0])} />
                    </label>
                    <span className='documentsTitle'> Docs complémentaire </span>
                </div>
            </div>
            </DialogContent>
            <DialogActions>
            <div className='dialogBottom'>
                <div>
                    {
                        validated_elem() 
                        ?<></>
                        :<p className={submitted ? "col-md-10 error" : "col-md-10 valid-champ"}>
                                {submitted ? errorText : ""}
                            </p>
                    }
                </div>
                <div>
                   <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon />} onClick={handleCloseAdd} >
                        Annuler
                    </Button>
                    <Button variant="contained" className="submit_but valide_but" startIcon={<AddIcon />} onClick={add_proposal} >
                        Suivant
                    </Button>  
                </div>
            </div>      
                
            </DialogActions>
            {
                nextStep == true?
                <Set_supplier fournisseursEdit={""} handleClose={()=>setNextStep(false)} handleCloseAdd={handleCloseAdd} update={update} open={nextStep}
                 formData={formData} setFormData={setFormData} offers={offers} setOffers={setOffers} 
                  suppliers={suppliers} setOffers_added={setOffers_added} />
                :<></>
            }

            
        </Dialog>
    )
}

export default Add_proposal
