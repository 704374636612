import React from "react";
import nunit_font from './nunito.light.ttf'
import nunit_font_bold from './nunito.bold.ttf'
import { Page, Text, Image, Document, View, Font  } from "@react-pdf/renderer";

Font.register({ family: 'Nunito', src: nunit_font });
Font.register({ family: 'Nunito-bold', src: nunit_font_bold });


function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function File_header({order_data}){
    return(
        <View style={{
            width:'95%',
            display:'flex',
            flexDirection:'row',
            margin:'0 auto',
        }}>
        <View style={{
                width:'245px',
                height:'100%',
                position:'relative',
                display:'flex',
                flexDirection:'column',
                justifyContent:'flex-start',
                alignItems:'flex-start',
                alignContent:'flex-start'
            }}>
            <Image style={{
                    height:'70px',
                    objectFit:'scale-down'
                }} src={`data:image/*;base64,${ order_data.company_logo }`} />
        </View>
        <View style={{
                flex:1,
                display:'flex',
                flexDirection:'column',
                justifyContent:'center',
                marginLeft:35,
            }}>
            <View style={{
                    width:'100%',
                    fontSize:23,
                    fontWeight:'600',
                    display:'flex',
                    flexDirection:'row',
                    alignItems:'flex-end',
                    alignContent:'flex-end',
                    paddingLeft:11,
                    marginBottom:'5px',
                    fontFamily:'Nunito-bold',
                    color:'#555555'
                }}><Text>Bon de Commande</Text></View>
            <View style={{
                    width:'100%',
                    display:'flex',
                    flexDirection:'column',
                    fontSize:9,
                    paddingLeft:13,
                }}>
                <View style={{
                    margin:'1px 0px',
                    display:'flex',
                    flexDirection:'row',
                    }}>
                    <Text style={{
                        fontFamily:'Nunito-bold',
                    }}>Code de demande : </Text>
                    <Text>{order_data.request_infos.request_code}</Text>
                </View>
                <View style={{ 
                    margin:'1px 0px',
                    display:'flex',
                    flexDirection:'row',
                    }}>
                    <Text style={{
                        fontFamily:'Nunito-bold',
                    }}>Date de création de la demande : </Text>
                    <Text>{(new Date(order_data.request_infos.created_at)).toLocaleDateString().split(',')[0]}</Text>
                </View>
                <View style={{ 
                    margin:'1px 0px',
                    display:'flex',
                    flexDirection:'row',
                    }}>
                    <Text style={{
                        fontFamily:'Nunito-bold',
                    }}>Date de livraison : </Text>
                    <Text>{order_data.request_infos.delivery_date?order_data.request_infos.delivery_date:"Pas encore"}</Text>
                </View>
                <View style={{ 
                    margin:'1px 0px',
                    display:'flex',
                     flexDirection:'row',
                    }}>
                    <Text style={{
                        fontFamily:'Nunito-bold',
                    }}>Num. de commande : </Text>
                    <Text>{order_data.request_infos.order_number}</Text>
                </View>
            </View>
        </View>
    </View>
    );
}



function Company_infos({company_infos}){
    return(
        <View style={{
                width:'95%',
                display:'flex',
                flexDirection:'row',
                margin:'12px auto 5px auto',
            }}>
            <View style={{
                    width:'220px',
                    display:'flex',
                    fontSize:9,
                    flexDirection:'column',
                }}>
                    <View style={{
                        width:'100%',
                        fontSize:'16pt',
                        marginBottom:'2px',
                        fontFamily:'Nunito-bold',
                        color:'#555555'
                    }}>
                        <Text>{company_infos.company_name}</Text>
                    </View>
                    <View style={{
                        width:'100%',
                        display:'flex',
                        flexDirection:'column',
                        color:'#555555',
                        paddingLeft:1
                    }}>
                        <Text style={{
                                marginBottom:1
                            }}>{company_infos.Pays+", "+company_infos.Ville}</Text>
                        <Text style={{
                                marginBottom:1
                            }}>{company_infos.Adresse+", "+company_infos.CodePostal}</Text>
                        <View style={{
                                width:'100%',
                                display:'flex',
                                flexDirection:'row',
                                marginBottom:1
                            }}>
                            <Text>Télephone : </Text>
                            <Text style={{
                                color:'#555555'
                                }}>+2125060222222</Text>
                        </View>
                    </View>
            </View>
        </View>
    )
}


function Personal_infos({requester_infos}){
    return(
        <View style={{
            width:'260px',
            display:'flex',
            flexDirection:'column',
            borderStyle:'solid',
            borderWidth:1,
            borderRadius:'5px',
        }}>
        <View style={{
                width:'100%',
                borderColor:"#999999",
                color:'white',
                fontSize:9,
                backgroundColor:'#999999',
                textTransform:'uppercase',
                padding:'4px 9px',
                borderTopLeftRadius:'4px',
                borderTopRightRadius:'4px',   
            }}><Text>Infos Demandeur</Text></View>
        <View style={{
                width:'100%',
                padding:'4px 9px',
                fontSize:9,
            }}>
            <Text style={{
                        marginBottom:1,
                        fontFamily:'Nunito-bold',
                    }}>{requester_infos.requester_lastName+" "+requester_infos.requester_firstName}</Text>
            <Text>{requester_infos.delivery_country}, {requester_infos.delivery_city}</Text>
            <Text>{requester_infos.delivery_address}</Text>
            <Text>{requester_infos.delivery_postCode}</Text>
        </View>
    </View>
    );
}


function Supplier_infos({supplier_infos}){
    return(
        <View style={{
            width:'260px',
            display:'flex',
            flexDirection:'column',
            borderStyle:'solid',
            borderWidth:1,
            borderRadius:'5px',
        }}>
        <View style={{
                width:'100%',
                borderColor:"#999999",
                color:'white',
                fontSize:9,
                backgroundColor:'#999999',
                textTransform:'uppercase',
                padding:'4px 9px',
                borderTopLeftRadius:'4px',
                borderTopRightRadius:'4px',   
            }}><Text>Infos Fournisseur</Text></View>
        <View style={{
                width:'100%',
                padding:'4px 9px',
                fontSize:9,
            }}>
            <Text style={{
                        marginBottom:1,
                        fontFamily:'Nunito-bold',
                    }}>{supplier_infos.nom}</Text>
            <Text>{supplier_infos.Pays}, {supplier_infos.Ville}</Text>
            <Text>{supplier_infos.Adresse}</Text>
            <Text>{supplier_infos.CodePostal}</Text>
        </View>
    </View>
    );
}

function Table_body_elem({product}){
    return(
        <View style={{
            width:'100%',
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'space-between',
            borderBottomWidth:.5,
            borderBottomStyle:'solid',
            borderBottomColor:'#999999',
            color:'#555555',
            fontSize:8.5,
            padding:'5px 0',
        }}>
            <View style={{
                width:'45%',
                padding:'0 4px'
            }}>
                <Text>{product.title}</Text>
            </View>
            <View style={{
                width:'15%',
                padding:'0 4px'
            }}>
                <Text>{numberWithSpaces(product.product_price)}</Text>
            </View>
            <View style={{
                width:'12%',
                textAlign:'center',
                padding:'0 4px'
            }}>
                <Text>{product.product_promo}</Text>
            </View>
            <View style={{
                width:'12%',
                textAlign:'center',
                padding:'0 4px'
            }}>
                <Text>{product.product_amount}</Text>
            </View>
            <View style={{
                width:'17%',
                textAlign:'right',
                padding:'0 4px'
            }}>
                <Text>{product.product_price!=='' && !isNaN(product.product_price) 
                                    ?numberWithSpaces(parseFloat((product.product_amount*(product.product_price - ((product.product_promo!=='' && !isNaN(product.product_promo))?(product.product_price*product.product_promo)/100:0))).toFixed(2)))
                                    :0}</Text>
            </View>
        </View>
    );
}


function Other_order_pricesInfos({title,data}){
    return(
        <View style={{
            width:'40%',
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'space-between',
            borderBottomWidth:.5,
            borderBottomStyle:'solid',
            fontSize:9,
            padding:'5px 0'
        }}>
            <View style={{
                width:'55%',
                paddingLeft:'4px',
                fontFamily:'Nunito-bold'
            }}>
                <Text>{title}</Text>
            </View>
            <View style={{
                width:'45%',
                color:'#555555',
                display:'flex',
                flexDirection:'row',
                justifyContent:'flex-end',
                paddingRight:'4px'
            }}>
                <Text>{data}</Text>
            </View>
        </View>
    )
}


const Order_form_header_pdf = ({order_data}) => {
  return (
    <Document>
      <Page style={{
                width:'100%',
                paddingBottom:6,
                paddingTop:15,
                paddingRight:10,
                paddingLeft:10,
                paddingHorizontal:10,
                backgroundColor: "#f7f7f7",
                fontFamily:'Nunito',
                color:'#555555'
            }}>

        <File_header order_data={order_data} />
        <Company_infos company_infos={order_data.company_infos}  />
        <View style={{
                    width:'100%',
                    display:'flex',
                    fontSize:'12pt',
                    flexDirection:'row',
                    justifyContent:'space-around',
                    margin:'20px 0',
                }}> 
            <Personal_infos requester_infos={order_data.requester_infos} />
            <Supplier_infos supplier_infos={order_data.supplier_infos} />            
        </View>

        <View style={{
                        width:'95%',
                        margin:'15px auto',
                    }}>
            <View style={{
                width:'100%',
                display:'flex',
                flexDirection:'row',
                alignItems:'center',
                justifyContent:'space-between',
                borderBottomWidth:.5,
                borderBottomStyle:'solid',
                fontSize:9,
                padding:'5px 0',
                fontFamily:'Nunito-bold',
            }}>
                <View style={{
                    width:'45%',
                    padding:'0 4px'
                }}>
                    <Text>Produit</Text>
                </View>
                <View style={{
                    width:'15%',
                    padding:'0 4px'
                }}>
                    <Text>P. unit. (MAD)</Text>
                </View>
                <View style={{
                    width:'12%',
                    textAlign:'center',
                    padding:'0 4px'
                }}>
                    <Text>Promo (%)</Text>
                </View>
                <View style={{
                    width:'12%',
                    textAlign:'center',
                    padding:'0 4px'                    
                }}>
                    <Text>Quant.</Text>
                </View>
                <View style={{
                    width:'17%',
                    textAlign:'right',
                    padding:'0 4px'
                }}>
                    <Text>Total (MAD)</Text>
                </View>
            </View>
            <>
                {
                    order_data.products.map((product,id) => (
                        <Table_body_elem product={product} />
                    ))
                }
            </>
         </View>

         <View style={{
                width:'95%',
                margin:'0 auto 15px auto',
                display:'flex',
                flexDirection:'column',
                alignContent:'flex-end',
                alignItems:'flex-end'
            }}>
            
            <Other_order_pricesInfos title={"Total HT (MAD)"} data={numberWithSpaces(order_data.total_ht)} />
            <Other_order_pricesInfos title={"Taux de TVA (%)"} data={order_data.vat} />
            <Other_order_pricesInfos title={"Frais d’expédition (%)"} data={order_data.shippingCosts} />
            <Other_order_pricesInfos title={"Remis (%)"} data={order_data.discount} />
            <Other_order_pricesInfos title={"Total TTC (MAD)"} data={numberWithSpaces(order_data.total_ttc)} />

        </View>
        <Text
          style={{
            position: "absolute",
            fontSize: 9.5,
            bottom: 15,
            left: 0,
            right: 0,
            textAlign:'center',
            color: "#555555",
          }}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber}/${totalPages}`
          }
        />
      </Page>
    </Document>
  );
};

export default Order_form_header_pdf;