import './add_categorie_modal.css'
import React, { useState} from 'react'
import Button from '@material-ui/core/Button';
import swal from 'sweetalert';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {makeStyles} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@mui/icons-material/Add';
import InputAdornment from '@material-ui/core/InputAdornment';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { add_categorie } from '../../../../services/company_admin_service';


const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    }
  }));

function Add_categorie_modal({add_categorie_modal,setadd_categorie_modal,setCategorie_added,update_categorie,setupdate_categorie}){

    const classes = useStyles();

    const [errorText,setErrorText] = useState("");

    const [categorie,setcategorie]=useState(update_categorie?update_categorie.nom:'');

    const [waiting,setWaiting] = useState(false)

    function submit(){
        setErrorText('');
        if(categorie==="") setErrorText('Champs invalides !');
        else{
            swal({
                title: "Etes-vous sûr ?",
                text: "!!!",
                icon: "warning",
                buttons: true,
                dangerMode: true,
            })
            .then((willDelete) => {
              if (willDelete) {
                setWaiting(true);
                let formData = new FormData();
               if(update_categorie) formData.append('id_categorie',update_categorie.id_categorie);
                formData.append('categorie',categorie);
                add_categorie(formData).then((response) => {
                  setWaiting(false);
                  if(update_categorie){
                    swal("La Famille d'achat a été modifiée avec succès !", {
                      icon: "success",
                      buttons: true,
                      }).then((willDelete) => {
                          if (willDelete) {
                              
                          }
                      })
                      setupdate_categorie(null);
                  }else{
                    swal("La Famille d'achat a été ajoutée avec succès !", {
                      icon: "success",
                      buttons: true,
                      }).then((willDelete) => {
                          if (willDelete) {
                              
                          }
                      })
                  }
                    setadd_categorie_modal(false);
                    setCategorie_added(response.data);
                }).catch((error) => {
                    //console.log(error.response);
                    let error_data = error.response.data;
                    if(error_data.errors){
                        if(error_data.errors.categorie) setErrorText("Famille déjà existant !");
                    }
                    setWaiting(false);
                });
              }
            })
        }
    }

    return (
      <Dialog fullWidth={true} maxWidth={'md'} open={add_categorie_modal} onClose={()=>{setupdate_categorie(null);setadd_categorie_modal(false)}} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">
        {!update_categorie?"Ajouter votre Famille d'achat":"Modifier votre Famille d'achat"}
        </DialogTitle>
        <DialogContent>
          <div className="add_categorie_modal_infos">
                <TextField className={classes.margin} label="Famille" style={{width:"250px"}} value={categorie}
                    InputProps={{ startAdornment: ( <InputAdornment position="start"> <BookmarkIcon  /> </InputAdornment> ), }} onChange={(e)=>setcategorie(e.target.value)} />
          </div>
        </DialogContent>
        <DialogActions>
          <div className='dialogBottom'>
            <div>
                {
                    errorText === ""
                    ?<></>
                    :<p className={errorText ? "col-md-10 error" : "col-md-10 valid-champ"}>
                            {errorText}
                        </p>
                }
            </div>
            <div>
                <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon />} onClick={()=>{setupdate_categorie(null);setadd_categorie_modal(false)}}>
                    Annuler
                </Button>
                <Button variant="contained" className="submit_but valide_but" startIcon={<AddIcon />} onClick={submit} >
                   {update_categorie?"Modifier":'Ajouter'}
                </Button>  
            </div>
               
          </div>
        </DialogActions>

        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>

      </Dialog>
    )
}

export default Add_categorie_modal
