import React, { Fragment , useEffect,useState } from 'react'

import './litigations.css'
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Axios from 'axios'
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import Rating from '@material-ui/lab/Rating';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CancelIcon from '@material-ui/icons/Cancel';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
let user=JSON.parse(localStorage.getItem('user-info'))

const columns = [
  { id: 'Nom', label: 'Direction', minWidth: 180 },
  { id: 'Ville', label: 'Ville', minWidth: 170 },
  { id: 'Raison', label: 'Raison', minWidth: 250, align: 'left', },
  { id: 'Show', label: 'Afficher', minWidth: 0, align: 'left', },
  { id: 'Rate', label: 'Évaluer', minWidth:  0, align: 'left' },
];

function createData(Nom, Ville, Raison,Show,Rate) {
  return { Nom, Ville, Raison,Show,Rate};
}

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});



function Litigations() {

  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [Data, setData] = React.useState([]);
  const handleCloseRate = () => { setOpenRate(false); };
  const handleClose = () => { setOpen(false); };
  const [open, setOpen] = React.useState(false);
  const [ReclamationTxt, setReclamationTxt] = React.useState('');
  const [OpenRate,setOpenRate]=useState(false);

  const rows = [
    Data.map((o, i) => createData(Data[i].nom,Data[i].Ville+" / "+Data[i].Pays, Data[i].raison,
    <Button variant='contained' onClick={()=>{Reclamation(i)}}>Afficher</Button>,
    <Box component="fieldset" ><Rating name="read-only" value={Data[i].rate} readOnly /></Box>))
  ]

  const Reclamation = (i) => {
    setOpenRate(true);
    setReclamationTxt(Data[i].reclamation);
    //console.log(Data[i].reclamation);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  useEffect(()=>{
      Axios.get("http://127.0.0.1:8000/api/servicePrincipale/litiges/"+user.id)
        .then((reponse)=> {
          setData(reponse.data);
          //console.log(reponse.data);
        })
  },[]);

  return (
    <div className='litigations_container'>
      <div className='litigations_container_header'>
            <div><label>Mes appels</label></div>
      </div>
          <Paper className='litigations_table'>
            <TableContainer className={classes.container}>
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }} >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows[0].slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.Ville}>
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell key={column.id} align={column.align}>
                              {value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            </Paper>

            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />

          <Dialog fullWidth={true} maxWidth={'md'} open={OpenRate} onClose={handleCloseRate} aria-labelledby="form-dialog-title" >
            <DialogTitle id="form-dialog-title">Noter Appel d'Offre </DialogTitle>
            <DialogContent className="rateEdit" >
              {ReclamationTxt}
            </DialogContent>
            <DialogActions>
              <Button variant="contained" style={{background:'red', color:'white'}} className={classes.button} startIcon={<CancelIcon />} onClick={handleClose} >
                cancel
              </Button>
            </DialogActions>
          </Dialog>
    </div>    
  );
}

export default Litigations;
