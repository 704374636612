import "./addUpdate_question_model.css"
import { Dialog, DialogContent, DialogTitle, DialogActions } from "@material-ui/core";
import React,{useState,useEffect} from "react";
import { useHistory } from "react-router-dom";
import CancelIcon from '@material-ui/icons/Cancel';
import {Form, Row} from 'react-bootstrap'
import AddIcon from '@mui/icons-material/Add';
import InputAdornment from '@material-ui/core/InputAdornment';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import RemoveIcon from '@mui/icons-material/Remove';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Button from '@material-ui/core/Button';
import { create_question } from "../../../../../services/company_admin_service";


const AddUpdate_question_model = ({questions,setquestions,questions_types,add_question_model,setadd_question_model,update_question_model,setupdate_question_model})=>{

    const [label, setlabel] = useState("");
    const [question_type, setquestion_type] = useState("null");
    const [content, setcontent] = useState("");
    const [errorText, setErrorText] = useState(null);
    const [wait_submitting, setwait_submitting] = useState(false);

    function submit(){
        setErrorText('');
        let error_tmp="";
        if(label!="" && question_type!="null"){
            if(question_type!=1 && question_type!=2){
                if(content == ""){
                    error_tmp = "Champs invalides !";
                    setErrorText('Contenue invalid !');
                }
            }
            if(error_tmp==""){
                setwait_submitting(true);
                let formData = new FormData();
                formData.append("label",label);
                formData.append("question_type",question_type);
                formData.append("content",content);
                create_question(formData).then((response)=>{
                    console.log(response.data);
                    let questions_tmp = [...questions];
                    questions_tmp.unshift(response.data);
                    setquestions(questions_tmp);
                    setadd_question_model(false);
                }).catch((error)=>{
                    //console.log(error.response);
                    setwait_submitting(false);
                })
            }
        }else{
            setErrorText('Champs invalides !');
        }
    }

    return (
        <Dialog fullWidth={true} maxWidth={'md'} open={add_question_model} onClose={()=>{setadd_question_model(false);setupdate_question_model(null)}} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">
          {update_question_model?"Modifier la question":"Ajouter une question"}
        </DialogTitle>
        <DialogContent>
        <div className="addupdate_question_form_container" >
                <Row className="mb-12">
                    <div className="col-md-6">
                        <label>Libellé</label>
                        <Form.Control as="textarea" placeholder='Libellé *' rows={5} 
                                value={label} onChange={ e =>  {setlabel(e.target.value)}}
                                className={ errorText && label==="" ? "is-invalid":""} />
                    </div>
                    
                </Row>  

                <Row className="mb-12">
                    <div className="col-md-6 simple_input">
                        <label>Type de question</label>
                        <Form.Select
                            required
                            value={question_type}
                            onChange={e => {
                                setquestion_type(e.target.value);
                            }}
                            className={ errorText && question_type==="null" ? "is-invalid":""}
                            name="question_type"
                        >
                            <option value={"null"} disabled selected>Type de question</option>
                            <>
                            {
                                questions_types.map((questions_type,ind) => (
                                    <option value={ind+1} key={ind}>{questions_type}</option>
                                ))
                            }
                            </>
                        </Form.Select>
                    </div>

                    <div className="col-md-6">
                        <label>Contenue</label>
                        <Form.Control as="textarea" placeholder="Contenue (Ex: Choix1;Choix2;... ) *" rows={5} 
                                value={content}
                                onChange={ e =>  {setcontent(e.target.value)}}
                                className={ errorText && (question_type!="null" && question_type!=1 && question_type!=2) && content==="" ? "is-invalid":""}
                             />
                    </div>
                </Row>          
        </div>
        </DialogContent>
        <DialogActions>
          <div className='dialogBottom'>
            <div>
                {
                    errorText === ""
                    ?<></>
                    :<p className={errorText ? "col-md-10 error" : "col-md-10 valid-champ"}>
                            {errorText}
                        </p>
                }
            </div>
            <div>
                <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon />} onClick={()=>{setadd_question_model(false);setupdate_question_model(null)}} >
                    Annuler
                </Button>
                <Button variant="contained" className="submit_but valide_but" startIcon={<AddIcon />} onClick={submit} >
                    {update_question_model?"Modifier":"Ajouter"}
                </Button>  
            </div>
               
          </div>
        </DialogActions>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait_submitting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    );
}

export default AddUpdate_question_model;