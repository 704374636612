import '../../../assets/css/all_services_profil.css'

import React, { useState,Fragment,useEffect } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import swal from 'sweetalert';
import Form from 'react-bootstrap/Form'
import Button from '@material-ui/core/Button';
import SaveIcon from '@mui/icons-material/Save';
import {makeStyles} from '@material-ui/core/styles';
import defaultProfil_pic from './defaultProfil_pic.png';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import {get_auditor_byIdLogin, update_auditor} from '../../../services/auditor_service';
//import {update_serviceIdentity_infos,update_serviceIdentity_profileImg,get_serviceIdentity_profileImg} from './../../services/for_allServices'

import Loading_animation_view from '../../loading_animation_view/loading_animation_view'

const useStyles = makeStyles((theme) => ({
  button: { margin: theme.spacing(1),},
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));


function Auditor_profil() {
  const classes = useStyles();

  const [serviceInfo,setserviceInfo]=useState(null);

  const [CNSS,setCNSS]= useState("");
  const [address,setaddress]= useState("");
  const [businessRegister,setbusinessRegister]= useState("");
  const [city,setcity]= useState("");
  const [country,setcountry]= useState("");
  const [departement,setdepartement]= useState("");
  const [fiscalIdentifier,setfiscalIdentifier]= useState("");
  const [fullName,setfullName]= useState("");
  const [iceNumbner	,seticeNumbner]= useState("");
  const [licenseNumber,setlicenseNumber]= useState("");
  const [company_name,setcompany_name]= useState("");
  const [phoneNumber,setphoneNumber]= useState("");
  const [position,setposition]= useState("");
  const [postcode,setpostcode]= useState("");
  
  const [disabled , setDisabled] =useState(true);
  const [userprofile , setUserprofile] = useState(null)

  const [error,setError]=useState(null);
  const [editing,setediting]=useState(false);
  const [waiting, setWaiting] = useState(false);

  let user = JSON.parse(localStorage.getItem('user-info'));

  useEffect(()=>{
    get_auditor_byIdLogin(user.id)
        .then((response)=> {
          //console.log(response.data);
          setfullName(response.data.fullName);
          setCNSS(response.data.CNSS);
          setaddress(response.data.address);
          setbusinessRegister(response.data.businessRegister);
          setcity(response.data.city);
          setcountry(response.data.country);
          setdepartement(response.data.departement);
          setfiscalIdentifier(response.data.fiscalIdentifier);
          seticeNumbner(response.data.iceNumbner);
          setlicenseNumber(response.data.licenseNumber);
          setcompany_name(response.data.company_name);
          setphoneNumber(response.data.phoneNumber);
          setposition(response.data.position);
          setpostcode(response.data.postcode);
          setserviceInfo(response.data);
      }).catch((error)=>{
        //console.log(error.response);
      });

    /*get_serviceIdentity_profileImg(user.id).then((response)=>{
      setUserprofile(URL.createObjectURL(response.data));
    }).catch((error)=>{
      //console.log(error.response);
      setUserprofile(defaultProfil_pic);
    });*/
    setUserprofile(defaultProfil_pic);

  },[]);

  function update_alertMsg(){
    setError(null);
    if(CNSS==="" || address==="" || city==="" || country==="" || departement==="" || fullName==="" 
          || phoneNumber==="" || position==="" || postcode===""){
        setError("Champs invalids !")
    }else{
      swal({
          title: "Etes-vous sûr ?",
          text: "Une fois sauvegardées, vous ne pourrez plus récupérer les données précédentes !",
          icon: "warning",
          buttons: true,
          dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          update_infos(serviceInfo.id);
          setDisabled(!disabled);
        }
      });
    }
  }

  function updateProfil_pics(file){
    setWaiting(true);
    const formData = new FormData();
    formData.append('id',user.id);
    formData.append('profile_img',file);
    /*update_serviceIdentity_profileImg(formData).then((response)=>{
      //console.log(response.data);
      setWaiting(false);
      setUserprofile(URL.createObjectURL(file));
    }).catch((error) => {
      //console.log(error.response);
    })*/
  }


   async function update_infos() {
    setWaiting(true);
      const formData = new FormData();
      formData.append('id',user.id);
      formData.append('CNSS',CNSS);
      formData.append('address',address);
      formData.append('city',city);
      formData.append('country',country);
      formData.append('departement',departement);
      formData.append('fullName',fullName);
      formData.append('phoneNumber',phoneNumber);
      formData.append('position',position);
      formData.append('postcode',postcode);
      update_auditor(user.id,formData).then((response)=>{
        setWaiting(false);
        swal("Votre fiche d'identité a été modifiée avec succès !", {
          icon: "success",
        });
        setediting(false);
      }).catch((error)=>{
        //console.log(error.response);
      })
  }


  return (
    <div className="all_services_identity_container">
      {
        serviceInfo===null || userprofile===null
        ?<Loading_animation_view />
        :<div className="all_services_identity_infos_container">
          <div className="all_services_identity_infos">
            <div className="srv_identity_img">
                <div >
                  <img src={userprofile} alt=''  />
                </div>
                <div>
                    <input accept=".jpg,.png,.jpeg" className={classes.input} name="profil_img" id="profil_img" multiple type="file" onChange={(e)=>updateProfil_pics(e.target.files[0])} />
                    <label htmlFor="profil_img">
                      <span className="submit_but edit_but disabled_button"  >
                        <EditIcon className="my_icon" /> Image de profil
                      </span>
                        
                    </label>
                </div>
            </div>
            <div className="srv_identity_element">
                <span>Nom complet</span>
                <span>
                    <Form.Control
                          required
                          value={fullName} 
                          autoComplete="new-password"
                          type="text"
                          placeholder="Nom complet *"
                          disabled = { disabled }
                          onChange={(e)=>setfullName(e.target.value)} 
                      />   
                </span>
            </div>
            <div className="srv_identity_element">
                <span>Département</span>
                <span>
                    <Form.Control
                          required
                          value={departement} 
                          autoComplete="new-password"
                          type="text"
                          placeholder="Département *"
                          disabled
                          onChange={(e)=>setdepartement(e.target.value)} 
                      />   
                </span>
            </div>
            <div className="srv_identity_element">
                <span>Position</span>
                <span>
                    <Form.Control
                          required
                          value={position} 
                          autoComplete="new-password"
                          type="text"
                          placeholder="Position *"
                          disabled
                          onChange={(e)=>setposition(e.target.value)} 
                      />   
                </span>
            </div>
            <div className="srv_identity_element">
              <span>Téléphone</span>
              <span>
                  <Form.Control
                        required
                        value={phoneNumber}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Téléphone *"
                        disabled = { disabled }
                        onChange={(e)=>setphoneNumber(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
                <span>CNSS</span>
                <span>
                    <Form.Control
                          required
                          value={CNSS} 
                          autoComplete="new-password"
                          type="text"
                          placeholder="CNSS *"
                          disabled = { disabled }
                          onChange={(e)=>setCNSS(e.target.value)} 
                      />   
                </span>
            </div>
            <div className="srv_identity_element">
              <span>Pays</span>
              <span>
                  <Form.Control
                        required
                        value={country} 
                        autoComplete="new-password"
                        type="text"
                        placeholder="Pays *"
                        disabled = { disabled }
                        onChange={(e)=>setcountry(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Ville</span>
              <span>
                  <Form.Control
                        required
                        value={city}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Ville *"
                        disabled = { disabled }
                        onChange={(e)=>setcity(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Adresse</span>
              <span>
                  <Form.Control
                        required
                        value={address}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Adresse *"
                        disabled = { disabled }
                        onChange={(e)=>setaddress(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Code Postal</span>
              <span>
                  <Form.Control
                        required
                        value={postcode}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Code Postal *"
                        disabled = { disabled }
                        onChange={(e)=>setpostcode(e.target.value)} 
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Date de constitution</span>
              <span>
                  <Form.Control
                        required
                        value={(new Date(serviceInfo.created_at)).toLocaleDateString().split(' ')[0]}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Date de constitution *"
                        disabled
                    />   
              </span>
            </div>
            </div>
          
           <div className='company_infos_header'>Fiche d'entreprise : </div>
           <div className="all_services_identity_infos" >
            <div className="srv_identity_element">
              <span>Maison-mère</span>
              <span>
                  <Form.Control
                        required
                        value={company_name}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Maison-mère *"
                        disabled = { true }
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Registre du commerce <br/> (Ou équivalent)</span>
              <span>
                  <Form.Control
                        required
                        value={businessRegister}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Registre du commerce (Ou équivalent) *"
                        disabled = { true }
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>Identifiant fiscal <br/>  (Ou équivalent)</span>
              <span>
                  <Form.Control
                        required
                        value={fiscalIdentifier}
                        autoComplete="new-password"
                        type="text"
                        placeholder="Identifiant fiscal (Ou équivalent) *"
                        disabled = { true }
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>N° Patente  <br/>  (Ou équivalent)</span>
              <span>
                  <Form.Control
                        required
                        value={licenseNumber}
                        autoComplete="new-password"
                        type="text"
                        placeholder="N° Patente (Ou équivalent) *"
                        disabled = { true }
                    />   
              </span>
            </div>
            <div className="srv_identity_element">
              <span>N° ICE  <br/> (Ou équivalent)</span>
              <span>
                  <Form.Control
                        required
                        value={iceNumbner}
                        autoComplete="new-password"
                        type="text"
                        placeholder="N° ICE (Ou équivalent) *"
                        disabled = { true }
                    />   
              </span>
            </div>
            <div className="srv_identity_element"></div>

          </div>
          <div className="all_services_identity_infos_config" >
              <div>
                  {
                      error==null
                      ?<></>
                      :<p className={error ? "col-md-10 error" : "col-md-10 valid-champ"}>
                              {error ? error : ""}
                          </p>
                  }
               </div>
              <div>
                <Button variant="contained" className="submit_but edit_but" startIcon={<EditIcon className="my_icon" />} onClick={() => {setDisabled(!disabled);setediting(true)}} >
                    Editer
                </Button>
                <Button variant="contained" className={editing===true?"submit_but valide_but":"submit_but valide_but disabled_button"} startIcon={<SaveIcon className="my_icon" />} onClick={update_alertMsg} >
                    Sauvegarder
                </Button> 
              </div>
             
          </div>
      </div>
      }
      <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={waiting}
      >
          <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

export default Auditor_profil;
