import '../../../../assets/css/scheduler_widget.css'
import React,{useEffect, useState} from 'react'
import { Calendar, momentLocalizer} from 'react-big-calendar'
import moment from 'moment'

import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'moment/locale/fr';

const localizer = momentLocalizer(moment);


const Scheduler_widget = ({categories,sheduler_events})=>{


    function getCategorieById(id){
      var categorie="";
      categories.map((catg)=>{
        if(catg.id_categorie == id) categorie = catg.nom;
      })
      return categorie;
  }

//////////////////////
///////////////////////////////////
    function event_details(event){
    }

    function customTooltipAccessor(event){
        if(event.id_event.includes("offer_")){
            return "\nObjet d'offre : "+event.title+
                      "\nFamille d'achat : "+getCategorieById(event.familleAchat)+
                      "\nDirection : "+event.direction;
          }else{
          return event.title;
        }
    }


//////////////////////
///////////////////////////////////
    function event_styling(event,start,end,isSelected){
      var backgroundColor = event.color;
      var style = {
          backgroundColor: backgroundColor,
          borderRadius: '0px',
          opacity: 0.8,
          color: 'white',
          border: '0px',
          display: 'block',
          padding:"0px 5px",
          fontSize:"11pt"
      };
      return {
          style: style
      }
    }

    

    
//////////////////////
///////////////////////////////////
    return (
      <main>
        <div className="myAgenda_container">
            <Calendar
            localizer={localizer}
            events={sheduler_events}
            start="start"
            end="end"
            min={new Date(1972, 0, 1,8,0,0)}
            max={new Date(2050, 0, 1,19,0,0)}
            defaultView="week"
            views={['day','week']}
            defaultDate={moment().toDate()}
            eventPropGetter={event_styling}
            onSelectEvent={(event) => event_details(event)}
            tooltipAccessor={(event) => customTooltipAccessor(event)}
            messages={{
              next: ">",
              previous: "<",
              day: "jour",
              month: "Мois",
              week: "semaine",
              showMore: function showMore(total) {
                return '+' + total + 'plus';
              }
            }}
            />
        </div>
        </main>
    )
}

export default React.memo(Scheduler_widget)