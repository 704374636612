import './set_refuse_reason.css'
import React, { useState } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import SendIcon from '@mui/icons-material/Send';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import { refuse_bill, refuse_request_order_bill } from '../../../../services/finance_service';


function Set_refuse_reason({open_reason_modal,setOpen_reason_modal,id_submission,request_code,bills_numbers,markets,setMarkets}){


  const [errorText,setErrorText] = useState("");
  const [waiting, setWaiting] = useState(false);

  const [reason,setReason]=useState('');


  function submit_refuse_reason() {
    setErrorText("");
    if(reason==''){
      setErrorText("Champ vide !");
    }else{
        setWaiting(true);
        let formData = new FormData();
        if(request_code) formData.append('request_code',request_code);
        else formData.append('id_submission',id_submission);
        formData.append('bills_numbers',JSON.stringify(bills_numbers));
        formData.append('refuse_reason',reason);
        if(request_code){
          refuse_request_order_bill(formData).then((response)=>{
              let tmp_array = [...markets];
              tmp_array[open_reason_modal].bills = response.data;
              setMarkets(tmp_array);
              setOpen_reason_modal(null);
              setWaiting(false);
          }).catch((error)=>{
              setWaiting(false);
              //console.log(error.response);
          })
        }else{
           refuse_bill(formData).then((response)=>{
              let tmp_array = [...markets];
              tmp_array[open_reason_modal].bills = response.data;
              setMarkets(tmp_array);
              setOpen_reason_modal(null);
              setWaiting(false);
          }).catch((error)=>{
              setWaiting(false);
              //console.log(error.response);
          })
        }
       
    }
        
     }

     

    return (
      <Dialog className="set_refuse_raison_container" fullWidth={true} maxWidth={'md'} open={open_reason_modal!=null?true:false} onClose={()=>setOpen_reason_modal(null)} aria-labelledby="form-dialog-title" >
        <DialogTitle id="form-dialog-title">Motif du rejet</DialogTitle>
        <DialogContent>
          <div>
                {
                    errorText == "" 
                    ?<></>
                    :<p className={errorText ? "error" : "valid-champ"}>
                            {errorText}
                        </p>
                }

              <div style={{width:"100%",marginTop:"5px"}} >
              <TextField
                  id="outlined-multiline-flexible"
                  label="Motif"
                  variant='outlined'
                  multiline
                  rows={4}
                  value={reason}
                  onChange={(e)=>setReason(e.target.value)} 
                  style={{width:"100%"}}
                />
              </div>
          </div>
     </DialogContent>
        <DialogActions>
          <div className='dialogBottom'>
            <div>
              <Button variant="contained" className="submit_but cancel_but" startIcon={<CancelIcon />} onClick={()=>setOpen_reason_modal(null)} >
                  Annuler
              </Button>
              <Button variant="contained" className="submit_but valide_but" startIcon={<SendIcon />} onClick={submit_refuse_reason} >
                  Envoyer
              </Button> 
            </div>
          </div>
        </DialogActions>
        <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
      </Dialog>
    )
}

export default Set_refuse_reason
