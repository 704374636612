import './bills_table.css';
import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Widget_count_elements from '../widget_count_elements/widget_count_elements'
import {makeStyles} from '@material-ui/core/styles';


const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

  

function Orders({orders}){
    
    return(
        <>
        {
            orders.map((order,index1) => (
                <TableRow key={order.id_appel+"col"+index1}>
                    <TableCell align="left">{order.request_code}</TableCell>
                    <TableCell align="left">{order.order_number}</TableCell>
                    <TableCell align="left">{order.requester_fullName}</TableCell>
                    <TableCell align="left">{order.supplier_fullName}</TableCell>
                    <TableCell align="left">{order.order_amount+" MAD"}</TableCell>
                    <TableCell align="center">{order.bills_number}</TableCell>
                </TableRow>                
             ))
        }
        </>
    );
}


function Orders_bills({data}) {

    const classes = useStyles();
    const [show_elemnts,setShowing_elements] = useState(false);


//////////////////////
/////////////////////////////////////////////////////////
///////////////////////////////////

  return (
    <>
    {
      show_elemnts!==true
        ?<Widget_count_elements setShowing_elements={setShowing_elements} elements_number={data.count} fontColor="#51a5cf" progress={data.progress}  />
        :<TableContainer className="bills_widget_table" onDoubleClick={() => setShowing_elements(false)} style={{cursor:'pointer'}} onTouchStartCapture={(e)=>{e.stopPropagation();setShowing_elements(false)}} >
            <Table className={classes.table} aria-label="sticky table">
                <TableHead>
                <TableRow>
                    <TableCell>Code de demande</TableCell>
                    <TableCell>Nm. commande</TableCell>
                    <TableCell>Demandeur</TableCell>
                    <TableCell>Fournisseur</TableCell>
                    <TableCell>Montant de demande</TableCell>
                    <TableCell>Nombre de factures</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                <>
                    <Orders orders={data.data} />
                </>
                </TableBody> 
            </Table>
        </TableContainer>
    }
    
      
    </>
    
  );
}

export default React.memo(Orders_bills);
