import '../../../assets/css/offers_calls.css';
import './offers_calls.css';
import React, { useState , useEffect } from 'react'
import { useHistory } from "react-router-dom";

import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import SearchIcon from '@material-ui/icons/Search';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DoneIcon from '@material-ui/icons/Done';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ClearIcon from '@material-ui/icons/Clear';
import DownloadIcon from '@mui/icons-material/Download';
import Fab from '@mui/material/Fab';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import Pagination from "react-js-pagination";
import swal from 'sweetalert';

import {makeStyles} from '@material-ui/core/styles';

import Download_proposal_files from './download_propasal_files/download_proposal_files';
import { appeloffresByFournisseur, demandeappels, getSupplier_categories } from '../../../services/supplier_service';
import Loading_animation_view from '../../loading_animation_view/loading_animation_view';


const useStyles = makeStyles((theme) => ({
  button: { margin: theme.spacing(1),},
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: 'none',
  },
}));



function getFileName(event) {
  const name = event.target.files[0].name;
  const lastDot = name.lastIndexOf('.');

  const fileName = name.substring(0, lastDot);
  const ext = name.substring(lastDot + 1);
  return fileName+"."+ext;
}

function AppelOffre (){

  const classes = useStyles();
  const [chosenProposal,setchosenProposal]=useState("");
  let history = useHistory();
  const [url_updated , setUrl_updated] = useState(null);

  const [DocAdmin,setDocAdmin]=useState(null);
  const [DocTech,setDocTech]=useState(null);
  const [DocFinance,setDocFinance]=useState(null);

  const [adminFile,setAdminFile]=useState("");
  const [techFile,setTechFile]=useState("");
  const [finFile,setFinFile]=useState("");

  const [adminFileLoaded,setAdminFileLoaded]=useState();
  const [techFileLoaded,setTechFileLoaded]=useState();
  const [finFileLoaded,setFinFileLoaded]=useState();

  let user = JSON.parse(localStorage.getItem('user-info'));

  const [open, setOpen] = React.useState(false);
  const [clickOffre, setClickOffre] = useState(0);

  const [openDoc, setOpenDoc] = useState(false);

  const [appelOffre , setAppelOffre] = useState(null);

  const [categories,setCategories]=useState([]);

  const [filter_categorie,setFilter_categorie]=useState("all");
  const [search_value,setsearch_value]=useState("");
  const [search_filter,setsearch_filter]=useState('all');

  const [errorText,setErrorText] = useState("");
  const [waiting, setWaiting] = useState(false);

  const [currentPage,setCurrentPage]=useState(1);
  const [dataPerPage,setdataPerPage] =useState(6);
  const [data_count,setdata_count]=useState(0);

  const handleClickOpen = (index) => {
    setchosenProposal(appelOffre[index])
    //console.log(appelOffre[index]);
    setClickOffre(index);
    setOpen(true);
  };

  function handleClose(){
    setOpen(false);
  }

  function handleCloseDoc(){
     setOpenDoc(false);
     DocNull(); 
  };


  function OpenDocModal(){
    setOpenDoc(true);
  }

  function DocNull(){
    setDocTech(null);
    setDocAdmin(null);
    setDocFinance(null);
    setAdminFile("");
    setTechFile("");
    setFinFile("");
  }

  useEffect(()=>{
    setdata_count(0);
    setAppelOffre(null);
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    var tmp_searchFilter ="all";
    var tmp_searchValue = null;
    var tmp_filterCategorie = "all";
    async function load_data(){
      if(urlParams.get('filtre') && urlParams.get('valeur')){
        await setsearch_filter(urlParams.get('filtre'));
        await setsearch_value(urlParams.get('valeur'));
        tmp_searchFilter = urlParams.get('filtre');
        tmp_searchValue = urlParams.get('valeur');
      }if(urlParams.get('categorie')){
        await setFilter_categorie(urlParams.get('categorie'));
        tmp_filterCategorie = urlParams.get('categorie');
      }

      let page = (urlParams.get('page')?urlParams.get('page'):1);
      await setCurrentPage(page)
      await getSupplier_categories(user.id).then((response)=>{
          setCategories(response.data);
      }).catch((error)=>{
        //console.log(error.response);
      })

      await appeloffresByFournisseur(user.id,tmp_filterCategorie,tmp_searchFilter,tmp_searchValue,page)
        .then((response)=> {
            setAppelOffre(response.data.data) ;
            setdata_count(response.data.total);
        }).catch((error)=>{
            //console.log(error.response);
        });
    }
    load_data();

  },[url_updated,history.location])
    

  
    
  function getCategorieById(id){
    var categorie="";
    categories.map((catg)=>{
      if(catg.id_categorie == id) categorie = catg.nom;
    })
    return categorie;
  }

  function downloadSupplierDocsForProposal(choix){
    var fileLink = document.createElement('a');
    if(adminFileLoaded!=="" && choix==="administration"){
        fileLink.href = adminFileLoaded;
        fileLink.setAttribute('download', adminFile);
        document.body.appendChild(fileLink);
        fileLink.click();
    }
    if(techFileLoaded!=="" && choix==="technique"){
        fileLink.href = techFileLoaded;
        fileLink.setAttribute('download', techFile);
        document.body.appendChild(fileLink);
        fileLink.click();
    }
    if(finFileLoaded!=="" && choix==="finance"){
        fileLink.href = finFileLoaded;
        fileLink.setAttribute('download', finFile);
        document.body.appendChild(fileLink);
        fileLink.click();
    }
        
    
  }

  function upload_admin(event){
    setDocAdmin(event.target.files[0]);
    setAdminFile(getFileName(event));
    setAdminFileLoaded(URL.createObjectURL(event.target.files[0]));
  }

  function upload_tech(event){
    setDocTech(event.target.files[0]);
    setTechFile(getFileName(event));
    setTechFileLoaded(URL.createObjectURL(event.target.files[0]));
  }

  function upload_fin(event){
    setDocFinance(event.target.files[0]);
    setFinFile(getFileName(event));
    setFinFileLoaded(URL.createObjectURL(event.target.files[0]));
  }

  async function Soumettre() {
    if(DocAdmin===null || DocTech===null || DocFinance===null){
      setErrorText('Importez Tout Les Document !!');
    }
    else{
      swal({
        title: "Etes-vous sûr ?",
        text: "!!!!",
        icon: "warning",
        buttons: true,
        dangerMode: true,
    })
    .then((willDelete) => {
      if (willDelete) {
          setWaiting(true);
          const formData = new FormData();
          formData.append('id_appel',chosenProposal.id_appel);
          formData.append('id_fournisseur',user.id);
          formData.append('DocAdmin',DocAdmin);
          formData.append('DocTech',DocTech);
          formData.append('DocFinance',DocFinance);
          formData.append('email',chosenProposal.email);
          demandeappels(formData)
            .then((response) => {
              setWaiting(false);
              swal("Votre soumission a été enregistrée avec succès, le service sera bientôt notifié !", {
                icon: "success",
                buttons: true,
              }).then((willDelete) => {
                  if (willDelete) { 
                    setUrl_updated(response.data);
              }}) 
              setOpenDoc(false);
              setchosenProposal("");
              setErrorText("");
              DocNull();
          }).catch(err=>{
            console.log(err.response);
          });
      }})
    }
  }


  function search(){
    let path = history.location.pathname;
    if(filter_categorie!=="all") history.push(path+"?categorie="+filter_categorie+"&filtre="+search_filter+"&valeur="+search_value);
    else history.push(path+"?filtre="+search_filter+"&valeur="+search_value);
    setUrl_updated(search_value);
  }


  function set__categorie(value){
    setFilter_categorie(value);
    let path = history.location.pathname;
    if(value!=="all") history.push(path+"?categorie="+value);
    else history.push(path+"?categorie=all");
    setUrl_updated(value);
  }

  function set__filter(value){
    if(value==="all"){
      setsearch_filter(value);
      let path = history.location.pathname;
      if(filter_categorie!=="all") history.push(path+"?categorie="+filter_categorie+"&filtre="+value);
      else history.push(path+"?filtre="+value);
      setUrl_updated(value);
    }else setsearch_filter(value);
  }

  async function change_page(page){
    await setCurrentPage(page);
    let path = history.location.pathname;
    let params = history.location.search;
    let search_params = params.split('&');
    let tmp_params = path;
    if(!params) history.push(path+"?page="+page);
    else{
      for(let i in search_params){
        if(search_params[i].includes("page=")){
          if(i==0) tmp_params=tmp_params+"?page="+page;
          else tmp_params=tmp_params+"&page="+page;
        }else{
          if(i==0) tmp_params=tmp_params+search_params[i];
          else tmp_params=tmp_params+"&"+search_params[i];
        }
      }
      if(!tmp_params.includes("page=")) tmp_params=tmp_params+"&page="+page;
      history.push(tmp_params);
    }
    await setUrl_updated(page);
  }

  return (
    <>
      {
        appelOffre===null
        ?<Loading_animation_view />
        :<div className="my_offers_container supplier_offers">
        <div className="my_offers_container_header">
        <div className='search_filter_container'>
          <FormControl variant="standard" className="header_form" >
                <InputLabel id="demo-simple-select-helper-label">Famille d'achat</InputLabel>
                <Select required value={filter_categorie} 
                      onChange={e => {set__categorie(e.target.value) }} >
                      
                      <MenuItem value={"all"}>Toutes les familles d'achat</MenuItem>
                      {
                      categories.map((categorie,id)=>(
                          <MenuItem value={categorie.nom} key={id} >{categorie.nom}</MenuItem>
                      ))
                      }
                      
                </Select>
             </FormControl>

            <FormControl className="header_form" >
              <InputLabel id="demo-simple-select-helper-label">Filtre</InputLabel>
                <Select value={search_filter}  onChange={(e)=>{set__filter(e.target.value)}}>
                  <MenuItem value="all">Aucun</MenuItem>
                  <MenuItem value='Object'>Objet d'appel</MenuItem>
                  <MenuItem value='Direction'>Direction</MenuItem>
                </Select>
              </FormControl>

                <Grid container spacing={1} alignItems="flex-start" className={search_filter==="all"?"search_bar disabled_button":"search_bar" } >
                  <Grid item>
                    <SearchIcon onClick={search} className={search_value===""?"my_icon disabled_button":"my_icon" } style={{cursor:"pointer"}}  />
                  </Grid>
                  <Grid>
                    <TextField label="Chercher" value={search_value} onChange={(e)=>{setsearch_value(e.target.value)}}/>
                  </Grid>
                </Grid>
              </div>
          </div>
          <div className="offers_contents_pagination_container">
              {
                appelOffre.length !== 0
                ?<>
                  <div className="offers_contents_container">
                      {
                        appelOffre.map((val,id) => {
                          return (
                              <React.Fragment key={id}>
                                  <div className="main_card_container">
                                        <div className="main_card_infos_container">
                                            <div className="main_card_offer_object">{val.object}</div>
                                            <div className="main_card_infos_content">
                                              <div className="main_card_infos_elements">
                                                  <div> <span>Famille d'achat : </span>{getCategorieById(val.familleAchat)}</div>
                                                  <div><span>Estimation : </span>{val.estimation} DH</div>
                                              </div>
                                              <div className="main_card_infos_elements">
                                                  <div><span>Direction :</span> {val.direction} </div>
                                                  <div><span>Date Offre : </span>{(new Date(val.dateOffre)).toLocaleString()}</div>
                                              </div>
                                              <div className="main_card_infos_element"><span>Date Limite :</span> {(new Date(val.dateLimite)).toLocaleString()}</div>
                                              <div className="main_card_infos_element"> <span>Date d'ouverture des plis : </span>{(new Date(val.dateOuvertureOffre)).toLocaleString()}</div>
                                            </div>
                                        </div>
                      
                                        <div className="card_buttons_container">
                                                <Button variant="contained" className={"submit_but but_color"} onClick={() => {handleClickOpen(id);DocNull();} } >
                                                      Soumettre
                                                </Button>  
                                        </div>
                                    </div>
                
                              </React.Fragment>
                      
                              );
                          }
                        )
                      }
                  </div>
                  <div className="pagination_container">
                          <Pagination
                              activePage={Number(currentPage)}
                              itemsCountPerPage={dataPerPage}
                              totalItemsCount={Number(data_count)}
                              pageRangeDisplayed={4}
                              onChange={change_page.bind(this)}
                          />
                      </div>
                </>:<>Aucun appel d'offre disponible</>
            }
          </div>
        </div>
      }

      {
        chosenProposal!=""
        ?<Download_proposal_files open={open} onClose={handleClose} handleClose={handleClose} chosenProposal={chosenProposal} OpenDocModal={OpenDocModal}/>
        :<></>
      }
      {
        openDoc!==false
        ?<Dialog fullWidth={true} maxWidth={'md'} open={openDoc} onClose={() => {handleClose();setErrorText('');}} aria-labelledby="form-dialog-title" >
          <DialogTitle id="form-dialog-title">Importez Vos Document !!</DialogTitle>
          <DialogContent className="update_porpose">
          <div className='documentsContainer_proposelPage'>
              <div className='documentsDownload' style={{margin:"7px 10px"}}>
                  <label htmlFor="DocAdmin" style={{marginRight:"7px"}}> 
                      <Fab size="small" color="default" component="span">
                          <CloudUploadIcon className="my_icon" />
                          </Fab>
                          <input accept="application/pdf" className={classes.input} name="DocAdmin" id="DocAdmin" multiple type="file" onChange={(e)=>upload_admin(e)} />
                  </label>
                  <Fab size="small" className={adminFile!=""?"":" disabled_button"} onClick={()=>downloadSupplierDocsForProposal("administration")}><DownloadIcon className="my_icon"/></Fab>
                  <span className='documentsTitle'>Document Administratif</span>
              </div>

              <div className='documentsDownload' style={{margin:"7px 10px"}}>
                  <label htmlFor="DocTech" style={{marginRight:"7px"}}> 
                      <Fab size="small" color="default" component="span">
                              <CloudUploadIcon className="my_icon" />
                              </Fab>
                          <input accept="application/pdf" className={classes.input} name="DocTech" id="DocTech" multiple type="file" onChange={(e)=>upload_tech(e)} />
                  </label>
                  <Fab size="small" className={techFile!=""?"":" disabled_button"} onClick={()=>downloadSupplierDocsForProposal("technique")}><DownloadIcon className="my_icon"/></Fab>
                  <span className='documentsTitle'>Document Technique</span>
              </div>

              <div className='documentsDownload' style={{margin:"7px 10px", marginRight:'0'}}>
                  <label htmlFor="DocFinance" style={{marginRight:"7px"}}> 
                      <Fab size="small" color="default" component="span">
                          <CloudUploadIcon className="my_icon" />
                          </Fab>
                          <input accept="application/pdf" className={classes.input} name="DocFinance" id="DocFinance" multiple type="file" onChange={(e)=>upload_fin(e)} />
                  </label>
                  <Fab size="small" className={finFile!=""?"":" disabled_button"} onClick={()=>downloadSupplierDocsForProposal("finance")}><DownloadIcon className="my_icon"/></Fab>
                  <span className='documentsTitle'>Document Financier </span>
              </div>
              
          </div>
          </DialogContent>
          <DialogActions>
            <div className='dialogBottom'>
                <div>
                    {
                        errorText == "" 
                        ?<></>
                        :<p className={errorText != "" ? "col-md-10 error" : "col-md-10 valid-champ"}>
                                  {errorText}
                            </p>
                    }
                </div>
                <div>
                    <Button variant="contained" className="submit_but cancel_but" startIcon={<ClearIcon className="my_icon" />} onClick={()=>{handleCloseDoc();DocNull();setErrorText('');}} >
                      Annuler
                    </Button>
                    <Button variant="contained" className="submit_but return_but" startIcon={<ArrowBackIosIcon className="my_icon" />} onClick={()=>{handleCloseDoc();setOpen(true);setErrorText('');}} >
                      Précedent
                    </Button>
                    <Button variant="contained" className="submit_but valide_but" startIcon={<DoneIcon className="my_icon" />} onClick={() => Soumettre()} >
                      Soumettre
                    </Button>
                </div>
            </div>
          </DialogActions>
          <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={waiting}
            >
                <CircularProgress color="inherit" />
            </Backdrop>
        </Dialog>
        :<></>
      }

      </>
  )

}

export default AppelOffre
