import './add_auditor.css';
import React, { useState } from 'react';
import { Row,InputGroup } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import swal from 'sweetalert';
import Button from '@material-ui/core/Button';
import AddIcon from '@mui/icons-material/Add';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { create_auditor, create_service } from '../../../services/company_admin_service';

function Add_auditor(){

    let user = JSON.parse(localStorage.getItem('user-info'));

    //////form inputs
    const [first_name,setFirst_name]=useState('');
    const [last_name,setLast_name]=useState('');
    const [email,setEmail]=useState('');
    
    const [cnss,setcnss]= useState("");
    const [country,setcountry]= useState("");
    const [city,setcity]= useState("");
    const [address,setaddress]= useState("");
    const [postcode,setpostcode]= useState("");
    const [phoneNumber,setphoneNumber]= useState("");

    const [departement,setdepartement]= useState("null");
    const [position,setposition]= useState("null");
  
    const [wait_submitting,setWait_submitting] = useState(false);
    const [errorText,setErrorText] = useState(null);


//////////////////////////////
/////////////email validation
    function invalid_email_pattern(email){
        var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
        if(email!==""){
            if (!pattern.test(email)) {
                return true ;
              }else{
                return false ;
              }
        }else return true;
    }

////////////////////////////

/////////////////////////////
/////////////////////////////Submit
    function submit() {
        setErrorText(null);
        if(email==="" || last_name==="" || first_name==="" || departement==="null"  || position==="null"  || country===""  || city==="" || address==="" || postcode==="" || phoneNumber===""  ){
            setErrorText("Champs invalides !");
        }else{
            if(invalid_email_pattern(email)){
                setErrorText("Email invalid !");
            }else{
                swal({
                    title: "Etes-vous sûr ?",
                    text: "!!!",
                    icon: "warning",
                    buttons: true,
                    dangerMode: true,
                })
                .then((willDelete) => {
                  if (willDelete) {
                    setWait_submitting(true);
                    let formData = new FormData();
                    formData.append('fullName',last_name+" "+first_name);
                    formData.append('email',email);
                    formData.append('country',country);
                    formData.append('cnss',cnss);
                    formData.append('city',city);
                    formData.append('address',address);
                    formData.append('postcode',postcode);
                    formData.append('phoneNumber',phoneNumber);
                    formData.append('departement',departement);
                    formData.append('position',position);
                    create_auditor(formData).then((response) => {
                        setWait_submitting(false);
                        setFirst_name('');
                        setLast_name('');
                        setEmail('');
                        setcnss('');
                        setcountry('');
                        setcity('');
                        setaddress('');
                        setpostcode('');
                        setphoneNumber('');
                        setdepartement('null');
                        setposition('null');
                        swal("Le commissaire a été ajoutée avec succès !", {
                            icon: "success",
                            buttons: true,
                            }).then((willDelete) => {
                                if (willDelete) {
                                    
                                }
                            })
                    }).catch((error) => {
                        //console.log(error.response);
                        let error_data = error.response.data;
                        if(error_data.errors){
                            if(error_data.errors.email) setErrorText("Email déjà existant !");
                            if(error_data.errors.tel) setErrorText("Téléphone déjà existant !");
                        }
                        setWait_submitting(false);
                    });
                  }
                })
            }
        }
        
    }


///////////////////////////////////////
///////////////////////////////////////

    return (
        <div className="add_auditor_form">
        <h2>Nouveau commissaire</h2>
        <div className="form" >
                {
                    <p className={errorText ? "col-md-12 error" : "col-md-12 valid-champ"}>
                        {errorText}
                    </p>
                }
                <Row className="mb-12">
                    <Form.Group className="col-md-6">
                        <label>Nom</label>
                        <Form.Control
                            required
                            autoComplete="new-password"
                            type="text"
                            placeholder="Nom *"
                            value={last_name}
                            onChange={ e =>  {setLast_name(e.target.value)}}
                            className={ errorText && last_name==="" ? "is-invalid":""}
                        />
                    </Form.Group>

                    <Form.Group className="col-md-6">
                        <label>Prénom</label>
                        <Form.Control
                            required
                            autoComplete="new-password"
                            type="text"
                            placeholder="Prénom *"
                            value={first_name}
                            onChange={ e =>  {setFirst_name(e.target.value)}}
                            className={ errorText && first_name==="" ? "is-invalid":""}
                        />
                    </Form.Group>
                    
                    <Form.Group className="col-md-6">
                        <label>Email</label>
                        <InputGroup hasValidation>
                            <Form.Control
                                required
                                autoComplete="new-password"
                                type="text"
                                placeholder="E-mail *"
                                value={email}
                                onChange={ e =>  {setEmail(e.target.value);}}
                                className={ errorText && invalid_email_pattern(email) ? "is-invalid":""}
                            />
                            <InputGroup.Text  className={ errorText &&  invalid_email_pattern(email) ? "invalid-inputgroup" : "valid-inputgroup"}> 
                                <FontAwesomeIcon icon={faEnvelope} />
                            </InputGroup.Text>        
                         </InputGroup>
                    </Form.Group>
                </Row>

            <Row className="mb-12">
                <Form.Group className="col-md-6">
                        <label>Departement</label>
                        <Form.Select
                            required
                            value={departement}
                            onChange={e => {
                                setdepartement(e.target.value);
                            }}
                            className={ errorText && departement==="null" ? "is-invalid":""}
                            name="categorie"
                        >
                            <option value={"null"} disabled selected>Departement</option>
                            <option value={"technique"}>{"membre technique"}</option>
                            <option value={"finance"}>{"membre de finance"}</option>
                            <option value={"juridique"}>{"membre juridique"}</option>
                        </Form.Select>
                    </Form.Group>

                    <Form.Group className="col-md-6">
                        <label>Poste</label>
                        <Form.Select
                            required
                            value={position}
                            onChange={e => {
                                setposition(e.target.value);
                            }}
                            className={ errorText && position==="null" ? "is-invalid":""}
                            name="categorie"
                        >
                            <option value={"null"} disabled selected>Poste</option>
                            <option value={"directeur"}>{"Directeur"}</option>
                        </Form.Select>
                    </Form.Group>
            </Row>
            
             <Row className="mb-12">
                <Form.Group className="col-md-6">
                    <label>CNSS</label>
                    <Form.Control
                        required
                        autoComplete="new-password"
                        type="text"
                        placeholder="CNSS *"
                        value={cnss}
                        onChange={ e =>  {setcnss(e.target.value)}}
                        className={ errorText && cnss==="" ? "is-invalid":""}
                    />
                </Form.Group>

                <Form.Group className="col-md-6">
                    <label>Téléphone</label>
                    <Form.Control
                        required
                        autoComplete="new-password"
                        type="text"
                        placeholder="Téléphone *"
                        value={phoneNumber}
                        onChange={ e =>  {setphoneNumber(e.target.value)}}
                        className={ errorText && phoneNumber==="" ? "is-invalid":""}
                    />
                </Form.Group>

                <Form.Group className="col-md-6">
                    <label>Pays</label>
                    <Form.Control
                        required
                        autoComplete="new-password"
                        type="text"
                        placeholder="Pays *"
                        value={country}
                        onChange={ e =>  {setcountry(e.target.value)}}
                        className={ errorText && country==="" ? "is-invalid":""}
                    />
                </Form.Group>

                <Form.Group className="col-md-6">
                    <label>Ville</label>
                    <Form.Control
                        required
                        autoComplete="new-password"
                        type="text"
                        placeholder="Ville *"
                        value={city}
                        onChange={ e =>  {setcity(e.target.value)}}
                        className={ errorText && city==="" ? "is-invalid":""}
                    />
                </Form.Group>

                <Form.Group className="col-md-6">
                    <label>Adresse</label>
                    <Form.Control
                        required
                        autoComplete="new-password"
                        type="text"
                        placeholder="Adresse *"
                        value={address}
                        onChange={ e =>  {setaddress(e.target.value)}}
                        className={ errorText && address==="" ? "is-invalid":""}
                    />
                </Form.Group>

                <Form.Group className="col-md-6">
                    <label>Code postal</label>
                    <Form.Control
                        required
                        autoComplete="new-password"
                        type="text"
                        placeholder="Code postal *"
                        value={postcode}
                        onChange={ e =>  {setpostcode(e.target.value)}}
                        className={ errorText && postcode==="" ? "is-invalid":""}
                    />
                </Form.Group>
            </Row> 
            <Row style={{display:'flex',justifyContent:"flex-end"}}>
                <Button variant="contained" startIcon={<AddIcon />} className={wait_submitting ? "submit_but disabled_button":"submit_but"} onClick={submit}>
                            Ajouter
                </Button>
            </Row>
            
        </div>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={wait_submitting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
      </div>
    );
    
}

export default Add_auditor
