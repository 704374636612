import './data_complements_dialog.css';

import React, { useState ,useEffect} from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import SendIcon from "@mui/icons-material/Send";
import Fab from "@mui/material/Fab";
import DownloadIcon from '@mui/icons-material/Download';
import {makeStyles} from '@material-ui/core/styles';
import Swal from 'sweetalert2'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import swal from 'sweetalert';

import ChatView_Service from '../../../chatView/chatView';
import { countmsg_non_lue_service_fournisseur, download_complement_docs, load_chat_service_fournisseur, send_complements_via_email, upload_complement_docs } from '../../../../../services/purchase_service';

function getFileName(event) {
    const name = event.target.files[0].name;
    const lastDot = name.lastIndexOf('.');
  
    const fileName = name.substring(0, lastDot);
    const ext = name.substring(lastDot + 1);
    return fileName+"."+ext;
  }


  const useStyles = makeStyles((theme) => ({
    button: { margin: theme.spacing(1),},
    root: {
      '& > *': {
        margin: theme.spacing(1),
      },
    },
    input: {
      display: 'none',
    },
  }));
  

function Data_complements({openDataComplements,handleCloseDataComplements,chosenSubmission,submissions,setsubmissions}) {

    let user = JSON.parse(localStorage.getItem('user-info'));
    const id_service = user.id;

    const classes = useStyles();


    const [ComplementAdmin, setComplementAdmin] = useState(null);
    const [ComplementTech, setComplementTech] = useState(null);
    const [ComplementFinance, setComplementFinance] = useState(null);

    const [adminFile,setAdminFile]=useState(null);
    const [techFile,setTechFile]=useState(null);
    const [finFile,setFinFile]=useState(null);

    const [adminFileLoaded,setAdminFileLoaded]=useState();
    const [techFileLoaded,setTechFileLoaded]=useState();
    const [finFileLoaded,setFinFileLoaded]=useState();

    const [id_supplier,setid_supplier]=useState(null);
    const [msg_non_lue,setMsg_non_lue]=useState(0);
    const [show_msg,setShow_msg]=useState(false);
    const [chats,setChats]=useState([]);

    const [waiting, setWaiting] = useState(false);


    function onCloseDiscussion(){
        setShow_msg(false); 
        setChats([]);
    }

    useEffect(()=>{
        countmsg_non_lue_service_fournisseur(id_service,chosenSubmission.id_fournisseur).then((response)=>{
            setMsg_non_lue(response.data);  
          }).catch((error) => {
            //console.log(error.response);
          });

          if(chosenSubmission.addOnAdministration!=null) setAdminFile(chosenSubmission.addOnAdministration);
          if(chosenSubmission.addOnTechnique != null) setTechFile(chosenSubmission.addOnTechnique);
          if(chosenSubmission.addOnFinance != null) setFinFile(chosenSubmission.addOnFinance);
    },[]);

    function viewService_Supplier_chat(id_supplier){ 
       setWaiting(true);
       setid_supplier(id_supplier);
       load_chat_service_fournisseur(id_service,id_supplier).then((response)=>{
            setChats(response.data);
            response.data.map((chat)=>{
                if(chat.id_personne != id_service ){
                    setMsg_non_lue(0);  
                    return;
                }
            });
            setShow_msg(true);
            setWaiting(false);
        })  
      }


    function upload_admin(event){
        let formData = new FormData();
        formData.append('AdminDocs',event.target.files[0]);
        formData.append('id_demande',chosenSubmission.id_demande);
        upload_complement_docs(formData).then((response)=>{
            setComplementAdmin(event.target.files[0]);
            setAdminFile(getFileName(event));
            setAdminFileLoaded(URL.createObjectURL(event.target.files[0]));
            const arr=[...submissions];
            chosenSubmission.addOnAdministration=getFileName(event);
            arr[openDataComplements]=chosenSubmission;
            setsubmissions(arr);
        }).catch((error) => {
            //console.log(error.response);
        });
    }
    
    function upload_tech(event){
        let formData = new FormData();
        formData.append('TechDocs',event.target.files[0]);
        formData.append('id_demande',chosenSubmission.id_demande);
        upload_complement_docs(formData).then((response)=>{
            setComplementTech(event.target.files[0]);
            setTechFile(getFileName(event));
            setTechFileLoaded(URL.createObjectURL(event.target.files[0]));
            const arr=[...submissions];
            chosenSubmission.addOnTechnique=getFileName(event);
            arr[openDataComplements]=chosenSubmission;
            setsubmissions(arr);
        }).catch((error) => {
            //console.log(error.response);
        });
    }

    function upload_fin(event){
        let formData = new FormData();
        formData.append('FinDocs',event.target.files[0]);
        formData.append('id_demande',chosenSubmission.id_demande);
        upload_complement_docs(formData).then((response)=>{
            setComplementFinance(event.target.files[0]);
            setFinFile(getFileName(event));
            setFinFileLoaded(URL.createObjectURL(event.target.files[0]));
            const arr=[...submissions];
            chosenSubmission.addOnFinance=getFileName(event);
            arr[openDataComplements]=chosenSubmission;
            setsubmissions(arr);
        }).catch((error) => {
            //console.log(error.response);
        });
    }


    function downloadSupplierDocsForProposal(choix){
        var fileLink = document.createElement('a');
        if(adminFileLoaded!="" && choix=="administrationComp"){
            fileLink.href = adminFileLoaded;
            fileLink.setAttribute('download', adminFile);
            document.body.appendChild(fileLink);
            fileLink.click();
        }else{
    
          if(techFileLoaded!="" && choix=="techniqueComp"){
              fileLink.href = techFileLoaded;
              fileLink.setAttribute('download', techFile);
              document.body.appendChild(fileLink);
              fileLink.click();
          }else{
                if(finFileLoaded!="" && choix=="financeComp"){
                    fileLink.href = finFileLoaded;
                    fileLink.setAttribute('download', finFile);
                    document.body.appendChild(fileLink);
                    fileLink.click();
                }else{
                    const formData = new FormData();
                    formData.append('id_demande',chosenSubmission.id_demande);
                    formData.append('choix',choix);
                    download_complement_docs(formData).then((response) => {
                        var fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        var fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download',chosenSubmission.id_demande+"_"+choix+".pdf");
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    });
                }
            }
        }
      }

      


    function SendComplement() {
        setWaiting(true);
        let formData = new FormData();
        if(ComplementAdmin != null) formData.append("compAdmin", ComplementAdmin);
        if(ComplementTech != null) formData.append("compTech", ComplementTech);
        if(ComplementFinance != null) formData.append("compFinance", ComplementFinance);
        formData.append("id_demande", chosenSubmission.id_demande);
        send_complements_via_email(formData).then((response) => {
            setWaiting(false);
            swal("le fournisseur sera bientôt notifié !", {
                icon: "success",
                buttons: true,
              }).then((willDelete) => {
                  if (willDelete) { 
              }}) 
              handleCloseDataComplements();
        });
    }




    return (
        <>  
        <Dialog className="data_complements_container update_porpose" fullWidth={true} maxWidth={"md"} open={openDataComplements!==null?true:false} onClose={()=>handleCloseDataComplements()} >
            <DialogTitle id="form-dialog-title">Complément de dossier</DialogTitle>
            <DialogContent>
              <div>
                <div className='inputs_container'>
                    <div className='documentsDownload'>
                        <label htmlFor="DocAdmin" style={{marginRight:'6px'}}> 
                                <Fab size="small" component='span' ><CloudUploadIcon className="my_icon" /></Fab>
                                <input accept="application/pdf" className={classes.input} name="DocAdmin" id="DocAdmin" multiple type="file" onChange={(e)=>upload_admin(e)} />
                        </label>
                        {
                            adminFile!=null
                            ?<Fab size="small" onClick={()=>downloadSupplierDocsForProposal("addOnAdministration")} ><DownloadIcon className="my_icon"  /> </Fab>
                            :<Fab size="small" className="disabled_button" ><DownloadIcon className="my_icon" /></Fab>
                        
                        }
                        <span className='documentsTitle'>Docs Administratif</span>
                    </div>

                    <div className='documentsDownload'>
                        <label htmlFor="DocTech" style={{marginRight:'6px'}}> 
                                <Fab size="small" component='span' ><CloudUploadIcon className="my_icon" /></Fab>
                                <input accept="application/pdf" className={classes.input} name="DocTech" id="DocTech" multiple type="file" onChange={(e)=>upload_tech(e)} />
                        </label>
                        {
                            techFile!=null
                            ?<Fab size="small" onClick={()=>downloadSupplierDocsForProposal("addOnTechnique")} ><DownloadIcon className="my_icon" /> </Fab>
                            :<Fab size="small" className="disabled_button" ><DownloadIcon className="my_icon" /></Fab>
                        
                        }
                        <span className='documentsTitle'>Docs Technique</span>
                    </div>

                    <div className='documentsDownload'>
                        <label htmlFor="DocFinance" style={{marginRight:'6px'}}> 
                            <Fab size="small" component='span' ><CloudUploadIcon className="my_icon" /></Fab>
                            <input accept="application/pdf" className={classes.input} name="DocFinance" id="DocFinance" multiple type="file" onChange={(e)=>upload_fin(e)} />
                        </label>
                        {
                            finFile!=null
                            ?<Fab size="small" onClick={()=>downloadSupplierDocsForProposal("addOnFinance")} ><DownloadIcon className="my_icon" /> </Fab>
                            :<Fab size="small" className="disabled_button" ><DownloadIcon className="my_icon" /></Fab>
                        
                        }
                        <span className='documentsTitle'>Docs Financier </span>
                    </div>
                    
                </div>
                <div className="dialogBottom">
                    <div>
                       <Button className="discussion_but submit_but" onClick={() => viewService_Supplier_chat(chosenSubmission.id_fournisseur)} variant="contained"> 
                            Discussion 
                            {
                            msg_non_lue != 0 
                                ?<div id="text_non_lue"> {msg_non_lue} </div>
                                :<></>
                            }
                        </Button>
                        <span className="divider_choices" >OU</span>
                        <Button onClick={SendComplement}
                            variant="contained"
                            className={adminFile===null && techFile===null && finFile===null ?"send_but submit_but disabled_button":"send_but submit_but"}
                            endIcon={<SendIcon className="my_icon"/>} >
                            Envoyer
                        </Button>  
                    </div>  
                </div>
                
                <br />
            </div>
        </DialogContent>
        
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={waiting}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        
        {   
            chats!=[] && show_msg==true
            ?<ChatView_Service open={show_msg} handleClose={onCloseDiscussion} chats={chats} id_service={id_service} id_supplier={id_supplier} />
            :<></>
        }
    </Dialog>
        

        
    </>
    );
}

export default Data_complements;
