import './count_offers_totalAmount.css';

import React, { useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import {makeStyles} from '@material-ui/core/styles';
import Widget_count_elements from '../widget_count_elements/widget_count_elements'

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
});

const columns = [
    { id: 'Objet', label: "Objet d'appel", minWidth: 0 },
    { id: 'direction', label: "Direction", minWidth: 0 },
    { id: 'categorie', label: "Famille d'achat", minWidth: 0 },
    { id: 'amount', label: "Montant", minWidth: 0 },
  ];
  

function Offers({offers,getCategorieById}){
    
    return(
        <>
        {
            offers.map((offer,index1) => (
                <TableRow key={offer.id_appel+"col"+index1}>
                    <TableCell align="left">{offer.object}</TableCell>
                    <TableCell align="left">{offer.direction}</TableCell>
                    <TableCell align="left">{getCategorieById(offer.familleAchat)}</TableCell>
                    <TableCell align="center">{offer.estimation+" MAD"}
                    </TableCell>
                </TableRow>                
             ))
        }
        </>
    );
}


function Count_offers_totalAmount({categories,data}) {

    const classes = useStyles();
    const [show_elemnts,setShowing_elements] = useState(false);


    function getCategorieById(id){
        var categorie="";
        categories.map((catg)=>{
          if(catg.id_categorie === id) categorie = catg.nom;
        })
        return categorie;
    }



//////////////////////
/////////////////////////////////////////////////////////
///////////////////////////////////

  return (
    <>
      {
        show_elemnts!==true
          ?<Widget_count_elements setShowing_elements={setShowing_elements} elements_number={parseFloat((data.total_amount).toFixed(2))+" MAD"} fontColor="rgb(59 174 130)" progress={data.progress} />
          :<TableContainer className="offers_totalAmount_widget" component={Paper}  onDoubleClick={() => setShowing_elements(false)} style={{cursor:'pointer'}} onTouchStartCapture={(e)=>{e.stopPropagation();setShowing_elements(false)}} >
              <Table className={classes.table} aria-label="sticky table">
                  <TableHead>
                  <TableRow>
                      {
                          columns.map((column) => (
                              <TableCell key={column.id} align="left">{column.label}</TableCell>
                          ))
                      }
                  </TableRow>
                  </TableHead>
                  <TableBody>
                  {
                      data.last_offers.length!=0
                      ?
                      <>
                          <Offers offers={data.last_offers} getCategorieById={getCategorieById} />
                      </>
                              
                      :<div style={{margin:"10px",fontSize:".8rem",width:'100%', textAlign:"center",alignContent:"center"}}>Aucun offre disponible</div>
                  }
                  </TableBody> 
              </Table>
          </TableContainer>
      }
    </>
  );
}

export default React.memo(Count_offers_totalAmount);
